/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
// import EditTypedDealDefForm from "../../../components/EditTypedDeal/edit-typed-deal-def-form";
import EditTypedDealDefForm from "../../../components/EditTypedDeal/edit-typed-deal-def-form-new";
import url from "../../../config/axios";
import {
  editDealDefThunk,
  getFormCompatibleDataFromResponse,
  replaceDealStateAction,
  resetOpStateAction,
  selectEditDealDefState,
} from "../../../redux/slices/editDealDefSlice";
import { TypedDealsTypes } from "../constants/typed-deals.constants";
export default function EditTypedDealsDefinition() {
  const [dealState, setDealState] = useState({
    loading: true,
    deal: null,
    error: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const dealInfo = useSelector(selectEditDealDefState);
  const { inProgress, error, success } = dealInfo;
  useEffect(() => {
    // dispatch(resetDealStateAction());
    const dealID = new URLSearchParams(history.location.search).get("_id");
    url
      .get(`/v1/typed-deals?_id=${dealID}`)
      .then((res) => {
        const deal = res.data?.data?.deal;
        console.log("API response", deal)
        try {
          dispatch(
            replaceDealStateAction(getFormCompatibleDataFromResponse(deal))
          );
          setDealState({ deal, error: null, loading: false });
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {
        setDealState({
          deal: null,
          error: "Unable to populate the deal",
          loading: false,
        });
      });
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12">
            <form className="row" onSubmit={(e) => e.preventDefault()}>
              {dealState.loading ? (
                "Populating data..."
              ) : (
                <EditTypedDealDefForm />
              )}
            </form>
          </div>
          <div className="d-flex justify-content-end border-0">
            {dealState?.loading ||
            [TypedDealsTypes.BOGO, TypedDealsTypes.TIERED].includes(
              dealInfo.dealType
            ) ? null : (
              <button
                type="button"
                onClick={() => {
                  dispatch(editDealDefThunk());
                }}
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382" }}
                className="btn  btn-cannaby">
                {!inProgress ? "Update Deal" : "Updating Deal..."}
              </button>
            )}
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Deal updated Successfully"}
          onClose={() => {
            dispatch(resetOpStateAction());
            history.push("/typed-deals");
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
      {dealState.error ? (
        <SimpleModal
          severity={"error"}
          highlights={dealState.error}
          onClose={() => {
            history.push("/typed-deals");
          }}
        />
      ) : null}
    </div>
  );
}
