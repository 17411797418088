import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSinglePropertyAction,
  createGoogleAnalyticsThunk,
  resetOpStateAction,
  selectAddAnalyticsState,
  selectValidationErrors,
  replaceAnalyticsStateAction,
  getFormCompatibleDataFromResponse,
} from "../../../redux/slices/addGoogleAnalyticsSlice";
import InputPrimary from "../../../atoms/InputPrimary";
import InputAreaPrimary from "../../../atoms/InputAreaPrimary/Index";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import { useEffect } from "react";
import url from "../../../config/axios";

const GoogleAnalytics = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectAddAnalyticsState);
  const { googleAnalyticsID, title, description } = state;
  const analyticsInfo = useSelector(selectAddAnalyticsState);
  const { inProgress, error, success } = analyticsInfo;
  const adminData = JSON.parse(localStorage.getItem("Admin"));
  const formValidationErrors = useSelector(selectValidationErrors);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };
  const [analyticsData, setAnalyticsData] = React.useState({});
  useEffect(() => {
    url
      .get(`/v1/google-analytics?consumerID=${adminData[0]?.consumerID}`)
      .then((res) => {
        const analyticsData = res.data?.data;
        setAnalyticsData(analyticsData);
        try {
          dispatch(
            replaceAnalyticsStateAction(
              getFormCompatibleDataFromResponse(analyticsData)
            )
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6 mb-4">
          <InputPrimary
            hasBorder={true}
            value={googleAnalyticsID}
            name="googleAnalyticsID"
            label={"Google Analytics ID*"}
            placeholder="Google Analytics ID"
            onChange={(e) =>
              handleChange(e.target.name, String(e.target.value))
            }
          />
          <span className="validation-help">
            {formValidationErrors?.googleAnalyticsID
              ? formValidationErrors.googleAnalyticsID
              : ""}
          </span>
        </div>
        <div className="col-md-6 mb-4">
          <InputPrimary
            hasBorder={true}
            value={title}
            name="title"
            label={"Title*"}
            placeholder="Title"
            onChange={(e) =>
              handleChange(e.target.name, String(e.target.value))
            }
          />
          <span className="validation-help">
            {formValidationErrors?.title ? formValidationErrors.title : ""}
          </span>
        </div>
        <div className="col-md-6 mb-4">
          <InputAreaPrimary
            hasBorder={true}
            value={description}
            name="description"
            label={"Description*"}
            placeholder="Description"
            onChange={(e) =>
              handleChange(e.target.name, String(e.target.value))
            }
          />
          <span className="validation-help">
            {formValidationErrors?.description
              ? formValidationErrors.description
              : ""}
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-end border-0">
        <button
          type="button"
          onClick={() => {
            dispatch(createGoogleAnalyticsThunk());
          }}
          disabled={inProgress}
          style={{ backgroundColor: "#FFA382" }}
          className="btn  btn-cannaby"
        >
          {!inProgress && !analyticsData
            ? "Create Google Analytics"
            : "Update Google Analytics"}
        </button>
      </div>

      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={
            analyticsData
              ? "Google analytics updated successfully"
              : "Google analytics created successfully"
          }
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
    </Fragment>
  );
};

export default GoogleAnalytics;
