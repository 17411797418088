import { CloudOff, CompassCalibration } from "@mui/icons-material";
import { Avatar, Paper } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSessionInfo,
  selectCurrentSessionMeta,
  setActiveSessionThunk,
} from "../../redux/slices/chatSessionSlice";
import "./eachsessioncard.styles.css";

const EachSessionCard = ({ data, boxShadow }) => {
  const unseenCount = +parseInt(
    (isNaN(data?.unseenCountForAdmin) ? 0 : data?.unseenCountForAdmin) + ""
  );
  const { initialLoading } = useSelector(selectCurrentSessionMeta) ?? {};
  const dispatch = useDispatch();
  const currentSessionInfo = useSelector(selectCurrentSessionInfo) ?? {};
  const getDisplayMessage = (data) => {
    const contentGroup = data?.contentGroup ?? [];
    if (!contentGroup?.length) {
      return "Latest message will appear here";
    }
    let toShow = "";
    if (contentGroup[0]?.contentType === "TEXT") {
      toShow = contentGroup[0]?.content ?? "Latest message will appear here";
    } else {
      toShow =
        contentGroup[0]?.displayName ?? "Latest message will appear here";
    }
    return toShow.length > 30 ? toShow.substring(0, 30) + "..." : toShow;
  };
  return (
    <div
      className={`${boxShadow ? "neuro" : ""} ${
        data._id === currentSessionInfo._id || !data?.isActive
          ? ""
          : "eachsessioncard"
      } `}
      onClick={() => dispatch(setActiveSessionThunk(data._id))}
      style={{
        display: "flex",
        gap: "0.5em",
        flexWrap: "wrap",
        padding: "0em 2em",
        cursor: "pointer",
        borderRadius: "14px",
        opacity: !data?.isActive ? 0.6 : 1,
        backgroundColor: data._id === currentSessionInfo._id ? "#ffa382" : "",
      }}>
      {/* avatar container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.8em",
        }}>
        <Avatar
          alt={data?.user?.name ?? "Anonymous"}
          component={Paper}
          elevation={5}
          src={data?.user?.image}
          sx={{ width: 50, height: 50, backgroundColor: "#18253c" }}>
          {(data?.user?.name ?? "Anonymous").trim()[0]}
        </Avatar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          flexGrow: 1,
        }}>
        {/**unseen container */}
        <div
          style={{
            display: "flex",
            width: "55px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          {!data?.isActive && (
            <CloudOff
              sx={{
                color: !(data._id !== currentSessionInfo._id)
                  ? "white"
                  : "black",
              }}
            />
          )}
          {data._id === currentSessionInfo._id &&
            initialLoading &&
            data?.isActive && (
              <CompassCalibration
                className="session-focus-progress"
                sx={{
                  color: "white",
                }}
              />
            )}
          {unseenCount > 0 &&
            data._id !== currentSessionInfo._id &&
            data?.isActive && (
              <div
                style={{
                  backgroundColor: "#ffa382",
                  height: "30px",
                  width: "30px",
                  padding: "0.5em 0.5em",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <span
                  style={{
                    fontSize: "90%",
                    fontWeight: "bold",
                    color: "white",
                  }}>
                  {unseenCount < 9 ? unseenCount : "9+"}
                </span>
              </div>
            )}
        </div>
        {/**info container */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            flexWrap: "wrap",
          }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexWrap: "nowrap",
            }}>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                height: "auto",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}>
              <span
                style={{
                  fontSize: "105%",
                  fontWeight: "400",
                  color:
                    data._id === currentSessionInfo._id ? "white" : "black",
                }}>
                {data?.user?.name ?? "Anonymous"}
              </span>
              <span
                style={{
                  fontSize: "90%",
                  color:
                    data._id === currentSessionInfo._id ? "white" : "black",
                }}>
                {moment(
                  data?.updatedAt
                    ? new Date(data.updatedAt).getTime()
                    : new Date().getTime()
                ).fromNow()}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              height: "auto",
            }}>
            <span
              style={{
                fontSize: "90%",
                color: data._id === currentSessionInfo._id ? "white" : "black",
              }}>
              {getDisplayMessage(data?.message ?? {})}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachSessionCard;
