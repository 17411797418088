import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import { initialEachTier } from "../../pages/TypedDeals/constants/form-initiator.constants";
import {
  TypedDealsAmountTypeSelectors,
  TypedDealsBuyMinimumTypeSelectors,
  TypedTieredBundledDealsAppliesToSelectors,
} from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import {
  TypedDealsTypes,
  TypedTieredBundledDealsAppliesTo,
} from "../../pages/TypedDeals/constants/typed-deals.constants";
import {
  changeSinglePropertyAction,
  selectTieredBubdlesDealInfoState,
  selectTieredBundledTypeValidationErrors,
} from "../../redux/slices/addDealSlice";
import {
  changeEditSinglePropertyAction,
  selectEditTieredBundledDealDefState,
  selectEditTieredBundledTypeValidationErrors,
} from "../../redux/slices/editDealDefSlice";
import DropDownProductPicker from "../common/DropDownProductPicker";
import SelectPrimary from "../../atoms/SelectPrimary";
import DropDownCategoryPicker from "../common/DropDownCategoryPicker";
import DropDownManufacturerPicker from "../common/DropDownManfacturerPicker";
import { Tooltip } from "@mui/material";
import { AccountTree } from "@mui/icons-material";
import { useState } from "react";
import TypedDealsProductSelectionDialog from "./typed-deals-product-selection-tree";

const TieredBundledDealFormComponent = ({ applyToAllOutlets, editMode }) => {
  const dispatch = useDispatch();
  const dealInfo = useSelector(
    editMode
      ? selectEditTieredBundledDealDefState
      : selectTieredBubdlesDealInfoState
  );
  const {
    buyMinimumType,
    issueAmountType,
    tiers,
    appliesTo,
    chosenBrands,
    chosenCategories,
    chosenProducts,
  } = dealInfo;
  const [previousAppliesTo, setPreviousAppliesTo] = useState(appliesTo);
  const formValidationErrors = useSelector(
    editMode
      ? selectEditTieredBundledTypeValidationErrors
      : selectTieredBundledTypeValidationErrors
  );
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      if (editMode) {
        dispatch(
          changeEditSinglePropertyAction({
            dealType: TypedDealsTypes.TIERED_BUNDLED,
            propName,
            propValue,
          })
        );
      }
      dispatch(
        changeSinglePropertyAction({
          dealType: TypedDealsTypes.TIERED_BUNDLED,
          propName,
          propValue,
        })
      );
    }
  };
  const addTier = () => {
    handleChange("tiers", {
      ...tiers,
      [Object.values(tiers).length]: initialEachTier,
    });
  };
  const removeTier = (index) => {
    if (!Number.isNaN(index)) {
      handleChange("tiers", {
        ...Object.values(tiers).filter((item, i) => i !== index),
      });
    }
  };
  /**
   *
   * @param {number} index array index of that tier
   * @param {string} propName name of the input field
   * @param { any } propValue value of the input field
   */
  const changeTieredProp = (index, propName, propValue) => {
    if (tiers[index]) {
      handleChange("tiers", {
        ...Object.values(tiers).map((item, key) => {
          if (+key === +index) {
            return {
              ...item,
              [propName]: propValue,
            };
          }
          return item;
        }),
      });
    }
  };
  const [treeViewOpen, setTreeViewOpen] = useState(false);
  useEffect(
    () => {
      if (!editMode) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
      }
    },
    //eslint-disable-next-line
    [applyToAllOutlets]
  );
  useEffect(
    () => {
      if (appliesTo !== previousAppliesTo) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
      }
      setPreviousAppliesTo(appliesTo);
    },
    //eslint-disable-next-line
    [appliesTo, previousAppliesTo]
  );

  return (
    <Fragment>
      <DealLabelWrapper>
        <label>TIERED BUNDLED RULES</label>
      </DealLabelWrapper>
      <div className="mb-4">
        <SelectPrimary
          value={appliesTo}
          name="appliesTo"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          label={"Applies To"}>
          {Object.values(TypedTieredBundledDealsAppliesToSelectors).map(
            (item, index) => (
              <option
                value={item.value}
                key={`selected-applies-to-type-${index}`}>
                {item.displayName}
              </option>
            )
          )}
        </SelectPrimary>
      </div>
      <div className="d-flex mb-4">
        <div style={{ width: "95%" }}>
          {appliesTo === TypedTieredBundledDealsAppliesTo.CATEGORIES ? (
            <DropDownCategoryPicker
              label={"Pick Categories*"}
              selected={chosenCategories}
              setSelected={(value) => handleChange("chosenCategories", value)}
              referLocalData={applyToAllOutlets ? false : true}
            />
          ) : null}
          {appliesTo === TypedTieredBundledDealsAppliesTo.PRODUCTS ? (
            <DropDownProductPicker
              label={"Pick Products"}
              selected={chosenProducts}
              setSelected={(value) => handleChange("chosenProducts", value)}
              referLocalInventory={applyToAllOutlets ? false : true}
            />
          ) : null}
          {appliesTo === TypedTieredBundledDealsAppliesTo.BRANDS ? (
            <DropDownManufacturerPicker
              label={"Pick Brands"}
              selected={chosenBrands}
              setSelected={(value) => handleChange("chosenBrands", value)}
              referLocalData={applyToAllOutlets ? false : true}
            />
          ) : null}
          <span className="validation-help">
            {formValidationErrors?.appliesTo
              ? formValidationErrors.appliesTo
              : ""}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "auto",
            marginLeft: "10px",
            marginTop: "35px",
          }}>
          <Tooltip title="View Product Selection Tree">
            <AccountTree
              style={{ cursor: "pointer" }}
              onClick={() => {
                setTreeViewOpen(true);
              }}
            />
          </Tooltip>
        </div>
        {treeViewOpen ? (
          <TypedDealsProductSelectionDialog
            dealType={TypedDealsTypes.TIERED_BUNDLED}
            editMode={editMode}
            dealSelector={
              editMode
                ? selectEditTieredBundledDealDefState
                : selectTieredBubdlesDealInfoState
            }
            onClose={() => setTreeViewOpen(false)}
          />
        ) : null}
      </div>
      <FormControl>
        <LabelWrapper>Consider Product on the basis of:</LabelWrapper>
        <RadioGroup
          row
          value={buyMinimumType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          aria-labelledby="buyMinimum"
          name="buyMinimumType">
          {Object.values(TypedDealsBuyMinimumTypeSelectors).map(
            (item, index) => (
              <FormControlLabel
                key={`selected-buy-minimum-type-${index}`}
                value={item.value}
                control={<Radio />}
                label={`${item.displayName} (${item.indicator})`}
              />
            )
          )}
        </RadioGroup>
      </FormControl>
      <FormControl className="mt-2">
        <LabelWrapper>Discount type:</LabelWrapper>
        <RadioGroup
          row
          value={issueAmountType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          aria-labelledby="issueAmount"
          name="issueAmountType">
          {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
            <FormControlLabel
              key={`selected-buy-minimum-type-${index}`}
              value={item.value}
              control={<Radio />}
              label={`${item.displayName} (${item.indicator})`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {Object.values(tiers).length ? (
        <Fragment>
          {Object.values(tiers).map((item, index) => {
            return (
              <TierWrapper key={`selected-tier-${index}`}>
                <TierContentHolderWrapper>
                  <TieredContentSection>
                    <div className="tiered-content-input">
                      <InputPrimary
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        onChange={(e) =>
                          changeTieredProp(index, e.target.name, e.target.value)
                        }
                        name="buyMinimum"
                        value={item.buyMinimum}
                        label={"Buy at least *"}
                        type="number"
                      />
                    </div>
                    <div className="tiered-content-selector">
                      <InputPrimary
                        style={{
                          borderLeft: "none",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        disabled
                        value={
                          TypedDealsBuyMinimumTypeSelectors[buyMinimumType]
                            .indicator
                        }
                        blankLabel={true}
                        type="text"
                      />
                    </div>
                  </TieredContentSection>

                  <TieredContentSection>
                    <div className="tiered-content-input">
                      <InputPrimary
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        name="getOffNumericAmount"
                        value={item.getOffNumericAmount}
                        onChange={(e) =>
                          changeTieredProp(index, e.target.name, e.target.value)
                        }
                        label={"For *"}
                        type="number"
                      />
                    </div>
                    <div className="tiered-content-selector">
                      <InputPrimary
                        style={{
                          borderLeft: "none",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        disabled
                        value={`${TypedDealsAmountTypeSelectors[issueAmountType].indicator} Off`}
                        blankLabel={true}
                        type="text"
                      />
                    </div>
                  </TieredContentSection>
                </TierContentHolderWrapper>
                <div className="col-md-1 filters-bar m-1">
                  <button
                    onClick={() => {
                      removeTier(index);
                    }}
                    className="btn btn-primary border-0"
                    style={{
                      color: "#FFA382",
                      backgroundColor: "white",
                    }}>
                    <i className="fas fa-minus-circle"></i>
                    Remove
                  </button>
                </div>
              </TierWrapper>
            );
          })}
        </Fragment>
      ) : (
        <div
          className="mt-2 mb-4 p-3 rounded"
          style={{
            backgroundColor: "#ddebeb",
          }}>
          No tiers have been added yet!
        </div>
      )}
      <span className="validation-help">
        {formValidationErrors?.tiersError
          ? formValidationErrors.tiersError
          : ""}
      </span>
      <div className="mt-2 mb-4">
        <button
          onClick={() => addTier()}
          className="btn btn-primary border-0"
          style={{
            backgroundColor: "#FFA382",
            color: "white",
          }}>
          <i className="fas fa-plus-circle"></i>
          Add Tier*
        </button>
      </div>
    </Fragment>
  );
};
export default TieredBundledDealFormComponent;

const DealLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;
const LabelWrapper = styled.label`
  color: #747474;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  display: block;
  text-transform: capitalize;
`;

const TierWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 2em;
`;

const TierContentHolderWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 2%;
`;
const TieredContentSection = styled.div`
  display: flex;
  width: 48%;
  flex-direction: row;

  .tiered-content-input {
    width: 70%;
  }
  .tiered-content-selector {
    width: 30%;
  }
`;
