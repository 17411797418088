import React, { useRef, useState } from "react";
import PrimaryDialog from "../../../atoms/Dialogs/PrimaryDialog";
import { Paper } from "@material-ui/core";
import styled from "@emotion/styled";
import { Dialog, useTheme } from "@mui/material";
import JoyButton from "../../../atoms/Buttons/JoyButton";
import CTDWelcome from "./create-typed-deal-steps/CTDWelcome";

function AddTypedDealByStep({ open, setOpen }) {
    // Constants
    const TOTAL_STEPS = 3;

    const theme = useTheme();
    const [currentStep, setCurrentStep] = useState(0);
    const lastSelectedStep = useRef(-1);
    const [atWelcomeScreen, setAtWelcomeScreen] = useState(true);

    const handleClickStep = (stepIndex) => {
        lastSelectedStep.current = currentStep
        setCurrentStep(stepIndex)
    }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "90vw",
          maxWidth: "unset",
          height: "100vh",
        },
      }}
    >
      <Wrapper>
        <div className="cd-content">
            {atWelcomeScreen && <CTDWelcome /> }
        </div>
        <div className="cd-footer">
            {!atWelcomeScreen && <StepperFlow theme={theme}>
                {Array(3).fill(3).map((item, i) => (
                    <div 
                        className={`dot ${currentStep === i && 'selected'}`} 
                        onClick={() => handleClickStep(i)}>
                    </div>)
                )}
            </StepperFlow>}
            <div className="cd-footer-action-btns">
                <JoyButton>Next</JoyButton>
                <JoyButton color={theme.palette.action.danger}>Discard</JoyButton>
            </div>
        </div>
      </Wrapper>
    </Dialog>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 800px;
  min-height: 70%;
  gap: 12px;
  .cd-content {
    flex: 1;
  }
  .cd-footer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    .cd-footer-action-btns {
        display: flex;
        gap: 8px;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
  }
`;

const StepperFlow = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    .dot {
        cursor: pointer;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.23) inset;
        transition: box-shadow .3s ease-out;
        &.selected {
            background-color: ${props => props.theme.palette.secondary.main};
        }
        &:hover {
            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.23) inset;
        }
    }
`

export default AddTypedDealByStep;
