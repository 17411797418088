import React from 'react'
import CustomPageEditor from './CustomPageEditor'
import { useParams } from 'react-router-dom'

function EditCustomPage() {
    const { id } = useParams();
  return (
    <CustomPageEditor id={id} />
  )
}

export default EditCustomPage