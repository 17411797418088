import Backdrop from "@mui/material/Backdrop";
import { LinearProgress } from "@mui/material";

export const CustomBackdrop = () => {
  return (
    <Backdrop sx={{ color: "#fff" }} open={true}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <div
          style={{
            width: "50vw",
          }}>
          <LinearProgress color="inherit" />
        </div>
        Switching...
      </div>
    </Backdrop>
  );
};
