import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMyOutlets, switchOutletThunk } from '../../redux/slices/authSlice';
import PopupMenu from '../../atoms/Popup/PopupMenu';
import styled from '@emotion/styled';
import { toggleChooserOpenStatus } from '../../redux/slices/ocSwitchSlice';
import OutletAvatarPlaceholderImg from '../../assets/outlet-avatar.png'

function OutletChooserNew({ anchorEl, setAnchorEl }) {
    const dispatch = useDispatch();
    const outlets = useSelector(selectMyOutlets);
    const [menuItems, setMenuItems] = useState([]);
    const [adminData, setAdmninData] = useState(null);
    
    useEffect(() => {
        try {
          const admin = JSON.parse(localStorage.getItem("Admin")) || null;
          setAdmninData(
            Array.isArray(admin) && admin?.length > 0 ? admin[0] : null
          );
        } catch (e) {
          //do nothing
          console.log("No admin data")
        }
      }, []);

    useEffect(() => {
        if (outlets && adminData) {
            const outherOutlets = outlets.filter(outlet => outlet.outletChainID !== adminData?.outletChainID)
            setMenuItems(outherOutlets.map(outlet => ({
                label: outlet.outletName,
                icon: <OutletAvatar src={outlet.adminImage ?? OutletAvatarPlaceholderImg} />,
                onClick: () => {
                    dispatch(switchOutletThunk(outlet.outletChainID));
                    dispatch(toggleChooserOpenStatus());
                }
            })))
        }
    }, [outlets, adminData])

  return (
    <PopupMenu 
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuItems={menuItems}
    />
  )
}

const OutletAvatar = styled.img`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
`


export default OutletChooserNew