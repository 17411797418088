export const initialPurchasedTypes = {
  points: 0,
  purchaseAmount: 1,
};

export const popOverRegistration = {
  title: "Registration",
  content:
    '"Points earned on registration" typically refers to a system in which users receive a certain number of points for signing up on our application',
};

export const popOverRegistrationToggle = {
  title: "Registration-Toggle",
  content:
    "The admin can use the disable button to prevent entering a value in this specific section. Once disabled, users will not receive any points in this section",
};

export const popOverOrder = {
  title: "Order",
  content:
    '"Points earned on first order" trefers to a system in which users will receive a certain number of points for placing their first order on the application.',
};

export const popOverPurchased = {
  title: "Purchased-Rules",
  content: `The admin can create different tiers with specific amounts of points that users can earn based on the number of orders they purchase. For example, the admin can create the following tiers:`,

  subContent:
    "For a purchase amount of $100, the user will receive 10 points.For a purchase amount of $500, the user will receive 20 points.For a purchase amount of $1000, the user will receive 50 points.This means that users will earn 10 points if they make a purchase of $100, 20 points if they make a purchase of $500",
};

export const popOverPoints = {
  title: "Points-Per-Dollar",
  content:
    '"Points per dollar" is a system in which users earn a certain number of points for every dollar they spend on a platform or service. These points can be used to redeem rewards or discounts, or to access additional benefits and features."',
  subContent:
    '"Points received per dollar" is a system in which users earn a certain number of points for every dollar they spend on a platform or service. These points can be used to redeem discounts to get access discount',
};

export const popOverMaximumDeduction = {
  title: "Maxiumum-Deduction",
  content:
    '"Maximum deduction" refers to the limit on the amount of points or rewards that can be redeemed on a single order. For example, if a user has 30 points worth $30 but the maximum deduction is set to $5, the user will only be able to redeem $5 worth of points on a single order. The admin can choose to disable this limitation if desired. This means that if the maximum deduction is set to $5, the user can only redeem up to $5 worth of points on a single order, even if they have more points available The admin has the option to disable this limitation if they do not want to have any restrictions on the amount of points that can be redeemed per order.',
};

export const popOverMaximumDeductionPercentage = {
  title: "Maxiumum-Deduction-Percentage",
  content:
    '"Maximum Deduction (% of subtotal)" is the maximum percentage of the order subtotal that can be deducted when redeeming points or rewards. For instance, if the percentage is set to 10%, and a user has points worth $500, and the subtotal of their order is $600, they can only redeem up to 10% of $600, which is $60.',
  subContent:
    "The admin can choose to disable this field if they dont want to restrict the percentage of the order subtotal that can be deducted.",
};

export const popOverMaximumSpendable = {
  title: "Maxiumum-Spendable",
  content:
    '"Maximum Spendable Points" refers to the limit on the number of points that a user can use for a single order. For example, if the limit is set to 10 points, and a user wants to redeem 20 points worth $10, they will only be allowed to use 10 points. This restriction ensures that users can only redeem up to the maximum number of points allowed per order. The admin can choose to disable this field if they dont want to restrict the maximum spendable points that can be deducted.',
};

export const popOverMainLoyalty = {
  title: "Loyalty",
  content:
    "Implementing a loyalty points system based on downtime can serve as an incentive for users to make purchases, particularly during periods when an outlet is experiencing downtime. By awarding loyalty points to users based on the duration of downtime, businesses can motivate customers to engage with their products or services, making the most of their time and potentially increasing sales.",
};
export const popOverSpecializedDay = {
  title: "Loyalty-Specialized-Day",
  content:
    "Specialized time refers to a feature that allows users to add time in bulk for multiple days at once, instead of adding it individually day by day.",
};
export const popOverDeals = {
  title: "Deals",
  content:
    'If the "Apply to all outlets" option is unchecked, only products/categories/brands from the current outlet will be there to choose from otherwise they will be populated from global entries.',
};
