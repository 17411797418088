import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material'

function JoyButton({ children, color, ...props }) {
    const theme = useTheme();
  return (
    <Button color={color} theme={theme} {...props }>
        {children}
    </Button>
  )
}

const Button = styled.button`
    padding: 6px 16px;
    border: none;
    outline: none;
    background-color: ${props => props.color ?? props.theme.palette.secondary.main};
    color: white;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.palette.secondary.darkBlue};
    }
`

export default JoyButton