/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import url from "../../../config/axios";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";
import {
  editCouponThunk,
  getFormCompatibleDataFromResponse,
  replaceCouponStateAction,
  resetOpStateAction,
  selectEditCouponState,
} from "../../../redux/slices/editCouponSlice";
import EditTypedCouponForm from "../../../components/EditTypedCoupon/edit-typed-coupon-form";
export default function EditTypedCouponPage() {
  const outlets = useSelector(selectMyOutlets);
  const [couponState, setCouponState] = useState({
    loading: true,
    coupon: null,
    error: null,
  });
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectEditCouponState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const couponInfo = useSelector(selectEditCouponState);
  const { inProgress, error, success } = couponInfo;
  useEffect(() => {
    const couponID = new URLSearchParams(history.location.search).get("_id");
    url
      .get(`/v1/typed-coupons?_id=${couponID}`)
      .then((res) => {
        const coupon = res.data?.data?.coupon;
        setCouponState({ coupon, error: null, loading: false });
        try {
          dispatch(
            replaceCouponStateAction(getFormCompatibleDataFromResponse(coupon))
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {
        setCouponState({
          coupon: null,
          error: "Unable to populate the coupon",
          loading: false,
        });
      });
  }, []);
  return (
    <div>
      <div className="row" style={{ borderRadius: "13px" }}>
        <div className="col-md-12">
          <div className="card" id="nopad1">
            <div className="col-md-12">
              <form className="row" onSubmit={(e) => e.preventDefault()}>
                <div className="col-md-12">
                  <div
                    className="row d-flex "
                    style={{
                      borderBottom: "2px solid #F1F5F9",
                      paddingBottom: "10px",
                    }}>
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-2px",
                        backgroundColor: "#FFA382",
                        marginTop: "10px ",
                      }}>
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    {/* <div className="d-flex justify-content-between w-75 border"> */}
                    <div className="col-md-3 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "18px",
                          marginTop: "10px",
                          fontWeight: "bold",
                        }}>
                        Edit Coupon
                      </div>
                    </div>
                    <div className="col-md-7"> </div>
                    <div className="col-md-1 filters-bar d-flex">
                      <button
                        className="btn btn-primary border-0 "
                        style={{
                          backgroundColor: "#FFA382",
                          marginBottom: "5px",
                          marginLeft: "90px",
                        }}
                        onClick={() => {
                          history.push("/typed-coupons");
                        }}>
                        <i className="fas fa-arrow-left"></i>
                        Back
                      </button>
                    </div>
                    {/* </div> */}
                    <br></br>
                  </div>
                </div>
                {couponState.loading ? (
                  "Populating data..."
                ) : (
                  <EditTypedCouponForm />
                )}
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            {couponState?.loading ? null : (
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    editCouponThunk(
                      isMultiChainAdmin && applyToAllOutlets
                        ? [
                            ...outlets
                              .map((key) => key?.outletChainID)
                              .filter((el) => !!el),
                            JSON.parse(localStorage.getItem("Admin"))[0]
                              ?.outletChainID,
                          ]
                        : JSON.parse(localStorage.getItem("Admin"))[0]
                            ?.outletChainID
                    )
                  );
                }}
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382" }}
                className="btn  btn-cannaby">
                {!inProgress ? "Update Coupon" : "Updating Coupon..."}
              </button>
            )}
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"The coupon is updated"}
          onClose={() => {
            dispatch(resetOpStateAction());
            history.push("/typed-coupons");
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
      {couponState.error ? (
        <SimpleModal
          severity={"error"}
          highlights={couponState.error}
          onClose={() => {
            history.push("/typed-coupons");
          }}
        />
      ) : null}
    </div>
  );
}
