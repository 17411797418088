import { useState, Fragment } from "react";
import { Avatar, Paper, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurentActiveSessionAction,
  selectCurrentSessionInfo,
  setActiveSessionAction,
} from "../../redux/slices/chatSessionSlice";
import url from "../../config/axios";
import { selectParticularSessionById } from "../../redux/slices/messageSessionSlice";
import { useEffect } from "react";
import moment from "moment";
import { formatNumber } from "../../helpers/formatNumber";

export const ChatHeaderComponent = () => {
  const data = useSelector(selectCurrentSessionInfo);
  const dispatch = useDispatch();
  const dataFromActiveSessionState = useSelector(selectCurrentSessionInfo);
  const dataFromMessageSessions = useSelector(
    selectParticularSessionById(dataFromActiveSessionState?._id)
  );
  const topic =
    dataFromMessageSessions?.topic ?? dataFromActiveSessionState?.topic ?? {};
  const activeStatus = dataFromMessageSessions?.isActive;
  const [progress, setProgress] = useState(false);
  const [userMeta, setUserMeta] = useState({
    loaded: false,
    totalOrders: 0,
    lastOrderDate: "",
  });

  const fetchUserMeta = async (userData) => {
    setUserMeta({
      totalOrders: 0,
      lastOrderDate: "",
      loaded: false,
    });
    url
      .get(
        `/v1/message-sessions/get-user-meta?userID=${userData?.user?.userID}`
      )
      .then((response) => {
        const { totalOrders, lastOrderDate } = response?.data?.data ?? {};
        setUserMeta({
          totalOrders,
          lastOrderDate,
          loaded: true,
        });
      })
      .catch((e) => {
        setUserMeta({
          totalOrders: 0,
          lastOrderDate: "",
          loaded: false,
        });
        console.error(e);
      });
  };
  useEffect(
    () => {
      fetchUserMeta({ ...data });
    },
    //eslint-disable-next-line
    [data]
  );
  const getDottedString = (str) => {
    if ((str + "").length < 40) {
      return str;
    }
    return (str + "").substring(0, 40) + "...";
  };
  const deactivateSession = () => {
    setProgress(true);
    url
      .delete(`/v1/message-sessions`, {
        params: {
          sessionID:
            dataFromMessageSessions?._id ?? dataFromActiveSessionState?._id,
          userID:
            dataFromMessageSessions?.userID ??
            dataFromActiveSessionState?.userID,
        },
      })
      .then(() => {
        setProgress(false);
        dispatch(setActiveSessionAction(null));
      })
      .catch((e) => {
        setProgress(false);
        // console.error(e.response?.data);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          flexWrap: "wrap",
          padding: "2em 1em",
          borderRadius: "14px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: "#18253c",
          height: "auto",
          position: "relative",
        }}
      >
        <CancelIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "5px",
            top: "5px",
            color: "#ff6d6d",
            fontSize: "120%",
          }}
          onClick={() => {
            dispatch(clearCurentActiveSessionAction());
          }}
        />
        {/* avatar container */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.8em",
          }}
        >
          <Avatar
            alt={data?.user?.name ?? "Anonymous"}
            component={Paper}
            elevation={5}
            src={data?.user?.image}
            sx={{ width: 60, height: 60 }}
          >
            {(data?.user?.name ?? "Anonymous").trim()[0]}
          </Avatar>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            flexGrow: 1,
          }}
        >
          {/* phone and end session button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              flexDirection: "column-reverse",
            }}
          >
            <Button
              size="medium"
              style={{
                textTransform: "none",
              }}
              sx={{
                backgroundColor: "#ff6d6d",
                padding: "0.5em 1.5em",
              }}
              onClick={() => deactivateSession()}
              disabled={progress || !activeStatus}
              variant="contained"
              color="error"
            >
              {activeStatus ? (
                <Fragment>
                  {progress ? " Deactivating..." : "End Session"}
                </Fragment>
              ) : (
                "Session ended"
              )}
            </Button>
            <span
              style={{
                fontSize: "110%",
                fontWeight: "400",
                color: "white",
              }}
            >
              {formatNumber(data?.user?.mobileNo ?? "")}
            </span>
          </div>
          {/**info container */}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "0.1em",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexWrap: "nowrap",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  height: "auto",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <span
                  style={{
                    fontSize: "120%",
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  {data?.user?.name ?? "Anonymous"}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                height: "auto",
                color: "white",
              }}
            >
              <span style={{ fontSize: "100%" }}>
                {userMeta?.loaded
                  ? `${userMeta?.totalOrders} order(s) | Last Ordered: ${
                      userMeta?.lastOrderDate
                        ? moment(userMeta?.lastOrderDate).format("YYYY-MM-DD")
                        : "N/A"
                    }`
                  : null}
              </span>
            </div>
            <div
              style={{
                display: "inline-flex",
                height: "auto",
                color: "white",
              }}
            >
              <span
                style={{
                  fontSize: "90%",
                }}
              >
                {/* {topic?.link ? (
                  <a
                    href={topic?.link}
                    target="_blank"
                    alt=""
                    rel="noreferrer"
                    style={{
                      fontSize: "100%",
                      color: "white",
                      textDecoration: "underline",
                    }}>
                    {getDottedString(topic?.link)}
                  </a>
                ) : topic?.title ? (
                  getDottedString(topic?.title)
                ) : null} */}
                {topic?.title ? getDottedString(topic?.title) : null}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          padding: "0em 0.8em",
          justifyContent: "flex-end",
          backgroundColor: "rgba(160, 32, 240, 0.2)",
        }}>
        <ActiveSessionInfoComponent />
      </div> */}
    </div>
  );
};

export default ChatHeaderComponent;
