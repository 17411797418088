import { dataURLToBlob } from "blob-util";
// import axios from "axios";
import url from "../config/axios";
export const singleImageUploadFromBase64 = async ({ name, source }) => {
  try {
    const formData = new FormData();
    const matches = source.match(/^data:image\/([a-z]+);base64,(.+)/);
    const fileExtension = matches[1];
    const blob = dataURLToBlob(source);
    const file = new File([blob], name, { type: `image/${fileExtension}` });
    formData.append("file", file);
    // let baseURL = url.defaults.baseURL;
    // baseURL = baseURL.endsWith("/") ? baseURL.slice(0, -1) : baseURL;
    // const response = await axios.post(
    //   `${baseURL}/v1/s3/upload-single-image`,
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    const response = await url.post(`v1/s3/upload-single-image`, formData);
    const data = response.data?.data;
    return {
      mediaKey: data?.key,
      image: data?.preProcessingURL,
    };
  } catch (e) {
    console.error("File upload error ", e);
    return null;
  }
};
