/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line react-hooks/exhaustive-deps
// import crown from "../../assets/crown.png";
// import dollar from "../../assets/dollar.png";
import phone from "../../assets/phone.svg";
import mail from "../../assets/mail.svg";

import url from "../../config/axios";
import blank from "../../assets/defaultImage.jpg";
import { useHistory, useLocation } from "react-router-dom";
import abandon from "../../assets/abandoncart.svg";
import dollar from "../../assets/dollar.png";
import chatbutton from "../../assets/chatbutton.svg";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dollar from "../../assets/dollar.png";
import { useState, useEffect, useMemo } from "react";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setActiveSessionThunk } from "../../redux/slices/chatSessionSlice";
import "./users.css";
import DeleteOrderDialog from "../../components/TypedOrders/DeleteOrderDialogComponent";
import { CircularProgress, Pagination } from "@mui/material";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";

export function checkColor(value) {
  let result;
  switch (value) {
    case "Processing":
      result = "red";
      break;
    case "New":
      result = "blue";
      break;
    case "Cancelled":
      result = "brown";
      break;
    case "Delivered":
      result = "purple";
      break;
    case "Completed":
      result = "#0CD600";
      break;
    default:
      result = "";
      break;
  }
  return result;
}
export function backgroundColor(value) {
  let result;
  switch (value) {
    case "Processing":
      result = "rgba(255, 109, 109, 0.08)";
      break;
    case "New":
      result = "rgba(108, 149, 255, 0.1)";
      break;
    case "Cancelled":
      result = "rgba(255, 109, 109, 0.08)";
      break;
    case "Delivered":
      result = "color-purple";
      break;
    case "Completed":
      result = "rgba(12, 214, 0, 0.1)";
      break;
    default:
      result = "";
      break;
  }
  return result;
}

export default function UserDetails() {
  const [cart, setCart] = useState([]);
  const history = useHistory();

  const [orders, setOrders] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const user = JSON.parse(localStorage.getItem("singleUser"));
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [pointsToPrice, setPointsToPrice] = useState();
  const [editMed, setEditMed] = useState(true);
  const [editLic, setEditLic] = useState(true);
  const [med, setMed] = useState("");
  const [lic, setLic] = useState("");
  const [lastActivity, setLastActivity] = useState("");
  const [messages, setMessage] = useState("");
  const [mlexpiry, setMlexpiry] = useState("");
  const [age, setAge] = useState("");
  const [image, setImage] = useState("");
  const [session, setSession] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [formValidation, setFormValidation] = useState({});
  const [userLoyalty, setUserLoyalty] = useState("");
  const [loyaltyInput, setLoyaltyInput] = useState(false);
  const [loyaltyPoints, setLoyaltyPoints] = useState("");
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [isMedFilled, setIsMedFilled] = useState(false);
  const [isLicFilled, setIsLicFilled] = useState(false);
  const [medError, setMedError] = useState("");
  const [licError, setLicError] = useState("");

  const initialPaginationData = {
    skip: 0,
    limit: 3,
    // userID: user.userID,
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 3,
    totalPages: 0,
    total: 0,
  });
  const { search } = useLocation();

  useEffect(() => {
    getAge();
    getUserDetails();
    getOrderUser();
    GetLoyaltyStandards();
    GetLoyaltyPoints();
    GetUserLoyaltyDetails();
    getAbandodedCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [parsed, setParsed] = useState(false);

  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 20
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        getOrderUser({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  const GetUserLoyaltyDetails = async () => {
    const body = {
      userID: user.userID,
      consumerID: admin[0]?.consumerId,
    };
    await url
      .put(`/v1/user-loyalty-points/points-info`, body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setUserLoyalty(res?.data?.data);
          setLoyaltyPoints(res?.data?.data?.points);
          // setLoading(!loading);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateLoyaltyPoints = async () => {
    const body = {
      userID: user.userID,
      desiredPoints: loyaltyPoints,
    };
    await url
      .put(`/v1/user-loyalty-points-admin/change-points`, body)
      .then(async (res) => {
        GetUserLoyaltyDetails();
      })
      .catch((e) => console.log(e));
  };

  function getAge() {
    var today = new Date();
    var birthDate = new Date(user.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  }
  const getUserDetails = async () => {
    try {
      url.get(`/v2/users/user-details/?userID=${user.userID}`).then((res) => {
        setLastActivity(res.data.data.lastActivityDate);
        setMessage(
          res.data.data?.messageSessionInfo?.messages[0]?.contentGroup[0]
            ?.content
        );
        setSession(res.data.data?.messageSessionInfo?._id);
        setImage(res.data.data?.userInfo?.image);
        setMed(res.data.data?.userInfo.medicalLicense);
        setLic(res.data.data?.userInfo.drivingLicense);
        setMlexpiry(res.data.data?.userInfo.mlExpiry);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getAbandodedCart = async () => {
    try {
      const body = {
        userID: user.userID,
      };
      url.patch(`/v1/user-cart-admin/get-on-hold-cart`, body).then((res) => {
        setCart(res?.data?.data?.cart);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const updateLicense = async () => {
    let data = { ...formValidation };
    if (med === "" && lic === "") {
      data = {
        ...data,
        LicError: "Medical Licence cannot be empty",
        DrivError: "Driver Licence cannot be empty",
      };
      setFormValidation(data);
    } else if (med === "") {
      const { DrivError, ...others } = data;
      data = { ...others, LicError: "Medical Licence cannot be empty" };
      setFormValidation(data);
    } else if (lic === "") {
      const { LicError, ...others } = data;
      data = { ...others, DrivError: "Driver Licence cannot be empty" };
      setFormValidation(data);
    } else {
      const { LicError, DrivError, ...others } = data;
      data = { ...others };
      setFormValidation(data);
      let body = {
        medicalLicense: med,
        drivingLicense: lic,
        mlExpiry: moment(mlexpiry).format(),
      };
      try {
        url
          .put(`/v1/auth/update-license-info/?userID=${user.userID}`, body)
          .then((res) => {
            getUserDetails();
            setIsMedFilled(true);
            setIsLicFilled(true);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getOrderUser = async () => {
    try {
      setLoading(true);
      let queryParams = { ...queryData };
      url
        .get(`/v1/user-orders/paginated`, {
          params: {
            ...queryParams,
            userID: user.userID,
          },
        })
        .then((res) => {
          if (res.data.Message === "Success") {
            setOrders(res.data.data.orders);
            setPaginationData({
              ...paginationData,
              ...res?.data?.data.paginationData,
            });
            setLoading(false);
          } else {
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const GetLoyaltyPoints = async () => {
    await url
      .get(
        `/loyaltyPoints/id?userID=${user.userID}&outletID=${admin[0].adminID}`
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setLoyalty(res.data.data);
        } else {
          url
            .post(`/loyaltyPoints?outletID=${admin[0].outletChainID}`, {
              userID: user.userID,
              points: 0,
            })
            .then(async (res) => {
              if (res.data.Message === "Success") {
                GetLoyaltyPoints();
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setPointsToPrice(res.data.data.pointsToPrice);
          setLoading(!loading);
        }
      })
      .catch((e) => console.log(e));
  };

  var num = 0;
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const userImage = useMemo(() => {
    return (
      <>
        {image ? (
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={image ? image : blank}
          />
        ) : (
          <div
            className="image-thumbnail-div"
            style={{
              backgroundColor: assignColor(),
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span>{user?.name?.substring(0, 1)}</span>
          </div>
        )}
      </>
    );
  }, [image]);

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/typed-user-detail${queryString}`);
    setQueryData({ ...query });
  };

  return (
    <div className="row">
      <>
        <div className="col-md-4">
          <div
            id="userDetailDiv"
            className="card-new usr-m-b"
            style={{ height: "97%", padding: "0px !important" }}
          >
            <div className="card-history-user">
              <div className="row">
                <div className="col-md-12 cust-user-img d-flex justify-content-around">
                  <div>{userImage}</div>
                  <div className="pl-2">
                    <div className="cust-user-namenew">
                      {user.name} {`(${age ? age : ""})`}
                    </div>
                    <div className="cust-user-activity">
                      <p style={{ textAlign: "left", fontSize: "10px" }}>
                        Last active on{" "}
                        {moment(lastActivity).format("MMM.Do, YYYY h:mm A")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Adding membership box from here */}
                <div className="col-md-12 cust-user-member">
                  <div className="chatDiv">
                    <img
                      onClick={() => {
                        if (session) {
                          dispatch(setActiveSessionThunk(session));
                          history.push("/chat");
                        }
                      }}
                      style={{ height: "35px", marginLeft: "10px" }}
                      src={chatbutton}
                    />
                    <span className="newMsg">
                      {messages
                        ? messages.substring(0, 30)
                        : `No new messages from ${user.name.substring(0, 6)}..`}
                    </span>
                  </div>
                  <div
                    className="cust-user-member-parent"
                    style={{ backgroundColor: "rgba(255, 199, 0, 0.11)" }}
                  >
                    <div className="row cust-user-member-child">
                      <div className="col-md-6 mem-header">Loyalty Points</div>
                      <div className="col-md-6 mem-header">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.3em",
                            float: "right",
                            cursor: "pointer",
                          }}
                          onClick={() => setLoyaltyInput(!loyaltyInput)}
                        >
                          <i
                            style={{
                              paddingTop: "2px",
                              color: "#FFA382",
                              paddingLeft: "3px",
                              fontSize: "10px",
                            }}
                            className="fas fa-pen"
                          ></i>
                          <p style={{ color: "#FFA382", fontSize: "12px" }}>
                            {" "}
                            Edit{" "}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-md-1 mem-icon">
                        <img src={crown} alt="crown" />
                      </div> */}
                      {/* <div className="col-md-11 mem-text">
                        Premium Member (Level 2)
                      </div> */}

                      {!loyaltyInput ? (
                        <>
                          <div className="col-md-1 mem-icon">
                            <img src={dollar} alt="dollar" />
                          </div>
                          <div className="col-md-6 mem-text">
                            Remaining Points
                          </div>
                          <div className="col-md-5">
                            <div className="text-right">
                              <span
                                style={{ color: "#192746", fontWeight: "300" }}
                              >
                                <b>{userLoyalty ? userLoyalty.points : "0"}</b>{" "}
                                ($
                                {userLoyalty ? userLoyalty.worth : ""})
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <input
                            className={"border-1"}
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              color: "#192746",
                              width: "100%",
                              fontSize: "12px",
                              outline: "none",
                              margin: "0px 10px",
                            }}
                            onKeyPress={(event) => {
                              if (event.key === " ") {
                                event.preventDefault();
                              }
                            }}
                            type="number"
                            maxLength={24}
                            defaultValue={userLoyalty?.points}
                            value={loyaltyPoints}
                            onChange={(e) => setLoyaltyPoints(e.target.value)}
                          />
                          <div
                            className="col-md-12 mt-3"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <button
                              className="border-0 "
                              onClick={() => {
                                setLoyaltyInput(!loyaltyInput);
                                updateLoyaltyPoints();
                              }}
                              style={{
                                backgroundColor: "#FFA382",
                                color: "white",
                                fontSize: "12px",
                                borderRadius: "5px",
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* Medicla licesnse number div */}
                <div className="col-md-12 mt-2">
                  <div
                    className="rounded h-100"
                    style={{ border: "1px solid rgba(207, 207, 207, 0.25)" }}
                  >
                    <div className="d-flex pt-2 pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "13px" }}>
                        Medical License Numbers:
                      </p>
                    </div>

                    <div className="d-flex pl-2 pr-0 justify-content-between">
                      <div className="d-flex flex-column w-100">
                        <input
                          disabled={editMed}
                          className={editMed ? "border-0" : "border-1"}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            color: "#192746",
                            width: "100%",
                            fontSize: "12px",
                          }}
                          onKeyPress={(event) => {
                            if (event.key === " ") {
                              event.preventDefault();
                            }
                          }}
                          maxLength={24}
                          defaultValue={med}
                          value={med}
                          onChange={(e) => {
                            setMed(e.target.value);
                            setIsMedFilled(!!e.target.value); // Check if Medical License is filled
                            setMedError(""); // Clear the error message when data is filled
                          }}
                        />
                        {!isMedFilled && medError && (
                          <p className="emsg">{medError}</p>
                        )}
                        {editMed ? (
                          <span style={{ color: "#747474", fontSize: "10px" }}>
                            Expiry: {moment(mlexpiry).format("MM YYYY")}
                          </span>
                        ) : (
                          <input
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              color: "#192746",
                              width: "60%",
                              fontSize: "12px",
                              marginTop: "3px",
                            }}
                            defaultValue={mlexpiry}
                            value={moment(mlexpiry).format("YYYY-MM-DD")}
                            onChange={(e) => setMlexpiry(e.target.value)}
                            type="date"
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.3em",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                          paddingRight: "10px",
                        }}
                        onClick={() => setEditMed(!editMed)}
                      >
                        <i
                          style={{
                            paddingTop: "2px",
                            color: "#FFA382",
                            paddingLeft: "3px",
                            fontSize: "10px",
                          }}
                          className="fas fa-pen"
                        ></i>
                        <p style={{ color: "#FFA382", fontSize: "12px" }}>
                          {" "}
                          Edit{" "}
                        </p>
                      </div>
                    </div>
                    {formValidation.LicError && (
                      <p className="emsg">Medical license cannot be empty</p>
                    )}
                    {!editMed && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="border-0 "
                          onClick={() => {
                            setEditMed(!editMed);
                            updateLicense();
                          }}
                          style={{
                            backgroundColor: "#FFA382",
                            color: "white",
                            fontSize: "12px",
                            borderRadius: "5px",
                          }}
                          disabled={!isMedFilled} // Disable button if Medical License is not filled
                        >
                          Update
                        </button>
                      </div>
                    )}
                    <hr className=" p-1" style={{ width: "90%" }} />
                    <div className="d-flex pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "13px" }}>
                        Drivers License Numbers:
                      </p>
                    </div>
                    <div className="d-flex  pl-2 pr-0 justify-content-between">
                      <div className="d-flex flex-column w-100">
                        <input
                          disabled={editLic}
                          className={editLic ? "border-0" : "border-1"}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            color: "#192746",
                            width: "100%",
                            fontSize: "12px",
                          }}
                          onKeyPress={(event) => {
                            if (event.key === " ") {
                              event.preventDefault();
                            }
                          }}
                          maxLength={24}
                          defaultValue={lic}
                          value={lic}
                          onChange={(e) => {
                            setLic(e.target.value);
                            setIsLicFilled(!!e.target.value); // Check if Driver License is filled
                            setLicError(""); // Clear the error message when data is filled
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.3em",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setEditLic(!editLic)}
                      >
                        <i
                          style={{
                            paddingTop: "2px",
                            color: "#FFA382",
                            paddingLeft: "3px",
                            fontSize: "10px",
                          }}
                          className="fas fa-pen"
                        ></i>
                        <p style={{ color: "#FFA382", fontSize: "12px" }}>
                          {" "}
                          Edit{" "}
                        </p>
                      </div>
                    </div>
                    {!isLicFilled && licError && (
                      <p className="emsg">{licError}</p>
                    )}
                    {formValidation.DrivError && (
                      <p className="emsg">Driver license cannot be empty</p>
                    )}
                    {!editLic && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="border-0 "
                          onClick={() => {
                            setEditLic(!editLic);
                            updateLicense();
                          }}
                          style={{
                            backgroundColor: "#FFA382",
                            color: "white",
                            fontSize: "12px",
                            borderRadius: "5px",
                          }}
                          disabled={!isLicFilled} // Disable button if Driver License is not filled
                        >
                          Update
                        </button>
                      </div>
                    )}
                    <hr className=" p-1" style={{ width: "90%" }} />
                  </div>
                </div>
                {/*  */}
                {/* Personal details */}
                <div className="col-md-12 mt-2">
                  <div
                    className="rounded h-100 mb-3"
                    style={{ border: "1px solid rgba(207, 207, 207, 0.25)" }}
                  >
                    <div className="d-flex pt-2 pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "14px" }}>
                        Personal details
                      </p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>Gender:</p>
                      <p style={{ color: "#192746" }}>{user.gender}</p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>DOB:</p>
                      <p style={{ color: "#192746" }}>
                        {moment(user.dob).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>Profession:</p>
                      <p style={{ color: "#192746" }}>
                        {user.profession === "Others"
                          ? "Other"
                          : user.profession}
                      </p>
                    </div>
                    <hr className=" p-1" style={{ width: "90%" }} />
                    <div className="d-flex pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "14px" }}>
                        Contact
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start">
                      <div className="cIcon mr-3">
                        <img src={phone} alt="crown" />
                      </div>
                      <div className="cText pr-1" style={{ fontSize: "13px" }}>
                        {user.mobileNo ?? "+92301231"}
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start">
                      <div className="cIcon mr-3">
                        <img src={mail} alt="crown" />
                      </div>
                      <div className="cText pr-1" style={{ fontSize: "13px" }}>
                        {user.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className="card-new"
              style={{ height: "auto", paddingBottom: "1em" }}
            >
              <div className="card-history">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row" style={{ borderRadius: "13px" }}>
                      <div
                        className="col-md-12 card-title"
                        style={{
                          backgroundColor: "#f5f5f569",
                        }}
                      >
                        <div
                          style={{
                            height: "40px",
                            marginLeft: "-15px",
                            backgroundColor: "#FFA382",
                            marginRight: "15px",
                          }}
                        >
                          <span style={{ color: "#FFA382" }}>.</span>
                        </div>
                        <span style={{ color: "#FFA382" }}>Order History</span>
                      </div>
                    </div>
                    {orders.length > 0 &&
                      (loading ? (
                        <div
                          className="row"
                          style={{
                            padding: "1em",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <div
                            className="scrollbar-hidden"
                            style={{
                              overflow: "hidden",
                              height: "30vh",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table">
                              <thead
                                style={{
                                  backgroundColor: "#f8fafc",
                                }}
                              >
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      fontWeight: "400",
                                      width: "6rem",
                                    }}
                                  >
                                    Order ID
                                  </th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Customer
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Price
                                  </th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>

                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Purchased On
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      textAlign: "center",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Status
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      color: "#747474",
                                      textAlign: "center",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders
                                  // .slice((currentPage - 1) * perPage, currentPage * perPage)
                                  .map((inv, i) => {
                                    return (
                                      <tr
                                        style={{
                                          borderBottom: "1px solid #F1F5F9",
                                        }}
                                        key={i}
                                      >
                                        <td
                                          colSpan="3"
                                          style={{ color: "#1E293B" }}
                                        >
                                          #{inv.orderID}
                                        </td>
                                        <td
                                          className="adminName"
                                          style={{ color: "#1E293B" }}
                                        >
                                          {inv.userInfo
                                            ? inv?.userInfo.name
                                            : ""}{" "}
                                          <br />
                                          <span style={{ fontSize: "80%" }}>
                                            {inv?.userInfo?.email}
                                          </span>
                                          <span></span>
                                        </td>
                                        <td
                                          colSpan="3"
                                          style={{ color: "#1E293B" }}
                                        >
                                          ${" "}
                                          {parseFloat(
                                            !isNaN(inv?.finalPrice)
                                              ? inv.finalPrice
                                              : 0
                                          ).toFixed(2)}
                                        </td>
                                        <td style={{ color: "#1E293B" }}>
                                          {moment(
                                            new Date(inv.createdAt)
                                          ).format("MMMM Do YYYY, h:mm A")}
                                        </td>
                                        <td
                                          className={checkColor(
                                            inv?.statusInfo?.displayName
                                          )}
                                        >
                                          {/* {inv?.statusInfo?.displayName} */}
                                          <div
                                            className="newClass"
                                            style={{
                                              marginLeft: "-10px",
                                              backgroundColor: `${backgroundColor(
                                                inv?.statusInfo?.displayName
                                              )}`,
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontWeight: "400",
                                                textAlign: "center",
                                                fontSize: "13px",
                                                paddingTop: "4px",
                                                color: checkColor(
                                                  inv?.statusInfo?.displayName
                                                ),
                                              }}
                                            >
                                              {inv?.statusInfo?.displayName}
                                            </p>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              gap: "0em",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <button
                                              style={{
                                                border: "none",
                                                color: "#FFA382",
                                                paddingRight: "10px",
                                                cursor: "pointer",
                                                background: "none",
                                              }}
                                              // className="btn btn-outline-primary"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "singleOrder",
                                                  JSON.stringify(inv)
                                                );
                                                window.oID = inv?.orderID;
                                                history.push(
                                                  `/typed-order-detail?_id=${inv?._id}`
                                                );
                                              }}
                                            >
                                              <i className="fas fa-eye"></i>{" "}
                                            </button>
                                            <i
                                              onClick={() => {
                                                setOrderToDelete(inv);
                                              }}
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              className="fas fa-trash"
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="d-flex col-md-12"
                            style={{
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                borderRadius: "5px",
                                padding: "6px",
                              }}
                            >
                              <Pagination
                                onChange={(e, value) => {
                                  handleChange("page", value);
                                }}
                                page={paginationData.currentPage}
                                count={paginationData.totalPages ?? 0}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    {orders.length === 0 ? (
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <img src={abandon} />
                        <p>No order has been placed yet</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card-new"
              style={{
                marginTop: "1em",
              }}
            >
              <div className="card-history">
                <div className="row">
                  <div className="col-md-12 card-title ">
                    <div
                      style={{
                        height: "40px",
                        marginLeft: "-15px",
                        backgroundColor: "#FFA382",
                        marginRight: "15px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <span style={{ color: "#FFA382" }}>Abandoned Cart</span>
                  </div>
                  <div className="col-md-12 table-ac table-mod table-mod-data-ac custom-scrollbar">
                    {cart &&
                      cart?.items?.map((item, i) => {
                        return (
                          <div
                            className="row-cust-ac"
                            style={{ borderBottom: "1px solid #EDEDED" }}
                          >
                            <div className="cust-row-ac-img">
                              <img
                                className="productImage"
                                alt=""
                                src={
                                  item?.productInfo?.variant?.image ??
                                  item?.productInfo?.rootProduct?.image ??
                                  blank
                                }
                              />
                            </div>
                            <div className="cust-row-ac-body">
                              {item?.name ??
                                item?.productInfo?.variant?.name ??
                                "N/A"}
                            </div>
                            <div className="cust-row-ac-desc">
                              <span>
                                Brand :
                                <span className="ac-green">
                                  {" "}
                                  {item?.brandName ??
                                    item?.productInfo?.rootProduct?.brandName ??
                                    "N/A"}
                                </span>
                              </span>
                            </div>
                            <div className="cust-row-ac-price">
                              {/* {item.quantity} x {item.variant.price} */}
                              {item?.purchaseQuantity ?? item?.quantity} x
                              {item?.productInfo?.variant?.pricingInfo
                                ?.currency === "USD"
                                ? "$"
                                : "CAD"}
                              {" " +
                                parseFloat(
                                  item?.originalSinglePrice
                                    ? item.originalSinglePrice
                                    : 0
                                ).toFixed(2) +
                                " "}
                            </div>
                          </div>
                        );
                      })}
                    {cart?.items?.length === 0 ? (
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <img src={abandon} />
                        <p>No product has been added to cart</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* card 2 */}

          {/* delete order popup */}
          {orderToDelete && (
            <DeleteOrderDialog
              orderInfo={orderToDelete}
              onDone={() => {
                setOrderToDelete(null);
                setQueryData({ ...initialPaginationData });
              }}
              onCancel={() => setOrderToDelete(null)}
            />
          )}
        </div>
      </>
    </div>
  );
}
