/* eslint-disable no-unused-vars */
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./privacy.css";
import moment from "moment";

export default function Privacy() {
  const history = useHistory();
  const [privacyDate, setPrivacyDate] = useState("Not Created Yet");
  const [termsDate, setTermsDate] = useState("Not Created Yet");
  const [aboutDate, setAboutDate] = useState("Not Created Yet");

  // let page = ["terms", "privacy", "about"];

  const getPrivacyPage = () => {
    url
      .get(`/page/id?consumerId=${admin[0].consumerId}&page=privacy`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
          } else {
            setPrivacyDate(
              moment(res.data.data.updatedAt).format("DD MMM YYYY, h:mm A")
            );
          }
        }
      });
  };

  const getTermsPage = () => {
    url
      .get(`/page/id?consumerId=${admin[0].consumerId}&page=terms`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
          } else {
            setTermsDate(
              moment(res.data.data.updatedAt).format("DD MMM YYYY, h:mm A")
            );
          }
        }
      });
  };

  useEffect(() => {
    getPrivacyPage();
    getTermsPage();
  });

  const admin = JSON.parse(localStorage.getItem("Admin"));

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-pro" id="nopad1">
            <div className="col-md-12" style={{ borderRadius: "12px" }}>
              <div
                className="row"
                style={{
                  paddingBottom: "4px",
                }}
              >
                <div
                  style={{
                    height: "20px",
                    marginLeft: "-1px",
                    backgroundColor: "#FFA382",
                    marginTop: "6px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-4 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      paddingTop: "1px",
                      fontWeight: "600",
                    }}
                  >
                    Pages
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col" style={{ fontWeight: 500 }}>
                      Name
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" style={{ fontWeight: 500 }}>
                      Last Activity
                    </th>
                    <th scope="col"></th>
                    <th scope="col" style={{ fontWeight: 500 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td style={{ color: "rgba(30, 41, 59, 1)" }} colSpan="4">
                      Terms & Conditions{" "}
                    </td>
                    <td></td>
                    <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                      {termsDate}
                      <br />
                    </td>
                    <td></td>
                    <td style={{ color: " #FFA382" }}>
                      <div onClick={() => history.push("add-page?id=terms")}>
                        <i
                          style={{ color: " #FFA382", marginRight: "2px" }}
                          class="fa fa-pen"
                        ></i>
                        <u>Edit</u>
                      </div>
                    </td>
                  </tr>

                  <tr key={2}>
                    <td style={{ color: "rgba(30, 41, 59, 1)" }} colSpan="4">
                      Privacy Policy
                    </td>
                    <td></td>
                    <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                      {privacyDate}
                      <br />
                    </td>
                    <td></td>
                    <td style={{ color: " #FFA382" }}>
                      <div onClick={() => history.push("add-page?id=privacy")}>
                        <i
                          style={{ color: " #FFA382", marginRight: "2px" }}
                          class="fa fa-pen"
                        ></i>
                        <u>Edit</u>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
