const EmptySessionComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "100%",
        height: "85vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "14px",
        flexWrap: "wrap",
      }}>
      <i
        class="fa-solid fa-inbox"
        style={{
          fontStyle: "normal",
          fontSize: "56px",
        }}></i>
      Message sessions will appear here!
    </div>
  );
};

export default EmptySessionComponent;
