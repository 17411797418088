import React from 'react'
import styled from "@emotion/styled"
const InputAreaPrimary = ({ label, ...props }) => {
    return (
        <Container>
            <label>{label}</label>
            <textarea
                {...props}
            />
        </Container>
    )
}

const Container = styled.div`
        label {
            color: #747474;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1rem;
            display: block;
        }
        textarea {
            display: block;
            width: 100%;
            outline: none;
            border: 1px solid #E2E8F0;
            background: transparent;
            font-size: 0.938rem;
            box-sizing: border-box;
            padding: 12px 16px; 
            border-radius: 5px;    
         
        }
    `
export default InputAreaPrimary