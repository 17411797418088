import styled from "@emotion/styled";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRegularDealInfoState,
  selectRegularTypeValidationErrors,
  changeSinglePropertyAction,
} from "../../redux/slices/addDealSlice";
import {
  changeEditSinglePropertyAction,
  selectEditRegularDealDefState,
  selectEditRegularTypeValidationErrors,
} from "../../redux/slices/editDealDefSlice";
import {
  TypedDealsTypes,
  TypedRegularDealsAppliesTo,
} from "../../pages/TypedDeals/constants/typed-deals.constants";
import { TypedRegularDealsAppliesToSelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import SelectPrimary from "../../atoms/SelectPrimary";
import DropDownCategoryPicker from "../common/DropDownCategoryPicker";
import DropDownProductPicker from "../common/DropDownProductPicker";
import { TypedDealsAmountTypeSelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import InputPrimary from "../../atoms/InputPrimary";
import DropDownManufacturerPicker from "../common/DropDownManfacturerPicker";

const RegularDealFormComponent = ({ applyToAllOutlets, editMode }) => {
  const dispatch = useDispatch();
  const dealInfo = useSelector(
    editMode ? selectEditRegularDealDefState : selectRegularDealInfoState
  );
  const formValidationErrors = useSelector(
    editMode
      ? selectEditRegularTypeValidationErrors
      : selectRegularTypeValidationErrors
  );
  const {
    appliesTo,
    chosenProducts,
    chosenCategories,
    getOffType,
    getOffNumeric,
    chosenBrands,
  } = dealInfo;
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      if (editMode) {
        dispatch(
          changeEditSinglePropertyAction({
            dealType: TypedDealsTypes.REGULAR,
            propName,
            propValue,
          })
        );
      } else {
        dispatch(
          changeSinglePropertyAction({
            dealType: TypedDealsTypes.REGULAR,
            propName,
            propValue,
          })
        );
      }
    }
  };
  const [previousAppliesTo, setPreviousAppliesTo] = useState(appliesTo);
  useEffect(
    () => {
      if (!editMode) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
      }
    },
    //eslint-disable-next-line
    [applyToAllOutlets]
  );
  useEffect(
    () => {
      if (appliesTo !== previousAppliesTo) {
        handleChange("chosenProducts", {});
        handleChange("chosenCategories", {});
        handleChange("chosenBrands", {});
      }
      setPreviousAppliesTo(appliesTo);
    },
    //eslint-disable-next-line
    [appliesTo]
  );

  return (
    <Fragment>
      <DealLabelWrapper>
        <label>REGULAR RULES</label>
      </DealLabelWrapper>
      <div className="mb-4">
        <SelectPrimary
          value={appliesTo}
          name="appliesTo"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          label={"Applies To"}>
          {Object.values(TypedRegularDealsAppliesToSelectors).map(
            (item, index) => (
              <option
                value={item.value}
                key={`selected-applies-to-type-${index}`}>
                {item.displayName}
              </option>
            )
          )}
        </SelectPrimary>
      </div>
      <div className="mb-4">
        {appliesTo === TypedRegularDealsAppliesTo.CATEGORIES ? (
          <DropDownCategoryPicker
            label={"Pick Categories*"}
            selected={chosenCategories}
            setSelected={(value) => handleChange("chosenCategories", value)}
            referLocalData={applyToAllOutlets ? false : true}
          />
        ) : null}
        {appliesTo === TypedRegularDealsAppliesTo.PRODUCTS ? (
          <DropDownProductPicker
            label={"Pick Products"}
            selected={chosenProducts}
            setSelected={(value) => handleChange("chosenProducts", value)}
            referLocalInventory={applyToAllOutlets ? false : true}
          />
        ) : null}
        {appliesTo === TypedRegularDealsAppliesTo.BRANDS ? (
          <DropDownManufacturerPicker
            label={"Pick Brands"}
            selected={chosenBrands}
            setSelected={(value) => handleChange("chosenBrands", value)}
            referLocalData={applyToAllOutlets ? false : true}
          />
        ) : null}
        <span className="validation-help">
          {formValidationErrors?.appliesTo
            ? formValidationErrors.appliesTo
            : ""}
        </span>
      </div>

      {/* discount */}
      <ChooserWrapper>
        <div className="col-md-4">
          <InputPrimary
            value={getOffNumeric}
            label={"For *"}
            type="number"
            name="getOffNumeric"
            onChange={(e) =>
              handleChange(
                e.target.name,
                getOffType === "PERCENTAGE"
                  ? Math.min(100, e.target.value)
                  : e.target.value
              )
            }
          />

          <span className="validation-help">
            {formValidationErrors?.getOffNumeric
              ? formValidationErrors.getOffNumeric
              : ""}
          </span>
        </div>
        <div className="col-md-4">
          <SelectPrimary
            blankLabel={true}
            value={getOffType}
            name="getOffType"
            onChange={(e) => handleChange(e.target.name, e.target.value)}>
            {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
              <option
                value={item.value}
                key={`selected-deal-amount-type-${index}`}>
                {`${item.indicator} Off`}
              </option>
            ))}
          </SelectPrimary>
        </div>
      </ChooserWrapper>
    </Fragment>
  );
};
export default RegularDealFormComponent;

const DealLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;

const ChooserWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1.5em;
  position: relative;
  transform: translateX(-14px);
`;
