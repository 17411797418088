import { addDays } from "date-fns";
import { TypedDealsDays, TypedDealsTypes } from "./typed-deals.constants";

export const initialEachTier = {
  buyMinimum: 0,
  getOffNumericAmount: 0,
};
export const DealInfoInitialData = {
  BOGO_BUNDLED: {
    //the whole selected buy product object <key> : <object>
    chosenProducts: {},
    chosenCategories: {},
    chosenBrands: {},
    variantExceptions: {},
    appliesTo: "PRODUCTS",
    extendedProductPreference: "SELF",
    //minimum product quantity need to buy
    buyProductExactQuantity: 0,
    //chosen variants
    // {<varinatID> : <variantDetails>}
    buyProductVariants: {},
    //the whole selected get product object <key> : <object>
    selectedGetProduct: {},
    //product quantity will get
    getProductExactQuantity: 0,
    //selected get product variant
    getProductVariant: {},
    //off to issue
    getOffNumeric: 0,
    //off type
    getOffType: "PERCENTAGE",
    // Discount applied on
    targetOffEntity: "ON_EXTENDED_PRODUCT",
    // User picked
    userPickedDiscount: {
      maxAmountConstraint: false,
      maxAmount: 0,
      categories: {}
    }
    
  },
  BOGO: {
    //the whole selected buy product object <key> : <object>
    selectedBuyProduct: {},
    //minimum product quantity need to buy
    buyProductExactQuantity: 0,
    //chosen variants
    // {<varinatID> : <variantDetails>}
    buyProductVariants: {},
    //the whole selected get product object <key> : <object>
    selectedGetProduct: {},
    //product quantity will get
    getProductExactQuantity: 0,
    //selected get product variant
    getProductVariant: {},
    //off to issue
    getOffNumeric: 0,
    //off type
    getOffType: "PERCENTAGE",
  },
  TIERED: {
    buyMinimumType: "QUANTITY",
    issueAmountType: "AMOUNT",
    //  <index> : {
    //     "buyMinimum": 2,
    //     "getOffNumericAmount": 5
    //   }
    tiers: {},
    //the whole selected product object
    selectedProduct: {},
    chosenVariants: {},
  },
  REGULAR: {
    // <key> : { categoryID: string, categoryName: string}
    chosenCategories: {},
    // <key> : { productID: string,productName: string}
    chosenProducts: {},
    // <key> : { brandID: string,productName: string}
    chosenBrands: {},

    //off to issue
    getOffNumeric: 0,
    //off type
    getOffType: "PERCENTAGE",
    appliesTo: "CATEGORIES",
  },
  TIERED_BUNDLED: {
    //the whole selected buy product object <key> : <object>
    chosenProducts: {},
    chosenCategories: {},
    chosenBrands: {},
    variantExceptions: {},
    appliesTo: "PRODUCTS",
    buyMinimumType: "QUANTITY",
    issueAmountType: "AMOUNT",
    //  <index> : {
    //     "buyMinimum": 2,
    //     "getOffNumericAmount": 5
    //   }
    tiers: {},
  },
};

export const getDealFormInitialData = (excludeRulesAndExpiry = false) => ({
  _id: null,
  inProgress: false,
  error: null,
  success: false,
  dealType: TypedDealsTypes.REGULAR,
  name: "",
  imageFile: null,
  images: [],
  dealInfo: {
    ...DealInfoInitialData,
  },
  validationErrors: {},
  applyToAllOutlets: false,
  hasDependenciesOnOtherOutlets: false,
  ...(!excludeRulesAndExpiry
    ? {
        applicableDays: Object.values(TypedDealsDays).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        ),
        startsOn: new Date(),
        endsOn: addDays(new Date(), 7),
        status: true,
        isRuleApplicable: true,
        neverExpires: true,
        totalUsageLimit: 200,
        usageLimitPerCustomer: 10,
        canBeOverridden: true,
      }
    : {}),
});
