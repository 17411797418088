import { createSlice } from "@reduxjs/toolkit";
import { playRing } from "../../helpers/play-ring";
const initialState = {
  message: null,
};
export const rootNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setRootNotificationMessageAction: (state, action) => {
      if (action.payload?.message?.length) {
        state.message = String(action.payload?.message);
        if (action.payload?.playSound) {
          playRing();
        }
      }
    },
    clearRootNotificationMessageAction: (state) => {
      state.message = null;
    },
  },
});
export const {
  setRootNotificationMessageAction,
  clearRootNotificationMessageAction,
} = rootNotificationSlice.actions;
export const selectRootNotification = (state) => state.rootNotification.message;
export default rootNotificationSlice.reducer;
