/* eslint-disable react-hooks/exhaustive-deps */
import "./catalog.css";
import React from "react";
import { useState, useEffect } from "react";
import url from "../../../config/axios";
import AddCatalog from "../../../components/OutletAdminComponents/AddCatalog";
import ViewProduct from "../../../components/OutletAdminComponents/ViewProduct";
import AddCatalogModal from "../../../components/Inventory/AddCatalogSelected";

export default function Index() {
  const [catalog, setCatalog] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    url
      .get(`/product/paginated/?page=${page}`)
      .then(async (res) => {
        await setCatalog(res.data.data);
      })
      .catch((e) => console.log(e));
    getAllProducts();
  }, [page]);

  const getAllProducts = () => {
    url.get(`/product/all/`).then(async (res) => {
      if (res.data.Message === "Success") {
        setTotalPage(res.data.data.length);
        setminPro(page * 50 - 50 + 1);
      } else {
        alert(res.data.data);
      }
    });
  };

  // const getPaginatedProducts = () => {
  //     url.get(`/product/paginated/?page=${page}`).then(async (res) => {
  //         if (res.data.Message === "Success") {
  //             await setCatalog(res.data.data);
  //         }
  //         else {
  //             alert(res.data.data);
  //         }
  //     }).catch(e => console.log(e));
  // }

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const setViewDetails = (product) => {
    setViewModal(!viewModal);
    setSelectedProduct(product);
  };

  const setAddDetails = (product) => {
    setAddModal(!addModal);
    setSelectedProduct(product);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Chain"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                                            className="btn cannaby-light"
                                            href="/"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-filter"></i>
                                        </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                                            className="btn cannaby-light"
                                            href="/"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-filter"></i>
                                        </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{catalog.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {catalog.map((cat, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          <img
                            img
                            alt="yo1"
                            src={cat.image}
                            className="img-fluid "
                            width="40"
                          />
                          {cat.name}
                        </td>
                        <td className="adminName">
                          {cat.brandName} <br /> <span></span>
                        </td>
                        <td>
                          {cat.category.length ? cat.category[0].name : ""}
                        </td>
                        <td>
                          {admin[0].isSubOutlet ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => setAddDetails(cat)}
                            >
                              Add to inventory
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setSelectedProduct(cat);
                                setOpenAddModal(true);
                              }}
                            >
                              Add to inventory
                            </button>
                          )}
                        </td>
                        <td className="status">
                          <button
                            onClick={() => {
                              setViewDetails(cat);
                            }}
                            className="btn btn-primary"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {addModal && (
        <AddCatalog setAddModal={setAddModal} myProduct={selectedProduct} />
      )}
      {viewModal && (
        <ViewProduct
          setViewModal={setViewModal}
          selectedProduct={selectedProduct}
        />
      )}
      {openAddModal && (
        <AddCatalogModal
          closeAddModal={setOpenAddModal}
          myProduct={selectedProduct}
        />
      )}
    </div>
  );
}
