/* eslint-disable jsx-a11y/alt-text */
import TuneIcon from "@mui/icons-material/Tune";
import { CircularProgress, IconButton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "../../../atoms/Popup/PopupMenu";
import InvokeLayoutComponent from "../../../components/common/InvokeLogout";
import url from "../../../config/axios";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
import { colors } from "../../../Style.style";
import "./inventory.css";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import { useMemo } from "react";
import InventoryTable from "./components/InventoryTable";
import { set } from "date-fns";

export default function Index() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const [inventory, setInventory] = useState([]);
  const [sortPopupMenuAnchor, setSortPopupMenuAnchor] = useState(null);
  const [filterPopupMenuAnchor, setFilterPopupMenuAnchor] = useState(null);
  const [sortByAlpha, setSortByAlpha] = useState(0);
  const [sortByPrice, setSortByPrice] = useState(1);
  const [sortByTime, setSortByTime] = useState(1);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [error, setError] = useState(null);

  const handleOpenSortPopupMenu = (event) => {
    setSortPopupMenuAnchor(event.currentTarget);
  };
  const handleSortMenuClose = () => {
    setSortPopupMenuAnchor(null);
  };
  const handleOpenFilterPopupMenu = (event) => {
    setFilterPopupMenuAnchor(event.currentTarget);
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const [inventoryToChangeHiddenStatus, setInventoryToChangeHiddenStatus] =
    useState(null);
  /**
   *
   * @param {string} value actual value
   */
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };
  // const performSearch = debounce(() => {
  //   if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
  //     setPreviousSearchTerm(searchTerm);
  //     handleChange("search", searchTerm, true);
  //   } else if (searchTerm === "") {
  //     setPreviousSearchTerm("");
  //     handleChange("search", "", true);
  //   }
  // }, 1500);
  // useEffect(
  //   () => {
  //     if (parsed && !loading) {
  //       performSearch();
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [searchTerm]
  // );

  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllProducts({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/inventory-outlet${queryString}`);
      setQueryData({ ...query });
    }
  };
  useEffect(() => {
    GetAllProducts();
  }, [sortByAlpha, sortByPrice, sortByTime, fromDate, toDate]);
  const GetAllProducts = () => {
    setLoading(true);
    handleSortMenuClose();
    let queryParams = {
      ...queryData,
      sortByAlpha,
      sortByPrice,
      sortByTime,
      fromDate,
      toDate,
    };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/inventories/aggregated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (!res.data?.data?.inventories?.length && searchTerm) {
            setError("Product(s) not found");
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.inventories?.length
          ) {
            setNoRecord(true);
          }
          setInventory(res.data?.data?.inventories);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };
  const updateInventoryHiddenStatus = async () => {
    let statusToChange = false;
    let toSet = [
      ...inventory.map((obj, i) => {
        if (obj.productID === inventoryToChangeHiddenStatus?.productID) {
          statusToChange = !inventoryToChangeHiddenStatus?.isHidden;
          return {
            ...obj,
            isHidden: statusToChange,
          };
        }
        return obj;
      }),
    ];
    await url
      .put(
        `/v1/inventories/update-hidden-status?_id=${inventoryToChangeHiddenStatus?._id}`,
        {
          status: statusToChange,
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setInventory(toSet);
          setInventoryToChangeHiddenStatus(null);
        } else {
          setInventoryToChangeHiddenStatus(null);
          alert(res.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
        setInventoryToChangeHiddenStatus(null);
      });
  };
  const updateInventory = async (outletId, productId, featuredOne) => {
    let toSet = [
      ...inventory.map((obj, i) =>
        obj.productID === currentEditingIndex?.productID
          ? { ...obj, featuredProduct: !obj.featuredProduct }
          : obj
      ),
    ];
    await url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        featuredProduct: featuredOne,
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setInventory(toSet);
          await setCurrentEditingIndex(null);
        } else {
          setCurrentEditingIndex(null);
          alert(res.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
        setCurrentEditingIndex(null);
      });
  };

  //update
  useEffect(
    () => {
      if (!!currentEditingIndex) {
        updateInventory(
          currentEditingIndex.outletChainID,
          currentEditingIndex.productID,
          !currentEditingIndex.featuredProduct
        );
      }
    },
    //eslint-disable-next-line
    [currentEditingIndex]
  );

  //update hidden status
  useEffect(
    () => {
      if (!!inventoryToChangeHiddenStatus) {
        updateInventoryHiddenStatus();
      }
    },
    //eslint-disable-next-line
    [inventoryToChangeHiddenStatus]
  );

  const Tablenew = useMemo(
    () => (
      <InventoryTable
        inventory={inventory}
        assignColor={assignColor}
        currentEditingIndex={currentEditingIndex}
        setCurrentEditingIndex={setCurrentEditingIndex}
        inventoryToChangeHiddenStatus={inventoryToChangeHiddenStatus}
        setInventoryToChangeHiddenStatus={setInventoryToChangeHiddenStatus}
      />
    ),
    [assignColor, currentEditingIndex, inventory, inventoryToChangeHiddenStatus]
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ borderRadius: "13px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Inventory
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center flex-gap-1">
                      <div className="input-group">
                        <input
                          type="text"
                          style={{ borderRight: "none" }}
                          className="form-control"
                          placeholder="Search Product "
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                        />
                        {searchTerm?.length ? (
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              onClick={() => handleSearchTermChange("")}
                              style={{
                                backgroundColor: "white",
                                borderLeft: "none",
                                paddingLeft: 0,
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="input-group-prepend"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                            onClick={performSearchOnEnter}
                          >
                            <i
                              style={{ color: colors.searchColor }}
                              className="fas fa-search"
                            ></i>
                          </div>
                        </div>
                      </div>
                      {/* Filter buttons */}
                      {/* <div>
                        <IconButton onClick={handleOpenFilterPopupMenu}>
                          <FilterAltIcon />
                        </IconButton>
                        <DateRangePopup
                          label="Purchased On"
                          anchorEl={filterPopupMenuAnchor}
                          setAnchorEl={setFilterPopupMenuAnchor}
                          fromDate={fromDate}
                          setFromDate={setFromDate}
                          toDate={toDate}
                          setToDate={setToDate}
                          sortByTime={sortByTime}
                          setSortByTime={setSortByTime}
                          customFormat="YYYY-MM-DD"
                        />
                      </div> */}
                      <div className="hidden">
                        <IconButton onClick={handleOpenSortPopupMenu}>
                          <TuneIcon />
                        </IconButton>
                        <PopupMenu
                          anchorEl={sortPopupMenuAnchor}
                          setAnchorEl={setSortPopupMenuAnchor}
                          handleClose={handleSortMenuClose}
                          menuItems={[
                            {
                              label: "Sort A to Z",
                              onClick: () => setSortByAlpha(1),
                            },
                            {
                              label: "Sort Z to A",
                              onClick: () => setSortByAlpha(-1),
                            },
                            // {
                            //   label: "Price: Low to High",
                            //   onClick: () => setSortByPrice(1),
                            // },
                            // {
                            //   label: "Price: High to Low",
                            //   onClick: () => setSortByPrice(-1),
                            // },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">{Tablenew}</div>
              </div>
            </Fragment>
          )}
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>

      {invokeLogOut && <InvokeLayoutComponent />}

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Product(s) not found"
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
    </div>
  );
}
