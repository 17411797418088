import { useState, useEffect, Fragment } from "react";
import "./LoginPageForm.styles.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ForgotPassword from "./ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  selectIfSwitchingProgress,
  selectAuthStateErrorMessage,
  selectIfUserIsAuthenticated,
  loginMultiChainAdminThunk,
  loginSoleChainAdminThunk,
  removeErrorMessageFromAuthStateAction,
} from "../../redux/slices/authSlice";

const LoginPageForm = ({ rememberMeState }) => {
  const loading = useSelector(selectIfSwitchingProgress);
  const error = useSelector(selectAuthStateErrorMessage);
  const isAuthenticated = useSelector(selectIfUserIsAuthenticated);

  const [openforgotPassword, setForgotPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    message: "",
    role: "oca", //oca => "outlet chain admin", mca => "multi chain admin"
    rememberMe: false,
    passwordVisible: false,
    triedBefore: false,
    ...rememberMeState,
  });
  const handleChange = (e) => {
    if (!formData.loading && !formData.success) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleChangeByNameValues = (name, value) => {
    if (!loading && !isAuthenticated) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const [validationMessages, setvalidationMessages] = useState({});

  const validateForm = async () => {
    let data = {};
    const { email, password } = formData;
    //validate email
    if (email.trim().length === 0) {
      data = { email: "Email is required" };
      setvalidationMessages({ ...data });
      return data;
    } else if (
      !(email + "")
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      data = { email: "Email is not valid" };
      setvalidationMessages({ ...data });
      return data;
    }

    if (Object.keys(data).length === 0) {
      //validate password
      if (password.trim().length === 0) {
        data = { password: "Password is required" };
        setvalidationMessages({ ...data });
        return data;
      } else if (password.trim().length < 6) {
        data = {
          password: "Password must of at least 6 characters long",
        };
        setvalidationMessages({ ...data });
        return data;
      }
    }
    setvalidationMessages({ ...data });
    return data;
  };
  useEffect(
    () => {
      if (formData.triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData]
  );
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!loading && !isAuthenticated) {
      handleChangeByNameValues("triedBefore", true);
      let validationData = await validateForm();
      if (Object.keys(validationData).length === 0) {
        const payload = {
          email: formData.email,
          password: formData.password,
          role: formData.role,
          SECRET: rememberMeState.SECRET,
          rememberMeState: formData.rememberMe,
        };
        if (formData.role === "oca") {
          dispatch(loginSoleChainAdminThunk(payload));
        } else {
          dispatch(loginMultiChainAdminThunk(payload));
        }
      }
    }
  };

  return (
    <Fragment>
      {openforgotPassword ? (
        <ForgotPassword onBack={() => setForgotPassword(false)} />
      ) : (
        <form
          className="login-v1-form-holder"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          noValidate>
          <div className="login-v1-form-title-holder">
            <h2 className="login-v1-form-title">Login</h2>
          </div>
          <div className="login-v1-field-border login-v1-form-field">
            <div
              onClick={() => handleChangeByNameValues("role", "oca")}
              className={`login-v1-form-tag ${
                formData.role === "oca"
                  ? "login-v1-form-tag-selected"
                  : "login-v1-form-tag-not-selected"
              }`}>
              Solechain Admin
            </div>
            <div
              onClick={() => handleChangeByNameValues("role", "mca")}
              className={`login-v1-form-tag ${
                formData.role !== "oca"
                  ? "login-v1-form-tag-selected"
                  : "login-v1-form-tag-not-selected"
              }`}>
              Multichain Admin
            </div>
          </div>
          <div className="login-v1-input-container">
            <label className="login-v1-input-label">Email</label>
            <input
              type={"email"}
              name="email"
              onChange={handleChange}
              value={formData.email}
              //   disabled={formData.loading}
              //   required={true}
              className="login-v1-input"
              placeholder="Enter Email"
            />
            {validationMessages.email && (
              <p className="login-v1-input-error">{validationMessages.email}</p>
            )}
          </div>

          <div className="login-v1-input-container">
            <label className="login-v1-input-label">Password</label>
            <div className="login-v1-input-password-container ">
              <div className="login-v1-password-icon-holder">
                <i
                  className={
                    formData.passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"
                  }
                  style={{
                    color: "gray",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleChangeByNameValues(
                      "passwordVisible",
                      !formData.passwordVisible
                    )
                  }></i>
              </div>
              <input
                type={formData.passwordVisible ? "text" : "password"}
                // required={true}
                name="password"
                // disabled={formData.loading}
                onChange={handleChange}
                value={formData.password}
                className="login-v1-input-password"
                placeholder="Enter Password"
              />
            </div>
            {validationMessages.password && (
              <p className="login-v1-input-error">
                {validationMessages.password}
              </p>
            )}
          </div>
          <div className="login-v1-input-container">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.rememberMe}
                  onChange={() =>
                    handleChangeByNameValues("rememberMe", !formData.rememberMe)
                  }
                  name="remember-me"
                  sx={{
                    color: "gray",
                    outline: "1px solid black",
                    "&.Mui-checked": {
                      color: "#ffa382",
                    },
                  }}
                />
              }
              label="Remember me"
            />
          </div>
          {(isAuthenticated || error) && (
            <Box sx={{ width: "100%" }}>
              <Alert
                severity={isAuthenticated ? "success" : "error"}
                action={
                  <IconButton
                    aria-label="close"
                    color={"default"}
                    size="small"
                    onClick={() => {
                      dispatch(removeErrorMessageFromAuthStateAction());
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}>
                {isAuthenticated ? "Successfully logged in" : error}
              </Alert>
            </Box>
          )}
          <button
            type="submit"
            className="login-v1-action-button"
            disabled={loading || isAuthenticated}>
            {loading
              ? "Logging in..."
              : isAuthenticated
              ? "Redirecting..."
              : "Login"}
          </button>
          <div className="login-v1-link-container">
            <span
              className="login-v1-link-text"
              onClick={() => {
                setvalidationMessages({});
                setForgotPassword(true);
              }}>
              Forgot Password?
            </span>
          </div>
        </form>
      )}
    </Fragment>
  );
};

export default LoginPageForm;
