import "./clientchat.styles.css";
import moment from "moment";
const ClientChatComponent = ({ content, time }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "0.5em",
        marginTop: "0.5em",
        flexWrap: "wrap",
        paddingRight: "20%",
        // overflow: "hidden",
      }}>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: "0.3em",
          paddingLeft: "1em",
        }}>
        <div className="client-speech-bubble">{content}</div>
        <span
          style={{
            marginRight: "auto",
            marginLeft: "0.5em",
            fontSize: "70%",
            color: "gray",
            fontWeight: "400",
          }}>
          {new Date(time).toString() !== "Invalid Date" &&
            moment(new Date(time).getTime()).fromNow()}
        </span>
      </div>
      {/**actions */}
      <div
        style={{
          display: "flex",
          width: "auto",
        }}></div>
    </div>
  );
};
export default ClientChatComponent;
