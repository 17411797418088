import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import {
  DealInfoInitialData,
  getDealFormInitialData,
} from "../../pages/TypedDeals/constants/form-initiator.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import moment from "moment";

const initialState = getDealFormInitialData();

export const getFormCompatibleDataFromResponse = (data) => {
  let currentOutletChainID = null;
  const {
    // name,
    // dealType,
    // images,
    canBeOverridden,
    outletBasisConfig,
    _id,
    rulesInfo,
    // dealInfo,
  } = data;
  try {
    currentOutletChainID = JSON.parse(localStorage.getItem("Admin"))[0]
      .outletChainID;
  } catch (e) {
    console.error("Cannot fetch any deal without selecting outlet");
  }
  const allOutlets = JSON.parse(localStorage.getItem("outlets"));
  let allOutletChainIds = [];
  if (allOutlets) {
    allOutletChainIds = allOutlets.map((outlet) => outlet.outletChainID);
  }
  // let parsedDealInfo = {};
  // if (dealType === "BOGO") {
  //   const dealData = dealInfo.BOGO;
  //   parsedDealInfo = {
  //     selectedBuyProduct: {
  //       [dealData.buyProduct.productID]: {
  //         product: {
  //           name: dealData.buyProduct.starterInfo.productName,
  //         },
  //       },
  //     },
  //     buyProductExactQuantity: dealData.buyProduct.exactQuantity,
  //     buyProductVariants:
  //       dealData?.buyProduct?.starterInfo.chosenVariants.reduce(
  //         (acc, item) => ({
  //           ...acc,
  //           [item.variantID]: { name: item.variantName },
  //         }),
  //         {}
  //       ),
  //     selectedGetProduct: {
  //       [dealData.getProduct.productID]: {
  //         product: {
  //           name: dealData.getProduct.starterInfo.productName,
  //         },
  //       },
  //     },
  //     getProductExactQuantity: dealData.getProduct.exactQuantity,
  //     getProductVariant: {
  //       [dealData.getProduct.variantID]: {
  //         name: dealData.getProduct.starterInfo.variantName,
  //       },
  //     },
  //     getOffNumeric: dealData.getOff.numericAmount,
  //     getOffType: dealData.getOff.type,
  //   };
  // } else if (dealType === "REGULAR") {
  //   const dealData = dealInfo.REGULAR;
  //   parsedDealInfo = {
  //     chosenCategories: dealData.starterInfo.chosenCategories.reduce(
  //       (acc, item) => ({
  //         ...acc,
  //         [item.categoryID]: {
  //           ...item,
  //           name: item.categoryName,
  //         },
  //       }),
  //       {}
  //     ),
  //     chosenProducts: dealData.starterInfo.chosenProducts.reduce(
  //       (acc, item) => ({
  //         ...acc,
  //         [item.productID]: {
  //           ...item,
  //           product: {
  //             name: item.productName,
  //           },
  //         },
  //       }),
  //       {}
  //     ),
  //     getOffNumeric: dealData.getOff.numericAmount,
  //     getOffType: dealData.getOff.type,
  //     appliesTo: dealData.chosenCategories?.length ? "CATEGORIES" : "PRODUCTS",
  //   };
  // } else if (dealType === "TIERED") {
  //   const dealData = dealInfo.TIERED;
  //   parsedDealInfo = {
  //     buyMinimumType: dealData.buyMinimumType,
  //     issueAmountType: dealData.issueAmountType,
  //     tiers: { ...dealData.tiers },
  //     selectedProduct: {
  //       [dealData.productID]: {
  //         product: {
  //           name: dealData.starterInfo.productName,
  //         },
  //       },
  //     },
  //     chosenVariants: dealData?.starterInfo.chosenVariants.reduce(
  //       (acc, item) => ({
  //         ...acc,
  //         [item.variantID]: { name: item.variantName },
  //       }),
  //       {}
  //     ),
  //   };
  // }
  const expiryDataToTarget = outletBasisConfig[currentOutletChainID];
  const startsOn =
    expiryDataToTarget.startsOnDate && expiryDataToTarget.startsOn12Hours
      ? new Date(
          `${expiryDataToTarget.startsOnDate} ${expiryDataToTarget.startsOn12Hours}`
        )
      : expiryDataToTarget.startsOn;
  const endsOn =
    expiryDataToTarget.endsOnDate && expiryDataToTarget.endsOn12Hours
      ? new Date(
          `${expiryDataToTarget.endsOnDate} ${expiryDataToTarget.endsOn12Hours}`
        )
      : expiryDataToTarget.endsOn;
  return {
    ...initialState,
    _id,
    // name,
    // dealType,
    // images,
    canBeOverridden,
    // dealInfo: {
    //   ...initialState.dealInfo,
    //   [dealType]: parsedDealInfo,
    // },
    applicableDays: outletBasisConfig[currentOutletChainID]?.applicableDays,
    neverExpires: outletBasisConfig[currentOutletChainID].neverExpires,
    startsOn,
    endsOn,
    // totalUsageLimit:
    //   outletBasisConfig[currentOutletChainID].rulesInfo.rules.totalUsageLimit,
    // usageLimitPerCustomer:
    //   outletBasisConfig[currentOutletChainID].rulesInfo.rules
    //     .usageLimitPerCustomer,
    // isRuleApplicable:
    //   outletBasisConfig[currentOutletChainID].rulesInfo.isApplicable,
    applyToAllOutlets:
      JSON.stringify(Object.keys(outletBasisConfig).sort()) ===
      JSON.stringify(allOutletChainIds.sort())
        ? true
        : false,
    totalUsageLimit: rulesInfo.rules.totalUsageLimit,
    usageLimitPerCustomer: rulesInfo.rules.usageLimitPerCustomer,
    isRuleApplicable: rulesInfo.isApplicable,
    status: !!outletBasisConfig[currentOutletChainID].status,
    hasDependenciesOnOtherOutlets:
      !outletBasisConfig[currentOutletChainID] ||
      Object.values(outletBasisConfig).length !== 1,
  };
};

const getFormValidationErrors = (formData) => {
  let errors = {};
  //manipulate start date and end date
  if (new Date(formData.startsOn).toString() === "Invalid Date") {
    errors["startsOn"] = "Invalid date-time";
  }
  if (new Date(formData.endsOn).toString() === "Invalid Date") {
    errors["endsOn"] = "Invalid date-time";
  }
  if (!formData.neverExpires) {
    if (!(new Date(formData.endsOn) > new Date(formData.startsOn))) {
      errors["startsOn"] = "Start date must start before End Date";
      errors["endsOn"] = "End date must start after Start Date";
    }
  }

  //manipulate rules
  if (!!formData?.isRuleApplicable) {
    if (+formData?.totalUsageLimit <= 0) {
      errors["totalUsageLimit"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer <= 0) {
      errors["usageLimitPerCustomer"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer >= +formData.totalUsageLimit) {
      errors["usageLimitPerCustomer"] =
        "Must be less than the total usage limit";
    }
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets) => {
  return {
    applicableOutlets: Array.isArray(targetOutlets) ? targetOutlets : [],
    canBeOverridden: !!formData?.canBeOverridden,
    applicableDays: formData.applicableDays,
    status: !!formData.status,
    neverExpires: !!formData.neverExpires,
    startsOn: new Date(formData.startsOn).toISOString(),
    endsOn: new Date(formData.endsOn).toISOString(),
    startsOn12Hours: moment(formData.startsOn).format("LT"),
    startsOnDate: moment(formData.startsOn).format("YYYY-MM-DD"),
    endsOn12Hours: moment(formData.endsOn).format("LT"),
    endsOnDate: moment(formData.endsOn).format("YYYY-MM-DD"),
    rulesInfo: {
      isApplicable: !!formData?.isRuleApplicable,
      rules: {
        totalUsageLimit: formData?.totalUsageLimit,
        usageLimitPerCustomer: formData?.usageLimitPerCustomer,
      },
    },
  };
};
export const editDealSlice = createSlice({
  name: "edit-deal",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    replaceDealStateAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetDealStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      // action.payload = { propName: String, propValue: any, dealType: TypedDealstype }
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetDealStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
  replaceDealStateAction,
} = editDealSlice.actions;

export const selectEditDealState = (state) => state?.editDeal ?? initialState;
export const selectEditBogoDealInfoState = (state) =>
  state.editDeal?.dealInfo[TypedDealsTypes.BOGO] ?? DealInfoInitialData.BOGO;
export const selectEditTieredDealInfoState = (state) =>
  state.editDeal?.dealInfo[TypedDealsTypes.TIERED] ??
  DealInfoInitialData.TIERED;
export const selectEditRegularDealInfoState = (state) =>
  state.editDeal?.dealInfo[TypedDealsTypes.REGULAR] ??
  DealInfoInitialData.REGULAR;
export const selectEditValidationErrors = (state) =>
  state?.editDeal?.validationErrors ?? {};
export const editDealThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(getState().editDeal);
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentDealData = getState().editDeal;
    let body = getSerializedFormData(currentDealData, outlets);
    url
      .put(`/v1/typed-deals?_id=${currentDealData._id}`, body)
      .then(() => {
        dispatch(setSuccessActions());
      })
      .catch((e) => {
        dispatch(setErrorAction(e));
      });
  }
};
export default editDealSlice.reducer;
