import React from "react";
import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinkDropDownComponent from "../../components/SocialLinksPage/LinksDropDown.component";

/**
 *
 * @param addedLinks<Object[]>: List of all the selected socials of all SocialInput component by user
 * @param selected<Object>: Selected social for this component
 * @param onChange<((socialInfo) => {})>: This returns an object containing url, selected medium and other relevent informations
 * @param onSocialSelect<(previousObject, toChange) => {}>: Currently selected social and previous selected social
 * @returns
 */
function SocialInput({ addedLinks, selected, onChange, onSocialSelect }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [medium, setMedium] = React.useState(
    selected ? selected : { medium: "choose" }
  );
  const [input, setInput] = React.useState(
    selected ? selected.starterLink : ""
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    setInput(selected.starterLink);
  }, [selected]);

  const handleSocialSelect = (previousObject, toChange) => {
    setMedium(toChange);
    setInput(toChange.starterLink);
    if (onSocialSelect) {
      onSocialSelect(previousObject, toChange);
    }
    handleClose();
  };
  const handleChange = (e) => {
    let paste = e.clipboardData || window.clipboardData;
    if (!paste) {
      setInput(e.target.value);
      if (onChange) {
        onChange({
          ...medium,
          url: e.target.value,
        });
      }
    }
  };
  // const handlePaste = (e) => {
  //   // let paste = (e.clipboardData || window.clipboardData).getData("text");
  //   //   setInput(paste)
  //   //   if (onChange) {
  //   //     onChange({
  //   //       ...medium,
  //   //       url: paste
  //   //     })
  //   // }
  // };

  return (
    <Container>
      <div className="input-container">
        <button aria-describedby={id} onClick={handleClick}>
          <span>{selected.medium}</span>
          <ArrowDropDownIcon />
        </button>
        <div className="v-divider"></div>
        <input
          type="text"
          value={input}
          onChange={handleChange}
          // onPaste={handlePaste}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LinkDropDownComponent
          taken={addedLinks.map((obj) => obj.medium)}
          handleChange={handleSocialSelect}
          previousObject={selected}
        />
      </Popover>
    </Container>
  );
}

const Container = styled.div`
  .input-container {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    display: flex;
    padding: 0px 16px;
    box-sizing: border-box;
    gap: 25px;
    background-color: white;
    /* align-items: center; */
    button {
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: white;
      color: #747474;
      cursor: pointer;
      span {
        color: #ffa382;
      }
    }
    .v-divider {
      width: 1px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        height: 70%;
        width: 100%;
        transform: translateY(-50%);
        background-color: #dbdbdb;
      }
    }
    input {
      flex: 1;
      border: none;
      outline: none;
      padding: 12px;
      box-sizing: border-box;
    }
  }
`;

export default SocialInput;
