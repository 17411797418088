import { Fragment } from "react";
import { Skeleton } from "@mui/material";
const SessionLoaderComponent = () => {
  return (
    <Fragment>
      {new Array(3).fill(1).map((_, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              gap: "2em",
              flexWrap: "wrap",
              padding: "0em 2em",
              borderRadius: "14px",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.8em",
              }}>
              <Skeleton
                animation={"wave"}
                variant={"circular"}
                height={50}
                width={50}
              />
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  paddingLeft: "1em",
                  gap: "1em",
                }}>
                <Skeleton
                  variant="rectangular"
                  animation={"wave"}
                  style={{
                    height: "10px",
                    width: "25vw",
                  }}></Skeleton>
                <Skeleton
                  variant="rectangular"
                  animation={"wave"}
                  style={{
                    height: "10px",
                    width: "10vw",
                  }}></Skeleton>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};
export default SessionLoaderComponent;
