import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";

export const addGlobalStateSlice = createSlice({
  name: "global",
  initialState: {
    stats: null,
  },
  reducers: {
    setDashboardStats: (state, action) => {
      const Stats = action.payload;
      return {
        ...state,
        stats: Stats,
      };
    },
  },
});

export const { setDashboardStats } = addGlobalStateSlice.actions;

export const getNotificationSettingsDetails =
  (outletChainID) => (dispatch, getState) => {
    url
      .get(`/v1/general-settings/basic-info?outletChainID=${outletChainID}`)
      .then((res) => {
        const response = res.data.data;
        if (response.timezone) {
          localStorage.setItem("timezone", response.timezone);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const fetchDashboardStatsThunk = (strategy, fromDays) => (dispatch) => {
  url
    .get(
      `/v1/sold-products-stats/day-basis-ranks?strategy=${strategy}&fromDays=${fromDays}`
    )
    .then((res) => {
      if (res) {
        dispatch(setDashboardStats(res?.data));
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export default addGlobalStateSlice.reducer;
