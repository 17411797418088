import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DoubleColumn, MultipleFlexibleColumns } from "../../Style.style";
import BasicSelectorChip from "../../atoms/Chips/BasicSelectorChips";
import InputPrimary from "../../atoms/InputPrimary";
import Section from "../../atoms/Section";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import { TypedDealsDaySelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import {
  changeSinglePropertyAction,
  selectEditDealState,
  selectEditValidationErrors,
} from "../../redux/slices/editDealSlice";
import { addDays } from "date-fns";
import DateTimePicker from "../../atoms/DatePicker/DateTimePicker";

const EditTypedDealRulesAndExpiryForm = () => {
  const dispatch = useDispatch();
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const state = useSelector(selectEditDealState);
  const {
    applicableDays,
    startsOn,
    endsOn,
    totalUsageLimit,
    usageLimitPerCustomer,
    neverExpires,
    status,
    applyToAllOutlets,
    canBeOverridden,
    hasDependenciesOnOtherOutlets,
  } = state;
  const formValidationErrors = useSelector(selectEditValidationErrors);

  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };

  return (
    <Fragment>
      {/* <div className="col-md-12" style={{ paddingTop: "5px" }}>
        <div
          style={{
            color: "#FFA382",
            fontSize: "17px",
            marginTop: "20px",
            marginBottom: "20px",
            paddingLeft: "5px",
            fontWeight: "bold",
          }}>
          Deal Details
        </div>
      </div> */}
      <div className="col-md-12 mt-4">
        <Section label="Validity">
          <FormControl>
            <RadioGroup
              row
              value={String(+neverExpires)}
              onChange={(e) => {
                handleChange(
                  e.target.name,
                  +e.target.value === 1 ? true : false
                );
                handleChange("startsOn", new Date());
                handleChange("endsOn", addDays(new Date(), 7));
              }}
              aria-labelledby="neverExpiresLabel"
              name="neverExpires">
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                label="Never Expires"
              />
              <FormControlLabel
                value={"0"}
                control={<Radio />}
                label="Specific Dates"
              />
            </RadioGroup>
          </FormControl>
          <DoubleColumn gap="20px">
            <div>
              <DateTimePicker
                label="Starts On"
                value={startsOn}
                onChange={(value) => handleChange("startsOn", value)}
                minDate={new Date()}
              />
              <span className="validation-help">
                {formValidationErrors?.startsOn
                  ? formValidationErrors.startsOn
                  : ""}
              </span>
            </div>
            {/* If never expires checked, expire date picker won't show */}
            {!neverExpires && (
              <div>
                <DateTimePicker
                  label="Expires On"
                  type="date"
                  value={endsOn}
                  onChange={(value) => handleChange("endsOn", value)}
                />
                <span className="validation-help">
                  {formValidationErrors?.endsOn
                    ? formValidationErrors.endsOn
                    : ""}
                </span>
              </div>
            )}
          </DoubleColumn>
          <DividerLine />
          <DaysWrapper>
            <label>Select Days</label>
            <div>
              {Object.values(TypedDealsDaySelectors).map((item, index) => (
                <BasicSelectorChip
                  key={`selected-days-${index}`}
                  selected={!!applicableDays[item.value]}
                  disabled={false}
                  label={item.displayName}
                  onChange={() => {
                    handleChange("applicableDays", {
                      ...applicableDays,
                      [item.value]: !applicableDays[item.value],
                    });
                  }}
                />
              ))}
            </div>
          </DaysWrapper>
        </Section>
      </div>
      {isMultiChainAdmin || !hasDependenciesOnOtherOutlets ? (
        <div className="col-md-12">
          <Section label="Rules">
            <MultipleFlexibleColumns width="350px" gap="20px">
              <div>
                <InputPrimary
                  label="Total Usage Limit"
                  type="number"
                  name="totalUsageLimit"
                  value={totalUsageLimit}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <span className="validation-help">
                  {formValidationErrors?.totalUsageLimit
                    ? formValidationErrors.totalUsageLimit
                    : ""}
                </span>
              </div>
              <div>
                <InputPrimary
                  label="Limit Uses per Customer"
                  type="number"
                  name="usageLimitPerCustomer"
                  value={usageLimitPerCustomer}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <span className="validation-help">
                  {formValidationErrors?.usageLimitPerCustomer
                    ? formValidationErrors.usageLimitPerCustomer
                    : ""}
                </span>
              </div>
            </MultipleFlexibleColumns>
          </Section>
        </div>
      ) : null}
      <div className="col-md-12">
        <Section label="Status">
          <div style={{ transform: "translateX(-15px)" }}>
            <ToggleSwitch
              status={status}
              onChange={() => handleChange("status", !status)}
            />
          </div>
        </Section>
      </div>
      {isMultiChainAdmin ? (
        <div className="col-md-12">
          <Section label="Access Control">
            <Fragment>
              <FormGroup className="mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applyToAllOutlets"
                      checked={applyToAllOutlets}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Apply to all outlets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="canBeOverridden"
                      checked={canBeOverridden}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Sole chain admins can override changes"
                />
              </FormGroup>
            </Fragment>
          </Section>
        </div>
      ) : null}
    </Fragment>
  );
};

export default EditTypedDealRulesAndExpiryForm;
const DaysWrapper = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
`;
const DividerLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-top: 12px;
  margin-bottom: 16px;
`;
