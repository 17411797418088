export const TypedDealsTypes = {
  BOGO_BUNDLED: "BOGO_BUNDLED",
  REGULAR: "REGULAR",
  BOGO: "BOGO",
  TIERED: "TIERED",
  TIERED_BUNDLED: "TIERED_BUNDLED",
};
export const TypedDealsDays = {
  MON: "MON",
  TUES: "TUES",
  WED: "WED",
  THURS: "THURS",
  FRI: "FRI",
  SAT: "SAT",
  SUN: "SUN",
};

export const TypedDealsAmountType = {
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE",
};

export const TypedDealsBuyMinimumType = {
  AMOUNT: "AMOUNT",
  QUANTITY: "QUANTITY",
};

export const TypedRegularDealsAppliesTo = {
  PRODUCTS: "PRODUCTS",
  CATEGORIES: "CATEGORIES",
  BRANDS: "BRANDS",
};

export const TypedBogoBundledDealsAppliesTo = {
  PRODUCTS: "PRODUCTS",
  CATEGORIES: "CATEGORIES",
  BRANDS: "BRANDS",
};

export const TypedTieredBundledDealsAppliesTo = {
  PRODUCTS: "PRODUCTS",
  CATEGORIES: "CATEGORIES",
  BRANDS: "BRANDS",
};

export const TypedBogoBundledDeralsExtendedProductPreference = {
  SELF: "SELF",
  OTHER: "OTHER",
};

export const BogoBundledTargetOffEntity = {
  ON_EXTENDED_PRODUCT: "ON_EXTENDED_PRODUCT",
  ON_BUNDLE: "ON_BUNDLE",
};
