import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import Success from "../../../assets/Success1.png";
import InputPrimary from "../../../atoms/InputPrimary/index.js";
import SelectPrimary from "../../../atoms/SelectPrimary/index.js";
import url from "../../../config/axios";
import {
  selectifMultiChainAdmin,
  selectMyOutlets,
} from "../../../redux/slices/authSlice";
import {
  chargeAmountTypes,
  initialEachChargeType,
} from "../constants/additonal-charges.constants.js";
import { checkIfArrayObjectsAreSame } from "../utils/additional-charges.utils";

function AdditionalCharges({ invokeLogout }) {
  const currentOutletChainID = JSON.parse(localStorage.getItem("Admin"))[0]
    ?.outletChainID;
  const consumerID = JSON.parse(localStorage.getItem("Admin"))[0]?.consumerID;
  const outlets = useSelector(selectMyOutlets);
  const [parsedOutlets, setParsedOutlets] = useState([currentOutletChainID]);
  const [formRelatedErrors, setFormRelatedErrors] = useState({});

  const validateForm = () => {
    let currentErrors = {};
    if (!formState?.arePricesTaxIncluded) {
      if (Object.values(formState?.charges)?.length < 1) {
        currentErrors["rootError"] = "At least one scenario needs to be added";
      } else {
        delete currentErrors["rootError"];
      }
    }
    //manipulate charges
    let charges = formState?.charges ?? {};
    let chargeRelatedErrors = {};
    Object.values(charges).forEach((obj, index) => {
      const { name, amount } = obj;
      if (!name?.trim()?.length) {
        if (!chargeRelatedErrors[index]) {
          chargeRelatedErrors[index] = {};
        }
        chargeRelatedErrors[index]["name"] =
          "The name of the scenario should be provided";
      }
      if (!(amount && !isNaN(amount) && Number(amount) > 0)) {
        if (!chargeRelatedErrors[index]) {
          chargeRelatedErrors[index] = {};
        }
        chargeRelatedErrors[index]["amount"] =
          "The amount of the scenario should be a positive number";
      }
    });
    if (Object.keys(chargeRelatedErrors)?.length) {
      currentErrors = { ...currentErrors, chargeRelatedErrors };
    }
    setFormRelatedErrors({ ...currentErrors });
    return currentErrors;
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const updateChanges = async () => {
    const errors = validateForm();
    if (!Object.values(errors)?.length) {
      setFormState({
        ...formState,
        updationInProgress: true,
        error: null,
      });
      const body = {
        consumerID,
        applicableOutlets:
          isMultiChainAdmin && formState?.applyToAllOutlets
            ? [...parsedOutlets]
            : [currentOutletChainID],
        charges: Object.values(formState.charges),
        canBeOverriden: isMultiChainAdmin ? formState?.canBeOverriden : true,
        arePricesTaxIncluded: formState.arePricesTaxIncluded,
      };
      url
        .put(`/v1/additional-charges/upsert-additional-charges`, body)
        .then((response) => {
          setFormState({
            ...formState,
            updationInProgress: false,
            error: null,
          });
          setFormRelatedErrors({});
          serializeApiResponsesToState(response?.data?.data);
          openTooltip();
        })
        .catch((e) => {
          setFormState({
            ...formState,
            updationInProgress: false,
            error: e?.response?.data?.data?.message ?? "Something went wrong",
          });
        });
    }
  };
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const initialFormState = {
    loading: false,
    error: null,
    arePricesTaxIncluded: false,
    canBeOverriden: true,
    charges: {},
    applyToAllOutlets: false,
    updationInProgress: false,
  };
  const [formState, setFormState] = useState({
    ...initialFormState,
  });
  const handleAddChargeSlot = () => {
    setFormState({
      ...formState,
      charges: {
        ...formState.charges,
        [Object.keys(formState.charges)?.length ?? 0]: {
          ...initialEachChargeType,
        },
      },
    });
  };
  const handleRemoveChargeSlot = (index) => {
    setFormState({
      ...formState,
      charges: Object.assign(
        {},
        Object.values(formState.charges).filter((_, i) => i !== index)
      ),
    });
  };
  const handlePropertyChange = (name, value) => {
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  /**
   *
   * @param {number} index index of the charge item
   * @param {string} name name of the charge property
   * @param {any} value value of the charge property
   */
  const handleChargeChange = (index, name, value) => {
    if (
      formState?.charges[index] &&
      formState.charges[index].hasOwnProperty(name)
    ) {
      const currentState = { ...formState };
      currentState.charges[index][name] = value;
      setFormState({
        ...currentState,
      });
    }
  };
  //parse all outlets
  useEffect(
    () => {
      if (isMultiChainAdmin && outlets?.length) {
        setParsedOutlets(
          outlets.map((obj) => obj.outletChainID).filter((item) => !!item)
        );
      }
    },
    //eslint-disable-next-line
    [isMultiChainAdmin, outlets]
  );
  const populateOldData = async () => {
    setFormState({
      ...initialFormState,
      loading: true,
    });
    await url
      .get(`/v1/additional-charges`)
      .then((response) => {
        setFormState({
          ...initialFormState,
          loading: false,
          error: null,
        });
        serializeApiResponsesToState(response?.data?.data);
      })
      .catch((e) => {
        setFormState({
          ...initialFormState,
          loading: false,
          error: e?.response?.data?.data?.message ?? "Something went wrong",
        });
      });
  };
  useEffect(
    () => {
      populateOldData();
    },
    //eslint-disable-next-line
    []
  );
  //response?.data?.data
  const serializeApiResponsesToState = (data) => {
    const outletBasisCharges = data?.additionalCharges?.outletBasisCharges;
    if (outletBasisCharges) {
      let applyToAllOutlets = false;
      if (isMultiChainAdmin) {
        try {
          const outletsToIterate = outlets?.length
            ? outlets.map((key) => key?.outletChainID).filter((el) => !!el)
            : [currentOutletChainID];
          const configsToIterate = outletsToIterate
            .map((key) => outletBasisCharges[key])
            .filter((el) => !!el);
          const sameObjectShared = checkIfArrayObjectsAreSame(configsToIterate);
          if (
            isMultiChainAdmin &&
            sameObjectShared &&
            outletsToIterate?.length === configsToIterate?.length
          ) {
            applyToAllOutlets = true;
          }
        } catch (e) {}
      }
      if (outletBasisCharges[currentOutletChainID]) {
        const charges = outletBasisCharges[currentOutletChainID]?.charges ?? [];
        setFormState({
          ...formState,
          applyToAllOutlets,
          charges: Object.assign({}, charges),
          canBeOverriden: isMultiChainAdmin
            ? !!outletBasisCharges[currentOutletChainID].canBeOverriden
            : true,
          arePricesTaxIncluded:
            !!outletBasisCharges[currentOutletChainID].arePricesTaxIncluded,
        });
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
      }}>
      {formState.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <FormControl>
            <FormLabel id="tax-label"></FormLabel>
            <RadioGroup
              row
              value={String(+formState.arePricesTaxIncluded)}
              onChange={(e) =>
                handlePropertyChange(
                  e.target.name,
                  +e.target.value === 1 ? true : false
                )
              }
              aria-labelledby="tax-label"
              name="arePricesTaxIncluded"
            >
              <FormControlLabel
                value={"0"}
                control={<Radio />}
                label="Tax Excluded"
              />
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                label="Tax Included"
              />
            </RadioGroup>
          </FormControl>
          {!formState?.arePricesTaxIncluded ? (
            <Fragment>
              {Object.values(formState.charges)?.length ? (
                <Fragment>
                  {Object.values(formState.charges).map((obj, index) => (
                    <Fragment key={`each-charge-${index}`}>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="group-comb">
                            <InputPrimary
                              label="Tax Name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Tax Name"
                              onChange={(e) => {
                                handleChargeChange(
                                  index,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              value={obj.name}
                            ></InputPrimary>
                            <span className="validation-help">
                              {formRelatedErrors?.chargeRelatedErrors &&
                              formRelatedErrors?.chargeRelatedErrors[index]
                                ?.name?.length
                                ? formRelatedErrors?.chargeRelatedErrors[
                                    `${index}`
                                  ]?.name
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row mt-2 mb-4"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-md-6">
                          <div>
                            <div className="group-comb">
                              <SelectPrimary
                                label="Tax Type"
                                value={obj.chargeType}
                                name="chargeType"
                                onChange={(e) => {
                                  handleChargeChange(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                              >
                                {Object.values(chargeAmountTypes).map(
                                  (value, i) => {
                                    return (
                                      <option key={i} value={value}>
                                        {value}
                                      </option>
                                    );
                                  }
                                )}
                              </SelectPrimary>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="group-comb">
                            <InputPrimary
                              label="Tax Value"
                              name="amount"
                              type="number"
                              className="form-control"
                              placeholder="Tax Value"
                              onChange={(e) => {
                                const parsedNumber = Number(e.target.value);
                                if (
                                  !Number.isNaN(parsedNumber) &&
                                  parsedNumber >= 0
                                ) {
                                  handleChargeChange(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  );
                                }
                              }}
                              value={obj.amount}
                            ></InputPrimary>
                          </div>
                          <span className="validation-help">
                            {formRelatedErrors?.chargeRelatedErrors &&
                            formRelatedErrors?.chargeRelatedErrors[index]
                              ?.amount?.length
                              ? formRelatedErrors?.chargeRelatedErrors[
                                  `${index}`
                                ]?.amount
                              : ""}
                          </span>
                        </div>
                        <div className="col-md-1 filters-bar m-1">
                          <button
                            onClick={() => handleRemoveChargeSlot(index)}
                            className="btn btn-primary border-0"
                            style={{
                              color: "#FFA382",
                              backgroundColor: "white",
                            }}
                          >
                            <i className="fas fa-minus-circle"></i>
                            Remove
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <span
                  className="m-2 p-3 rounded"
                  style={{
                    backgroundColor: "#ddebeb",
                  }}
                >
                  No tax-related scenarios have been added yet!
                </span>
              )}
              <div className="col-md-1 filters-bar m-1">
                <button
                  onClick={() => handleAddChargeSlot()}
                  className="btn btn-primary border-0"
                  style={{
                    color: "#FFA382",
                    backgroundColor: "white",
                  }}
                >
                  <i className="fas fa-plus-circle"></i>
                  Add Tax
                </button>
              </div>
              {/* root errors */}
              {formState?.error?.length ||
              formRelatedErrors?.rootError?.length ? (
                <span
                  className="m-2 p-3 rounded"
                  style={{
                    backgroundColor: "#f0ffff",
                    color: "red",
                  }}
                  onClick={() => {
                    setFormState({
                      ...formState,
                      error: null,
                    });
                    setFormRelatedErrors({
                      ...formRelatedErrors,
                      rootError: null,
                    });
                  }}
                >
                  {formState?.error ??
                    formRelatedErrors?.rootError ??
                    "Something went wrong"}
                </span>
              ) : null}
            </Fragment>
          ) : null}
          {/* checkboxes */}
          {isMultiChainAdmin ? (
            <Fragment>
              <FormGroup className="mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applyToAllOutlets"
                      checked={formState?.applyToAllOutlets}
                      onChange={(e) =>
                        handlePropertyChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Apply to all outlets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="canBeOverriden"
                      checked={formState?.canBeOverriden}
                      onChange={(e) =>
                        handlePropertyChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Can sole admin change the tax scenario?"
                />
              </FormGroup>
            </Fragment>
          ) : null}
          <div
            className="row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              gap: "2em",
              marginTop: "3em",
            }}
          >
            <button
              onClick={() => updateChanges()}
              className="btn btn-cannaby"
              disabled={formState?.updationInProgress}
              style={{
                margin: "0",
                padding: "0.5em 3em",
                borderRadius: "5px",
                // fontWeight: "bold",
                // color: "white",
                backgroundColor: "#FFA382",
                border: "none",
                cursor: "pointer",
              }}
            >
              {formState?.updationInProgress ? "Updating..." : "Update"}
            </button>
          </div>
        </Fragment>
      )}

      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          {" "}
          <div>
            <img height="60" alt="" src={Success} />
          </div>
          <p className="popup-heading" style={{ marginTop: "20px" }}>
            Updated successfully <br />
            <span
              style={{
                color: "#747474",
                fontSize: "10px",
                marginTop: "-10px !important",
              }}
            >
              Tax details have been updated
            </span>
          </p>
          <button
            className="btn btn-cannaby"
            style={{
              backgroundColor: "#FFA382",
              marginTop: "0",
              width: "20vh",
              alignSelf: "center",
            }}
            onClick={() => {
              closeTooltip();
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default AdditionalCharges;
