import React from 'react'
import './style.css'

function Section({ label, children }) {
  return (
    <div className="section">
        <h2 className="section-title">{label}</h2>
        {children}
    </div>
  )
}

export default Section