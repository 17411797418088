import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Popup from "reactjs-popup";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";

export default function Privacy() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [Body, setBody] = useState("");
  // const [outletId, setOutletId] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  const queryParams = new URLSearchParams(window.location.search);
  let pageName = queryParams.get("id");

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const addPage = () => {
    let body = {
      pageName: pageName,
      consumerId: admin[0].consumerId,
      body: Body,
      title: title,
      subtitle: subtitle,
    };

    url
      .put(`/page?consumerId=${admin[0].consumerId}&page=${pageName}`, body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setSuccess("Page updated successfully");
          setTimeout(() => {
            history.push("/Page");
          }, 1000);
        } else {
          setError("Failed to update page");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    function getPage() {
      url
        .get(`/page/id?consumerId=${admin[0].consumerId}&page=${pageName}`)
        .then(async (res) => {
          if (res.data.Message === "Success") {
            if (res.data.data.length === 0) {
              setTitle("");
              setSubtitle("");
              setBody("");
            } else {
              setTitle(res.data.data.title);
              setSubtitle(res.data.data.subtitle);
              setBody(res.data.data.body);
            }
          }
        });
    }
    getPage();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-md-12">
          <div className="card card-pro" id="nopad">
            <div className="row" style={{ borderRadius: "7px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{
                        borderBottom: "2px solid #F1F5F9",
                        paddingBottom: "4px",
                      }}>
                      <div
                        style={{
                          height: "30px",
                          marginLeft: "0px",
                          backgroundColor: "#FFA382",
                          marginTop: "10px",
                        }}>
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      <div className="col-md-4 d-flex">
                        <div
                          style={{
                            color: "#FFA382",
                            fontSize: "20px",
                            paddingTop: "10px",
                          }}>
                          {pageName === "privacy"
                            ? "Privacy policy"
                            : "Terms & Conditions"}
                        </div>
                      </div>
                      <div className="col-md-2 filters-bar">
                        <div className="dropdown"></div>
                        <div className="dropdown">
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" href="/">
                              Action
                            </a>
                            <a className="dropdown-item" href="/">
                              Another action
                            </a>
                            <a className="dropdown-item" href="/">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="group-comb" style={{ marginTop: "2em" }}>
                      <div style={{ borderRadius: "2px" }}>
                        <label>Title </label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div style={{ borderRadius: "2px" }}>
                        <label>Subtitle </label>
                        <input
                          type="text"
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                          className="form-control"
                          placeholder="Subtitle"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <label>Body </label>

                      <div>
                        <div>
                          <CKEditor
                            row={"20"}
                            editor={ClassicEditor}
                            data={Body}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "height",
                                  "400px",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBody(data);
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                            config={{
                              toolbarLocation: "bottom",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "flex-end",
                        paddingBottom: "4px",
                        marginTop: "-8px",
                      }}>
                      <button
                        type="button"
                        onClick={addPage}
                        className="btn btn-cannaby"
                        style={{ backgroundColor: " #FFA382" }}>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights="Page updated successfully"
          onClose={() => {
            setSuccess(null);
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Failed to update page"
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
    </div>
  );
}
