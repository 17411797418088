import FormControlLabel from "@mui/material/FormControlLabel";
import { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import FormGroup from "@mui/material/FormGroup";
import DateTimePicker from "../../atoms/DatePicker/DateTimePicker";
import Checkbox from "@mui/material/Checkbox";
import {
  changeSinglePropertyAction,
  selectAddNotificationState,
  selectValidationErrors,
} from "../../redux/slices/addNotificationSlice";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import styled from "@emotion/styled";
import getCroppedImg from "../../helpers/cropImage";
import moment from "moment";

const AddTypedNotification = () => {
  const dispatch = useDispatch();
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const state = useSelector(selectAddNotificationState);
  const {
    title,
    highlights,
    status,
    applyToAllOutlets,
    viaPush,
    viaInApp,
    scheduledAtDateTime,
    scheduleForLater,
  } = state;
  const formValidationErrors = useSelector(selectValidationErrors);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      // e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("croppedImage", croppedImage);
        setCroppedImage(croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
        handleChange("imageFile", {
          name: fileName,
          source: croppedImage,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    let numFile = Math.floor(Math.random() * 10) + 1;
    setFileName(event.name + numFile);
    if (event) {
      setEditImage(URL.createObjectURL(event));
      setFile(event);
      setOpen(true);
      setShowCrop(true);
    }
  };

  return (
    <>
      <div className="col-md-12 mb-4 mt-3">
        <InputPrimary
          value={title}
          name="title"
          label={"Title"}
          placeholder="Title"
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.title ? formValidationErrors.title : ""}
        </span>
      </div>
      <div className="col-md-12 mb-4">
        <InputPrimary
          value={highlights}
          name="highlights"
          label={"Description"}
          placeholder="Write Here..."
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.highlights
            ? formValidationErrors.highlights
            : ""}
        </span>
      </div>
      <div className="col-md-12 px-3 mb-4">
        <div
          className="fluid d-flex justify-content-center rounded py-4"
          style={{ backgroundColor: "#f9fbff" }}>
          <FileUpload
            onFileRemoved={async () => {
              handleChange("images", []);
              handleChange("imageFile", null);
            }}
            // onFileLoaded={() => {
            // }}
            label={"Upload Notification Image"}
            size={"500 x 500 px"}
            ImageHeight={"500"}
            ImageWidth={"500"}
            imageSelector={imageSelector}
            cropProperties={{
              cropDialogOpen: open,
              setCropDialogOpen: setOpen,
              showCrop,
              showCroppedImage,
              onCropComplete,
              editImage,
              ImageHeight: 500,
              ImageWidth: 500,
            }}
            editImage={editImage}
          />
        </div>
      </div>
      <div className="col-md-12">
        <p
          style={{
            color: "red",
          }}>
          Please upload a JPG file with dimensions of 500 x 500 px pixels and a
          file size not exceeding 1 MB.
        </p>
      </div>
      <div className="col-md-12">
        <LabelWrapper>
          <label></label>
        </LabelWrapper>
        <>
          <FormGroup className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="scheduleForLater"
                  checked={scheduleForLater}
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.checked)
                  }
                />
              }
              label="Schedule For Later"
            />
            {scheduleForLater ? (
              <div style={{ marginBottom: "2em" }}>
                <DateTimePicker
                  label="Choose Time"
                  value={scheduledAtDateTime}
                  onChange={(value) =>
                    handleChange("scheduledAtDateTime", value)
                  }
                  minDate={new Date()}
                  maxDate={moment(new Date()).add(7, "days").toDate()}
                  disableTextField={true}
                />
                <span className="validation-help">
                  {formValidationErrors?.startsOn
                    ? formValidationErrors.startsOn
                    : ""}
                </span>
              </div>
            ) : null}
          </FormGroup>
        </>
      </div>
      <div className="col-md-12">
        <LabelWrapper>
          <label>Notification Type</label>
        </LabelWrapper>
        <>
          <FormGroup className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="viaPush"
                  checked={viaPush}
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.checked)
                  }
                />
              }
              label="Push Notifications"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="viaInApp"
                  checked={viaInApp}
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.checked)
                  }
                />
              }
              label="In-App Notifications"
            />
          </FormGroup>
        </>
      </div>
      {isMultiChainAdmin && !scheduleForLater ? (
        <div className="col-md-12 mt-3">
          <>
            <LabelWrapper>
              <label>Access Control</label>
            </LabelWrapper>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="applyToAllOutlets"
                    checked={applyToAllOutlets}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.checked)
                    }
                  />
                }
                label="Send to all outlets"
              />
            </FormGroup>
          </>
        </div>
      ) : null}
    </>
  );
};

const LabelWrapper = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
`;

export default AddTypedNotification;
