import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { getCouponFormInitialData } from "../../pages/TypedCoupons/constants/form-initiator.constants";
import moment from "moment";

const initialState = getCouponFormInitialData();

export const getFormCompatibleDataFromResponse = (data) => {
  let currentOutletChainID = null;
  const {
    canBeOverridden,
    outletBasisConfig,
    _id,
    rulesInfo,
    couponCode,
    discountAmount,
  } = data;
  try {
    currentOutletChainID = JSON.parse(localStorage.getItem("Admin"))[0]
      .outletChainID;
  } catch (e) {}
  const expiryDataToTarget = outletBasisConfig[currentOutletChainID];
  const startsOn =
    expiryDataToTarget.startsOnDate && expiryDataToTarget.startsOn12Hours
      ? new Date(
          `${expiryDataToTarget.startsOnDate} ${expiryDataToTarget.startsOn12Hours}`
        )
      : expiryDataToTarget.startsOn;
  const endsOn =
    expiryDataToTarget.endsOnDate && expiryDataToTarget.endsOn12Hours
      ? new Date(
          `${expiryDataToTarget.endsOnDate} ${expiryDataToTarget.endsOn12Hours}`
        )
      : expiryDataToTarget.endsOn;
  return {
    ...initialState,
    _id,
    canBeOverridden,
    couponCode: couponCode,
    discountAmount: discountAmount,
    applicableDays: outletBasisConfig[currentOutletChainID]?.applicableDays,
    neverExpires: outletBasisConfig[currentOutletChainID].neverExpires,
    startsOn,
    endsOn,
    totalUsageLimit: rulesInfo.rules.totalUsageLimit,
    minimumOrderValue: rulesInfo.rules.minimumOrderValue,
    usageLimitPerCustomer: rulesInfo.rules.usageLimitPerCustomer,
    isRuleApplicable: rulesInfo.isApplicable,
    status: !!outletBasisConfig[currentOutletChainID].status,
    hasDependenciesOnOtherOutlets:
      !outletBasisConfig[currentOutletChainID] ||
      Object.values(outletBasisConfig).length !== 1,
  };
};

const getFormValidationErrors = (formData) => {
  let errors = {};
  //manipulate start date and end date
  if (new Date(formData.startsOn).toString() === "Invalid Date") {
    errors["startsOn"] = "Invalid date-time";
  }
  if (new Date(formData.endsOn).toString() === "Invalid Date") {
    errors["endsOn"] = "Invalid date-time";
  }
  if (!formData.neverExpires) {
    if (!(new Date(formData.endsOn) > new Date(formData.startsOn))) {
      errors["startsOn"] = "Start date must start before End Date";
      errors["endsOn"] = "End date must start after Start Date";
    }
  }

  //manipulate rules
  if (!!formData?.isRuleApplicable) {
    if (+formData?.totalUsageLimit <= 0) {
      errors["totalUsageLimit"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer <= 0) {
      errors["usageLimitPerCustomer"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer >= +formData.totalUsageLimit) {
      errors["usageLimitPerCustomer"] =
        "Must be less than the total usage limit";
    }
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets) => {
  return {
    couponCode: formData?.couponCode,
    discountAmount: formData?.discountAmount,
    applicableOutlets: Array.isArray(targetOutlets) ? targetOutlets : [],
    canBeOverridden: !!formData?.canBeOverridden,
    applicableDays: formData.applicableDays,
    status: !!formData.status,
    neverExpires: !!formData.neverExpires,
    startsOn: new Date(formData.startsOn).toISOString(),
    endsOn: new Date(formData.endsOn).toISOString(),
    startsOn12Hours: moment(formData.startsOn).format("LT"),
    startsOnDate: moment(formData.startsOn).format("YYYY-MM-DD"),
    endsOn12Hours: moment(formData.endsOn).format("LT"),
    endsOnDate: moment(formData.endsOn).format("YYYY-MM-DD"),
    rulesInfo: {
      isApplicable: !!formData?.isRuleApplicable,
      rules: {
        minimumOrderValue: formData?.minimumOrderValue,
        totalUsageLimit: formData?.totalUsageLimit,
        usageLimitPerCustomer: formData?.usageLimitPerCustomer,
      },
    },
  };
};
export const editCouponSlice = createSlice({
  name: "edit-coupon",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    replaceCouponStateAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetCouponStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetCouponStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
  replaceCouponStateAction,
} = editCouponSlice.actions;

export const selectEditCouponState = (state) =>
  state?.editCoupon ?? initialState;
export const selectEditValidationErrors = (state) =>
  state?.editCoupon?.validationErrors ?? {};
export const editCouponThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(getState().editCoupon);
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentCouponData = getState().editCoupon;
    let body = getSerializedFormData(currentCouponData, outlets);
    url
      .put(`/v1/typed-coupons?_id=${currentCouponData._id}`, body)
      .then(() => {
        dispatch(setSuccessActions());
      })
      .catch((e) => {
        dispatch(setErrorAction(e));
      });
  }
};
export default editCouponSlice.reducer;
