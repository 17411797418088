import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BasicInfoPage from "./sub-pages/BasicInfoPage";
import NotificationSettingsPage from "./sub-pages/NotificationSettingsPage";
import MediasPageTajwar from "./sub-pages/MediaPageTajwar";
import SocialLinkPageTajwar from "./sub-pages/SocialLinkPageTajwar";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import LoyaltySetting from "./sub-pages/Loyalty-Setting";
import { Grid, Button } from "@mui/material";
import ForgotPassword from "../../components/LoginPage/ForgotPassword";
import AdditionalCharges from "./sub-pages/AdditionalCharges";
import AppDetailsPage from "./sub-pages/AppDetails";
import GoogleAnalytics from "./sub-pages/Google-Analytics";
import PricingPreferences from "./sub-pages/PricingPreferences";
function GeneralSettingsPage() {
  const tabs = [
    {
      title: "Basic Info",
      id: "basic-info",
    },
    {
      title: "Media",
      id: "media",
    },
    {
      title: "Social Link",
      id: "social-link",
    },
    {
      title: "App Details",
      id: "app-details",
    },
    {
      title: "Tax Details",
      id: "tax-details",
    },
    {
      title: "Google Analytics",
      id: "google-analytics",
    },
    {
      title: "Pricing Preferences",
      id: "pricing-preferences"
    }
  ];

  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [forgot, setForgot] = useState(false);
  // const forgot = Location?.state?.forgotPassword;

  useEffect(
    () => {
      const query = new URLSearchParams(search);
      // if (query.get("forgot")) {
      //   setForgot(true);
      // }
      setCurrentTab(query.get("tab") ?? currentTab);
    },
    //eslint-disable-next-line
    [search]
  );
  const [shouldLogout, setShouldLogout] = useState(false);
  /**
   * Set the value as
   * {
   *    text: "Button text",
   *    onClick: onclick function
   * }
   */
  const [actionButtonFunctions, setActionButtonFunctions] = useState(null);
  const invokeLogout = () => setShouldLogout(true);
  return (
    <>
      <div className="card">
        {/* Navigations */}
        <div
          className="row"
          style={{
            paddingBottom: "10px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <div
            style={{
              height: "25px",
              marginLeft: "-32px",
              backgroundColor: "#FFA382",
            }}
          >
            <span style={{ color: "#FFA382" }}>.</span>
          </div>
          <div className="col-md-3 d-flex">
            <div
              style={{ color: "#FFA382", fontSize: "18px", fontWeight: "600" }}
            >
              Settings
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "3em",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1em 2em",
            borderBottom: "2px solid #F1F5F9",
            flexGrow: 1,
          }}
        >
          {tabs.map((obj, i) => {
            return (
              <Link
                key={i}
                to={`?tab=${obj.id}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.5em 1.5em",
                    color: obj.id === currentTab ? "#FFA382" : "#747474",
                    border: "none",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    fontSize: 12
                  }}
                >
                  {obj.title}
                </div>
                <div
                  style={{
                    marginBottom: "-17px",
                    borderBottom:
                      obj.id === currentTab ? "6px solid #FFA382" : null,
                  }}
                >
                  <span style={{ color: "#FFA382", height: "15px" }}></span>
                </div>
              </Link>
            );
          })}
        </div>
        {/* tab-contents */}
        {currentTab === "basic-info" && (
          <BasicInfoPage invokeLogout={invokeLogout} />
        )}
        {currentTab === "media" && (
          <MediasPageTajwar invokeLogout={invokeLogout} />
        )}
        {currentTab === "social-link" && (
          <SocialLinkPageTajwar invokeLogout={invokeLogout} />
        )}
        {currentTab === "notification-settings" && (
          <NotificationSettingsPage invokeLogout={invokeLogout} />
        )}
        {currentTab === "Loyalty-settings" && (
          <LoyaltySetting invokeLogout={invokeLogout} />
        )}
        {currentTab === "app-details" && (
          <AppDetailsPage invokeLogout={invokeLogout} />
        )}
        {currentTab === "google-analytics" && (
          <GoogleAnalytics invokeLogout={invokeLogout} />
        )}
        {/* {currentTab === "basic-info" && forgot && (
          <ForgotPassword
            onBack={() => setForgot(false)}
            backText="Back to Basic info"
          />
        )} */}
        {currentTab === "tax-details" && (
          <AdditionalCharges invokeLogout={invokeLogout} />
        )}
        {(currentTab === 'pricing-preferences') && (
          <PricingPreferences invokeLogout={invokeLogout} />
        )}
        {shouldLogout && <InvokeLayoutComponent />}
      </div>
      <Grid justifyContent="flex-end">
        {actionButtonFunctions && (
          <Button variant="contained" onClick={actionButtonFunctions.onClick}>
            {actionButtonFunctions.text}
          </Button>
        )}
      </Grid>
    </>
  );
}

export default GeneralSettingsPage;
