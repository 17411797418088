import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import SelectPrimary from "../../atoms/SelectPrimary";
import Success from "../../assets/Success1.png";
import Failure from "../../assets/failure.png";
export default function EditSpecial() {
  const history = useHistory();
  const Coupon = JSON.parse(localStorage.getItem("specialcouponEdit"));
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [activeStatus, setActiveStatus] = useState(false);
  const [allCoupon, setAllCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [eventType, setEventType] = useState("Birthday");
  const [discountValue, setDiscountValue] = useState("");
  const [specialDate, setSpecialDate] = useState();
  let year = new Date().getFullYear();
  const specialDaysArray = [
    { name: "Nurse", date: `${year}-05-12` },
    { name: "Firefighter", date: `${year}-05-05` },
    { name: "Veterans", date: `${year}-11-11` },
    { name: "Teachers", date: `${year}-05-15` },
  ];
  const [notificationText, setNotificationText] = useState("");
  const [workType, setWorkType] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.text === "Success") {
      history.goBack();
    } else {
      ref.current.close();
    }
  };
  useEffect(() => {
    setCouponCode(Coupon.couponCode);
    setCouponType(Coupon.couponType);
    setDiscountValue(Coupon.discountValue + "");
    setEventType(Coupon.eventType);
    setWorkType(Coupon?.workType ?? "");
    setSpecialDate(Coupon.eventDate);
    setNotificationText(Coupon?.notificationText ?? "");
    setActiveStatus(Coupon.status);

    GetAllCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetAllCoupon = async () => {
    try {
      const res = await url.get(
        `/specialEvents/all/?consumerId=${adminID[0].consumerId}`
      );
      if (res.data.Message === "Success") {
        let filtered = res.data.data.filter((i) => {
          return i.couponId !== Coupon.couponId;
        });
        setAllCoupon(filtered);
      }
    } catch (e) {}
  };

  const filteredArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.eventType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {}
  };
  const filteredWorkArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.workType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {}
  };
  const handleChange = async (e) => {
    if (e !== "") {
      let find = specialDaysArray.find((i) => {
        return i.name === e;
      });
      setWorkType(find.name);
      setSpecialDate(moment(find.date));
    } else {
      setWorkType("");
      setSpecialDate("");
    }
  };
  let triedBefore = false;
  const [inProgress, setInProgres] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (notificationText.trim().length === 0) {
      data = {
        ...data,
        notificationTextError: "Notification text is required",
      };
    } else {
      let { notificationTextError, ...others } = data;
      data = { ...others };
    }
    // Validate couponCode: couponCode
    if (couponCode.trim().length === 0) {
      data = { ...data, couponCodeError: "Coupon Code is required" };
    } else {
      let { couponCodeError, ...others } = data;
      data = { ...others };
    }
    //Validate EventType: Event type
    if (eventType.trim().length === 0) {
      data = { ...data, eventTypeError: "Event type is required" };
    } else {
      let { eventTypeError, ...others } = data;
      data = { ...others };
    }
    //Validate Work Type: Work type
    if (eventType === "workAnniversary") {
      if (workType.trim().length === 0) {
        data = { ...data, workTypeError: "Work type is required" };
      } else {
        let { workTypeError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { workTypeError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (couponType === "Percentage") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0 || discountValue >= 100) {
        data = {
          ...data,
          discountError: "Please a choose a postive value within 100",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else if (couponType === "Amount") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0) {
        data = {
          ...data,
          discountError: "Positive discount value is required",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }

    setFormValidationErrors({ ...data });
    return data;
  };

  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountValue, couponType, couponCode, triedBefore]);
  const editCoupon = () => {
    let formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setInProgres(true);
      const admin = JSON.parse(localStorage.getItem("Admin"));
      const body = {
        consumerId: admin[0].consumerId,
        couponCode: couponCode,
        couponType: couponType,
        eventType: eventType,
        eventDate: specialDate ?? new Date(),
        discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
        applyToAllOutlets: true,
        entireCart: true,
        workType: workType,
        notificationText,
        status: activeStatus,
      };
      url
        .patch(`/specialEvents/?couponId=${Coupon.couponId}`, body)
        .then(async (res) => {
          setInProgres(false);
          if (res.data.Message === "Success") {
            setMessage({
              text: "Success",
              status: true,
              display: true,
            });
            openTooltip();
          }
        })
        .catch((e) => {
          setMessage({
            text: "Failed",
            status: false,
            display: true,
          });
          openTooltip();
          setInProgres(false);
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="card"
            id="nopad1"
            style={{ padding: "0px !important" }}
          >
            <div>
              {/* <div ></div> */}
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  borderBottom: "1px solid rgba(241, 245, 249, 1)",
                }}
              >
                <div className="d-flex" style={{ gap: "12px" }}>
                  {" "}
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-16px",
                      backgroundColor: "#FFA382",
                    }}
                  >
                    <span className="orangeColor">.</span>
                  </div>
                  <div className=" d-flex">
                    <div style={{ color: "#FFA382", fontSize: "18px" }}>
                      Special days
                    </div>
                  </div>
                </div>

                <div className="  d-flex">
                  <button
                    className="btn btn-primary border-0 "
                    style={{
                      backgroundColor: "#FFA382",
                      marginBottom: "5px",
                      marginLeft: "100px",
                    }}
                    onClick={() => {
                      history.push("/Special-Day");
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                    Back
                  </button>
                </div>
              </div>
              <div
                className="mt-4 orangeColor"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: "#FFA382" }}>Basic information</h5>
              </div>

              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="group-comb">
                      <label style={{ color: "#747474" }}>Coupon Code</label>
                      <div>
                        <input
                          type="text"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          className="form-control"
                          // id="exampleFormControlInput1"
                          placeholder="Coupon code"
                        />
                        {formValidationErrors.couponCodeError && (
                          <span className="emsg">
                            {formValidationErrors.couponCodeError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      eventType === "workAnniversary" ? "col-md-6" : "col-md-12"
                    }
                  >
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>Event Type</label>
                      <div>
                        <SelectPrimary
                          value={eventType}
                          onChange={(e) => setEventType(e.target.value)}
                          className="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option value="">Select Event type</option>

                          {filteredArray("Birthday") ? (
                            <option value="Birthday">Birthday</option>
                          ) : null}
                          {filteredArray("platformAnniversary") ? (
                            <option value="platformAnniversary">
                              Platform Anniversary
                            </option>
                          ) : null}
                          <option value="workAnniversary">Special Day</option>
                        </SelectPrimary>
                        {formValidationErrors.eventTypeError && (
                          <span className="emsg">
                            {formValidationErrors.eventTypeError}
                          </span>
                        )}
                        {/* <input type="text" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="discount value" /> */}
                      </div>
                    </div>
                  </div>
                  {eventType === "workAnniversary" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label style={{ paddingLeft: "0.4em" }}>
                          Special Day
                        </label>
                        <div>
                          <SelectPrimary
                            value={workType}
                            onChange={(e) => {
                              handleChange(e.target.value);
                            }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option value="">Select special day</option>
                            {specialDaysArray.map((i, key) => {
                              return filteredWorkArray(i.name) ? (
                                <option key={key} value={i.name}>
                                  {i.name} Day
                                </option>
                              ) : null;
                            })}
                          </SelectPrimary>

                          {formValidationErrors.workTypeError && (
                            <span className="emsg">
                              {formValidationErrors.workTypeError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {eventType === "workAnniversary" && workType !== "" && (
                    <div className="col-md-12">
                      <div className="alert alert-info alertBox">
                        <span>
                          * This voucher will be valid for '{" "}
                          {moment(specialDate).format("DD MMMM ")}' only
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>
                        Coupon Type
                      </label>
                      <div>
                        <SelectPrimary
                          onChange={(e) => setCouponType(e.target.value)}
                          className="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option value="Percentage">Percentage</option>
                          <option value="Amount">Amount</option>
                        </SelectPrimary>
                      </div>
                    </div>
                  </div>
                  {couponType !== "Free Shiping" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label>
                          {couponType === "Percentage"
                            ? "Discount value %"
                            : "Discount value"}
                        </label>
                        <div>
                          <input
                            type="number"
                            value={discountValue}
                            onKeyPress={(e) => {
                              var charCode =
                                typeof e.which == "undefined"
                                  ? e.keyCode
                                  : e.which;
                              var charStr = String.fromCharCode(charCode);

                              if (!charStr.match(/^[0-9]+$/))
                                e.preventDefault();
                            }}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.value.match(/^[0-9]*$/)) {
                                if (couponType === "Percentage") {
                                  if (Math.abs(e.target.value) <= 99) {
                                    setDiscountValue(
                                      parseInt(Math.abs(e.target.value)) + ""
                                    );
                                  } else {
                                    setDiscountValue("");
                                  }
                                } else {
                                  setDiscountValue(
                                    Math.abs(e.target.value) + ""
                                  );
                                }
                              }
                            }}
                            className="form-control"
                            id="exampleFormControlInpu1"
                            placeholder={
                              couponType === "Percentage" ? "20 %" : "$ 1000"
                            }
                            style={
                              couponType !== "Percentage" &&
                              discountValue !== ""
                                ? {
                                    paddingLeft: "10px",
                                    marginTop: "9px",
                                    height: "6vh",
                                  }
                                : { marginTop: "8.3px", height: "5.8vh" }
                            }
                            min={0}
                            max={couponType === "Percentage" ? 99 : 999999999}
                          />
                          {discountValue !== "" ? (
                            couponType === "Percentage" ? (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "54px",
                                  left: `${
                                    Math.pow((discountValue + "").length, 3) +
                                    40
                                  }px`,
                                }}
                              >
                                %
                              </span>
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "55px",
                                  left: "17px",
                                }}
                              >
                                $
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {formValidationErrors.discountError && (
                            <span className="emsg">
                              {formValidationErrors.discountError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div class="alert alert-info alertBox" role="alert">
                      <span className="orangeColor">
                        * Coupon will be applicable for <b>Entire cart</b>
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h5 className="orangeColor">Notification Text</h5>
                    <div>
                      <input
                        type="text"
                        value={notificationText}
                        onChange={(e) => setNotificationText(e.target.value)}
                        className="form-control"
                        // style={{ height: "20vh", borderRadius: "25px" }}
                      />
                      {formValidationErrors.notificationTextError && (
                        <span className="emsg">
                          {formValidationErrors.notificationTextError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ color: "#FFA382" }}>Status</h5>
                      </div>

                      <div className="col-md-6 switch-col">
                        <label className="switch switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span className="slider round"></span>
                          <span
                            className="slider-text"
                            style={
                              activeStatus
                                ? { paddingLeft: "81px", color: "#FFA382" }
                                : { paddingLeft: "81px", color: "red" }
                            }
                          >
                            {activeStatus ? "Enabled" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            <button
              type="button"
              onClick={() => editCoupon()}
              className="btn btn-cannaby"
              style={{ backgroundColor: "#FFA382" }}
              disabled={inProgress}
            >
              {inProgress ? "Creating..." : "Create Coupon"}
            </button>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={() => {
                closeTooltip();
              }}
              style={{
                color: "red",
                cursor: "pointer",
                textAlign: "right",
                fontSize: "20px",
              }}
            >
              x
            </div>
            <div>
              <img height="60" alt="" src={Success} />
            </div>

            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Updated successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}
              >
                Coupon is updated{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={() => {
                closeTooltip();
              }}
              style={{
                color: "red",
                cursor: "pointer",
                textAlign: "right",
                fontSize: "20px",
              }}
            >
              x
            </div>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
