import { useSelector } from "react-redux";
import { selectCurrentSessionMeta } from "../../redux/slices/chatSessionSlice";
import ChatHeaderComponent from "./ChatHeader";
import MessagesHolder from "./MessagesHolder";
import SendMessageComponent from "./SendMessage";
const ChatWindowComponent = () => {
  const { initialLoading } = useSelector(selectCurrentSessionMeta);
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        overflow: "auto",
        position: "relative",
      }}
    >
      <ChatHeaderComponent />
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          flexGrow: 1,
          padding: "0.5em 0em",
        }}
      >
        {!initialLoading && <SendMessageComponent />}
        <MessagesHolder />
      </div>
    </div>
  );
};

export default ChatWindowComponent;
