import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Dialog } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMyOutlets,
  switchOutletThunk,
} from "../../redux/slices/authSlice";
import { toggleChooserOpenStatus } from "../../redux/slices/ocSwitchSlice";
export const OutletChooser = () => {
  const dispatch = useDispatch();
  const outlets = useSelector(selectMyOutlets);
  const handleClose = () => {
    dispatch(toggleChooserOpenStatus());
  };
  const [adminData, setAdmninData] = useState(null);
  useEffect(() => {
    try {
      const admin = JSON.parse(localStorage.getItem("Admin")) || null;
      setAdmninData(
        Array.isArray(admin) && admin?.length > 0 ? admin[0] : null
      );
    } catch (e) {
      //do nothing
    }
  }, []);
  return (
    <Dialog open={true} fullWidth={true} onClose={handleClose}>
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          overflow: "auto",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}>
          <h3>Choose Outlet</h3>
          <CloseIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
            flexGrow: 1,
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          {outlets.map((obj) => {
            if (obj.outletChainID === adminData?.outletChainID) {
              return null;
            }
            return (
              <div
                key={obj._id}
                className="d-flex flex-row"
                style={{
                  justifyContent: "flex-start",
                  backgroundColor: "#daded9",
                  flexGrow: 1,
                  width: "100%",
                  height: "auto",
                  maxHeight: "150px",
                  padding: "1em",
                  borderRadius: "15px",
                }}>
                <div
                  className="d-flex"
                  style={{
                    width: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Avatar
                    sx={{ bgcolor: "#0b300a", width: 56, height: 56 }}
                    src={obj?.adminImage ?? undefined}>
                    {obj.outletName[0].toUpperCase()}
                  </Avatar>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{
                    flexGrow: 1,
                    gap: "1em",
                    paddingLeft: "1em",
                    justifyContent: "space-between",
                  }}>
                  <p style={{ fontSize: "large" }}>
                    {obj?.outletName ?? "not Provided"}
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      dispatch(switchOutletThunk(obj.outletChainID));
                      dispatch(toggleChooserOpenStatus());
                    }}>
                    Switch
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};
