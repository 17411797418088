import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { default as authReducer } from "./slices/authSlice";
import { default as ocSwitchReducer } from "./slices/ocSwitchSlice";
import { default as socketReducer } from "./slices/socketSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import messageSessionReducer from "./slices/messageSessionSlice";
import chatSessionReducer from "./slices/chatSessionSlice";
import sendBoxReducer from "./slices/sendBoxSlice";
import sessionSearchReducer from "./slices/sessionSearchSlice";
import rootNotificationReducer from "./slices/rootNotificationSlice";
import addDealReducer from "./slices/addDealSlice";
import editDealReducer from "./slices/editDealSlice";
import addCouponReducer from "./slices/addCouponSlice";
import editCouponReducer from "./slices/editCouponSlice";
import addNotificationReducer from "./slices/addNotificationSlice";
import editNotificationReducer from "./slices/editNotificationSlice";
import addNotificationSubReducer from "./slices/addNotificationSubSlice";
import addBannerReducer from "./slices/addBannerSlice";
import editBannerReducer from "./slices/editBannerSlice";
import globalStateReducer from "./slices/globalStateSlice";
import loyaltyRedemptionReducer from "./slices/loyaltyRedemptionSlice";
import googleAnalyticsReducer from "./slices/addGoogleAnalyticsSlice";
import editDealDefRecucer from "./slices/editDealDefSlice";
const reducers = combineReducers({
  auth: authReducer,
  ocSwitch: ocSwitchReducer,
  socket: socketReducer,
  messageSession: messageSessionReducer,
  chatSession: chatSessionReducer,
  sendBox: sendBoxReducer,
  sessionSearch: sessionSearchReducer,
  rootNotification: rootNotificationReducer,
  addDeal: addDealReducer,
  editDeal: editDealReducer,
  editDealDef: editDealDefRecucer,
  addCoupon: addCouponReducer,
  editCoupon: editCouponReducer,
  addNotification: addNotificationReducer,
  addNotificationSubSlice: addNotificationSubReducer,
  editNotification: editNotificationReducer,
  addBanner: addBannerReducer,
  editBanner: editBannerReducer,
  globalState: globalStateReducer,
  loyaltyRedemptionPolicy: loyaltyRedemptionReducer,
  analyticsState: googleAnalyticsReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: getDefaultMiddleware({
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
