import React from "react";
import "./deals.css";
import url from "../../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function Index() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [outlets, setOutlets] = useState([]);
  const [deals, setDeals] = useState([]);
  // const [sendDeal, setSendDeal] = useState(null)
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    GetAllOutlets();
    GetAllDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllDeals = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(
        `/deal/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${adminID[0].outletChainID}`
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          let modified = res.data?.data ?? [];
          setDeals(modified);
          setTotalPage(modified);
          setminPro(page * 50 - 50 + 1);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const editDeal = (data) => {
    localStorage.setItem("dealEdit", JSON.stringify(data));
    history.push("/edit-deal");
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => {
              history.push("/add-deal");
            }}
          >
            <i className="fas fa-plus-circle"></i>
            Add GetDealsByOutlet
          </button>
          {/* onClick={() => {
              history.push("/addCatalog");
            }} */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      style={{ borderRight: "none" }}
                      className="form-control"
                      placeholder="Search Deals"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{deals.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Applicable to</th>
                    <th scope="col">Value</th>
                    <th scope="col">Expires On</th>
                    {/* <th scope="col">Usage</th> */}
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {deals.length !== 0 ? (
                    deals.map((deal, i) => {
                      return (
                        <tr key={i}>
                          <td colSpan="4">
                            <img
                              img
                              alt="yo1"
                              src={deal.image}
                              className="img-fluid "
                              width="40"
                            />
                            {deal.name}
                          </td>
                          <td>
                            {deal.selectedProducts.length !== 0 &&
                              deal.selectedProducts.map((prod, i) => {
                                return <span key={i}>{prod.name}</span>;
                              })}
                            {deal.selectedCategories.length !== 0 &&
                              deal.selectedCategories.map((prod, i) => {
                                return <span key={i}>{prod.name}</span>;
                              })}
                          </td>
                          <td>{deal.discountValue}</td>
                          <td>
                            {moment(deal.endDate).format(
                              "MMM. Do YYYY, h:mm A"
                            )}
                          </td>
                          {/* <td className="status">
                          <span>{deal.name}</span>
                        </td> */}
                          <td className="status">
                            <span>
                              {deal.status ? "Published" : "Not Published"}{" "}
                            </span>
                          </td>
                          <td>
                            <i
                              className="fas fa-pen"
                              onClick={() => {
                                editDeal(deal);
                              }}
                            ></i>
                            {/* onClick={() => moveToEdit(product)} */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No deals found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
