/* eslint-disable jsx-a11y/alt-text */
import { CircularProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import url from "../../config/axios";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { colors } from "../../Style.style";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import DeleteBannerDialogComponent from "../../components/DeleteBannerDialogComponent/DeleteBannerDialog";
import moment from "moment";
import BannersTable from "../../components/AddTypedBanner/BannersTable";

export default function TypedBanners() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const [banners, setBanners] = useState([]);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [currentOutletChainID, setCurrentOutletChainID] = useState(null);
  const setCurrentOutletChainIDState = () => {
    try {
      setCurrentOutletChainID(
        JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID ?? null
      );
    } catch (e) {}
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [error, setError] = useState(null);
  /**
   *
   * @param {string} value actual value
   */
  const handleSearchTermChange = (value = "") => {
    setSearchTerm(value.trim());
    setPrevSearchTerm(searchTerm);
    handleChange("search", value.trim(), true);
  };

  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };

  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      setCurrentOutletChainIDState();
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllTypedBanners({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/typed-banners${queryString}`);
    setQueryData({ ...query });
  };
  const GetAllTypedBanners = () => {
    setLoading(true);
    let queryParams = {
      ...queryData,
    };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/consumer-banners/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          const response = res.data?.data;
          if (!response?.banners?.length && searchTerm) {
            setError("No banners found");
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.inventories?.length
          ) {
            setNoRecord(true);
          }
          setBanners(response?.banners);
          setPaginationData({
            ...paginationData,
            ...response?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  const Tablenew = useMemo(
    () => (
      <BannersTable banners={banners} setBannerToDelete={setBannerToDelete} />
    ),
    [banners]
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ borderRadius: "13px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Banners
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center flex-gap-1">
                      <div className="input-group col-md-10">
                        <input
                          type="text"
                          style={{ borderRight: "none" }}
                          className="form-control"
                          placeholder="Search Banner"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                          value={searchTerm}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm?.length ? (
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              onClick={() => handleSearchTermChange("")}
                              style={{
                                backgroundColor: "white",
                                borderLeft: "none",
                                paddingLeft: 0,
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="input-group-prepend"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                            onClick={performSearchOnEnter}
                          >
                            <i
                              style={{ color: colors.searchColor }}
                              className="fas fa-search"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 filters-bar">
                        <button
                          className="btn btn-primary border-0"
                          style={{ backgroundColor: "#FFA382" }}
                          onClick={() => {
                            history.push("/add-typed-banner");
                          }}
                        >
                          <i className="fas fa-plus-circle"></i>
                          Add Banner
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {Tablenew}
                  {!banners?.length ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      No banners found
                    </div>
                  ) : null}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>

      {invokeLogOut && <InvokeLayoutComponent />}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Banner (s) not found"
          onClose={() => {
            setError(null);
            // handleSearchTermChange();
          }}
        />
      ) : null}

      {/* delete banner popup */}
      {bannerToDelete && bannerToDelete?._id && (
        <DeleteBannerDialogComponent
          bannerInfo={bannerToDelete}
          onDone={() => {
            setBannerToDelete(null);
            setQueryData({ ...initialPaginationData });
          }}
          onCancel={() => setBannerToDelete(null)}
        />
      )}
    </div>
  );
}
