import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
export default function CustomInputPrimary({
  label,
  blankLabel,
  disabled = false,
  hasBorder = true,
  labelHasMargin = false,
  ...props
}) {
  const theme = useTheme();
  return (
    <div>
      {" "}
      <Container theme={theme} disabled={disabled} labelMargin={labelHasMargin}>
        {blankLabel ? <label>&nbsp;</label> : <label>{label}</label>}
        <input disabled={disabled} {...props} />
      </Container>
    </div>
  );
}
const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
    margin-top: ${(props) => (!props.labelMargin ? "0px" : "30px")};
  }
  input {
    display: block;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #e2e8f0;
    background: ${(props) =>
      props.disabled ? props.theme.palette.gray.disabled : "transparent"};
    font-size: 0.938rem;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 5px;
  }
`;
