/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import "./deals.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import sort from "../../assets/sort.svg";
import moment from "moment";
import Popup from "reactjs-popup";
import Pagination from "@mui/material/Pagination";
import Failure from "../../assets/failure.png";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
export default function Index() {
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [deals, setDeals] = useState([]);
  // const [sendDeal, setSendDeal] = useState(null)
  const [totalPage, setTotalPage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);

  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  //clientSide search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
        setDeals(filtered);
        if (filtered.length === 0) {
          openNotFoundPopUp();
        }
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(filtered.length / perPage));
        setCurrentPage(1);
      } else {
        setDeals(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );

  useEffect(() => {
    GetAllOutlets();
    GetAllDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllDeals();
    } else {
      ref.current.close();
    }
    setDealId(null);
  };
  const [dealId, setDealId] = useState(null);
  const deleteDeal = () => {
    url.delete(`/deal/?dealId=${dealId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        // openTooltip();
        closeTooltip(true);
      } else {
        alert(res.data.data);
      }
    });
  };
  const GetAllDeals = () => {
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/deals/paginated-for-admin`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          let modified = res.data.data?.deals.reverse() ?? [];
          setUnfiltered(modified);
          setDeals(modified);
          setTotalPage(Math.ceil(modified.length / perPage));
          setCurrentPage(1);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetDealsByOutlet = (outletId) => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    if (outletId !== "select") {
      url
        .get(
          `/deal/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${outletId}`
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            let modified =
              res.data.data?.sort((obj1, obj2) =>
                new Date(obj1.endDate) > new Date(obj2.endDate) ? -1 : 1
              ) ?? [];
            setDeals(modified);
            // setTotalPage(modified.length);
            // setminPro(page * 50 - 50 + 1);
            setTotalPage(Math.ceil(modified.length / perPage));
            setCurrentPage(1);
          } else {
            alert(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    } else {
      GetAllDeals();
    }
  };
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/deals${queryString}`);
      setQueryData({ ...query });
    }
  };
  const editDeal = (data) => {
    localStorage.setItem("dealEdit", JSON.stringify(data));
    history.push("/edit-deal");
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  return (
    <div className="row">
      {" "}
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row" style={{ borderRadius: "13px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "6px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Deals
                  </div>
                </div>
                <div className="col-md-4 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <input
                      type="text"
                      style={{ borderRight: "none" }}
                      className="form-control"
                      placeholder="Search"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>
                                    <img src={sort} />
                                </div> */}
                <div className="col-md-2 filters-bar">
                  <button
                    className="btn btn-primary border-0"
                    style={{ backgroundColor: "#FFA382" }}
                    onClick={() => {
                      history.push("/add-deal");
                    }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Deals
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col" nowrap="nowrap">
                      Name
                    </th>
                    <th scope="col">Applicable to</th>
                    <th scope="col">Value</th>
                    <th scope="col">Expires On</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      {" "}
                      Status
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      {" "}
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deals.length !== 0 ? (
                    deals
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((deal, i) => {
                        return (
                          <tr key={i}>
                            <td colSpan="1">
                              <div style={{ display: "flex" }}>
                                {" "}
                                {deal.image ? (
                                  <img
                                    img
                                    alt="yo1"
                                    src={deal.image}
                                    className="img-fluid "
                                    width="40"
                                    style={{ height: "40px" }}
                                  />
                                ) : (
                                  <div
                                    className="image-thumbnail-div"
                                    style={{
                                      backgroundColor: assignColor(),
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span>{deal?.name?.substring(0, 1)}</span>
                                  </div>
                                )}
                                <span
                                  style={{
                                    paddingTop: "10px",
                                    color: "#1E293B",
                                  }}
                                >
                                  {deal.name}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div style={{ width: "150px", color: "#1E293B" }}>
                                {!!deal.selectedCategories?.length ? (
                                  <span>Selected Categories</span>
                                ) : (
                                  <span>Selected Products</span>
                                )}
                              </div>
                            </td>
                            <td style={{ color: "#1E293B" }}>
                              {deal.discountValue} %
                            </td>
                            <td style={{ color: "#1E293B" }}>
                              {!!deal.neverExpires
                                ? "Never Expires"
                                : moment(deal.endDate).format(
                                    "MMMM Do YYYY h:mm A"
                                  )}
                            </td>
                            <td
                              className={
                                deal.status ? "color-blue" : "color-red"
                              }
                            >
                              <span>
                                {deal.status ? (
                                  <div
                                    className="newClass"
                                    id="deals"
                                    style={{
                                      backgroundColor: `rgba(12, 214, 0, 0.1)`,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "center",
                                        fontSize: "13px",
                                        paddingTop: "2px",
                                      }}
                                    >
                                      <span style={{ color: "#0CD600" }}>
                                        Enabled
                                      </span>
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="newClass"
                                    id="deals"
                                    style={{
                                      backgroundColor: `rgba(255, 109, 109, 0.08)`,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "center",
                                        fontSize: "13px",
                                        paddingTop: "2px",
                                      }}
                                    >
                                      <span style={{ color: "red" }}>
                                        Disabled
                                      </span>
                                    </p>
                                  </div>
                                )}{" "}
                              </span>
                            </td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1em",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                onClick={() => {
                                  editDeal(deal);
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "0.3em",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  style={{ color: "#FFA382", marginTop: "4px" }}
                                  className="fas fa-pen"
                                ></i>
                                {/* <u style={{ color: "#FFA382" }}>Edit</u> */}
                              </div>
                              <div
                                onClick={() => {
                                  setDealId(deal.dealId);
                                  openTooltip();
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "0.3em",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  style={{ color: "red" }}
                                  className="fas fa-trash"
                                ></i>
                                {/* <u style={{ color: "red" }}>Delete</u> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <>
                      <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Deals found
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <h4 className="popup-heading">Do you want to delete this deal ?</h4>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteDeal();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <div
            onClick={() => {
              closeNotFoundPopUp();
            }}
            style={{
              color: "red",
              cursor: "pointer",
              textAlign: "right",
              fontSize: "20px",
            }}
          >
            x
          </div>
          <div style={{ color: "red", marginbottom: "10px" }}>Whoops!</div>
          <div style={{ marginTop: "10px" }}>
            <img alt="" src={Failure} />
          </div>
          <p style={{ marginTop: "20px" }}>Deal(s) not found</p>
          <button
            type="button"
            onClick={() => {
              closeNotFoundPopUp();
            }}
            className="btn btn-primary border-0"
            style={{
              backgroundColor: "#FFA382",
              marginTop: "10px",
              width: "20vh",
              alignSelf: "center",
            }}
          >
            ok
          </button>
        </div>
      </Popup>
    </div>
  );
}
