import React from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";

export default function CouponsTable({
  coupons,
  currentOutletChainID,
  setCouponToDelete,
}) {
  const history = useHistory();
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Coupon Code</th>
            <th scope="col">Value</th>
            <th scope="col">Expires on</th>
            <th
              scope="col"
              style={{
                textAlign: "center",
              }}
            >
              Status
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((item, i) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid rgb(241, 245, 249)",
                }}
                key={i}
              >
                <td style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item?.couponCode}
                  </div>
                </td>
                <td>
                  <div>
                    {item?.discountAmountType === "AMOUNT" && "$"}
                    {item.discountAmount}
                    {item?.discountAmountType === "PERCENTAGE" && "%"}
                  </div>
                </td>
                <td>
                  <div>
                    {item?.outletBasisConfig[currentOutletChainID]?.neverExpires
                      ? "Never Expires"
                      : moment(
                          item?.outletBasisConfig[currentOutletChainID]?.endsOn
                        ).format("MMMM Do YYYY, HH:MM a")}
                  </div>
                </td>
                <td
                  className={
                    item.outletBasisConfig[currentOutletChainID]?.status
                      ? "color-blue"
                      : "color-red"
                  }
                >
                  <span>
                    {item.outletBasisConfig[currentOutletChainID]?.status ? (
                      <div
                        className="newClass"
                        id="coupons"
                        style={{
                          backgroundColor: `rgba(12, 214, 0, 0.1)`,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "400",
                            textAlign: "center",
                            fontSize: "13px",
                            paddingTop: "2px",
                          }}
                        >
                          <span style={{ color: "#0CD600" }}>Enabled</span>
                        </p>
                      </div>
                    ) : (
                      <div
                        className="newClass"
                        id="coupons"
                        style={{
                          backgroundColor: `rgba(255, 109, 109, 0.08)`,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "400",
                            textAlign: "center",
                            fontSize: "13px",
                            paddingTop: "2px",
                          }}
                        >
                          <span style={{ color: "red" }}>Disabled</span>
                        </p>
                      </div>
                    )}{" "}
                  </span>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1em",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      onClick={() => {
                        history.push(`/edit-typed-coupon?_id=${item._id}`);
                      }}
                      style={{
                        color: "#FFA382",
                        cursor: "pointer",
                      }}
                      className="fas fa-pen"
                    ></i>
                    <i
                      onClick={() => setCouponToDelete(item)}
                      style={{ color: "red", cursor: "pointer" }}
                      className="fas fa-trash"
                    ></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
