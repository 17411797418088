import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import DualArrow from "../../../../assets/dual-arrow.svg";
import InputPrimary from "../../../../atoms/InputPrimary/index.js";
import {
  changeSinglePropertyAction,
  selectLoyaltyFormValidationErrors,
  selectLoyaltyRedemptionPolicyState,
} from "../../../../redux/slices/loyaltyRedemptionSlice.js";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants.js";
import { calculatePointsWorth } from "../utils/calculate-amount-representation";
function PerSpendingBasisAward() {
  const dispatch = useDispatch();
  const formValidationErrors = useSelector(selectLoyaltyFormValidationErrors);
  const {
    valuationStrategy: { targetPoints, targetPrice },
    planInfo: {
      PER_SPENDING_BASIS: { points },
    },
  } =
    useSelector(selectLoyaltyRedemptionPolicyState) ??
    initialLoyaltyRedemptionPolicyState;

  const handleChange = (propValue) => {
    dispatch(
      changeSinglePropertyAction({
        propName: "planInfo",
        propValue: {
          PER_SPENDING_BASIS: {
            targetUnitPrice: 1,
            points: propValue,
          },
          SPENDING_BASIS: {
            strategies: [],
          },
        },
      })
    );
  };

  return (
    <Fragment>
      <div className="row d-flex-column">
        <div className="col-md-12 d-flex my-2">
          <div className="col-md-5">
            <div className="group-comb">
              <InputPrimary
                label="Amount Spent ($)"
                name="purchaseAmount"
                type="number"
                className="form-control"
                placeholder="Running Total"
                disabled
                value={"1"}
              ></InputPrimary>
            </div>
          </div>
          <div className="col-md-2 filters-bar">
            <img src={DualArrow} alt="dual-arrow-icon" />
          </div>
          <div className="col-md-5">
            <div className="group-comb">
              <InputPrimary
                label={`Points To Be Awarded ($${calculatePointsWorth(
                  targetPrice,
                  targetPoints,
                  points
                )})`}
                name="points"
                type="number"
                className="form-control"
                placeholder="Points To Be Awarded"
                onChange={(e) => {
                  const parsedNumber = Number(e.target.value);
                  if (!Number.isNaN(parsedNumber) && parsedNumber >= 0) {
                    handleChange(parseInt(Math.abs(e.target.value)));
                  }
                }}
                value={points}
              ></InputPrimary>
            </div>
            <span className="validation-help">
              {formValidationErrors?.perSpendingPointsError ?? ""}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PerSpendingBasisAward;
