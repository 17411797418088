import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import moment from "moment/moment";
import { useEffect, useRef } from "react";
import TimePicker from "../../atoms/DatePicker/TimePicker";

// const CustomeInputTime = styled.input`
//     width: 100%;
//     background-color: white;
//     /* width:35vh !important; */
//     padding: 8px 16px;
//     box-sizing: border-box;
//     border:1px solid #E2E8F0;
//     border-radius: 5px;
//    ::-webkit-calendar-picker-indicator{
//      filter:invert(83%) sepia(64%) saturate(3544%) hue-rotate(308deg) brightness(107%) contrast(93%);
// }
// `
const NewTimePicker = styled.div`
  .MuiFormControl-root {
    background-color: white;
  }
  input {
    padding: 8px 16px;
  }
`;
const CustomeSelect = styled.input`
  /* width:35vh !important; */
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  /* height:5vh; */
`;
const currentDate = new Date();
const timestamp = currentDate.getTime();
const time = moment(timestamp).format("hh:mm:ss");
function AddLoyaltypoints({
  daySlots,
  days,
  Name,
  index,
  onChange,
  validation,
}) {
  // days is basically array of slots for each day
  const [slots, setSlots] = useState([
    {
      from: "",
      to: "",
      points: 0,
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  useEffect(() => {
    if (daySlots.length !== 0) {
      let data = [...slots];
      data = daySlots;
      setSlots([...data]);
    }
  }, [daySlots]);

  // Adding slot in each day that is passed here
  const addSlots = () => {
    setSlots([
      {
        from: "",
        to: "",
        points: 0,
      },
      ...slots,
    ]);
  };
  // Removing the slots
  const removeSlot = (key) => {
    let daySlots = [...slots];
    daySlots = slots.filter((i, index) => key !== index);
    setSlots(daySlots);
    onChange(daySlots, days);
  };
  // Managing slots for each day and passing it back to parent component
  const manageSlots = (i, value) => {
    let daySlots = [...slots];
    value.target.name === "points"
      ? (daySlots[i][value.target.name] = value.target.value)
      : (daySlots[i][value.target.name] = moment(
          value.target.value,
          "HH:mm"
        ).format("h:mm a"));
    // Set the focus on the input field after the state has been updated
    setSlots(daySlots);
    onChange(daySlots, days);
  };
  const manageSlots2 = (i, value, name) => {
    const newTime = moment(value, "ddd MMM DD yyyy HH:mm:ss").format("h:mm a");
    let daySlots = [...slots];
    daySlots[i][name] = newTime;
    // Set the focus on the input field after the state has been updated
    setSlots(daySlots);
    onChange(daySlots, days);
  };

  const Container = styled.div`
    background-color: rgba(248, 250, 252, 1);
    margin-bottom: 16px;
    border-radius: 5px;
    .addLine {
      color: #e2e8f0;
    }
    .main-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      border-radius: 10px;
      /* margin-bottom: 10px; */
      background-color: rgba(248, 250, 252, 1);
      padding: 10px 15px;
      .addSlot-Div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(1) {
          justify-content: center;
          width: 120px;
          flex-basis: 140px;
          flex-grow: 0;
          flex-shrink: 0;
        }
        .dayText {
          color: #192746;
          font-size: 15px;
          text-align: left;
          font-weight: 700;
        }
        .text {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1rem;
          /* display: block; */
          /* padding-top:10px; */
          margin-bottom: 6px;
          color: #747474;
          /* font-size: 13px; */
          text-align: left;
          font-weight: 500;
          align-self: flex-start;
        }
        .remove-button {
          background-color: rgba(248, 250, 252, 1);
          border: none;
          color: #ff6d6d;
          cursor: pointer;
          /* margin-bottom:10px; */
        }
        .my-button {
          background-color: rgba(248, 250, 252, 1);
          border: none;
          color: #ffa382;
          /* margin-bottom:10px; */
          cursor: pointer;
        }
        /* .inputField{
                    margin-bottom:10px;
                } */
        .pointsText {
          color: #747474;
        }
      }
    }
  `;

  return (
    <Container>
      {slots &&
        slots.map((i, key) => {
          return (
            <>
              {key !== 0 && <hr className="addLine" />}
              <div className="main-container">
                {/* Name and add slot here */}
                {key === 0 ? (
                  <div className="addSlot-Div">
                    <span className="dayText">{Name[index]}</span>
                    <button onClick={addSlots} className="my-button">
                      <i className="fas fa-plus-circle"></i>
                      Add time slot
                    </button>
                  </div>
                ) : (
                  <div className="addSlot-Div justify-content-center">
                    <button
                      onClick={() => removeSlot(key)}
                      className="remove-button"
                    >
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                      Remove Slot
                    </button>
                  </div>
                )}

                <div className="addSlot-Div">
                  {/* <span className='text'>
                                    Give loyalty points from
                                </span> */}
                  {/* <CustomeInputTime
                                    className='inputField'
                                    placeholder='Time slot'
                                    name='from'
                                    type="time"
                                    defaultValue={time}
                                    value={moment(i.from, 'h:mm a').format("HH:mm")}
                                    onChange={(e) => {
                                        manageSlots(key, e)
                                    }}
                                /> */}
                  <NewTimePicker>
                    <TimePicker
                      label="Give loyalty points from"
                      className="inputField"
                      placeholder="Time slot"
                      name="from"
                      type="time"
                      value={moment(i.from, "h:mm a").format(
                        "ddd MMM DD yyyy HH:mm:ss"
                      )}
                      onChange={(value) => {
                        manageSlots2(key, value, "from");
                      }}
                    />
                  </NewTimePicker>
                </div>
                <div className="addSlot-Div">
                  {/* <span className='text'>
                                    Give loyalty points to
                                </span>
                                <CustomeInputTime
                                    className='inputField'
                                    placeholder='Time slot'
                                    name='to'
                                    type="time"
                                    defaultValue={time}
                                    value={moment(i.to, 'h:mm a').format("HH:mm")}
                                    onChange={(e) => {
                                        manageSlots(key, e)
                                    }}
                                /> */}
                  <NewTimePicker>
                    <TimePicker
                      label="Give loyalty points to"
                      className="inputField"
                      placeholder="Time slot"
                      name="to"
                      type="time"
                      value={moment(i.to, "h:mm a").format(
                        "ddd MMM DD yyyy HH:mm:ss"
                      )}
                      onChange={(value) => {
                        manageSlots2(key, value, "to");
                      }}
                    />
                  </NewTimePicker>
                </div>
                <div className="addSlot-Div">
                  <span className="text">Loyalty Points To Be Given</span>
                  <CustomeSelect
                    key={key}
                    type="number"
                    step="any"
                    name="points"
                    min={0}
                    max={100}
                    // onInput={(e) => {
                    //     e.target.value = Math.abs(e.target.value);
                    //     if (e.target.value > 100) {
                    //         e.target.value = 100;
                    //     }
                    // }}
                    value={i.points}
                    onChange={(e) => {
                      setCurrentIndex(key);
                      manageSlots(key, e);
                    }}
                    autoFocus={key === currentIndex}
                  />
                </div>
              </div>
            </>
          );
        })}
      <div
        style={{
          // marginLeft: "0.5em",
          color: "red",
          backgroundColor: "white",
          width: "100%",
          // marginTop: "0.3em",
        }}
      ></div>
    </Container>
  );
}

export default AddLoyaltypoints;
