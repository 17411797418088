/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import calculateAspectRatio from "calculate-aspect-ratio";
import "./BannerPopup.css";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import { ProductPicker } from "../../../components/Product-Picker/index-updated";
import url from "../../../config/axios";
import storage from "../../../config/firebase";

import defaultImage from "../../../assets/defaultImage.jpg";
import { Dialog } from "@mui/material";
import { getUniqueFileName } from "../../../helpers/getFileName";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: "60%",
    overflowX: "hidden",
    overflowY: "Scroll",
    minHeight: "90%",
  },
}));
function Editbannerpopup({ onClose, response }) {
  const classes = useStyles();
  const [bannerTitle, setBannerTitle] = useState("");
  const [image, setImage] = useState("");
  const [time, setTime] = useState("");
  const [file, setFile] = useState("");
  // const [link, setLink] = useState("");
  const [status, setStatus] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [resolutionError, setResolutionError] = useState(null);
  const allowedRatios = ["5:2"];
  const [pickedProducts, setPickedProducts] = useState({});
  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const [promotions, setPromotions] = useState("");

  const editBanner = (blob) => {
    let body = {
      consumerId: Banner.consumerId,
      outletChainID: Banner.outletChainID,
      title: bannerTitle,
      image: !!blob ? blob : Banner.image,
      //   link: link,
      time: time,
      status: status,
      targetInfo: {
        ...pickedProducts[Object.keys(pickedProducts)[0]],
        type: promotions,
      },
    };
    url
      .put(`/banner/?bannerId=${Banner.bannerId}`, body)
      .then(async (res) => {
        let message = {
          text: "Success",
          status: true,
          display: true,
        };
        response(message);
        setInProgress(false);
        onClose(false);
      })
      .catch((e) => {
        console.error(e);
        setInProgress(false);

        let message = {
          text: "Failed",
          status: false,
          display: true,
        };
        response(message);
        onClose(false);
      });
  };
  const Banner = JSON.parse(localStorage.getItem("editBanner"));
  useEffect(() => {
    setBannerTitle(Banner?.title ?? "");
    // setLink(Banner?.link ?? "");
    setTime(Banner?.time ?? "");
    setStatus(!!Banner?.status);
    setPromotions(Banner?.targetInfo?.type);
    setImage(Banner?.image);
    if (Banner.targetInfo?.type === "PRODUCTS") {
      setPickedProducts(
        Banner?.targetInfo && Banner.targetInfo?.productID
          ? {
              [Banner.targetInfo?.productID]: Banner.targetInfo,
            }
          : {}
      );
    } else if (Banner.targetInfo?.type === "DEALS") {
      setPickedProducts(
        Banner?.targetInfo && Banner.targetInfo?.dealId
          ? {
              [Banner.targetInfo?.dealId]: Banner.targetInfo,
            }
          : {}
      );
    } else if (Banner.targetInfo.type === "MANUFACTURERS") {
      setPickedProducts(
        Banner?.targetInfo && Banner.targetInfo?.brandID
          ? {
              [Banner.targetInfo?.brandID]: Banner.targetInfo,
            }
          : {}
      );
    } else if (Banner.targetInfo.type === "CATEGORIES") {
      setPickedProducts(
        Banner?.targetInfo && Banner.targetInfo?.categoryID
          ? {
              [Banner.targetInfo?.categoryID]: Banner.targetInfo,
            }
          : {}
      );
    }
  }, []);
  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    const gotError = validateForm();
    if (
      Object.keys(gotError).length === 0 &&
      resolutionError === null &&
      Object.keys(pickedProducts).length !== 0
    ) {
      setInProgress(true);
      if (file !== "") {
        const fileName = getUniqueFileName(file.name);
        const uploadTask = storage.ref(`images/${fileName}`).put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          (error) => {
            console.error(error);
          },
          () => {
            storage
              .ref("images")
              .child(fileName)
              .getDownloadURL()
              .then((blob) => {
                editBanner(blob);
              });
          }
        );
      } else {
        editBanner("");
      }
    }
  };

  const imageSelector = (event) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

    if (event.target.files[0]) {
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        var i = new Image();
        i.onload = () => {
          try {
            const aspectRatio = calculateAspectRatio(i.width, i.height);
            if (allowedRatios.includes(aspectRatio + "")) {
              setResolutionError(null);
            } else {
              setResolutionError(
                `Allowed ratio(s) : ${allowedRatios.join(", ")}`
              );
            }
          } catch (e) {
            setResolutionError(`Invalid format`);
          }
        };
        const objectURL = URL.createObjectURL(event.target.files[0]);
        i.src = objectURL;
        setImage(URL.createObjectURL(event.target.files[0]));
        setFile(event.target.files[0]);
      }
    }
  };

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //manipulate banner title
    if (bannerTitle.trim().length === 0) {
      data = { ...data, btError: "Title of the banner is required" };
    } else {
      let { btError, ...others } = data;
      data = { ...others };
    }

    //manipulate time
    if ((time + "").trim().length === 0 || Number((time + "").trim()) <= 0) {
      data = { ...data, timeError: "A positive duration is required" };
    } else {
      let { timeError, ...others } = data;
      data = { ...others };
    }
    if (promotions.trim().length === 0) {
      data = { ...data, pmError: "Promotion is required" };
    } else {
      let { pmError, ...others } = data;
      data = { ...others };
    }

    // manipulate image
    if (!image) {
      data = { ...data, imgError: "Image of the banner is required" };
    } else {
      let { imgError, ...others } = data;
      data = { ...others };
    }
    if (Object.keys(pickedProducts).length === 0) {
      data = { ...data, proError: "Select a promotion" };
    } else {
      let { proError, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors({ ...data });
    return data;
  };

  return (
    // <div className={classes.dialogContainer}>
    <Dialog
      open={true}
      PaperProps={{ className: classes.dialogPaper }}
      // style={{ width: "80%", marignLeft: "50px !important" }}
    >
      <div className="row BannerMain">
        <div className="col-md-12">
          <div
            className="row"
            style={{ paddinBottom: "3px", borderBottom: "1px solid #F1F5F9" }}
          >
            <div
              style={{
                height: "25px",
                marginLeft: "2px",
                backgroundColor: "#FFA382",
                marginTop: "3px",
              }}
            >
              <span style={{ color: "#FFA382" }}>a</span>
            </div>
            <div className="col-md-3 d-flex">
              <div style={{ color: "#FFA382", fontSize: "20px" }}>
                Edit Banner
              </div>
            </div>
            <div className="col-md-4 filters-bar">
              <div className="dropdown"></div>
              <div className="dropdown">
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="/">
                    Action
                  </a>
                  <a className="dropdown-item" href="/">
                    Another action
                  </a>
                  <a className="dropdown-item" href="/">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="BannerFileuploader">
            {image !== "" ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: " 0 auto",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: " 0 auto",
                      position: "relative",
                    }}
                    src={image}
                  />
                  <label
                    className=""
                    style={{
                      border: "none",
                      backgroundColor: "#FFA382",
                      position: "absolute",
                      top: "10%",
                      right: "5%",
                      width: "70px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <input
                      type="file"
                      id="image"
                      name="Image"
                      onChange={imageSelector}
                      accept="image/png, image/jpeg"
                    />
                    <div style={{ color: "white", justifyContent: "flex-end" }}>
                      <i
                        style={{ color: "white", marginRight: "7px" }}
                        className="fas fa-pen"
                      ></i>
                      <u>Edit</u>
                    </div>
                  </label>
                  {resolutionError && (
                    <p style={{ color: "red", marginBottom: "25px" }}>
                      {resolutionError}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="defaultImage">
                  <img
                    style={{
                      width: "40%",
                      height: "9vh",
                      margin: "6px auto",
                      position: "relative",
                    }}
                    src={defaultImage}
                  />
                </div>
                <p style={{ textAlign: "center" }}>
                  Upload Banner Image
                  <p
                    style={{
                      fontSize: "11px",
                      color: "rgba(116, 116, 116, 0.5)",
                      marginBottom: "0px !important",
                    }}
                  >
                    Files include PNG, JPEG
                  </p>
                </p>
                <label
                  className="custom-file-upload"
                  style={{ border: "none", display: "block", padding: "0px" }}
                >
                  <input
                    type="file"
                    id="image"
                    name="Image"
                    onChange={imageSelector}
                    accept="image/png, image/jpeg"
                  />
                  <p
                    style={{
                      backgroundColor: "#FFA382",
                      border: "1px solid #FFA382",
                      width: "30%",
                      color: "white",
                      borderRadius: "5px",
                      paddingLeft: "2em",
                      paddingRight: "2em",
                      paddingTop: "0.2em",
                      paddingBottom: "0.15em",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Choose File
                  </p>
                </label>
              </>
            )}
          </div>
          {formValidationErrors.imgError ? (
            <span className="validation-help">
              {formValidationErrors.imgError}
            </span>
          ) : (
            ""
          )}
          <div style={{ paddingTop: "15px" }}>
            <label className="labelStyle">Banner Title</label>
            <input
              type="text"
              value={bannerTitle}
              onChange={(e) => setBannerTitle(e.target.value)}
              className="myInput"
              placeholder="Banner Title"
            />
            {formValidationErrors.btError ? (
              <span className="validation-help">
                {formValidationErrors.btError}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div style={{ paddingTop: "5px" }}>
                <label className="labelStyle">Banner Duration (seconds)</label>
                <input
                  type="text"
                  value={time}
                  onChange={(e) =>
                    setTime(e.target.value ? Math.abs(e.target.value) : "")
                  }
                  className="myInput"
                  placeholder="5 sec"
                />
                {formValidationErrors.timeError ? (
                  <span className="validation-help">
                    {formValidationErrors.timeError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ paddingTop: "5px" }}>
                <label className="labelStyle">Promotions</label>

                <select
                  className="myInput"
                  style={{ width: "100%" }}
                  name="state"
                  defaultValue={"Select"}
                  value={promotions}
                  onChange={(e) => {
                    setPickedProducts({});
                    setPromotions(e.target.value);
                  }}
                >
                  <option value="">
                    {promotions === "" ? "Select" : "Select"}
                  </option>
                  <option value={"CATEGORIES"}>Categories</option>
                  <option value={"MANUFACTURERS"}>Manufacturers</option>
                  <option value={"DEALS"}>Deals</option>
                  <option value={"PRODUCTS"}>Products</option>
                </select>
              </div>
              {formValidationErrors.pmError ? (
                <span className="validation-help">
                  {formValidationErrors.pmError}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {/*  Products */}
          {promotions === "PRODUCTS" && (
            <div className="col-md-12" style={{ marginLeft: "-10px" }}>
              <Box>
                {Object.keys(pickedProducts).length ? (
                  <Fragment>
                    {Object.keys(pickedProducts).map((value, i) => (
                      <Fragment>
                        <div>
                          <span>Selected product</span>

                          <h6
                            class="card-subtitle mb-2 text-muted"
                            style={{ paddingTop: "1em" }}
                          >
                            {" "}
                            {pickedProducts[value]?.product?.name}
                          </h6>
                          <p>
                            <b>Brand: </b>
                            {pickedProducts[value]?.product?.brandName ??
                              "Not provided"}
                          </p>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <p>No product(s) selected</p>
                )}
              </Box>
            </div>
          )}
          {/* deals */}
          {promotions === "DEALS" && (
            <div className="col-md-12" style={{ marginLeft: "-10px" }}>
              <Box>
                {Object.keys(pickedProducts).length ? (
                  <Fragment>
                    {Object.keys(pickedProducts).map((value, i) => (
                      <Fragment>
                        <div>
                          <span>Selected product</span>

                          <h6
                            class="card-subtitle mb-2 text-muted"
                            style={{ paddingTop: "1em" }}
                          >
                            {" "}
                            {pickedProducts[value].name}
                          </h6>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <p>No deal(s) selected</p>
                )}
              </Box>
            </div>
          )}
          {/* Brands */}
          {promotions === "MANUFACTURERS" && (
            <div className="col-md-12" style={{ marginLeft: "-10px" }}>
              <Box>
                {Object.keys(pickedProducts).length ? (
                  <Fragment>
                    {Object.keys(pickedProducts).map((value, i) => (
                      <Fragment>
                        <div>
                          <span>Selected product</span>

                          <h6
                            class="card-subtitle mb-2 text-muted"
                            style={{ paddingTop: "1em" }}
                          >
                            {" "}
                            {pickedProducts[value].name}
                          </h6>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <p>No Manufacturer(s) selected</p>
                )}
              </Box>
            </div>
          )}
          {/* deals */}
          {promotions === "CATEGORIES" && (
            <div className="col-md-12" style={{ marginLeft: "-10px" }}>
              <Box>
                {Object.keys(pickedProducts).length ? (
                  <Fragment>
                    {Object.keys(pickedProducts).map((value, i) => (
                      <Fragment>
                        <div>
                          <span>Selected product</span>

                          <h6
                            class="card-subtitle mb-2 text-muted"
                            style={{ paddingTop: "1em" }}
                          >
                            {" "}
                            {pickedProducts[value].name}
                          </h6>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <p>No Categorie(s) selected</p>
                )}
              </Box>
            </div>
          )}

          <div className="col-lg-12" style={{ marginLeft: "-20px" }}>
            {promotions === "PRODUCTS" && (
              <button
                className="btn bannerAdd border-0"
                onClick={() => setProductPickerOpen(true)}
              >
                <i className="fas fa-plus-circle"></i>
                <u>
                  {!Object.keys(pickedProducts).length
                    ? "Add Products"
                    : "Change Product"}
                </u>
              </button>
            )}
            {promotions === "DEALS" && (
              <button
                className="btn bannerAdd border-0"
                onClick={() => setProductPickerOpen(true)}
              >
                <i className="fas fa-plus-circle"></i>
                <u>
                  {!Object.keys(pickedProducts).length
                    ? "Add deals"
                    : "Change deal"}
                </u>
              </button>
            )}{" "}
            {promotions === "MANUFACTURERS" && (
              <button
                className="btn bannerAdd border-0"
                onClick={() => setProductPickerOpen(true)}
              >
                <i className="fas fa-plus-circle"></i>
                <u>
                  {!Object.keys(pickedProducts).length
                    ? "Add Manufacturers"
                    : "Change Manufacturers"}
                </u>
              </button>
            )}{" "}
            {promotions === "CATEGORIES" && (
              <button
                className="btn bannerAdd border-0"
                onClick={() => setProductPickerOpen(true)}
              >
                <i className="fas fa-plus-circle"></i>
                <u>
                  {!Object.keys(pickedProducts).length
                    ? "Add Categories"
                    : "Change Categories"}
                </u>
              </button>
            )}
          </div>

          {formValidationErrors.proError ? (
            <span className="validation-help">
              {formValidationErrors.proError}
            </span>
          ) : (
            ""
          )}
          {/* Status */}
          <div className="col-md-12">
            <h5 style={{ fontWeight: "normal", fontSize: "100%" }}>Status</h5>
          </div>
          <div className="col-md-6 switch-col">
            <label className="switch switch-demo">
              <input
                type="checkbox"
                checked={status}
                onChange={() => setStatus(!status)}
              />
              <span className="slider round"></span>
              <span className="slider-text" style={{ paddingLeft: "81px" }}>
                {status ? "Show" : "Hide"}
              </span>
            </label>
          </div>
          <div
            className="col-lg-12 d-flex"
            style={{ justifyContent: "flex-end", marginBottom: "10px" }}
          >
            <button
              onClick={() => onClose(false)}
              type="button"
              className="btn cancelButton"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={fileUpload}
              disabled={inProgress}
              className="btn createButton"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {productPickerOpen && (
        <ProductPicker
          promotion={promotions}
          handleClose={() => setProductPickerOpen(false)}
          single={true}
          previouslyChoosen={Object.keys(pickedProducts).map(
            (el) => pickedProducts[el]
          )}
          handleDone={(map) => {
            setPickedProducts(map);
            setProductPickerOpen(false);
          }}
        />
      )}
    </Dialog>
    // </div>
  );
}

export default Editbannerpopup;
