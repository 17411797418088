import axios from "axios";

const customInstance = axios.create({
  // baseURL: "http://localhost:8080/",
  // baseURL: "https://dev-bleaum-api-growflow.herokuapp.com/",
  // prodURL: https://v1.pos.canary.bleaum.dev
  // staging: https://pos.staging.bleaum.dev/
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    consumerid: (function () {
      try {
        let admin = JSON.parse(localStorage.getItem("Admin"));
        if (admin.length) {
          return admin[0].consumerId ?? null;
        }
        return null;
      } catch (e) {
        //TO DO
        return null;
      }
    })(),
    outletchainid: (function () {
      try {
        let admin = JSON.parse(localStorage.getItem("Admin"));
        if (admin.length) {
          return admin[0].outletChainID ?? null;
        }
        return null;
      } catch (e) {
        //TO DO
        return null;
      }
    })(),
  },
});

export default customInstance;
