/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import AddLoyaltypoints from "../../components/AddLoyalty";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import Success from "../../assets/Success1.png";
import Cross from "../../assets/cross.png";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";
import "./loyalty.css";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import MouseOverPopover from "../../atoms/Popover/Popover";
import { popOverMainLoyalty } from "../Loyalty/LoyaltyRules/constants/purchased-rules-constants";
import styles from "../Loyalty/LoyaltyRules/styles.module.css";
import LoyaltyQR from "./qr-popup";

const PopUpDiv = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
function Loyalty() {
  const ref = useRef();
  const notRef = useRef();
  const history = useHistory();
  const notOpenToolTip = () => notRef.current.open();
  const closenotToolTip = () => notRef.current.close();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showQRPopUp, setShowQRPopUp] = useState(false);
  const [days, setDays] = useState({
    MON: [],
    TUES: [],
    WED: [],
    THURS: [],
    FRI: [],
    SAT: [],
    SUN: [],
  });
  const Name = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  // Setting the days that are being passed from child to parent
  const setNewDays = (value, key) => {
    let tempDays = { ...days };
    tempDays[key] = value;
    setDays(tempDays);
  };
  // Api calls to be made in this file
  // <----------!!!!!------->
  // Get loyalty api
  useEffect(() => {
    getLoyalty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLoyalty = async () => {
    try {
      const getLoyaltyPoint = await url.get(
        "/v1/outlet-time-basis-loyalty-settings"
      );
      let data = getLoyaltyPoint?.data?.data?.data?.strategies;
      setDays({ ...data });
    } catch (e) {
      console.error(e);
    }
  };
  // Update and add loyaltypoint api
  const addUpdateLoyalty = async () => {
    try {
      const addUpdate = await url.put(
        "/v1/outlet-time-basis-loyalty-settings/update-time-basis-strategy",
        {
          enabled: true,
          strategies: days,
        }
      );

      let data = addUpdate.data.data.data?.strategies;
      setDays({ ...data });
      setSuccess("Loyalty points updated successfully");
      setFormvalidationErrors({});
      getLoyalty();
    } catch (e) {
      for (let i in e?.response?.data?.data) {
        setError(e?.response?.data?.data[i]);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card ">
          <div
            className="row"
            style={{
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              paddingBottom: "10px",
            }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "2px",
                  }}>
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}>
                    Loyalty points
                    <div className={styles.LoyaltyPop}>
                      <MouseOverPopover text={popOverMainLoyalty.content} />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4"></div>
                {/* <div className="col-md-1 filters-bar">
                  <button
                    onClick={() => history.push("/Specializedloyalty")}
                    className="btn btn-primary border-0 addPoints">
                    <i className="fas fa-plus-circle"></i>
                    Add Points
                  </button>
                </div> */}
                <div className="col-md-1 filters-bar">
                  <button
                    onClick={() => setShowQRPopUp(true)}
                    className="btn btn-primary border-0 showQR">
                    <i className="fas fa-qrcode"></i>
                    Show QR Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Next component here */}
          <div
            style={{
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}>
            {Object.keys(days).map((item, key) => {
              return (
                <AddLoyaltypoints
                  daySlots={days[item]}
                  days={item}
                  Name={Name}
                  index={key}
                  onChange={setNewDays}
                  validation={formValidationErrors}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="d-flex col-md-12"
        style={{
          justifyContent: "flex-end",
          marginTop: "5px",
          marginLeft: "11px",
        }}>
        <div style={{ borderRadius: "5px", padding: "6px" }}>
          <button
            className="btn btn-primary border-0"
            style={{ backgroundColor: "#FFA382" }}
            onClick={() => {
              addUpdateLoyalty();
            }}>
            Update
          </button>
        </div>
      </div>
      <Popup ref={ref} id="latestPopup" className="myPopup" position="center">
        <div style={{ height: " 30vh " }}>
          <div style={{ textAlign: "right" }}>
            <img
              onClick={() => {
                closeTooltip();
              }}
              src={Cross}
            />
          </div>
          <PopUpDiv>
            <div>
              <img height="60" src={Success} />
            </div>
            <div className="mt-3 mb-3">
              <span
                style={{
                  marginTop: "20px",
                  color: "#192746",
                  fontSize: "18px",
                }}>
                Updated Successfully
              </span>
              <p style={{ color: "#747474" }}>Loyaltypoints updated </p>
            </div>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}>
              Close
            </button>
          </PopUpDiv>
        </div>
      </Popup>

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}

      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={success}
          onClose={() => {
            setSuccess(null);
          }}
        />
      ) : null}
      {showQRPopUp ? <LoyaltyQR onClose={() => setShowQRPopUp(false)} /> : null}
    </div>
  );
}

export default Loyalty;
