/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { addDays, subDays } from "date-fns";

import ContentContainer from "../../atoms/ContentContainer";
import { ContentContainer as Container } from "../../Style.style";
import InputPrimary from "../../atoms/InputPrimary";
import SelectPrimary from "../../atoms/SelectPrimary";
import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary";
import Section from "../../atoms/Section";
import CheckboxPrimary from "../../atoms/CheckboxPrimary";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import ConfirmationPopup from "../../atoms/ConfirmationPopup/ConfirmationPopup";
import SimpleDialog from "../../atoms/Dialogs/SimpleDialog";
import DatePicker from "../../atoms/DatePicker/DateTimePicker";
import SimplePopup from "../../atoms/Dialogs/SimplePopup";
import {
  ButtonPrimary,
  DoubleColumn,
  MultipleFlexibleColumns,
  AutoFlow,
} from "../../Style.style";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";

function AddCouponTajwar() {
  const history = useHistory();
  const [activeStatus, setActiveStatus] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("Percentage");
  // const [memberType, setMemberType] = useState("All Users");
  const [discountValue, setDiscountValue] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [appliesTo, setAppliesTo] = useState("Selected Products");
  const [neverExpires, setNeverExpires] = useState(false);
  const [specificDates, setSpecificDates] = useState(true);
  const [stateRules, setStateRules] = useState({
    MinOrderValue: 0,
    TotalUsageLimit: 0,
    LimitUsesPerCustomer: 0,
  });

  const [message, setMessage] = useState({
    display: false,
    status: false,
    success: true,
    heading: "Successfully Created",
    text: "Coupon Created Successfully",
  });

  const confirmationPopup = useRef();
  const openConfirmationPopup = () => confirmationPopup.current.open();
  const closeConfirmationPopup = () => {
    if (message.success) {
      history.goBack();
    } else {
      confirmationPopup.current.close();
    }
  };
  // const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  // const [endDate, setEndDate] = useState(addDays(new Date(), 1).toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));

  const theme = useTheme();
  const [outName, setOutName] = useState([]);
  const [prodName, setProdName] = useState([]);
  const [catName, setCatName] = useState([]);

  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState([]);
  const [addCouponConfirmationDialog, setAddCouponConfirmationDialog] =
    useState({
      open: false,
      message: "",
    });
  const onConfirmationOfCouponDialog = () => {
    setAddCouponConfirmationDialog({
      open: false,
      message: "",
      error: false,
    });
    history.push("/discounts-outlet");
  };

  // React.useEffect(() => {
  // }, [selected])

  const adminID = JSON.parse(localStorage.getItem("Admin"));

  useEffect(() => {
    GetAllOutlets();
    GetAllProducts();
    GetAllCategories();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      setOptions(
        products.map((item) => ({
          value: item.product.productID,
          label: item.product.name,
        }))
      );
    }
  }, [products]);

  useEffect(() => {
    if (selected.length > 0) {
      setSelProduct(
        selected.map((item) => ({
          productID: item.value,
          name: item.label,
        }))
      );
    }
  }, [selected]);

  const handleNextOne = (e) => {
    const { name, value } = e.target;
    setStateRules((prevState) => ({
      ...prevState,
      [name]: value ? Math.abs(value) + "" : "",
    }));
  };

  const handleChange = async (newValue) => {
    setStartDate(newValue);
  };

  const handleChange1 = (newValue) => {
    setEndDate(newValue);
  };
  const [triedBefore, setTriedBefore] = useState(false);
  const [inProgress, setInProgres] = useState(false);
  const [dateRelatedErrors, setDateRelatedErrors] = useState({});
  const [confirmationPopupState, setConfirmationPopupState] = useState({
    show: false,
    text: "",
    error: false,
  });
  useEffect(() => {
    let data = { ...dateRelatedErrors };
    //manipualte start date
    if (new Date(startDate).toString() === "Invalid Date") {
      data = { ...data, start: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate) && !neverExpires) {
      data = { ...data, start: "Happening after the date of being end" };
    } else if (new Date(startDate) < subDays(new Date(), 1)) {
      data = { ...data, start: "Must be a date today or in the future" };
    } else {
      const { start, ...others } = data;
      data = { ...others };
    }
    //manipulate end date
    if (new Date(endDate).toString() === "Invalid Date") {
      data = { ...data, end: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, end: "Happening before the date of being started" };
    } else {
      let { end, ...others } = data;
      data = { ...others };
    }
    setDateRelatedErrors({ ...data });
  }, [startDate, endDate]);

  const [formValidationErrors, setFormValidationErrors] = useState({});
  useEffect(() => {
    setDiscountValue("");
  }, [couponType]);
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (couponCode.trim().length === 0) {
      data = { ...data, couponCodeError: "Coupon Code is required" };
    } else if (couponCode.trim().length < 4) {
      data = { ...data, couponCodeError: "Must of at leat 4 characters long" };
    } else {
      let { couponCodeError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (couponType === "Percentage") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0 || discountValue >= 100) {
        data = {
          ...data,
          discountError: "Please a choose a postive value within 100",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else if (couponType === "Amount") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0) {
        data = {
          ...data,
          discountError: "Positive discount value is required",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }
    if (appliesTo === "Selected Products") {
      if (selProduct.length === 0) {
        data = { ...data, selectError: "Select at least one product" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else if (appliesTo === "Selected Categories") {
      if (selCategory.length === 0) {
        data = { ...data, selectError: "Select at least one category" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { selectError, ...others } = data;
      data = { ...others };
    }

    //manipulate rules
    if (!!stateRules?.MinOrderValue) {
      if (+stateRules?.MinOrderValue <= 0) {
        data = { ...data, minOrderValueError: "Must be a positive number" };
      } else {
        let { minOrderValueError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { minOrderValueError, ...others } = data;
      data = { ...others };
    }

    if (!!stateRules?.TotalUsageLimit) {
      if (+stateRules?.TotalUsageLimit <= 0) {
        data = { ...data, totalUsageError: "Must be a positive number" };
      } else {
        let { totalUsageError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { totalUsageError, ...others } = data;
      data = { ...others };
    }

    if (!!stateRules?.LimitUsesPerCustomer) {
      if (+stateRules?.LimitUsesPerCustomer > 0) {
        if (!!stateRules?.TotalUsageLimit) {
          let roundResult =
            +stateRules?.TotalUsageLimit / stateRules?.LimitUsesPerCustomer;
          if (roundResult !== parseInt(roundResult)) {
            data = {
              ...data,
              limitUsageError: "Must be multiple of total usage",
            };
          } else {
            let { limitUsageError, ...others } = data;
            data = { ...others };
          }
        } else {
          let { limitUsageError, ...others } = data;
          data = { ...others };
        }
      } else if (+stateRules?.LimitUsesPerCustomer <= 0) {
        data = { ...data, limitUsageError: "Must be a positive number" };
      } else {
        let { limitUsageError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { limitUsageError, ...others } = data;
      data = { ...others };
    }

    setFormValidationErrors({ ...data });
    return data;
  };

  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [
    discountValue,
    couponType,
    couponCode,
    selProduct,
    selCategory,
    stateRules,
    triedBefore,
    appliesTo,
    neverExpires,
    startDate,
    activeStatus,
  ]);

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const GetAllProducts = async () => {
    url
      .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setProducts(res.data.data.slice(0, 100));
        } else {
          alert(res.data.data);
          setProducts([]);
        }
      });
  };

  const GetAllCategories = () => {
    url
      .get(`/category/`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setCategories(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const handleRulesInput = (e) => {
    setStateRules({
      ...stateRules,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const selectedOutlets = async (outl) => {
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      await setSelOutlet(obj);
    }
  };

  const selectedProducts = async (prod) => {
    setProdName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? prod.split("split") : prod
    );
    let obj = [];
    for (let i = 0; i < prod.length; i++) {
      var splitted = prod[i].split("split", 3);
      obj.push({ productID: splitted[0], name: splitted[1] });
    }
    await setSelProduct(obj);
  };

  const selectedCategories = async (cate) => {
    setCatName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? cate.split("split") : cate
    );
    let obj = [];
    for (let i = 0; i < cate.length; i++) {
      var splitted = cate[i].split("split", 3);
      obj.push({ categoryID: splitted[0], name: splitted[1] });
    }
    await setSelCategory(obj);
  };

  const setSelectedOne = (value) => {
    setAppliesTo(value);
  };

  const addCoupon = () => {
    setTriedBefore(true);
    let formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setInProgres(true);
      const admin = JSON.parse(localStorage.getItem("Admin"));
      const selectedOne = adminID[0].isSubOutlet
        ? [
            {
              outletChainID: adminID[0].outletChainID,
              name: adminID[0].outletName,
            },
          ]
        : selOutlet;
      const body = {
        consumerId: admin[0].consumerId,
        couponCode: couponCode,
        couponType: couponType,
        discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
        selectedOutlets: selectedOne,
        selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
        selectedCategories:
          appliesTo === "Selected Categories" ? selCategory : [],
        applyToAllOutlets: applyToAll,
        entireCart: appliesTo === "Entire Cart" ? true : false,
        memberType: "All Users",
        startDate: startDate,
        endDate: endDate,
        neverExpires: neverExpires,
        rules: stateRules,
        status: activeStatus,
        strict: true,
      };

      url.put(`/v1/coupons/upsert`, body).then(async (res) => {
        setInProgres(false);
        if (res.data.Message === "Success") {
          // alert("Coupon Added");
          setMessage({
            text: "Coupon created successfully",
            status: true,
            display: true,
            success: true,
            heading: "Successfully Created",
          });
          openConfirmationPopup();
          // setConfirmationPopupState({
          //   show: true,
          //   text: "New Coupon Added Successfully",
          //   error: false,
          // });
          // setAddCouponConfirmationDialog({
          //   open: true,
          //   message: "New coupon created successfully",
          //   error: false
          // })

          // openTooltip();
          // history.push('/discounts');
          // adminID[0].isSubOutlet
          //   ? history.push("/discounts-outlet")
          //   : history.push("/discounts");
        } else {
          setMessage({
            text: "Something went wrong",
            status: true,
            display: true,
            success: false,
            heading: "Failed!",
          });
          openConfirmationPopup();
          // setConfirmationPopupState({
          //   show: true,
          //   text: "Failed to create new coupon",
          //   error: true,
          // });
          // setConfirmationPopupState({
          //   show: true,
          //   text: "Failed to update new coupon",
          //   error: true,
          // });
          // openTooltip();
        }
      });
    } else {
      setMessage({
        text: "Please fillup the form properly",
        status: true,
        display: true,
        success: false,
        heading: "Failed!",
      });
      openConfirmationPopup();
      // setConfirmationPopupState({
      //   show: true,
      //   text: "Please fillup the form properly",
      //   error: true,
      // });
    }
  };
  const handleAddProduct = (values) => {
    const newList = values.map((item) => `${item.value}. ${item.label}`);
  };
  const handleCouponCodeInput = (e) => {
    const val = e.target.value;
    // Check if value is letter
    if (/^[a-zA-Z0-9]*$/.test(val)) {
      setCouponCode(val.toUpperCase());
    }
  };
  return (
    <Wrapper>
      <ContentContainer heading="Add New Coupon">
        <Section label="Coupon Details">
          <div>
            <InputPrimary
              label="Coupon Code"
              type="text"
              value={couponCode}
              onChange={handleCouponCodeInput}
              // id="exampleFormControlInput1"
              placeholder="Coupon code"
            />
            {formValidationErrors.couponCodeError && (
              <span className="emsg">
                {formValidationErrors.couponCodeError}
              </span>
            )}
          </div>
          <DoubleColumn gap="20px">
            <div>
              <CustomSelectPrimary
                label="Coupon Type"
                options={[
                  { label: "Percentage", value: "Percentage" },
                  { label: "Amount", value: "Amount" },
                ]}
                value={couponType}
                setValue={setCouponType}
              />
            </div>
            <div>
              <InputPrimary
                label={
                  couponType === "Percentage"
                    ? "Discount value %"
                    : "Discount value"
                }
                type="number"
                value={discountValue}
                onKeyPress={(e) => {
                  var charCode =
                    typeof e.which == "undefined" ? e.keyCode : e.which;
                  var charStr = String.fromCharCode(charCode);

                  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                }}
                onChange={(e) => {
                  if (e.target.value.match(/^[0-9]*$/)) {
                    if (couponType === "Percentage") {
                      if (Math.abs(e.target.value) <= 99) {
                        setDiscountValue(
                          parseInt(Math.abs(e.target.value)) + ""
                        );
                      } else {
                        setDiscountValue("");
                      }
                    } else {
                      setDiscountValue(Math.abs(e.target.value) + "");
                    }
                  }
                }}
                placeholder={
                  couponType === "Percentage" ? "20 %" : "$ 1000"
                  // "Discount value"
                }
                min={0}
                max={couponType === "Percentage" ? 99 : 999999999}
              />
              {formValidationErrors.discountError && (
                <span className="emsg">
                  {formValidationErrors.discountError}
                </span>
              )}
            </div>
          </DoubleColumn>
        </Section>
        <Section label="Applies To">
          <CustomSelectPrimary
            label="Applies To"
            options={[
              { label: "Selected Products", value: "Selected Products" },
              { label: "Entire Cart", value: "Entire Cart" },
            ]}
            value={appliesTo}
            setValue={setAppliesTo}
          />
          <div>
            {appliesTo === "Selected Products" && (
              <MultipleSelectPrimary
                label="Pick Products"
                inputLabel="Select Products"
                options={options}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {formValidationErrors.selectError && (
              <span className="emsg">{formValidationErrors.selectError}</span>
            )}
          </div>
        </Section>
        <Section label="Validity">
          <AutoFlow>
            <CheckboxPrimary
              label="Never Expires"
              checked={neverExpires}
              onChange={(e) => setNeverExpires(e.target.checked)}
            />
          </AutoFlow>
          <DoubleColumn gap="20px">
            {!neverExpires && (
              <div>
                <DatePicker
                  label="Starts On"
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                  minDate={new Date()}
                />
                {dateRelatedErrors.start && (
                  <span className="emsg">{dateRelatedErrors.start}</span>
                )}
              </div>
            )}
            {/* If never expires checked, expire date picker won't show */}
            {!neverExpires && (
              <div>
                <DatePicker
                  label="Expires On"
                  type="date"
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
                {dateRelatedErrors.end && (
                  <span className="emsg">{dateRelatedErrors.end}</span>
                )}
              </div>
            )}
          </DoubleColumn>
        </Section>
        <Section label="Rules">
          <MultipleFlexibleColumns width="350px" gap="20px">
            <div>
              <InputPrimary
                label="Minimum Order Value"
                type="number"
                name="MinOrderValue"
                value={stateRules.MinOrderValue}
                onChange={handleRulesInput}
              />
              {formValidationErrors.MinOrderValue && (
                <span className="emsg">
                  {formValidationErrors.minOrderValueError}
                </span>
              )}
            </div>
            <div>
              <InputPrimary
                label="Total Usage Limit"
                type="number"
                name="TotalUsageLimit"
                value={stateRules.TotalUsageLimit}
                onChange={handleRulesInput}
              />
              {formValidationErrors.TotalUsageLimit && (
                <span className="emsg">
                  {formValidationErrors.totalUsageError}
                </span>
              )}
            </div>
            <div>
              <InputPrimary
                label="Limit Uses per Customer"
                type="number"
                name="LimitUsesPerCustomer"
                value={stateRules.LimitUsesPerCustomer}
                onChange={handleRulesInput}
              />
              {formValidationErrors.LimitUsesPerCustomer && (
                <span className="emsg">
                  {formValidationErrors.limitUsageError}
                </span>
              )}
            </div>
          </MultipleFlexibleColumns>
        </Section>
        <Section label="Status">
          <ToggleSwitch status={activeStatus} onChange={setActiveStatus} />
        </Section>
      </ContentContainer>
      <ConfirmationPopup data={confirmationPopupState} />
      <div className="submit-button">
        <ButtonPrimary onClick={addCoupon} disabled={inProgress}>
          Create
        </ButtonPrimary>
      </div>
      <SimplePopup
        ref={confirmationPopup}
        message={message}
        close={closeConfirmationPopup}
      />
      {/* <SimpleDialog
        open={addCouponConfirmationDialog.open}
        setOpen={(open) => setAddCouponConfirmationDialog({ ...addCouponConfirmationDialog, open })}
        message={addCouponConfirmationDialog.message}
        onAgree={onConfirmationOfCouponDialog}
        _agreeButtonText="Okay"
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .submit-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;
export default AddCouponTajwar;
