export const calculatePointsWorth = (
  targetPrice,
  targetPoints,
  pointsToConsider
) => {
  try {
    const val = parseFloat(
      pointsToConsider * (+targetPrice / +targetPoints)
    ).toFixed(4);
    if (isFinite(val)) {
      return val;
    }
  } catch (e) {}
  return 0;
};
