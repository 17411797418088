import React, { useState, useRef } from "react";
import { useEffect } from "react";
// import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import InputPrimary from "../../../atoms/InputPrimary";
import url from "../../../config/axios";
import Success from "../../../assets/Success1.png";
import Failure from "../../../assets/failure.png";
function LoyaltySetting() {
  const [pointPrice, setPointPrice] = useState("0");
  const [pointRegistration, setpointRegistration] = useState(0);
  const [pointEarned, setpointEarned] = useState(0);
  const [cursorPos, setCursorPos] = useState(0);
  const [amountThreshold, setAmountThreshold] = useState(0);
  const [percentageThreshold, setPercentageThreshold] = useState(0);
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };

  const getLoyalty = async () => {
    try {
      const get = await url.get("/v1/loyalty");
      const response = get?.data.data?.loyalty;
      setPointPrice(response?.pointsToPrice);
      setpointRegistration(response?.PointsForRegistration);
      setpointEarned(response?.PointsOnFirstOrder);
      setPercentageThreshold(
        isNaN(response?.percentageThreshold)
          ? 0
          : Number(response?.percentageThreshold)
      );
      setAmountThreshold(
        isNaN(response?.amountThreshold) ? 0 : Number(response?.amountThreshold)
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getLoyalty();
  }, []);
  const handleChange = (event) => {
    const { value, selectionStart } = event.target;
    if (value < 100) {
      if (String(value).includes(".")) {
        if (String(value).split(".")[1]?.length > 6) {
          return;
        }
      }
      setPointPrice(value);
      setCursorPos(selectionStart);
    }
  };
  const handleBlur = (event) => {
    const formattedValue = Number(pointPrice).toFixed(6);
    setPointPrice(formattedValue);
    event.target.setSelectionRange(cursorPos, cursorPos);
  };
  const addLoyalty = async () => {
    try {
      const addUpdate = await url.put("/v1/loyalty/update-data", {
        PointsForRegistration: pointRegistration,
        PointsOnFirstOrder: pointEarned,
        PointsToPointsEarnedRatio: 0,
        pointsToPrice: pointPrice,
        percentageThreshold: Number(percentageThreshold),
        amountThreshold: Number(amountThreshold),
      });
      if (addUpdate.data.Message === "Success") {
        setMessage({
          text: "Success",
          status: true,
          display: true,
        });
        openTooltip();
        getLoyalty();
      }
    } catch (e) {
      setMessage({
        text: "Failed",
        status: false,
        display: true,
      });
      openTooltip();
    }
  };
  return (
    <div
      style={{
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
      }}>
      <div className="row">
        <div className="col-lg-6">
          <InputPrimary
            label="Points to Price"
            type="text"
            value={pointPrice}
            onChange={handleChange}
            onBlur={handleBlur}
            max={100}
            placeholder="3"
          />
        </div>
        <div className="col-lg-6">
          <InputPrimary
            label="Points Earned for Registration"
            type="number"
            onInput={(e) => {
              e.target.value = Math.abs(e.target.value);
            }}
            value={pointRegistration}
            onChange={(e) => setpointRegistration(e.target.value)}
            // id="exampleFormControlInput1"
            placeholder="3"
          />
        </div>
        <div className="col-lg-6 mt-2">
          <InputPrimary
            label="Points Earned on First Order"
            type="number"
            onInput={(e) => {
              e.target.value = Math.abs(e.target.value);
            }}
            value={pointEarned}
            onChange={(e) => setpointEarned(e.target.value)}
            // id="exampleFormControlInput1"
            placeholder="3"
          />
        </div>
        <div className="col-lg-6 mt-2">
          <InputPrimary
            label="Maximum deduction(% of subtotal)"
            type="number"
            onInput={(e) => {
              e.target.value = Math.abs(e.target.value);
            }}
            value={percentageThreshold}
            onChange={(e) => {
              if (Number(e.target.value) <= 100) {
                setPercentageThreshold(e.target.value);
              }
            }}
            // id="exampleFormControlInput1"
            placeholder="3"
          />
        </div>
        <div className="col-lg-6 mt-2">
          <InputPrimary
            label="Maximum deduction($)"
            type="number"
            value={String(amountThreshold)?.length ? amountThreshold : ""}
            onChange={(e) => {
              let currentValue = Number(e.target.value);
              if (!isNaN(currentValue) && currentValue >= 0) {
                setAmountThreshold(currentValue);
              }
            }}
            // id="exampleFormControlInput1"
            placeholder="3"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end ">
        <button
          onClick={() => {
            addLoyalty();
          }}
          style={{
            color: "white",
            backgroundColor: "#FFA382",
            padding: "0.5em 1.5em",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}>
          Update
        </button>
      </div>
      <Popup ref={ref} position="center">
        {message.text === "Success" ? (
          <div style={{ textAlign: "center" }}>
            {" "}
            <div>
              <img height="60" alt="" src={Success} />
            </div>
            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Updated successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}>
                Loyalty is updated{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeTooltip();
              }}>
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}>
              ok
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default LoyaltySetting;
