import NotificationSettingsPage from "../../GeneralSettings/sub-pages/NotificationSettingsPage";

const PrefferencesSettings = () => {
  return (
    <div>
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          background: "#fff",
        }}
      >
        <div className="col-md-12">
          <form
            className="row"
            onSubmit={(e) => e.preventDefault()}
            style={{ height: "auto" }}
          >
            <div className="col-md-12">
              <div
                className="row d-flex "
                style={{
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-2px",
                    backgroundColor: "#FFA382",
                    marginTop: "10px ",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-5 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "18px",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Notification Settings
                  </div>
                </div>
              </div>
            </div>
          </form>
          <NotificationSettingsPage />
        </div>
      </div>
    </div>
  );
};

export default PrefferencesSettings;
