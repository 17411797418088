import { useState, useRef, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { fileUpload } from "../../../helpers/firebaseFileUpload";
import calculateAspectRatio from "calculate-aspect-ratio";
import FileUpload from "../../../atoms/FileUpload/NoCropUpload";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import ConfirmationPopup from "../../../atoms/ConfirmationPopup/ConfirmationPopup";
import InputPrimary from "../../../atoms/InputPrimary";
import InputAreaPrimary from "../../../atoms/InputAreaPrimary/Index";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import { set } from "date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initialFormState = {
  appIconFile: null,
  appIconFileName: null,
  appIconFileSource: null,
  screenShot1File: null,
  screenShot1FileName: null,
  screenShot1FileSource: null,
  screenShot2File: null,
  screenShot2FileName: null,
  screenShot2FileSource: null,
  screenShot3File: null,
  screenShot3FileName: null,
  screenShot3FileSource: null,
  screenShot4File: null,
  screenShot4FileName: null,
  screenShot4FileSource: null,
};

function AppDetailsPage({ invokeLogout, setActionButtonFunctions }) {
  const [formData, setFormData] = useState({
    ...initialFormState,
  });
  const [errors, setErrors] = useState({
    faviconLogoError: null,
    mainLogoError: null,
    footerLogoError: null,
  });
  const [state, setState] = useState({
    name: "",
    about: "",
    description: "",
    version: "",
    age: "",
    releaseDate: "",
    downloads: "",
    androidVersion: "",
    appIcon: "",
  });
  const allowedRatios = ["9:16"];
  const [success, setSuccess] = useState(null);

  const [errorApp, setErrorApp] = useState(null);
  const [Body, setBody] = useState("");

  /**
   *
   * @param {event} event Actual HTML file event
   * @param {string} target if target = "tom" => set"tom"FileName(filename) and set"tom"File(buffer) and set"tom"FileSource(sourceurl)
   */
  const imageSelector = (event, target) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        var i = new Image();
        i.onload = () => {
          try {
            const aspectRatio = calculateAspectRatio(i.width, i.height);
            if (allowedRatios.includes(aspectRatio + "")) {
              setErrors({
                ...errors,
                [`${target}Error`]: null,
              });
            } else {
              setErrors({
                ...errors,
                [`${target}Error`]: `Allowed ratio(s) : ${allowedRatios.join(
                  ", "
                )}`,
              });
            }
          } catch (e) {
            setErrors({
              ...errors,
              [`${target}Error`]: "Invalid format",
            });
          }
        };
        const objectUrl = URL.createObjectURL(file);
        setFormData({
          ...formData,
          [target + "FileName"]: file.name,
          [target + "File"]: file,
          [target + "FileSource"]: objectUrl,
        });
        i.src = objectUrl;
      }
    }
  };
  const [inProgress, setInProgress] = useState(true);
  const [previousData, setPreviousData] = useState({});
  const [confirmationPopupState, setConfirmationPopupState] = useState({
    show: false,
    text: "",
    error: false,
  });
  const [appIcon, setMainIcon] = useState({ file: null, source: null });
  const [screenShot1, setScreenShot1] = useState({ file: null, source: null });
  const [screenShot2, setScreenShot2] = useState({ file: null, source: null });
  const [screenShot3, setScreenShot3] = useState({ file: null, source: null });
  const [screenShot4, setScreenShot4] = useState({ file: null, source: null });
  const [appId, setAppId] = useState(null);
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [error, setError] = useState(null);

  const validateForm = () => {
    let data = { ...formValidationErrors };
    if (state.name === "") {
      data = {
        ...data,
        appName: "App Name is required",
      };
    } else {
      let { appName, ...others } = data;
      data = { ...others };
    }
    if (Body === "") {
      data = {
        ...data,
        Body: "Description is required",
      };
    } else {
      let { Body, ...others } = data;
      data = { ...others };
    }
    if (state.version === "") {
      data = {
        ...data,
        version: "Version is required",
      };
    } else {
      let { version, ...others } = data;
      data = { ...others };
    }
    if (state.age === "") {
      data = {
        ...data,
        age: "Age is required",
      };
    } else {
      let { age, ...others } = data;
      data = { ...others };
    }
    if (state.releaseDate === "") {
      data = {
        ...data,
        releaseDate: "Release Date is required",
      };
    } else {
      let { releaseDate, ...others } = data;
      data = { ...others };
    }
    if (state.downloads === "") {
      data = {
        ...data,
        downloads: "Downloads is required",
      };
    } else {
      let { downloads, ...others } = data;
      data = { ...others };
    }
    if (state.androidVersion === "") {
      data = {
        ...data,
        androidVersion: "Android Version is required",
      };
    } else {
      let { androidVersion, ...others } = data;
      data = { ...others };
    }
    if (formData.appIconFile === null && formData.appIconFileSource === null) {
      data = {
        ...data,
        appIcon: "App Icon is required",
      };
    } else {
      let { appIcon, ...others } = data;
      data = { ...others };
    }
    if (
      formData.screenShot1File === null &&
      formData.screenShot1FileSource === null
    ) {
      data = {
        ...data,
        screenshot1: "Screenshot1 is required",
      };
    } else {
      let { screenshot1, ...others } = data;
      data = { ...others };
    }
    if (
      formData.screenShot2File === null &&
      formData.screenShot2FileSource === null
    ) {
      data = {
        ...data,
        screenshot2: "Screenshot2 is required",
      };
    } else {
      let { screenshot2, ...others } = data;
      data = { ...others };
    }
    if (
      formData.screenShot3File === null &&
      formData.screenShot3FileSource === null
    ) {
      data = {
        ...data,
        screenshot3: "Screenshot3 is required",
      };
    } else {
      let { screenshot3, ...others } = data;
      data = { ...others };
    }
    if (
      formData.screenShot4File === null &&
      formData.screenShot4FileSource === null
    ) {
      data = {
        ...data,
        screenshot4: "Screenshot4 is required",
      };
    } else {
      let { screenshot4, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors({ ...data });
    return data;
  };

  const updateMedias = async () => {
    let formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setInProgress(true);
      let currentScreenShot1FileSource = previousData.screenShot1FileSource;
      let currentScreenShot2FileSource = previousData.screenShot2FileSource;
      let currentScreenShot3FileSource = previousData.screenShot3FileSource;
      let currentScreenShot4FileSource = previousData.screenShot4FileSource;
      let currentAppIconFileSource = previousData.appIconFileSource;
      if (formData.appIconFile) {
        let url = await fileUpload(formData.appIconFile);
        if (!!url) {
          currentAppIconFileSource = url;
        }
      } else {
        if (
          !formData.appIconFileSource ||
          formData.appIconFileSource.length === 0
        )
          currentAppIconFileSource = null;
      }

      if (formData.screenShot1File) {
        let url = await fileUpload(formData.screenShot1File);
        if (!!url) {
          currentScreenShot1FileSource = url;
        }
      } else {
        if (
          !formData.screenShot1FileSource ||
          formData.screenShot1FileSource.length === 0
        )
          currentScreenShot1FileSource = null;
      }

      if (formData.screenShot2File) {
        let url = await fileUpload(formData.screenShot2File);
        if (!!url) {
          currentScreenShot2FileSource = url;
        }
      } else {
        if (
          !formData.screenShot2FileSource ||
          formData.screenShot2FileSource.length === 0
        )
          currentScreenShot2FileSource = null;
      }
      if (formData.screenShot3File) {
        let url = await fileUpload(formData.screenShot3File);
        if (!!url) {
          currentScreenShot3FileSource = url;
        }
      } else {
        if (
          !formData.screenShot3FileSource ||
          formData.screenShot3FileSource.length === 0
        )
          currentScreenShot3FileSource = null;
      }
      if (formData.screenShot4File) {
        let url = await fileUpload(formData.screenShot4File);
        if (!!url) {
          currentScreenShot4FileSource = url;
        }
      } else {
        if (
          !formData.screenShot4FileSource ||
          formData.screenShot4FileSource.length === 0
        )
          currentScreenShot4FileSource = null;
      }
      setInProgress(true);
      const body = {
        ...state,
        _id: appId,
        appIcon: currentAppIconFileSource,
        description: Body,
        screenShots: [
          currentScreenShot1FileSource,
          currentScreenShot2FileSource,
          currentScreenShot3FileSource,
          currentScreenShot4FileSource,
        ],
      };
      //sanity
      Object.keys(body).forEach((key) => {
        if (!body[key]) {
          delete body[key];
        }
      });
      await url
        .put(`/v1/app-settings/upsert`, {
          ...body,
        })
        .then((res) => {
          let response = res.data?.data ?? {};
          if (res?.data?.Message === "Success") {
            updateLocalStorage(response);
            setSuccess("Data Updated Successfully.");
          }
          setFormData({
            ...initialFormState,
            appIconFileSource: response?.appIcon ?? null,
            screenShot1FileSource: response?.screenShots[0] ?? null,
            screenShot2FileSource: response?.screenShots[1] ?? null,
            screenShot3FileSource: response?.screenShots[2] ?? null,
            screenShot4FileSource: response?.screenShots[3] ?? null,
          });
          setPreviousData({
            appIconFileSource: response?.appIcon ?? "",
            screenShot1FileSource: response.screenShots[0] ?? "",
            screenShot2FileSource: response.screenShots[1] ?? "",
            screenShot3FileSource: response.screenShots[2] ?? "",
            screenShot4FileSource: response.screenShots[3] ?? "",
          });
          setInProgress(false);
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          for (let i in e?.response?.data?.data) {
            setErrorApp(e?.response?.data?.data[i]);
          }
          setInProgress(false);
        });
    }
  };

  /**
   *
   * @param {object} data media object
   */
  const updateLocalStorage = async (data) => {
    try {
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        prevData[0] = { ...prevData[0], media: { ...data } };
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        // window.location.reload();
      }
    } catch (e) {}
  };
  useEffect(
    () => {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      const consumerID = adminID[0]?.consumerId ?? null;
      url
        .get(`/v1/app-settings/app?consumerID=${consumerID}`)
        .then((res) => {
          let response = res.data.data.app ?? {};
          setAppId(response?._id ?? null);
          if (response) {
            setBody(response?.description ?? "");
            setState({
              name: response?.name ?? "",
              description: response?.description ?? "",
              version: response?.version ?? "",
              age: response?.age ?? "",
              releaseDate: response?.releaseDate ?? "",
              downloads: response?.downloads ?? "",
              androidVersion: response?.androidVersion ?? "",
            });
          }
          setFormData({
            ...formData,
            appIconFileSource: response?.appIcon ?? null,
            screenShot1FileSource: response?.screenShots[0] ?? null,
            screenShot2FileSource: response?.screenShots[1] ?? null,
            screenShot3FileSource: response?.screenShots[2] ?? null,
            screenShot4FileSource: response?.screenShots[3] ?? null,
          });
          setPreviousData({
            appIconFileSource: response?.appIcon ?? "",
            screenShot1FileSource: response.screenShots[0] ?? "",
            screenShot2FileSource: response.screenShots[1] ?? "",
            screenShot3FileSource: response.screenShots[2] ?? "",
            screenShot4FileSource: response.screenShots[3] ?? "",
          });
          setInProgress(false);
        })
        .catch((e) => {
          setInProgress(false);
        });
    },
    //eslint-disable-next-line
    []
  );
  useEffect(() => {}, [formData]);
  useEffect(() => {
    setFormData({
      ...formData,
      appIconFile: appIcon.file,
      appIconFileSource: appIcon.source,
    });
  }, [appIcon]);
  useEffect(() => {
    setFormData({
      ...formData,
      screenShot1File: screenShot1.file,
      screenShot1FileSource: screenShot1.source,
    });
  }, [screenShot1]);
  useEffect(() => {
    setFormData({
      ...formData,
      screenShot2File: screenShot2.file,
      screenShot2FileSource: screenShot2.source,
    });
  }, [screenShot2]);
  useEffect(() => {
    setFormData({
      ...formData,
      screenShot3File: screenShot3.file,
      screenShot3FileSource: screenShot3.source,
    });
  }, [screenShot3]);
  useEffect(() => {
    setFormData({
      ...formData,
      screenShot4File: screenShot4.file,
      screenShot4FileSource: screenShot4.source,
    });
  }, [screenShot4]);

  return (
    <div
      style={{
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
      }}
    >
      <div className="row">
        <div className="col-md-3">
          {/* Main Logo */}
          <FileUpload
            label="App Icon"
            onUpload={setMainIcon}
            file={formData.appIconFileSource}
            size={"512 x 512 px"}
            ImageHeight={"512"}
            ImageWidth={"512"}
          />
          {formValidationErrors.appIcon && (
            <span className="validation-help">
              {formValidationErrors.appIcon}
            </span>
          )}
        </div>

        <div className="col-md-9 mb-4">
          <InputPrimary
            value={state.name}
            name="name"
            label={"App Name"}
            placeholder="App Name"
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
          {formValidationErrors.appName && (
            <span className="validation-help">
              {formValidationErrors.appName}
            </span>
          )}
          <div className="mt-3">
            {/* <InputAreaPrimary
              label="Description"
              type="text"
              name="description"
              rows={4}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              placeholder="Write here..."
              value={state.description}
            /> */}
            <label>Description</label>
            <div>
              <CKEditor
                row={"5"}
                editor={ClassicEditor}
                data={Body ?? ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setBody(data);
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
                config={{
                  toolbarLocation: "bottom",
                }}
              />
            </div>

            {formValidationErrors.Body && (
              <span className="validation-help">
                {formValidationErrors.Body}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4">
          <InputPrimary
            value={state.version}
            name="version"
            label={"Version Number"}
            placeholder="Version Number"
            onChange={(e) => setState({ ...state, version: e.target.value })}
          />
          {formValidationErrors.version && (
            <span className="validation-help">
              {formValidationErrors.version}
            </span>
          )}
        </div>
        <div className="col-md-4 mb-4">
          <InputPrimary
            value={state.age}
            name="age"
            label={"Age"}
            placeholder="Enter Age"
            onChange={(e) => setState({ ...state, age: e.target.value })}
          />
          {formValidationErrors.age && (
            <span className="validation-help">{formValidationErrors.age}</span>
          )}
        </div>
        <div className="col-md-4 mb-4">
          <InputPrimary
            value={state.androidVersion}
            name="androidVersion"
            label={"Android Number"}
            placeholder="Android Number"
            onChange={(e) =>
              setState({ ...state, androidVersion: e.target.value })
            }
          />
          {formValidationErrors.androidVersion && (
            <span className="validation-help">
              {formValidationErrors.androidVersion}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4">
          <InputPrimary
            value={state.releaseDate}
            name="releaseDate"
            label={"Released On"}
            placeholder="Released On"
            onChange={(e) =>
              setState({ ...state, releaseDate: e.target.value })
            }
          />
          {formValidationErrors.releaseDate && (
            <span className="validation-help">
              {formValidationErrors.releaseDate}
            </span>
          )}
        </div>
        <div className="col-md-4 mb-4">
          <InputPrimary
            value={state.downloads}
            name="downloads"
            label={"Downloads"}
            placeholder="Downloads"
            onChange={(e) => setState({ ...state, downloads: e.target.value })}
          />
          {formValidationErrors.downloads && (
            <span className="validation-help">
              {formValidationErrors.downloads}
            </span>
          )}
        </div>
      </div>
      <h6
        style={{
          color: "#FFA382",
        }}
      >
        Add screenshots For App
      </h6>

      <Container>
        {/* Screenshot 1 */}
        <div>
          <FileUpload
            label="Screenshot 1"
            onUpload={setScreenShot1}
            file={formData.screenShot1FileSource}
            height="400px"
            ImageHeight={"1080"}
            ImageWidth={"1920"}
          />
          {formValidationErrors.screenshot1 && (
            <span className="validation-help">
              {formValidationErrors.screenshot1}
            </span>
          )}
        </div>
        {/* Screenshot 2 */}
        <div>
          <FileUpload
            label="Screenshot 2"
            onUpload={setScreenShot2}
            file={formData.screenShot2FileSource}
            height="400px"
            ImageHeight={"1080"}
            ImageWidth={"1920"}
          />
          {formValidationErrors.screenshot2 && (
            <span className="validation-help">
              {formValidationErrors.screenshot2}
            </span>
          )}
        </div>
        {/* Screenshot 3 */}
        <div>
          <FileUpload
            label="Screenshot 3"
            onUpload={setScreenShot3}
            file={formData.screenShot3FileSource}
            height="400px"
            ImageHeight={"1080"}
            ImageWidth={"1920"}
          />
          {formValidationErrors.screenshot3 && (
            <span className="validation-help">
              {formValidationErrors.screenshot3}
            </span>
          )}
        </div>
        {/* Screenshot 4 */}
        <div>
          <FileUpload
            label="Screenshot 4"
            onUpload={setScreenShot4}
            file={formData.screenShot4FileSource}
            height="400px"
            ImageHeight={"1080"}
            ImageWidth={"1920"}
          />
          {formValidationErrors.screenshot4 && (
            <span className="validation-help">
              {formValidationErrors.screenshot4}
            </span>
          )}
        </div>
      </Container>
      <p
        style={{
          color: "#ff6d6d",
          fontWeight: "500",
          marginTop: "10px",
        }}
      >
        * Screenshots size must be 1080 x 1920
      </p>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={updateMedias}
          disabled={inProgress}
        >
          Update
        </Button>
      </Grid>

      {/* <ConfirmationPopup data={confirmationPopupState} /> */}

      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Data Updated Successfully"}
          onClose={() => {
            setSuccess(null);
          }}
        />
      ) : null}

      {errorApp ? (
        <SimpleModal
          severity={"error"}
          highlights={errorApp}
          onClose={() => {
            setErrorApp(null);
          }}
        />
      ) : null}
    </div>
  );
}

const Container = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;
export default AppDetailsPage;
