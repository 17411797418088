import { ArrowDownward } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageSessionThunk,
  selectMessageSessions,
  selectWholeMessageSessionState,
} from "../../redux/slices/messageSessionSlice";
import EachSessionCard from "./EachSessionCard";
import SessionLoaderComponent from "./SessionLoader";
import SessionSearchComponent from "./SessionSearch";

const SessionComponent = () => {
  const dispatch = useDispatch();
  const { error, inProgress, hasMore } = useSelector(
    selectWholeMessageSessionState
  );
  const messageSessions = useSelector(selectMessageSessions);
  // useEffect(() => {
  // }, [messageSessions])
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1.2em",
        padding: "2em 1em",
      }}
    >
      {/**Search bar */}
      <SessionSearchComponent />
      {/* Sessions */}
      <div
        className="custom-scrollbar"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1.2em",
          padding: "1em 1em",
          maxHeight: "85vh",
          overflowY: "scroll",
        }}
      >
        {Object.keys(messageSessions).map((obj, i) => {
          return (
            <EachSessionCard
              key={i}
              _id={obj}
              data={messageSessions[obj]}
              boxShadow={(i + 1) % 2 === 0}
            />
          );
        })}
        {inProgress && <SessionLoaderComponent />}
        {hasMore && !error && !inProgress && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
              }}
              endIcon={<ArrowDownward />}
              onClick={() =>
                dispatch(getMessageSessionThunk({ initialAttempt: false }))
              }
            >
              See more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionComponent;
