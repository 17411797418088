import React from 'react'
import styled from '@emotion/styled'

export function FadeIn({ direction = 'right', children }) {
  return (
    <Wrapper>
        {direction === "right" && <div className='fadeInFromLeft'>{children}</div> }
        {direction === "left" && <div className='fadeInFromRight'>{children}</div> }
        {direction === "up" && <div className='fadeInFromBottom'>{children}</div> }
        {direction === "down" && <div className='fadeInFromTop'>{children}</div> }
    </Wrapper>
  )
}


const Wrapper = styled.div`
    .fadeInFromLeft {
        animation: fadeInFromLeft 0.3s ease-out;
    } 
    .fadeInFromRight {
        animation: fadeInFromRight 0.3s ease-out;
    } 
    .fadeInFromBottom {
        animation: fadeInFromBottom 0.3s ease-out;
    } 
    .fadeInFromTop {
        animation: fadeInFromTop 0.3s ease-out;
    } 
`