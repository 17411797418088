import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styledEngine from "@mui/styled-engine";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import SuccessDialogIcon from '../../assets/success-dialog.svg'
import FailedDialogIcon from '../../assets/failed-dialog.svg'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrimaryDialog({ open, setOpen, isSuccess, successData, failedData, onClose = null, sx }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          width: "90%",
          maxWidth: "400px",
          ...sx,
        },
      }}
    >
      {isSuccess ? (
        <FeedbackDialogContent>
            <img src={SuccessDialogIcon} />
            <h2>{ successData?.title ?? "Successfully Completed" }</h2>
            <p>{ successData?.description ?? "Your job is successfully completed. You can close the dialog now!" }</p>

            <Button variant="contained" onClick={() => handleClose()}>Close</Button>
        </FeedbackDialogContent> 
        ) : (
        <FeedbackDialogContent>
            <img src={FailedDialogIcon} />
            <h2>{ failedData?.title ?? "Failed to Complete!" }</h2>
            <p>{ failedData?.description ?? "Sorry, Something went wrong! Please check your inputs correctly, if you are still unable please contact to our support" }</p>

            <Button variant="contained" onClick={() => handleClose()} color="error">Close</Button>
        </FeedbackDialogContent> 
        )}
    </Dialog>
  );
}

const FeedbackDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 32px;
  text-align: center;
  img {
    height: 200px;
    width: 200px;
  }
  p {
    color: #383838;
    font-size: 18px;
  }
`;

export default React.memo(PrimaryDialog);
