import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Success from "../../assets/Success1.png";
import Failure from "../../assets/failure.png";
import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "25px",
  boxShadow: 24,
  pt: 5,
  px: 4,
  pb: 5,
};

//severirty = "success"
export default function SimpleModal({
  severity,
  highlights,
  onClose,
  onDone,
  closeModal,
  ...props
}) {
  const theme = useTheme();
  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose();
      if (closeModal instanceof Function) {
        closeModal();
      }
    }
  };
  return (
    <div>
      <Modal open={true} onClose={onClose}>
        <Box sx={{ ...style, width: 400 }}>
          <div>
            {severity === "success" ? (
              <div style={{ textAlign: "center" }}>
                <div
                  onClick={() => {
                    handleClose();
                  }}
                  style={{
                    color: "red",
                    cursor: "pointer",
                    textAlign: "right",
                    fontSize: "20px",
                    position: "absolute",
                    top: "20px",
                    right: "50px",
                  }}
                >
                  x
                </div>
                <div>
                  <img height="60" alt="" src={Success} />
                </div>
                <p className="popup-heading" style={{ marginTop: "20px" }}>
                  {"Sucess"} <br />
                  <span
                    style={{
                      color: "#747474",
                      fontSize: "12px",
                      marginTop: "-10px !important",
                    }}
                  >
                    {highlights}{" "}
                  </span>
                </p>
                <button
                  className="btn btn-cannaby"
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginTop: "0",
                    width: "20vh",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    if (onDone instanceof Function) {
                      onDone();
                    }
                    handleClose();
                  }}
                >
                  OK
                </button>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <div
                  onClick={() => {
                    if (onDone instanceof Function) {
                      onDone();
                    }
                    handleClose();
                  }}
                  style={{
                    color: "red",
                    cursor: "pointer",
                    textAlign: "right",
                    fontSize: "20px",
                    position: "absolute",
                    top: "20px",
                    right: "50px",
                  }}
                >
                  x
                </div>
                <div>
                  <img alt="" src={Failure} />
                </div>
                <p className="popup-heading" style={{ marginTop: "20px" }}>
                  {"Failure"} <br />
                  <span
                    style={{
                      color: theme.palette.gray.dark,
                      fontSize: "12px",
                      marginTop: "-10px !important",
                    }}
                  >
                    {highlights}{" "}
                  </span>
                </p>
                <button
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                  className="btn btn-primary border-0"
                  style={{
                    backgroundColor: "#FFA382",
                    marginTop: "0",
                    width: "20vh",
                    alignSelf: "center",
                  }}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
const Wrapper = styled.div`
  div {
    background: white;
    padding: 1rem 3rem;
    width: auto;
    max-width: 400px;
    border-radius: 15px;
    height: auto;
  }
`;
