import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";

const initialState = {
  initialLoading: false,
  loading: false,
  error: null,
  limit: 100,
  session: null,
  messages: {},
  hasMore: false,
};

const chatSessionSlice = createSlice({
  name: "chatSession",
  initialState,
  reducers: {
    setActiveSessionAction: (state, action) => {
      state.session = action.payload;
    },
    clearCurentActiveSessionAction: (state) => {
      state.session = null;
    },
    //{messages: [], hasMore: boolean}
    addNewMessagesAction: (state, action) => {
      let parsedPayload = [];
      if (!Array.isArray(action.payload?.messages)) {
        parsedPayload = [action.payload?.messages];
      } else {
        parsedPayload = action.payload?.messages;
      }
      let messagesToAdd = parsedPayload.reduce(
        (acc, obj) => ({ ...acc, [obj._id]: obj }),
        {}
      );
      if (action?.payload?.prepend) {
        state.messages = {
          ...messagesToAdd,
          ...(!!action.payload?.initialAttempt ? {} : state.messages),
        };
      } else {
        state.messages = {
          ...(!!action.payload?.initialAttempt ? {} : state.messages),
          ...messagesToAdd,
        };
      }
      state.loading = false;
      state.error = null;
      state.hasMore = action.payload?.hasMore ? true : false;
      state.initialLoading = false;
    },
    startFetchingMessagesAction: (state, action) => {
      state.error = null;
      state.loading = true;
      state.initialLoading = !!action?.payload;
      if (action.payload) {
        state.messages = {};
      }
    },
    finishFetchingMessagesWithErrorAction: (state, action) => {
      state.error = action?.payload ?? "Something went wrong";
      state.loading = false;
      state.initialLoading = false;
    },
  },
});

//export actions
export const {
  setActiveSessionAction,
  clearCurentActiveSessionAction,
  addNewMessagesAction,
  startFetchingMessagesAction,
  finishFetchingMessagesWithErrorAction,
} = chatSessionSlice.actions;

//export selectors
export const selectCurrentSessionInfo = (state) => state?.chatSession?.session;
export const selectCurrentMessages = (state) => state?.chatSession?.messages;
export const selectSendBox = (state) => state?.chatSession?.sendBox;
export const selectCurrentSessionMeta = (state) => ({
  loading: state?.chatSession?.loading,
  error: state?.chatSession?.error,
  initialLoading: state?.chatSession?.initialLoading,
  hasMore: state?.chatSession?.hasMore,
});
//export thunks
export const setActiveSessionThunk = (sessionId) => (dispatch, getState) => {
  const foundSession = getState().messageSession?.sessions[sessionId];
  if (foundSession) {
    dispatch(setActiveSessionAction(foundSession));
  }
};

export const fetchMessagesthunk = (initialAttempt) => (dispatch, getState) => {
  const sessionID = getState().chatSession?.session?._id;
  const userID = getState().chatSession?.session?.userID;
  const limit = getState().chatSession?.limit ?? 10;
  const currentTotalMessages = Object.keys(
    getState().chatSession?.messages ?? {}
  )?.length;
  dispatch(startFetchingMessagesAction(initialAttempt));

  url
    .get(`/v1/messages`, {
      params: {
        userID,
        sessionID,
        limit,
        skip: initialAttempt ? 0 : currentTotalMessages,
      },
    })
    .then((res) => {
      if (!initialAttempt) {
      }
      dispatch(
        addNewMessagesAction({ ...(res?.data?.data ?? {}), initialAttempt })
      );
    })
    .catch((e) => {
      dispatch(
        finishFetchingMessagesWithErrorAction(
          e?.response?.data?.message ?? "Something went wrong"
        )
      );
    });
};
export default chatSessionSlice.reducer;
