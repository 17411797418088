import { Fragment } from "react";
import { Skeleton } from "@mui/material";
const ChatLoaderComponent = () => {
  return (
    <Fragment>
      {new Array(5).fill(1).map((_, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5em",
              flexWrap: "wrap",
              padding: "0em 2em",
              borderRadius: "14px",
            }}>
            <Skeleton
              variant="rounded"
              animation={"wave"}
              style={{
                height: "50px",
                width: "20vw",
                marginLeft: "auto",
                borderRadius: "15px",
                marginTop: "0.8em",
                borderBottomRightRadius: "0px",
              }}></Skeleton>
            <Skeleton
              variant="rounded"
              animation={"wave"}
              style={{
                height: "50px",
                width: "15vw",
                borderRadius: "15px",
                borderBottomLeftRadius: "0px",
              }}></Skeleton>
          </div>
        );
      })}
    </Fragment>
  );
};
export default ChatLoaderComponent;
