import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { addNewMessagesAction } from "./chatSessionSlice";

const initialState = {
  inProgress: false,
  error: null,
  sessions: {},
  hasMore: false,
  limit: 50,
};

const messageSessionSlice = createSlice({
  name: "messageSession",
  initialState,
  reducers: {
    setSessionsLimitAction: (state, action) => {
      if (!isNaN(action.payload)) {
        state.limit = action.payload;
      }
    },
    resetMessageSessionsAction: () => initialState,
    startFetchingSessionsAction: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    //{sessions: [], hasMore: boolean, initialAttempt: boolean }
    addSessionsAction: (state, action) => {
      let parsedSessions = {};
      if (Array.isArray(action.payload?.sessions)) {
        parsedSessions = action?.payload?.sessions.reduce(
          (acc, obj) => ({
            ...acc,
            [obj._id]: obj,
          }),
          {}
        );
      }
      //don't include duplicate entries if not initial attempt
      if (!action.payload?.initialAttempt) {
        let keys = Object.keys(parsedSessions);
        for (let i = 0; i < keys.length; i++) {
          if (state.sessions[keys[i]]) {
            delete parsedSessions[keys[i]];
          }
        }
      }
      if (!action.payload?.initialAttempt) {
        state.sessions = { ...state.sessions, ...parsedSessions };
      } else {
        state.sessions = { ...parsedSessions };
      }
      state.hasMore = action.payload?.hasMore ?? false;
      state.inProgress = false;
      state.error = null;
    },
    //string
    fetchingErrorAction: (state, action) => {
      state.inProgress = false;
      state.error = action?.payload ?? "Something went wrong";
    },
    addNewSessionAction: (state, action) => {
      if (action?.payload?.session?._id) {
        state.sessions = {
          [action?.payload?.session?._id]: {
            ...action?.payload?.session,
            user: action?.payload?.user ?? action?.payload?.session?.user,
          },
          ...state.sessions,
        };
      }
    },
    //{session: objetc}
    updateSessionStatusAction: (state, action) => {
      const sessionToSet = action?.payload?.session;
      if (sessionToSet?._id) {
        let currentSessions = { ...state.sessions };
        let prevData = currentSessions[sessionToSet._id];
        delete currentSessions[sessionToSet._id];
        state.sessions = {
          [sessionToSet._id]: {
            ...prevData,
            ...(action.payload?.session ?? {}),
            message: action.payload?.message ?? prevData.message,
            user: action?.payload?.user ?? prevData.user,
          },
          ...currentSessions,
        };
      }
    },
  },
});

export const {
  setSessionsLimitAction,
  startFetchingSessionsAction,
  addSessionsAction,
  fetchingErrorAction,
  updateSessionStatusAction,
  addNewSessionAction,
  resetMessageSessionsAction,
} = messageSessionSlice.actions;
//{initialAttempt: boolean, search: string}
export const getMessageSessionThunk =
  ({ initialAttempt }) =>
  (dispatch, getState) => {
    dispatch(startFetchingSessionsAction());
    const currentLength = Object.keys(
      getState()?.messageSession?.sessions
    ).length;
    const limit = getState()?.messageSession?.limit;
    const search = getState()?.messageSession?.search ?? "";
    url
      .get(`/v1/message-sessions`, {
        params: {
          skip: initialAttempt ? 0 : currentLength,
          search: search ?? "",
          limit,
        },
      })
      .then((res) => {
        dispatch(addSessionsAction({ ...res?.data?.data, initialAttempt }));
      })
      .catch((e) => {
        console.error(e);
        dispatch(fetchingErrorAction());
      });
  };

const updateSeenStatus = async ({ userID, sessionID }) => {
  url
    .put(`/v1/message-sessions/update-seen-status`, {
      userID,
      sessionID,
    })
    .catch((e) => console.error(e));
};
export const updateMessageSessionAndChatWindowThunk =
  (payload) => (dispatch, getState) => {
    const currentSessionID = getState()?.chatSession?.session?._id;
    if (currentSessionID && currentSessionID === payload?.session?._id) {
      dispatch(
        addNewMessagesAction({ messages: payload?.message, prepend: true })
      );
      dispatch(
        updateSessionStatusAction({
          ...payload,
          session: { ...payload?.session, unseenCountForAdmin: 0 },
        })
      );
      //TO DO update seen status
      updateSeenStatus({
        sessionID: payload?.session?._id,
        userID: payload?.user?._id,
      });
    } else {
      dispatch(updateSessionStatusAction(payload));
    }
  };
export const selectWholeMessageSessionState = (state) => state?.messageSession;
export const selectMessageSessions = (state) => state?.messageSession?.sessions;
export const selectParticularSessionById = (sessionID) => (state) =>
  state?.messageSession?.sessions[sessionID] ?? {};

export default messageSessionSlice.reducer;
