import * as React from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styledEngine from "@mui/styled-engine";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog({
  open,
  setOpen,
  title,
  description,
  onConfirm = null,
  onCancel = null,
  disableAutoClose = false,
  sx,
  width = null,
  maxWidth = null,
  children,
  buttonText,
  loading = false
}) {
  const handleCancel = () => {
    setOpen(false);

    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  }

  return (
    <Dialog
      open={open}
      onClose={() => !disableAutoClose && !loading && handleCancel()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          width: width ?? "90%",
          maxWidth: maxWidth ?? "400px",
          ...sx,
        },
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleConfirm} disabled={loading}>{buttonText?.positive ?? 'Confirm'}</Button>
        <Button onClick={handleCancel} disabled={loading}>{buttonText?.negative ?? 'Cancel'}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ConfirmationDialog);
