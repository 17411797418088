import React, { Fragment, useState } from "react";
import styled from "@emotion/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../Style.style";
import { textShrink } from "../../helpers/textShrink";
import url from "../../config/axios";
import InvokeLayoutComponent from "./InvokeLogout";
import { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
const initialQueryData = {
  page: 1,
  limit: 60,
};
const initialPaginationData = {
  currentPage: 1,
  perPage: 60,
  totalPages: 0,
  total: 0,
};

function DropDownManufacturerPicker({
  label,
  inputLabel,
  setSelected,
  //   {
  //     <categoryID> : {...<categoryInfo>}
  //   }
  selected,
  isSingleSelect,
  referLocalData,
  hidePreview,
}) {
  const [searchState, setSearchState] = useState({
    currentSearchTerm: "",
    previousSearchTerm: "",
  });
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const [brandsState, setBrandsState] = useState({
    brands: [],
    loading: true,
    error: null,
  });
  const [paginationData, setPaginationData] = useState({
    ...initialPaginationData,
  });
  const [queryData, setQueryData] = useState({
    ...initialQueryData,
  });
  useEffect(() => {
    GetAllCategories();
  }, [referLocalData]);

  const GetAllCategories = (withSearch = false) => {
    if (withSearch) {
      setSearchState({
        ...searchState,
        previousSearchTerm: searchState.currentSearchTerm,
      });
    }
    setBrandsState({
      ...brandsState,
      brands: [],
      loading: true,
      error: null,
    });
    let queryParams = {
      ...Object.keys(queryData).reduce((acc, key) => {
        if (queryData[key]) {
          return {
            ...acc,
            [key]: queryData[key],
          };
        }
        return acc;
      }, {}),
    };
    const search = withSearch
      ? searchState.currentSearchTerm
      : searchState.previousSearchTerm;
    if (search?.length) {
      queryParams = {
        ...queryParams,
        search,
      };
    }
    url
      .get(
        `${
          !referLocalData
            ? "/v1/shared-brands/aggregated"
            : "/v2/admin/brands/paginated"
        }`,
        {
          params: {
            ...queryParams,
          },
        }
      )
      .then(async (res) => {
        const response = res.data?.data;
        if (response.brands?.length) {
          setBrandsState({
            ...brandsState,
            brands: response.brands,
            loading: false,
            error: null,
          });
          setPaginationData({
            ...paginationData,
            ...response?.paginationData,
          });
        } else {
          setBrandsState({
            ...brandsState,
            categories: response.brands,
            loading: false,
            error: "No manufacturer found",
          });
        }
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setBrandsState({
          ...brandsState,
          loading: false,
          error: "Unable to populate manufacturers",
        });
      });
  };
  useEffect(
    () => {
      GetAllCategories();
    },
    //eslint-disable-next-line
    [queryData]
  );

  const handleSelect = (item) => {
    if (isSingleSelect) {
      setSelected({ [item.brandID]: item });
    } else {
      setSelected({ ...selected, [item.brandID]: item });
    }
  };
  const handleRemove = (value) => {
    if (isSingleSelect) {
      setSelected({});
    } else {
      setSelected(
        Object.keys(selected).reduce((acc, key) => {
          if (key === value) {
            return acc;
          }
          return { ...acc, [key]: selected[key] };
        }, {})
      );
    }
  };
  return (
    <Container>
      <label>{label ?? "Pick Manufacturer"}</label>
      <div className="select-container">
        <div
          className="select-btn"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {inputLabel ?? label ?? "Pick Manufacturer"}{" "}
          <span>{dropdownOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}</span>
        </div>
        {!brandsState.loading ? (
          <div className={`dropdown-container ${!dropdownOpen && "collapsed"}`}>
            <div className="search-product">
              <input
                type="text"
                placeholder="Search..."
                value={searchState.currentSearchTerm}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    GetAllCategories(true);
                  }
                }}
                onChange={(e) => {
                  setSearchState({
                    ...searchState,
                    currentSearchTerm: e.target.value,
                  });
                }}
              />
            </div>
            {brandsState?.error?.length ? (
              <div
                className="row d-flex m-2"
                style={{ justifyContent: "center", width: "100%" }}
              >
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setQueryData({ ...initialQueryData });
                        GetAllCategories();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {brandsState.error}
                </Alert>
              </div>
            ) : null}
            <ul className={`select-options`}>
              {brandsState.brands?.length
                ? brandsState.brands.map((item, index) => (
                    <li
                      key={`selected-items-${index}`}
                      className={`option ${
                        selected[item.brandID] && "selected"
                      }`}
                      onClick={() => handleSelect(item)}
                    >
                      {item.name}
                    </li>
                  ))
                : null}
            </ul>
            {brandsState.brands?.length ? (
              <div
                className="d-flex col-md-12"
                style={{
                  justifyContent: "flex-end",
                  marginTop: "5px",
                  marginLeft: "13px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "6px",
                  }}
                >
                  <Pagination
                    onChange={(e, value) => {
                      setQueryData({ ...queryData, page: value });
                    }}
                    page={paginationData.currentPage}
                    count={paginationData.totalPages ?? 0}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={`loading-container dropdown-container ${
              !dropdownOpen && "collapsed"
            }`}
          >
            <CircularProgress />
          </div>
        )}
      </div>
      {hidePreview ? null : (
        <Fragment>
          {Object.values(selected).length > 0 && (
            <div className="selected-items">
              {Object.keys(selected).map((key) => {
                const category = selected[key];
                return (
                  <Fragment key={`selected-categories-${key}`}>
                    <span
                      className="select-item-tag"
                      data-value={key}
                      key={key}
                    >
                      {textShrink(category.name, 20)}{" "}
                      <button onClick={() => handleRemove(key)}>
                        <CloseIcon />
                      </button>
                    </span>
                  </Fragment>
                );
              })}
            </div>
          )}
        </Fragment>
      )}
      {invokeLogOut && <InvokeLayoutComponent />}
    </Container>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .select-container {
    position: relative;
    .select-btn {
      position: relative;
      display: block;
      width: 100%;
      border: 1px solid #e2e8f0;
      background: transparent;
      font-size: 0.938rem;
      box-sizing: border-box;
      padding: 12px 16px;
      border-radius: 5px;
      cursor: pointer;
      span {
        position: absolute;
        right: 15px;
        top: 60%;
        transform: translateY(-50%);
        svg {
          font-size: 2.5rem;
          color: #747474;
        }
      }
    }
    .loading-container {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-container {
      position: absolute;
      left: 0px;
      top: 60px;
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 3;
      width: 100%;
      max-height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: max-height 0.3s ease-out;
      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b4b4b4a9;
        border-radius: 5px;
        cursor: pointer;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4d5;
        border-radius: 5px;
      }
      .search-product {
        box-sizing: border-box;
        padding: 5px;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #e2e8f0;
          background: transparent;
          font-size: 0.938rem;
          box-sizing: border-box;
          padding: 12px 16px;
          border-radius: 5px;
        }
      }
      .select-options {
        list-style-type: none;
        padding: 0;
        li {
          width: 100%;
          padding: 12px 16px;
          background-color: white;
          transition: background-color 0.2s ease-out;
          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        li.selected {
          background-color: ${colors.accent};
          color: white;
        }
      }
    }
    .dropdown-container.collapsed {
      max-height: 0px;
    }
  }
  .selected-items {
    padding: 10px;
    padding-left: 0px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .select-item-tag {
      padding: 8px;
      color: white;
      background-color: ${colors.accent};
      border-radius: 4px;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      button {
        border: none;
        outline: none;
        background: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
`;

export default DropDownManufacturerPicker;
