import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { base64FileUploadWithPredefinedName } from "../../helpers/firebaseFileUpload";
import { getBannerFormInitialData } from "../../pages/TypedBanners/constants/form-initiator.constants";
import { singleImageUploadFromBase64 } from "../../helpers/singleImageUpload";

const initialState = getBannerFormInitialData();
const getFormValidationErrors = (formData) => {
  let errors = {};
  if (formData?.title?.trim().length === 0) {
    errors["title"] = "Title must be provided";
  }
  if (formData?.image === null) {
    errors["image"] = "Image must be provided";
  }
  if (formData?.title?.trim().length < 3) {
    errors["title"] = "The Title must be at least 3 characters";
  }
  if (formData?.title?.trim().length < 3) {
    errors["title"] = "The Title must be at least 3 characters";
  }
  if (
    +formData?.time <= 0 ||
    !/^[0-9]+$/.test(+formData?.time) ||
    formData?.time?.includes(".")
  ) {
    errors["time"] = "Must be a positive number";
  }
  if (
    formData?.targetTopic?.trim().length === 0 &&
    formData?.targetTopic !== "" &&
    formData?.targetTopic !== "PROMOTIONS"
  ) {
    errors["targetTopic"] = "Choose atleast one topic";
  }
  // check the target object is empty
  if (
    (Object.keys(formData?.targetInfo).length === 0 &&
      formData?.targetTopic !== "" &&
      formData?.targetTopic !== "PROMOTIONS" &&
      formData?.targetInfo?.constructor === Object) ||
    formData?.targetInfo === undefined
  ) {
    errors["targetInfo"] = "Choose atleast one target";
  }

  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets) => {
  return {
    _id: undefined,
    applicableOutlets: Array.isArray(targetOutlets)
      ? targetOutlets
      : [JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID],
    title: formData.title,
    targetTopic:
      formData.targetTopic === "PROMOTIONS" ? "" : formData.targetTopic,
    targetInfo: formData.targetInfo,
    time: formData.time,
    image: formData.image ? formData.image : undefined,
    status: formData.status ? formData.status : false,
    mediaKey: formData.mediaKey,
  };
};
export const addBannerSlice = createSlice({
  name: "add-banner",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    resetBannerStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetBannerStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
} = addBannerSlice.actions;

export const selectAddBannerState = (state) => state?.addBanner ?? initialState;
export const selectValidationErrors = (state) =>
  state?.addBanner?.validationErrors ?? {};
export const createBannerThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(getState().addBanner);
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentBannerData = getState().addBanner;
    let body = getSerializedFormData(currentBannerData, outlets);
    if (currentBannerData?.image) {
      // base64FileUploadWithPredefinedName(currentBannerData?.image)
      //   .then((res) => {
      //     if (res) {
      //       body = getSerializedFormData(
      //         { ...currentBannerData, image: res },
      //         outlets
      //       );
      //       dispatch(changeSinglePropertyAction("image", res));
      //     } else {
      //       body = getSerializedFormData(
      //         { ...currentBannerData, image: undefined },
      //         outlets
      //       );
      //     }

      //     url
      //       .put(`/v1/consumer-banners/upsert`, body)
      //       .then(() => {
      //         dispatch(setSuccessActions());
      //       })
      //       .catch((e) => {
      //         dispatch(setErrorAction(e));
      //       });
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //     body = getSerializedFormData(
      //       { ...currentBannerData, image: undefined },
      //       outlets
      //     );
      //     url
      //       .put(`/v1/consumer-banners/upsert`, body)
      //       .then(() => {
      //         dispatch(setSuccessActions());
      //       })
      //       .catch((e) => {
      //         dispatch(setErrorAction(e));
      //       });
      //   });

      singleImageUploadFromBase64(currentBannerData?.image)
        .then((res) => {
          if (res) {
            body = getSerializedFormData(
              {
                ...currentBannerData,
                image: res.image,
                mediaKey: res.mediaKey,
              },
              outlets
            );
            dispatch(changeSinglePropertyAction("image", res.image));
            dispatch(changeSinglePropertyAction("mediaKey", res.mediaKey));
          } else {
            dispatch(setErrorAction("Something went wrong"));
            return;
          }

          url
            .put(`/v1/consumer-banners/upsert`, body)
            .then(() => {
              dispatch(setSuccessActions());
            })
            .catch((e) => {
              dispatch(setErrorAction(e));
            });
        })
        .catch((e) => {
          console.error(e);
          body = getSerializedFormData(
            { ...currentBannerData, image: undefined },
            outlets
          );
          url
            .put(`/v1/consumer-banners/upsert`, body)
            .then(() => {
              dispatch(setSuccessActions());
            })
            .catch((e) => {
              dispatch(setErrorAction(e));
            });
        });
    } else {
      url
        .put(`/v1/consumer-banners/upsert`, body)
        .then(() => {
          dispatch(setSuccessActions());
        })
        .catch((e) => {
          dispatch(setErrorAction(e));
        });
    }
  }
};
export default addBannerSlice.reducer;
