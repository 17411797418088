/* eslint-disable jsx-a11y/alt-text */

import React, { useRef } from "react";
import "../Discounts/discounts.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import Failure from "../../assets/failure.png";

export default function SpecialCoupon() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [outlets, setOutlets] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  var perPage = 10;
  //clientSide search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.couponCode
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        );

        if (filtered.length === 0) {
          openNotFoundPopUp();
        }

        setCoupons(filtered);
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(filtered.length / perPage));
        setCurrentPage(1);
      } else {
        setCoupons(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );

  useEffect(() => {
    GetAllOutlets();
    GetAllCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ref = useRef();
  const openTooltip = (couponId) => {
    if (!!couponId) {
      setCouponId(couponId);
      ref.current.open();
    }
  };
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllCoupons();
    } else {
      ref.current.close();
    }
  };
  const [couponId, setCouponId] = useState(null);
  const deleteCoupon = () => {
    url.delete(`/specialEvents/?couponId=${couponId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        closeTooltip(true);
      } else {
        openNotFoundPopUp();
      }
    });
  };

  const GetAllCoupons = () => {
    url
      .get(`/specialEvents/all/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          let modified = res.data?.data?.reverse() ?? [];
          setCount(modified.length);
          setCoupons(modified);
          setUnfiltered(modified);
          setTotalPage(Math.ceil(modified.length / perPage));
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };

  const editCoupon = (data) => {
    localStorage.setItem("specialcouponEdit", JSON.stringify(data));
    history.push("/Edit-SpecialEvent");
  };

  const checkEvent = (eventType, eventDate) => {
    if (eventType === "platformAnniversary") {
      return "Valid on user's joining date";
    } else if (eventType === "Birthday") {
      return "Valid on user's birthday";
    } else {
      return moment(eventDate).format("MMMM Do, YYYY h:mm A");
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row" style={{ borderRadius: "13px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "6px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Special Days
                  </div>
                </div>
                <div className="col-md-4 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                      style={{ borderRight: "none" }}
                      className="form-control"
                      placeholder="Search..."
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>

                                    <img src={sort} />

                                </div> */}
                <div className="col-md-2 filters-bar">
                  {count < 6 ? (
                    <button
                      style={{ backgroundColor: "#FFA382", border: "none" }}
                      className="btn btn-primary"
                      onClick={() => history.push("/Add-SpecialDay")}
                    >
                      <i className="fas fa-plus-circle"></i>
                      Add Coupon
                    </button>
                  ) : null}
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Coupon Code
                      </span>
                    </th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">
                      {" "}
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Day
                      </span>
                    </th>
                    {/* <th scope="col">Applicable to</th> */}
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Value
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Valid On
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Usage
                      </span>
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                      scope="col"
                    >
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Status
                      </span>
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.length === 0 ? (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No record found
                      </td>
                    </tr>
                  ) : (
                    coupons
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((coupon, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{ color: "rgba(30, 41, 59, 1)" }}
                              colSpan="4"
                            >
                              {coupon.couponCode}
                            </td>
                            {coupon.eventType !== "platformAnniversary" ? (
                              <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                                {
                                  // eslint-disable-line prefer-template
                                  coupon.eventType === "workAnniversary"
                                    ? coupon.workType + " Day"
                                    : coupon.eventType
                                }
                              </td>
                            ) : (
                              <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                                Platform Anniversary
                              </td>
                            )}
                            <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                              {coupon.couponType === "Free Shiping"
                                ? "Free Shipping"
                                : coupon.couponType === "Percentage"
                                ? `${coupon.discountValue ?? 0} %`
                                : `$ ${coupon.discountValue ?? 0}`}
                            </td>
                            <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                              {!!coupon.neverExpires
                                ? "Never Expires"
                                : checkEvent(
                                    coupon.eventType,
                                    coupon.eventDate
                                  )}
                            </td>
                            <td
                              style={{ color: "rgba(30, 41, 59, 1)" }}
                              className="usage"
                            >
                              <span>
                                {coupon.rules?.TotalUsageLimit || "N/A"}
                              </span>
                            </td>
                            <td className="status">
                              {coupon.status ? (
                                <div
                                  className="newClass"
                                  id="coupons"
                                  style={{
                                    backgroundColor: `rgba(12, 214, 0, 0.1)`,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      textAlign: "center",
                                      fontSize: "13px",
                                      paddingTop: "2px",
                                    }}
                                  >
                                    <span style={{ color: "#0CD600" }}>
                                      Enabled
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="newClass"
                                  id="coupons"
                                  style={{
                                    backgroundColor: `rgba(255, 109, 109, 0.08)`,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      textAlign: "center",
                                      fontSize: "13px",
                                      paddingTop: "2px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#FF6D6D",
                                        paddingBottom: "6px",
                                      }}
                                    >
                                      Disabled
                                    </span>
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "2em",
                                }}
                              >
                                <i
                                  className="fas fa-pen "
                                  style={{ color: "#FFA382" }}
                                  onClick={() => {
                                    editCoupon(coupon);
                                  }}
                                ></i>
                                {/* onClick={() => moveToEdit(product)} */}
                                <i
                                  className="fa fa-trash-alt"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => {
                                    openTooltip(coupon.couponId);
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* {!!coupons.length && (
          <div className="col-md-4 pagination">
            <span>
              {`${perPage * (currentPage - 1) + 1} - ${perPage * (currentPage - 1) +
                coupons.slice(
                  (currentPage - 1) * perPage,
                  currentPage * perPage
                ).length
                } of ${coupons?.length ?? 0}`}
            </span>
            <div
              className="btn cannaby-light"
              onClick={() => decrementPage()}
              role="button">
              <i className="fas fa-chevron-left"></i>
            </div>
            <div
              className="btn cannaby-light"
              onClick={() => incrementPage()}
              role="button">
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
        )} */}
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this coupon ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteCoupon();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <div
            onClick={() => {
              closeNotFoundPopUp();
            }}
            style={{
              color: "red",
              cursor: "pointer",
              textAlign: "right",
              fontSize: "20px",
            }}
          >
            x
          </div>
          <div style={{ color: "red", marginbottom: "10px" }}>Whoops!</div>
          <div style={{ marginTop: "10px" }}>
            <img alt="" src={Failure} />
          </div>
          <p style={{ marginTop: "20px" }}>Coupon(s) not found</p>
          <button
            type="button"
            onClick={() => {
              closeNotFoundPopUp();
            }}
            className="btn btn-primary border-0"
            style={{
              backgroundColor: "#FFA382",
              marginTop: "10px",
              width: "20vh",
              alignSelf: "center",
            }}
          >
            ok
          </button>
        </div>
      </Popup>
    </div>
  );
}
