export const timeZoneData = {
  "US/Samoa": { name: "(GMT-11:00) Samoa", timezone: "US/Samoa" },
  "US/Hawaii": { name: "(GMT-10:00) Hawaii", timezone: "US/Hawaii" },
  "US/Alaska": { name: "(GMT-09:00) Alaska", timezone: "US/Alaska" },
  "US/Pacific": {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    timezone: "US/Pacific",
  },
  "America/Tijuana": {
    name: "(GMT-08:00) Tijuana",
    timezone: "America/Tijuana",
  },
  "US/Arizona": { name: "(GMT-07:00) Arizona", timezone: "US/Arizona" },
  "US/Mountain": {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    timezone: "US/Mountain",
  },
  "America/Chihuahua": {
    name: "(GMT-07:00) Chihuahua",
    timezone: "America/Chihuahua",
  },
  "America/Mazatlan": {
    name: "(GMT-07:00) Mazatlan",
    timezone: "America/Mazatlan",
  },
  "America/Mexico_City": {
    name: "(GMT-06:00) Mexico City",
    timezone: "America/Mexico_City",
  },
  "America/Monterrey": {
    name: "(GMT-06:00) Monterrey",
    timezone: "America/Monterrey",
  },
  "Canada/Saskatchewan": {
    name: "(GMT-06:00) Saskatchewan",
    timezone: "Canada/Saskatchewan",
  },
  "US/Central": {
    name: "(GMT-06:00) Central Time (US & Canada)",
    timezone: "US/Central",
  },
  "US/Eastern": {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    timezone: "US/Eastern",
  },
  "US/East-Indiana": {
    name: "(GMT-05:00) Indiana (East)",
    timezone: "US/East-Indiana",
  },
  "America/Bogota": { name: "(GMT-05:00) Bogota", timezone: "America/Bogota" },
  "America/Lima": { name: "(GMT-05:00) Lima", timezone: "America/Lima" },
  "America/Caracas": {
    name: "(GMT-04:30) Caracas",
    timezone: "America/Caracas",
  },
  "Canada/Atlantic": {
    name: "(GMT-04:00) Atlantic Time (Canada)",
    timezone: "Canada/Atlantic",
  },
  "America/La_Paz": { name: "(GMT-04:00) La Paz", timezone: "America/La_Paz" },
  "America/Santiago": {
    name: "(GMT-04:00) Santiago",
    timezone: "America/Santiago",
  },
  "Canada/Newfoundland": {
    name: "(GMT-03:30) Newfoundland",
    timezone: "Canada/Newfoundland",
  },
  "America/Buenos_Aires": {
    name: "(GMT-03:00) Buenos Aires",
    timezone: "America/Buenos_Aires",
  },
  "Asia/Dhaka": {
    name: "(GMT+06:00) Dhaka",
    timezone: "Asia/Dhaka",
  },
};
