import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { getNotificationFormInitialData } from "../../pages/TypedNotifications/constants/form-initiator.constants";
import { base64FileUploadWithPredefinedName } from "../../helpers/firebaseFileUpload";
import moment from "moment";
const initialState = getNotificationFormInitialData();
let currentNotifyID = null;
let targetID = null;
export const getFormCompatibleDataFromResponse = (data) => {
  currentNotifyID = data._id;
  targetID = data.notificationInfo.targetID;
  const { _id, scheduledAtDate, scheduledAtTwelveHours } = data;
  const scheduleForLater = scheduledAtDate && scheduledAtTwelveHours;
  return {
    ...initialState,
    viaInApp: false,
    viaPush: false,
    markAsManual: true,
    title: data.notificationInfo.title,
    highlights: data.notificationInfo.highlights,
    topic: "others",
    _id: _id,
    targetID: data.notificationInfo.targetID,
    imageUrl: data.notificationInfo.imageUrl,
    scheduleForLater,
    scheduledAtDateTime: scheduleForLater
      ? new Date(`${scheduledAtDate} ${scheduledAtTwelveHours}`)
      : new Date(),
  };
};

const getFormValidationErrors = (formData) => {
  let errors = {};
  //manipulate start date and end date
  if (formData?.title?.trim().length === 0) {
    errors["title"] = "Title must be provided";
  }
  if (formData?.highlights?.trim().length === 0) {
    errors["highlights"] = "Highlights must be provided";
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets) => {
  return {
    viaInApp: formData.viaInApp,
    viaPush: formData.viaPush,
    markAsManual: true,
    title: formData.title,
    highlights: formData?.highlights,
    topic: "others",
    applicableOutlets: Array.isArray(targetOutlets)
      ? targetOutlets
      : [JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID],
    _id: currentNotifyID,
    targetID: targetID,
    imageUrl: formData.imageUrl ? formData.imageUrl : undefined,
    scheduledAtDate: formData.scheduleForLater
      ? moment(formData.scheduledAtDateTime).format("YYYY-MM-DD")
      : undefined,
    scheduledAtTwelveHours: formData.scheduleForLater
      ? moment(formData.scheduledAtDateTime).format("LT")
      : undefined,
  };
};
export const editNotificationSlice = createSlice({
  name: "edit-notification",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    replaceNotificationStateAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetBannerStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetBannerStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
  replaceNotificationStateAction,
} = editNotificationSlice.actions;

export const selectEditNotificationState = (state) =>
  state?.editNotification ?? initialState;
export const selectEditValidationErrors = (state) =>
  state?.editNotification?.validationErrors ?? {};
export const editNotificationThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(
    getState().editNotification
  );
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentNotificationData = getState().editNotification;
    let body = getSerializedFormData(currentNotificationData, outlets);
    if (currentNotificationData?.imageFile) {
      base64FileUploadWithPredefinedName(currentNotificationData?.imageFile)
        .then((res) => {
          if (res) {
            body = getSerializedFormData(
              { ...currentNotificationData, imageUrl: res },
              outlets
            );
            dispatch(changeSinglePropertyAction("imageUrl", res));
          } else {
            body = getSerializedFormData(
              { ...currentNotificationData, imageUrl: undefined },
              outlets
            );
          }

          url
            .put(
              `/v1/consumer-notifications-admin/upsert-manual-notification`,
              body
            )
            .then(() => {
              dispatch(setSuccessActions());
            })
            .catch((e) => {
              dispatch(setErrorAction(e));
            });
        })
        .catch((e) => {
          console.error(e);
          body = getSerializedFormData(
            { ...currentNotificationData, imageUrl: undefined },
            outlets
          );
        });
    } else {
      url
        .put(
          `/v1/consumer-notifications-admin/upsert-manual-notification`,
          body
        )
        .then(() => {
          dispatch(setSuccessActions());
        })
        .catch((e) => {
          dispatch(setErrorAction(e));
        });
    }
  }
};
export default editNotificationSlice.reducer;
