import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import {
  DealInfoInitialData,
  getDealFormInitialData,
} from "../../pages/TypedDeals/constants/form-initiator.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import { getSerializedFormData, getFormValidationErrors } from "./addDealSlice";
import { updateObjectByKey } from "../../helpers/utils";

const initialState = getDealFormInitialData(true);
function serializeStarterInfoProductsForFormData(starterProducts) {
  const starterProductsArray = Array.isArray(starterProducts)
    ? starterProducts
    : [starterProducts];
  return starterProductsArray.reduce((acc, item) => {
    if (item.productID && item.productName) {
      return {
        ...acc,
        [item.productID]: {
          productID: item.productID,
          product: {
            name: item.productName,
          },
        },
      };
    }
    return acc;
  }, {});
}
function serializeStarterInfoCategoriesForFormData(starterCategories) {
  const starterCategoriesArray = Array.isArray(starterCategories)
    ? starterCategories
    : [starterCategories];
  return starterCategoriesArray.reduce((acc, item) => {
    if (item.categoryID && item.categoryName) {
      return {
        ...acc,
        [item.categoryID]: {
          categoryID: item.categoryID,
          name: item.categoryName,
        },
      };
    }
    return acc;
  }, {});
}
function serializeStarterInfoBrandsForFormData(starterBrands) {
  const starterBrandsArray = Array.isArray(starterBrands)
    ? starterBrands
    : [starterBrands];
  return starterBrandsArray.reduce((acc, item) => {
    if (item.brandID && item.brandName) {
      return {
        ...acc,
        [item.brandID]: {
          brandID: item.brandID,
          name: item.brandName,
        },
      };
    }
    return acc;
  }, {});
}
function serializeChosenBrandsProductsCategoriesFromStarterInfo(starterInfo) {
  const toReturn = {
    appliesTo: "CATEGORIES",
    chosenBrands: {},
    chosenProducts: {},
    chosenCategories: {},
  };
  try {
    if (Object.keys(starterInfo.chosenBrands)?.length) {
      toReturn.appliesTo = "BRANDS";
      toReturn.chosenBrands = serializeStarterInfoBrandsForFormData(
        starterInfo.chosenBrands
      );
    } else if (Object.keys(starterInfo.chosenCategories)?.length) {
      toReturn.appliesTo = "CATEGORIES";
      toReturn.chosenCategories = serializeStarterInfoCategoriesForFormData(
        starterInfo.chosenCategories
      );
    } else if (Object.keys(starterInfo.chosenProducts)?.length) {
      toReturn.appliesTo = "PRODUCTS";
      toReturn.chosenProducts = serializeStarterInfoProductsForFormData(
        starterInfo.chosenProducts
      );
    }
  } catch (e) {
    console.error(e);
  }
  return toReturn;
}

function serializeUserPickedDiscountCategories (startedInfoCategories) {
  const result = {}
  if (startedInfoCategories) {
    startedInfoCategories.forEach(cat => {
      result[cat.categoryID] = { categoryID: cat.categoryID, name: cat.categoryName }
    })
  }
  return result;
}
export const getFormCompatibleDataFromResponse = (data) => {
  const { _id, name, dealType, images, dealInfo, outletBasisConfig } = data;
  let parsedDealInfo = {};
  if (dealType === TypedDealsTypes.REGULAR) {
    const dealData = dealInfo.REGULAR;
    parsedDealInfo = {
      ...DealInfoInitialData.REGULAR,
      ...serializeChosenBrandsProductsCategoriesFromStarterInfo(
        dealData.starterInfo
      ),
      //off to issue
      getOffNumeric: dealData?.getOff?.numericAmount ?? 0,
      //off type
      getOffType: dealData?.getOff?.type ?? "PERCENTAGE",
    };
  } else if (dealType === TypedDealsTypes.BOGO_BUNDLED) {
    const dealData = dealInfo.BOGO_BUNDLED;
    
    parsedDealInfo = {
      ...DealInfoInitialData.BOGO_BUNDLED,
      ...serializeChosenBrandsProductsCategoriesFromStarterInfo(
        dealData.starterInfo
      ),
      variantExceptions: (Array.isArray(dealData.variantExceptions)
        ? dealData.variantExceptions
        : []
      ).reduce((acc, item) => ({ ...acc, [item]: true }), {}),
      // extendedProductPreference: dealData?.getProductDetails ? "OTHER" : "SELF",
      extendedProductPreference: dealData?.extendedProductPreference,
      targetOffEntity: dealData?.targetOffEntity,
      //minimum product quantity need to buy
      buyProductExactQuantity: dealData.buyProductExactQuantity,
      //the whole selected get product object <key> : <object>
      selectedGetProduct: dealData?.getProductDetails?.productID
        ? {
            [dealData?.getProductDetails?.productID]:
              dealData?.getProductDetails,
          }
        : {},
      //product quantity will get
      getProductExactQuantity: dealData.getProductExactQuantity,
      //selected get product variant
      getProductVariant: dealData?.getProductVariantSelectionDetails ?? {},
      //off to issue
      getOffNumeric: dealData?.getOff?.numericAmount ?? 0,
      //off type
      getOffType: dealData?.getOff?.type ?? "PERCENTAGE",
      // User picked
      userPickedDiscount: {
        maxAmountConstraint: dealData?.userPickedDiscount?.maxAmountConstraint,
        maxAmount: dealData?.userPickedDiscount?.maxAmount,
        categories: serializeUserPickedDiscountCategories(dealData.starterInfo.userPickedDiscountCategories)
      }
    };

    console.log("Parsed deal info", parsedDealInfo)
  } else if (dealType === TypedDealsTypes.TIERED_BUNDLED) {
    const dealData = dealInfo.TIERED_BUNDLED;
    parsedDealInfo = {
      ...DealInfoInitialData.TIERED_BUNDLED,
      ...serializeChosenBrandsProductsCategoriesFromStarterInfo(
        dealData.starterInfo
      ),
      tiers: {
        ...dealData.tiers,
      },
      variantExceptions: (Array.isArray(dealData.variantExceptions)
        ? dealData.variantExceptions
        : []
      ).reduce((acc, item) => ({ ...acc, [item]: true }), {}),
      buyMinimumType: dealData.buyMinimumType,
      issueAmountType: dealData.issueAmountType,
    };
  }
  const toReturn = {
    ...initialState,
    _id,
    name,
    dealType,
    images,
    applyToAllOutlets:
      Object.keys(outletBasisConfig)?.length > 1 ? true : false,
    dealInfo: {
      ...initialState.dealInfo,
      [dealType]: parsedDealInfo,
    },
  };
  return toReturn;
};
export const editDealDefSlice = createSlice({
  name: "edit-deal-def",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    replaceDealStateAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetDealStateAction: () => initialState,
    changeEditSinglePropertyAction: (state, action) => {
      // action.payload = { propName: String, propValue: any, dealType: TypedDealstype }
      const { propName, propValue, dealType } = action?.payload ?? {};
      if (dealType && Object.keys(TypedDealsTypes).includes(dealType)) {
        state = {
          ...state,
          dealInfo: {
            ...state.dealInfo,
            [dealType]: {
              ...state.dealInfo[dealType],
              [propName]: propValue,
            },
          },
        };
      } else {
        state = {
          ...state,
          [propName]: propValue,
        };
      }
      return state;
    },
    changeEditSinglePropertyActionUpdated: (state, action) => {
      // action.payload = { propName: String, propValue: any, dealType: TypedDealstype }
      const { propName, propValue, dealType } = action?.payload ?? {};
      if (dealType && Object.keys(TypedDealsTypes).includes(dealType)) {
        const dealTypeData = state.dealInfo[dealType]
        state = {
          ...state,
          dealInfo: {
            ...state.dealInfo,
            [dealType]: updateObjectByKey(propName, propValue, dealTypeData),
          },
        };
      } else {
        state = {
          ...state,
          [propName]: propValue,
        };
      }
      return state;
    },
  },
});

export const {
  resetDealStateAction,
  changeEditSinglePropertyAction,
  changeEditSinglePropertyActionUpdated, 
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
  replaceDealStateAction,
} = editDealDefSlice.actions;

export const selectEditDealDefState = (state) =>
  state?.editDealDef ?? initialState;

//select specific deal type state
// export const selectEditBogoDealDefState = (state) =>
//   state.editDealDef?.dealInfo[TypedDealsTypes.BOGO] ?? DealInfoInitialData.BOGO;
// export const selectEditTieredDealDefState = (state) =>
//   state.editDealDef?.dealInfo[TypedDealsTypes.TIERED] ??
//   DealInfoInitialData.TIERED;
export const selectEditRegularDealDefState = (state) =>
  state.editDealDef?.dealInfo[TypedDealsTypes.REGULAR] ??
  DealInfoInitialData.REGULAR;
export const selectEditBogoBundledDealDefState = (state) =>
  state.editDealDef?.dealInfo[TypedDealsTypes.BOGO_BUNDLED] ??
  DealInfoInitialData.BOGO_BUNDLED;
export const selectEditTieredBundledDealDefState = (state) =>
  state.editDealDef?.dealInfo[TypedDealsTypes.TIERED_BUNDLED] ??
  DealInfoInitialData.TIERED_BUNDLED;

//form validation errors
export const selectEditDealDefValidationErrors = (state) =>
  state?.editDealDef?.validationErrors ?? {};
export const selectEditRegularTypeValidationErrors = (state) =>
  state?.editDealDef?.validationErrors?.regularErrors ?? {};
// export const selectBogoTypeValidationErrors = (state) =>
//   state?.editDealDef?.validationErrors?.bogoErrors ?? {};
export const selectEditBogoBundledTypeValidationErrors = (state) =>
  state?.editDealDef?.validationErrors?.bogoBundledErrors ?? {};
// export const selectTieredTypeValidationErrors = (state) =>
//   state?.editDealDef?.validationErrors?.tieredErrors ?? {};
export const selectEditTieredBundledTypeValidationErrors = (state) =>
  state?.editDealDef?.validationErrors?.tieredBundledErrors ?? {};

export const editDealDefThunk = () => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(
    getState().editDealDef,
    true
  );
  dispatch(
    changeEditSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentDealData = getState().editDealDef;

    let body = getSerializedFormData(currentDealData, [], true);
    
    url
      .put(`/v1/typed-deals/update-def?_id=${currentDealData._id}`, body)
      .then(() => {
        dispatch(setSuccessActions());
      })
      .catch((e) => {
        dispatch(setErrorAction(e));
      });
  }
};
export default editDealDefSlice.reducer;
