import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styledEngine from '@mui/styled-engine';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function PrimaryDialog({ open, setOpen, title, onClose = null, disableAutoClose = false, sx, width = null, maxWidth = null, children }) {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
        onClose();
    }
  };

  return (
      <Dialog
        open={open}
        onClose={() => !disableAutoClose && handleClose()}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            "& .MuiPaper-root": {
                width: width ?? '90%',
                maxWidth: maxWidth ?? "400px",
                ...sx
            }
        }}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {children}
      </Dialog>
  );
}

export default React.memo(PrimaryDialog)