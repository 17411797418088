import { Drawer } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import url from "../../config/axios";
import { changeSinglePropertyAction } from "../../redux/slices/addDealSlice";
import { changeEditSinglePropertyAction } from "../../redux/slices/editDealDefSlice";

const TypedDealsProductSelectionDialog = ({
  onClose,
  dealSelector,
  dealType,
  editMode,
}) => {
  const dealInfo = useSelector(dealSelector);
  const dispatch = useDispatch();
  const { chosenCategories, chosenProducts, chosenBrands, variantExceptions } =
    dealInfo;
  const [progress, setProgress] = useState(false);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      if (editMode) {
        dispatch(
          changeEditSinglePropertyAction({
            dealType,
            propName,
            propValue,
          })
        );
      } else {
        dispatch(
          changeSinglePropertyAction({
            dealType,
            propName,
            propValue,
          })
        );
      }
    }
  };
  const handleChangeSelection = (variantID) => {
    if (variantExceptions[variantID]) {
      handleChange("variantExceptions", {
        ...variantExceptions,
        [variantID]: false,
      });
    } else {
      handleChange("variantExceptions", {
        ...variantExceptions,
        [variantID]: true,
      });
    }
  };
  const fetchProducts = async () => {
    let params = { limit: 500 };
    const parsedChosenBrands = Object.keys(chosenBrands ?? {});
    const parsedChosenCategories = Object.keys(chosenCategories ?? {});
    const parsedChosenProducts = Object.keys(chosenProducts ?? {});
    if (
      parsedChosenCategories.length ||
      parsedChosenBrands.length ||
      parsedChosenProducts.length
    ) {
      setProgress(true);
      if (parsedChosenBrands.length) {
        params["brandIDs"] = parsedChosenBrands;
      }
      if (parsedChosenCategories.length) {
        params["categoryIDs"] = parsedChosenCategories;
      }
      if (parsedChosenProducts.length) {
        params["productIDs"] = parsedChosenProducts;
      }
      url
        .get(`/v1/shared-catalog/aggregated`, {
          params,
        })
        .then((res) => {
          const inventories = res.data?.data?.inventories;
          setProductsData(inventories?.length ? inventories : []);
          setProgress(false);
        })
        .catch((e) => {
          console.error(e.response);
          setProgress(false);
        });
    }
  };
  useEffect(
    () => {
      fetchProducts();
    },
    //eslint-disable-next-line
    [chosenBrands, chosenProducts, chosenCategories]
  );
  const [productsData, setProductsData] = useState([]);
  return (
    <Drawer anchor={"right"} open={true} onClose={() => onClose()}>
      <div
        style={{
          display: "flex",
          width: "550px",
          height: "100vh",
          maxHeight: "100vh",
          maxWidth: "60vw",
          flexDirection: "column",
        }}>
        {/* description */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            height: "10%",
          }}>
          <h5>Selected Products</h5>
          <span style={{ fontSize: "80%", color: "gray" }}>
            (Uncheck to add execptions - <i>limited to 500</i>)
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "column",
            height: "90%",
            flexGrow: 1,
            flexDirection: "column-reverse",
          }}>
          <div
            style={{
              display: "flex",
              height: "auto",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}>
            <Button onClick={() => onClose()}>Close</Button>
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              overflow: "auto",
              flexDirection: "column",
              padding: "10px",
            }}>
            {progress ? <i>In Progress...</i> : null}
            {!progress && productsData.length === 0 ? (
              <i>Products will appear here...</i>
            ) : null}
            {productsData.map((obj) => {
              const variants = obj.variants;
              const firstVariant = variants[0];
              const firstVariantID =
                firstVariant?.tempProductID ?? firstVariant?.productID;
              if (
                variants.length === 1
                // && firstVariantID === obj.productID
              ) {
                return (
                  <FormGroup key={`product-selection-node-${obj.productID}`}>
                    <FormControlLabel
                      onChange={() => handleChangeSelection(firstVariantID)}
                      control={
                        <Checkbox
                          checked={!variantExceptions[firstVariantID]}
                        />
                      }
                      label={obj.product?.name}
                    />
                  </FormGroup>
                );
              }
              return (
                <TreeView
                  style={{ marginTop: "1em" }}
                  key={`product-selection-node-${obj.productID}`}
                  defaultCollapseIcon={<RemoveIcon />}
                  defaultExpandIcon={<AddIcon />}>
                  <TreeItem nodeId={obj.productID} label={obj.product.name}>
                    {variants.map((variant) => {
                      const variantKey =
                        variant?.tempProductID ?? variant?.productID;
                      return (
                        <FormGroup
                          style={{ marginLeft: "10px" }}
                          key={`product-selection-node-variant-${variantKey}`}>
                          <FormControlLabel
                            onChange={() => handleChangeSelection(variantKey)}
                            control={
                              <Checkbox
                                checked={!variantExceptions[variantKey]}
                              />
                            }
                            label={variant.name}
                          />
                        </FormGroup>
                      );
                    })}
                  </TreeItem>
                </TreeView>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TypedDealsProductSelectionDialog;
