import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../../../components/common/InvokeLogout";
// import EditTypedDealsDefinitoion from "./edit-typed-deals-definition";
import EditTypedDealsDefinitoion from "./edit-typed-deals-definition-new";
import EditTypedDealsRulesAndExpiry from "./edit-typed-deals-rules-expiry";
export default function EditTypedDealPage() {
  const tabs = [
    {
      title: "Rules & Expiry",
      id: "rules-and-expiry",
    },
    {
      title: "Description & Structure",
      id: "struct",
    },
  ];

  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const queryParams = new URLSearchParams(search);
  useEffect(
    () => {
      // if (query.get("forgot")) {
      //   setForgot(true);
      // }
      setCurrentTab(queryParams.get("tab") ?? currentTab);
    },
    //eslint-disable-next-line
    [queryParams]
  );
  const [shouldLogout, setShouldLogout] = useState(false);
  const invokeLogout = () => setShouldLogout(true);
  return (
    <>
      <div className="card">
        {/* Navigations */}
        <div
          className="row"
          style={{
            paddingBottom: "10px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}>
          <div
            style={{
              height: "25px",
              marginLeft: "-32px",
              backgroundColor: "#FFA382",
            }}>
            <span style={{ color: "#FFA382" }}>.</span>
          </div>
          <div className="col-md-3 d-flex">
            <div
              style={{ color: "#FFA382", fontSize: "18px", fontWeight: "600" }}>
              Edit Deal
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "3em",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "1em 2em",
            borderBottom: "2px solid #F1F5F9",
            flexGrow: 1,
          }}>
          {tabs.map((obj, i) => {
            return (
              <Link
                key={i}
                to={`?tab=${obj.id}&_id=${queryParams.get("_id")}`}
                style={{ textDecoration: "none" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.5em 1.5em",
                    color: obj.id === currentTab ? "#FFA382" : "#747474",
                    border: "none",
                    fontWeight: "bold",
                    borderRadius: "10px",
                  }}>
                  {obj.title}
                </div>
                <div
                  style={{
                    marginBottom: "-17px",
                    borderBottom:
                      obj.id === currentTab ? "6px solid #FFA382" : null,
                  }}>
                  <span style={{ color: "#FFA382", height: "15px" }}></span>
                </div>
              </Link>
            );
          })}
        </div>
        {/* tab-contents */}
        {currentTab === "rules-and-expiry" && (
          <EditTypedDealsRulesAndExpiry invokeLogout={invokeLogout} />
        )}
        {currentTab === "struct" && (
          <EditTypedDealsDefinitoion invokeLogout={invokeLogout} />
        )}
        {shouldLogout && <InvokeLayoutComponent />}
      </div>
    </>
  );
}
