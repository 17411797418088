import React, { useState } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import InputPrimary from "../../atoms/InputPrimary";
import PasswordInput from "../../atoms/PasswordInput";
import PhoneNumberInputAnik from "../../atoms/PhoneNumberInput/PhoneNumberInputAnik";
import { useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import url from "../../config/axios";
import { Button } from "@mui/material";
import Logout from "../../assets/LOGOUT.svg";
function ProfilePopup({
  show,
  setShow,
  admin,
  editAdmin,
  imageSelector,
  editImage,
  cropProperties,
  validationMessages,
  fileUpdate,
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const history = useHistory();
  const [passwordVisibiltyMap, setPasswordVisibilityMap] = useState({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  const theme = useTheme();
  const imageUploadRef = React.useRef();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const changeValue = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const validateForm = () => {
    let data = { ...formValidationErrors };
    const { oldPassword, newPassword, confirmPassword } = formData;
    //oldPassword
    if (oldPassword.trim().length === 0) {
      data = { ...data, oldPassword: "Current Password is required" };
    } else {
      const { oldPassword, ...others } = data;
      data = { ...others };
    }
    //newPassword
    if (newPassword.trim().length === 0) {
      data = { ...data, newPassword: "New Password is required" };
    } else if (newPassword.trim().length < 8) {
      data = { ...data, newPassword: "Must be of at least 8 characters long" };
    } else {
      let value = formData.newPassword;
      // eslint-disable-next-line
      let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let containsSpecialCharacter = specialChars.test(value);
      let containsNumber = /[0-9]/.test(value);
      let containsUppercase = /[A-Z]/.test(value);
      let valid =
        containsNumber && containsUppercase && containsSpecialCharacter;
      if (valid) {
        const { newPassword, ...others } = data;
        data = { ...others };
      } else {
        data = {
          ...data,
          newPassword:
            "Must contain at least 1 number, 1 uppercase letter and one 1 special character",
        };
      }
    }
    //confirmPassword
    if (
      confirmPassword.trim().length === 0 &&
      newPassword.trim().length !== 0
    ) {
      data = { ...data, confirmPassword: "Confirm Password is required" };
    } else if (
      confirmPassword !== newPassword &&
      newPassword.trim().length !== 0
    ) {
      data = { ...data, confirmPassword: "Passwords don't match" };
    } else {
      const { confirmPassword, ...others } = data;
      data = { ...others };
    }
    setFormvalidationErrors({ ...data });
    return data;
  };
  const changePasswordVisibiltyMap = (name) =>
    setPasswordVisibilityMap({
      ...passwordVisibiltyMap,
      [name]: !passwordVisibiltyMap[name],
    });
  const updatePassword = async () => {
    let result = validateForm();
    if (Object.keys(result).length === 0) {
      //TO DO
      // setInProgress(true);
      let path = `/v1/security/change-multi-chain-password`;
      url
        .put(path, {
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        })
        .then(() => {
          setShowPass(!showPass);
        })
        .catch((e) => {
          setErrorMessage(
            e?.response?.data?.data?.message ?? "Something went wrong"
          );
        });
    }
  };
  return (
    <Wrapper theme={theme} show={show}>
      <button className="close-icon" onClick={() => setShow(false)}>
        <CloseIcon />
      </button>
      <div className="image-container">
        {editImage ? (
          <img
            alt="yo4"
            src={editImage}
            className="img-fluid profile-image"
            style={inlineStyles.thumbnailImage}
          />
        ) : (
          <div className="image-thumbnail-div" style={inlineStyles.textImage}>
            <span style={{ fontSize: "40px" }}>
              {admin[0]?.adminName?.substring(0, 1)}
            </span>
          </div>
        )}
        <input
          type="file"
          id="image1"
          className="edit-image"
          visbility="hidden"
          disabled={!editAdmin}
          onChange={imageSelector}
          name="Image1"
          ref={imageUploadRef}
        />
        <button
          className="edit-image"
          onClick={() => imageUploadRef.current.click()}
        >
          <i className="fas fa-pen"></i> Edit Profile Picture
        </button>
        <ImageCropper {...cropProperties} />
      </div>
      <div className="info">
        <InputPrimary
          label="Name"
          disabled={!editAdmin}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {validationMessages?.name ? (
          <span className="validation-help" style={{ marginLeft: "10px" }}>
            {validationMessages?.name}
          </span>
        ) : (
          ""
        )}
        <PhoneNumberInputAnik
          label="Phone"
          phone={phone ?? ""}
          setPhone={setPhone}
          disabled={!editAdmin}
        />
        <InputPrimary
          label="Email"
          type="email"
          value={email}
          // disabled={!editAdmin}
          disabled="true"
          onChange={(e) => setEmail(e.target.value)}
        />
        {!showPass && isMultiChainAdmin && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="edit-image"
              onClick={() => setShowPass(!showPass)}
              style={{ color: "#FFA382" }}
            >
              Reset Password?
            </button>
          </div>
        )}
        {showPass && (
          <div>
            {/* <p style={{ color: "#FFA382" }}>Reset Password</p> */}
            <PasswordInput
              label="Old password"
              value={formData.oldPassword}
              name="oldPassword"
              changeVisibility={changePasswordVisibiltyMap}
              visibility={passwordVisibiltyMap}
              change={changeValue}
            />{" "}
            {errorMessage ? (
              <p style={{ color: "red" }}>{errorMessage}</p>
            ) : (
              <p style={{ color: "red" }}>{formValidationErrors.oldPassword}</p>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <span
                onClick={() => {
                  history.push({
                    pathname: "/ForgotPassword",
                    // search: '?tab=basic-info&forgot=true',  // query
                  });
                }}
                style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  textDecoration: "underline",
                  color: "#FFA382",
                  marginTop: "0.7em",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </span>
            </div>
            <PasswordInput
              label="New Password"
              name="newPassword"
              changeVisibility={changePasswordVisibiltyMap}
              visibility={passwordVisibiltyMap}
              value={formData.newPassword}
              change={changeValue}
            />
            {<p style={{ color: "red" }}>{formValidationErrors.newPassword}</p>}
            <PasswordInput
              label="Confirm Password"
              name="confirmPassword"
              changeVisibility={changePasswordVisibiltyMap}
              visibility={passwordVisibiltyMap}
              value={formData.confirmPassword}
              change={changeValue}
            />
            {
              <p style={{ color: "red" }}>
                {formValidationErrors.confirmPassword}
              </p>
            }
          </div>
        )}
        <div className="d-flex" style={{ justifyContent: "space-evenly" }}>
          {showPass && (
            <Button
              sx={{
                backgroundColor: theme.palette.gray.main + "!important",
                color: theme.palette.gray.dark,
              }}
              onClick={() => setShowPass(!showPass)}
              variant="contained"
            >
              Cancel
            </Button>
          )}
          <Button
            sx={{
              backgroundColor: theme.palette.primary.main + "!important",
              width: !showPass ? "100%" : "30%",
            }}
            color="primary"
            onClick={() => {
              showPass ? updatePassword() : fileUpdate();
            }}
            variant="contained"
          >
            Update
          </Button>
        </div>
        <div className="d-flex" style={{ gap: "3px",cursor:"pointer " }}  onClick={() => {
                  history.push({
                    pathname: "/logout",
                  });
                }}>
          <img src={Logout} />
          <p style={{ marginTop: "13px", color: "#FF6D6D" }}>Logout</p>
        </div>
      </div>
    </Wrapper>
  );
}
function ImageCropper({
  showCrop,
  showCroppedImage,
  onCropComplete,
  cropDialogOpen,
  setCropDialogOpen,
  editImage,
}) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    showCrop && (
      <Dialog
        fullScreen={fullScreen}
        open={cropDialogOpen}
        fullWidth={true}
        onClose={() => setCropDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          className="App"
          style={{
            padding: "1em",
            width: "auto",
            height: "auto",
          }}
        >
          <div className="crop-container">
            <Cropper
              image={editImage}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              // classes={{ root: "slider" }}
            />
          </div>
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            <Button
              sx={{
                backgroundColor: theme.palette.gray.main + "!important",
                color: theme.palette.gray.dark + "!important",
                width: "180px",
                marginRight: "20px",
              }}
              color="primary"
              onClick={() => setCropDialogOpen(false)}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: theme.palette.primary.main + "!important",
                width: "180px",
              }}
              color="primary"
              onClick={() => showCroppedImage()}
              variant="contained"
            >
              Crop
            </Button>
          </div>
        </div>
      </Dialog>
    )
  );
}
const Wrapper = styled.div`
  position: fixed;
  right: 15px;
  top: 15px;
  bottom: 15px;
  width: ${(props) => (props.show ? "333px" : "0px")};
  box-shadow: -8px 0px 30px rgba(0, 0, 0, 0.13);
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.2s ease-out;
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    border: none;
    outline: none;
    background: transparent;
    height: 30px;
    width: 30px;
    background-color: ${(props) => props.theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
    svg {
      color: white;
      font-size: 1rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.hover};
      svg {
        color: white;
      }
    }
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    .edit-image {
      border: none;
      outline: none;
      background: transparent;
      color: ${(props) => props.theme.palette.primary.main};
      text-decoration: underline;
      i {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
const inlineStyles = {
  thumbnailImage: {
    width: "150px",
    height: "150px",
  },
  textImage: {
    backgroundColor: "green",
    width: "150px",
    height: "150px",
    borderRadius: "60px",
  },
};
export default ProfilePopup;
