import React from "react";
import Success from "../../assets/Success1.png";
import Failure from "../../assets/failure.png";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
const SimplePopup = React.forwardRef(({ message, close }, ref) => {
  const theme = useTheme();
  return (
    <Popup ref={ref} position="center">
      <Wrapper>
        {message.success ? (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={() => {
                close();
              }}
              style={{
                position: "absolute",
                color: "red",
                cursor: "pointer",
                textAlign: "right",
                fontSize: "20px",
                top: "10px",
                right: "50px",
              }}>
              x
            </div>
            <div>
              <img height="60" alt="" src={Success} />
            </div>
            <p className="popup-heading" style={{ marginTop: "20px" }}>
              {message.heading} <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}>
                {message.text}{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: theme.palette.primary.main,
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                close();
              }}>
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={() => {
                close();
              }}
              style={{
                position: "absolute",
                color: "red",
                cursor: "pointer",
                textAlign: "right",
                fontSize: "20px",
                top: "10px",
                right: "50px",
              }}>
              x
            </div>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p className="popup-heading" style={{ marginTop: "20px" }}>
              {message.heading} <br />
              <span
                style={{
                  color: theme.palette.gray.dark,
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}>
                {message.text}{" "}
              </span>
            </p>
            <button
              type="button"
              onClick={() => {
                close();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}>
              ok
            </button>
          </div>
        )}
      </Wrapper>
    </Popup>
  );
});

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SimplePopup;
