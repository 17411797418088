import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";

const initialState = {
  inProgress: false,
  error: null,
  sessions: {},
  hasMore: false,
  limit: 30,
  previousSearchTerm: "",
  searchTerm: "",
};

const sessionSearchSlice = createSlice({
  name: "sessionSearch",
  initialState,
  reducers: {
    setSessionSearchTermAction: (state, action) => {
      state.searchTerm = action.payload;
    },
    clearSearchTermsAction: (state) => {
      state.previousSearchTerm = "";
      state.searchTerm = "";
      state.sessions = {};
      state.hasMore = false;
    },
    setSessionsSearchLimitAction: (state, action) => {
      if (!isNaN(action.payload)) {
        state.limit = action.payload;
      }
    },
    startFetchingSessionsSearchAction: (state) => {
      state.inProgress = true;
      state.error = null;
      if (state.previousSearchTerm !== state.searchTerm) {
        state.previousSearchTerm = state.searchTerm;
        state.sessions = {};
      }
    },
    //{sessions: [], hasMore: boolean, initialAttempt: boolean }
    addSessionsSearchResultAction: (state, action) => {
      let parsedSessions = {};
      if (Array.isArray(action.payload?.sessions)) {
        parsedSessions = action?.payload?.sessions.reduce(
          (acc, obj) => ({
            ...acc,
            [obj._id]: obj,
          }),
          {}
        );
      }
      let keys = Object.keys(parsedSessions);
      for (let i = 0; i < keys.length; i++) {
        if (state.sessions[keys[i]]) {
          delete parsedSessions[keys[i]];
        }
      }
      state.sessions = { ...state.sessions, ...parsedSessions };
      state.hasMore = action.payload?.hasMore ?? false;
      state.inProgress = false;
      state.error = null;
    },
    //string
    fetchingSessionSearchErrorAction: (state, action) => {
      state.inProgress = false;
      state.error = action?.payload ?? "Something went wrong";
    },
  },
});

export const {
  fetchingSessionSearchErrorAction,
  setSessionsSearchLimitAction,
  startFetchingSessionsSearchAction,
  addSessionsSearchResultAction,
  clearSearchTermsAction,
  setSessionSearchTermAction,
} = sessionSearchSlice.actions;

export const getSessionSearchResultsThunk = () => (dispatch, getState) => {
  dispatch(startFetchingSessionsSearchAction());
  const currentLength =
    Object.keys(getState()?.sessionSearch?.sessions)?.length ?? 0;
  const limit = getState()?.sessionSearch?.limit;
  const search = getState()?.sessionSearch?.searchTerm ?? "";
  url
    .get(`/v1/message-sessions`, {
      params: {
        skip: currentLength,
        search: search,
        limit,
        forRealtime: -1,
      },
    })
    .then((res) => {
      dispatch(addSessionsSearchResultAction(res?.data?.data));
    })
    .catch((e) => {
      //   console.error(e?.reponse?.data);
      dispatch(fetchingSessionSearchErrorAction());
    });
};

export const selectSessionSearchMeta = (state) => ({
  inProgress: state?.sessionSearch?.inProgress,
  hasMore: state?.sessionSearch?.hasMore,
  error: state?.sessionSearch?.error,
});
export const selectSessionSearchTerm = (state) =>
  state?.sessionSearch?.searchTerm;
export const selectPreviousSessionSearchTerm = (state) =>
  state?.sessionSearch?.previousSearchTerm;
export const selectMessageSessionSearches = (state) =>
  state?.sessionSearch?.sessions;

export default sessionSearchSlice.reducer;
