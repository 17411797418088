export const getBannerFormInitialData = () => ({
  title: "",
  time: 0,
  image: null,
  targetTopic: "",
  targetInfo: {},
  chosenCategories: {},
  chosenProducts: {},
  chosenDeals: {},
  chosenBrands: {},
  mediaKey: null,
});
