import React from "react";
import url from '../../config/axios'
import { resetAuthStateAction } from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function Logout() {
  console.log("Logout screen loading")
    const dispatch = useDispatch()
    const history = useHistory()
  const logout = () => {
    try {
      url.put("/oca/logout");
    } catch (e) {
      console.error(e);
    }
    dispatch(resetAuthStateAction());
    const tempRememberMeState = localStorage.getItem("remeberMeState");
    localStorage.clear();
    try {
      localStorage.setItem(
        "remeberMeState",
        tempRememberMeState ??
          JSON.stringify({ rememberMe: false, email: "", password: "" })
      );
    } catch (e) {}
    history.push("/");
  };
  React.useEffect(() => {
    logout();
  }, []);
  return <div>Logout</div>;
}
