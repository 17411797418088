import styled from "@emotion/styled";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../../../atoms/InputPrimary";
import MouseOverPopover from "../../../../atoms/Popover/Popover";
import ToggleSwitch from "../../../../atoms/ToggleSwitch";
import {
  changeSinglePropertyAction,
  selectLoyaltyFormValidationErrors,
  selectLoyaltyRedemptionPolicyState,
} from "../../../../redux/slices/loyaltyRedemptionSlice";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants";
import {
  popOverOrder,
  popOverRegistration,
  popOverRegistrationToggle,
} from "../constants/purchased-rules-constants";
import styles from "../styles.module.css";
import { calculatePointsWorth } from "../utils/calculate-amount-representation";

function AwardsSettings() {
  const dispatch = useDispatch();
  const {
    valuationStrategy: { targetPoints, targetPrice },
    awardedPoints: {
      userRegistration: {
        enabled: userRegistrationEnabled,
        points: userRegistrationPoints,
      },
      firstOrder: { enabled: firstOrderEnabled, points: firstOrderPoints },
    },
  } =
    useSelector(selectLoyaltyRedemptionPolicyState) ??
    initialLoyaltyRedemptionPolicyState;
  const handleChange = (propValue) => {
    dispatch(
      changeSinglePropertyAction({ propName: "awardedPoints", propValue })
    );
  };
  const formValidationErrors = useSelector(selectLoyaltyFormValidationErrors);
  return (
    <div className="row">
      <DividerLine
        style={{
          margin: "0.5em 15px 1em 15px",
        }}
      />
      <div className="col-lg-6">
        <div
          className="row"
          style={{ alignItems: "center", marginTop: "0.5em" }}
        ></div>
        <div className={styles.registrationPop}>
          <MouseOverPopover text={popOverRegistration.content} />
        </div>
        <InputPrimary
          label={`Points Earned for Registration ($${calculatePointsWorth(
            targetPrice,
            targetPoints,
            +userRegistrationPoints
          )})`}
          type="number"
          onInput={(e) => {
            e.target.value = Math.abs(e.target.value);
          }}
          disabled={!userRegistrationEnabled}
          value={userRegistrationPoints}
          onChange={(e) => {
            handleChange({
              userRegistration: {
                enabled: userRegistrationEnabled,
                points: parseInt(Math.abs(e.target.value)),
              },
              firstOrder: {
                enabled: firstOrderEnabled,
                points: firstOrderPoints,
              },
            });
          }}
          placeholder="3"
        />
        <span className="validation-help">
          {formValidationErrors?.userRegError ?? ""}
        </span>
        <div className="col-md-12">
          <div
            style={{
              transform: "translateX(-15px)",
              position: "absolute",
              top: "10px",
              left: "-0.3em",
            }}
          >
            <ToggleSwitch
              status={userRegistrationEnabled}
              onChange={() => {
                handleChange({
                  userRegistration: {
                    enabled: !userRegistrationEnabled,
                    points: userRegistrationPoints,
                  },
                  firstOrder: {
                    enabled: firstOrderEnabled,
                    points: firstOrderPoints,
                  },
                });
              }}
            />
            <div className={styles.registrationToggle}>
              <MouseOverPopover text={popOverRegistrationToggle.content} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 mb-3">
        <div
          className="row"
          style={{ alignItems: "center", marginTop: "0.5em" }}
        ></div>
        <div className={styles.registrationPop}>
          <MouseOverPopover text={popOverOrder.content} />
        </div>
        <InputPrimary
          label={`Points Earned on First Order ($${calculatePointsWorth(
            targetPrice,
            targetPoints,
            +firstOrderPoints
          )})`}
          type="number"
          onInput={(e) => {
            e.target.value = Math.abs(e.target.value);
          }}
          disabled={!firstOrderEnabled}
          value={firstOrderPoints}
          onChange={(e) => {
            handleChange({
              userRegistration: {
                enabled: userRegistrationEnabled,
                points: userRegistrationPoints,
              },
              firstOrder: {
                enabled: firstOrderEnabled,
                points: parseInt(Math.abs(e.target.value)),
              },
            });
          }}
          placeholder="3"
        />
        <span className="validation-help">
          {formValidationErrors?.firstOrderError ?? ""}
        </span>
        <div className="col-md-12">
          <div
            style={{
              transform: "translateX(-15px)",
              position: "absolute",
              top: "10px",
              left: "-0.3em",
            }}
          >
            <ToggleSwitch
              status={firstOrderEnabled}
              onChange={() => {
                handleChange({
                  userRegistration: {
                    enabled: userRegistrationEnabled,
                    points: userRegistrationPoints,
                  },
                  firstOrder: {
                    enabled: !firstOrderEnabled,
                    points: firstOrderPoints,
                  },
                });
              }}
            />
            <div className={styles.registrationToggle}>
              <MouseOverPopover text={popOverRegistrationToggle.content} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AwardsSettings;
const DividerLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-top: 30px;
  margin-bottom: 16px;
`;
