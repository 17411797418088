import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useReactToPrint } from "react-to-print";
import url from "../../config/axios";
const useStyles = makeStyles((theme) => ({
  marginTop: theme.spacing(0.3),
  marginBottom: theme.spacing(0.3),
  minWidth: "790px",
  Height: "750px",
  overflowX: "hidden",
  borderRadius: "10px",
  "&::-webkit-scrollbar": {
    width: "5px !important",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00) !important",
    borderRadius: "10px !important",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#D5D5D5 !important",
    borderRadius: "10px !important",
  },
}));

function LoyaltyQR({ onClose }) {
  const classes = useStyles();
  const [qrCode, setQrCode] = useState(null);
  const [inProgress, setInProgress] = useState(true);
  const refreshQrCode = async () => {
    try {
      const getLoyaltyPoint = await url.get(
        "/v1/outlet-time-basis-loyalty-settings"
      );
      let data = getLoyaltyPoint?.data?.data?.data?.qrSession;
      console.log(data);
      setQrCode(data);
      setInProgress(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    refreshQrCode();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  const handleGenerateQR = async () => {
    try {
      setInProgress(true);
      const getLoyaltyPoint = await url.put(
        "/v1/outlet-time-basis-loyalty-settings/generate-qr-code-session"
      );
      let data = getLoyaltyPoint?.data?.data?.data?.qrSession;
      console.log(data);
      setQrCode(data);
      setInProgress(false);
    } catch (e) {
      console.error(e);
    }
  };
  const ref = useRef();
  return (
    <Dialog
      open={true}
      className="popup-scroll"
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}>
      <div
        style={{
          display: "flex",
          height: "auto",
          width: "auto",
          gap: "1em",
          flexDirection: "column",
        }}>
        {inProgress ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1em",
            }}>
            <CircularProgress />
          </div>
        ) : null}
        {qrCode && !inProgress ? (
          <div
            ref={ref}
            style={{
              height: "auto",
              overflow: "visible",
              padding: "1em",
              display: "flex",
              width: "auto",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <QRCode
              value={qrCode}
              qrStyle="dots"
              logoImage="https://pbs.twimg.com/profile_images/1734321939932430336/i5QOpNzV_400x400.jpg"
              size={512}
              logoHeight={128}
              logoWidth={128}
              logoPaddingStyle="square"
              logoPadding={10}
              // fgColor="#FD7C6E"
              // ecLevel="H"
              eyeRadius={50}
            />
          </div>
        ) : null}
        {!inProgress ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              padding: "1em",
            }}>
            <div className="col-md-1 filters-bar">
              <button
                onClick={() => {
                  handleGenerateQR();
                }}
                className="btn btn-primary border-0 qrCode">
                <i className="fas fa-qrcode"></i>
                Generate New QR Code
              </button>
            </div>
            {qrCode ? (
              <div className="col-md-1 filters-bar">
                <button
                  onClick={() => {
                    handlePrint();
                  }}
                  className="btn btn-primary border-0 qrCode">
                  <i className="fas fa-print"></i>
                  Print QR Code
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Dialog>
  );
}

export default LoyaltyQR;
