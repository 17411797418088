import { Fragment } from "react";
import { Popover } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import defaultImage from "../../assets/defaultImage.jpg";

const pricingSymbols = {
  USD: "$",
  CAD: "C$",
};

export const getPriceByVariant = (item) => {
  return `${pricingSymbols[item?.pricingInfo?.currency] ?? "$"} ${parseFloat(
    String(item.price)
  ).toFixed(2)} / ${
    // item?.uom?.length
    //   ? String(item.uom).trim().toLowerCase() === "unit"
    //     ? "item"
    //     : item.uom
    //   : "item"

    String(item?.measured_by).trim().toUpperCase() === "UNIT"
      ? "item"
      : item?.uom?.length
      ? item.uom
      : "item"
  }`;
};

export const getQuantityFromVariant = (item, rootObject = {}) => {
  return !item?.measured_by ||
    String(item?.measured_by).trim().toUpperCase() === "UNIT"
    ? `${item?.quantity ?? rootObject?.quantity} item(s)`
    : `${item?.weight ?? rootObject?.quantity} ${
        item?.uom?.length ? `${item.uom}(s)` : "item(s)"
      }`;
};
const VariantsComponent = ({ variants, masterProductName, rootObject }) => {
  return (
    <Fragment>
      <PopupState variant="popover">
        {(popupState) => (
          <Fragment>
            <div className="row col-md-12">
              <div
                className="col-md-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1em",
                  // justifyContent: "center",
                  alignItems: "center",
                  gap: "0.2em",
                  color: "#FFA382",
                  cursor: "pointer",
                }}
                {...bindTrigger(popupState)}>
                Variants
                <ExpandMoreIcon />
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  padding: "0.3em",
                  width: "35vw",
                  maxWidth: "450px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <table className="table table-striped-cust">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Stock</th>
                      <th scope="col">Price/Item</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {variants.map((item, i) => {
                      return (
                        <tr
                          height={"2px"}
                          style={{
                            borderBottom: "1px solid rgb(241, 245, 249)",
                          }}
                          key={i}>
                          <td style={{ display: "flex" }}>
                            <div
                              style={{
                                width: "auto",
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <img
                                img
                                alt="yo1"
                                src={
                                  item.image?.length ? item.image : defaultImage
                                }
                                className="img-fluid"
                                width="40"
                                style={{ height: "40px" }}
                              />
                              <span
                                style={
                                  {
                                    //   width: "20%",
                                  }
                                }>
                                {item?.name ?? masterProductName}
                              </span>
                            </div>
                          </td>

                          <td style={{ color: "#1E293B" }}>
                            {parseFloat(
                              getQuantityFromVariant(item, rootObject ?? {}) +
                                ""
                            ).toFixed(2)}
                          </td>

                          <td>{getPriceByVariant(item, rootObject ?? {})}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Popover>
          </Fragment>
        )}
      </PopupState>
    </Fragment>
  );
};

export default VariantsComponent;
