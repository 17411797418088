import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material'
import { FadeIn } from '../../../../atoms/Animations/FadeIn';

function CTDWelcome() {
    const theme = useTheme();
  return (
    <Wrapper theme={theme}>
        <FadeIn>
            <h1 className='heading'><span>Welcome</span>, Start creating your own deals!</h1>
        </FadeIn>
        <FadeIn direction='up'>
            <p className="description">Start creating your deals for your shop right now! Deals are important to attract more customers to your shop. We are giving you the flexibility to create/customize almost any kinds of deals you can imagine. This deal creation screen will walk you throught the process!</p>
        </FadeIn>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    .heading {
        font-weight: 400;
        span {
            color: ${props => props.theme.palette.secondary.main};
            font-size: 42px;
            font-weight: 800;
        }
    }
    .description {
        font-size: 20px;
        font-weight: 300;
    }
`

export default CTDWelcome