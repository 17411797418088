import { createSlice } from "@reduxjs/toolkit";
import { initialLoyaltyRedemptionPolicyState } from "../../pages/Loyalty/LoyaltyRules/constants/form-initiator.constants";
import url from "../../config/axios";
const getFormValidationErrors = (formData) => {
  let errors = {};
  const {
    awardedPoints: {
      userRegistration: {
        enabled: userRegistrationEnabled,
        points: userRegPoints,
      },
      firstOrder: { enabled: firstOrderEnabled, points: firstOrderPoints },
    },
    valuationStrategy: { targetPoints, targetPrice },
    usageRules: {
      isApplicable,
      rulesInfo: {
        percentageThreshold,
        amountThreshold,
        maximumSpendablePoints,
      },
    },
    plan,
    planInfo: {
      SPENDING_BASIS: { strategies },
      PER_SPENDING_BASIS: { points: perSpendingPoints },
    },
  } = formData;
  if (plan === "PER_SPENDING_BASIS") {
    if (perSpendingPoints <= 0) {
      errors["perSpendingPointsError"] = "Must be a non zero positive value";
    }
  }
  if (plan === "SPENDING_BASIS") {
    const amountsMap = {};
    if (strategies?.length === 0) {
      errors["spendingError"] = "Add least add one scenario";
    }
    for (let i = 0; i < strategies.length; i++) {
      const parsed = Math.abs(Number(strategies[i].purchaseAmount));
      if (amountsMap[parsed]) {
        errors["spendingError"] =
          "One or more tier contains conflicting statements";
        break;
      }
      amountsMap[parsed] = 1;
      if (strategies[i].points <= 0) {
        errors["spendingError"] =
          "One or more tier contains points with non positive values";
      }
    }
  }
  if (isApplicable) {
    if (percentageThreshold.enabled) {
      if (percentageThreshold.value <= 0) {
        errors["percentageError"] = "Must be a non zero positive value";
      }
      if (percentageThreshold.value >= 100) {
        errors["percentageError"] = "Should be less than 100";
      }
    }
    if (amountThreshold.enabled) {
      if (amountThreshold.value <= 0) {
        errors["amountThresholdError"] = "Must be a non zero positive value";
      }
    }
    if (maximumSpendablePoints.enabled) {
      if (maximumSpendablePoints.value <= 0) {
        errors["spendablePointsError"] = "Must be a non zero positive value";
      }
    }
  }
  if (userRegistrationEnabled) {
    if (userRegPoints <= 0) {
      errors["userRegError"] = "Points must be a non zero positive value";
    }
  }
  if (firstOrderEnabled) {
    if (firstOrderPoints <= 0) {
      errors["firstOrderError"] = "Points must be a non zero positive value";
    }
  }
  if (targetPoints <= 0) {
    errors["targetPoints"] = "Points must be a non zero positive value";
  }
  if (targetPrice < 0) {
    errors["targetPoints"] = "Can't be negative";
  }
  return errors;
};
const loyaltyRedemptionSlice = createSlice({
  name: "loyaltyRedemptionPolicy",
  initialState: initialLoyaltyRedemptionPolicyState,
  reducers: {
    startInitialDataLoadAction: (state) => {
      return {
        ...state,
        initialDataLoaded: false,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    startAsyncCallAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    finishWithErrorAsyncCallAction: (state, action) => {
      return {
        ...state,
        inProgress: false,
        error: action?.payload?.length
          ? action.payload
          : "Something went wrong",
        success: false,
      };
    },
    clearErrorMessageAction: (state) => {
      return {
        ...state,
        error: null,
      };
    },
    clearSuccessStatusAction: (state) => {
      return {
        ...state,
        success: false,
      };
    },
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action.payload ?? {};
      if (state[propName] && (propValue !== undefined || propValue !== null)) {
        return {
          ...state,
          [propName]: propValue,
        };
      }
      // const state = {...current(paramsState)};
      // (function updateStateRecursive(state, propName, propValue) {
      //   const [root, ...others] = propName.split(".");
      //   !others.length
      //     ? (state[root] = propValue)
      //     : updateStateRecursive(state[root], others.join("."), propValue);
      // })(state, propName, propValue);
    },
    setPolicyAction: (state, action) => {
      const payload = action.payload ?? {};
      return {
        ...state,
        ...payload,
        success: !!payload?.success,
        inProgress: false,
        error: null,
        initialDataLoaded: true,
      };
    },
  },
});

export const {
  setPolicyAction,
  startInitialDataLoadAction,
  startAsyncCallAction,
  finishWithErrorAsyncCallAction,
  clearErrorMessageAction,
  clearSuccessStatusAction,
  changeSinglePropertyAction,
} = loyaltyRedemptionSlice.actions;
export const getInitialDataThunk = () => (dispatch) => {
  dispatch(startInitialDataLoadAction());
  url
    .get(`/v1/consumer-loyalty-settings/current-reward-and-rules`)
    .then((response) => {
      const res = response.data?.data ?? {};
      dispatch(
        setPolicyAction({
          ...res,
          planInfo: {
            ...initialLoyaltyRedemptionPolicyState.planInfo,
            ...(res.applicableStrategies ?? {}),
          },
        })
      );
    })
    .catch((e) => {
      dispatch(
        finishWithErrorAsyncCallAction(e.response?.error?.data?.message)
      );
    });
};
export const updatePolicyThunk = () => (dispatch, getState) => {
  const data = getState()?.loyaltyRedemptionPolicy;
  const formValidationErrors = getFormValidationErrors(data);
  dispatch(
    changeSinglePropertyAction({
      propName: "formValidationErrors",
      propValue: formValidationErrors,
    })
  );
  if (Object.values(formValidationErrors).length) {
    return;
  }
  dispatch(startAsyncCallAction());
  url
    .put(`/v1/consumer-loyalty-settings/update-reward-and-rules`, data)
    .then((response) => {
      const res = response.data?.data?.data ?? {};
      dispatch(
        setPolicyAction({
          ...res,
          planInfo: {
            ...initialLoyaltyRedemptionPolicyState.planInfo,
            ...(res.applicableStrategies ?? {}),
          },
          success: true,
        })
      );
    })
    .catch((e) => {
      dispatch(finishWithErrorAsyncCallAction(e.response?.data?.data?.message));
    });
};
export const selectLoyaltyRedemptionPolicyState = (state) =>
  state.loyaltyRedemptionPolicy;
export const selectLoyaltyFormValidationErrors = (state) =>
  state.loyaltyRedemptionPolicy?.formValidationErrors ?? {};
export default loyaltyRedemptionSlice.reducer;
