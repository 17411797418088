import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../../../atoms/InputPrimary";
import MouseOverPopover from "../../../../atoms/Popover/Popover";
import ToggleSwitch from "../../../../atoms/ToggleSwitch";
import {
  changeSinglePropertyAction,
  selectLoyaltyFormValidationErrors,
  selectLoyaltyRedemptionPolicyState,
} from "../../../../redux/slices/loyaltyRedemptionSlice";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants";
import {
  popOverMaximumDeduction,
  popOverMaximumDeductionPercentage,
  popOverMaximumSpendable,
} from "../constants/purchased-rules-constants";
import styles from "../styles.module.css";
function UsageRules() {
  const dispatch = useDispatch();
  const { usageRules } =
    useSelector(selectLoyaltyRedemptionPolicyState) ??
    initialLoyaltyRedemptionPolicyState;
  const {
    isApplicable,
    rulesInfo: { percentageThreshold, amountThreshold, maximumSpendablePoints },
  } = usageRules;
  const handleChange = (propValue) => {
    dispatch(changeSinglePropertyAction({ propName: "usageRules", propValue }));
  };
  const formValidationErrors = useSelector(selectLoyaltyFormValidationErrors);
  return (
    <Fragment>
      <DividerLine />
      <div className="row d-flex align-items-center">
        <Checkbox
          checked={isApplicable}
          value={!isApplicable ? false : true}
          onChange={() =>
            handleChange({
              ...usageRules,
              isApplicable: !isApplicable,
            })
          }
        />
        <span>
          Would you like to impose restrictions on the number of points that can
          be used by the customer?
        </span>
      </div>
      <div className={`row ${isApplicable ? "d-flex" : "hidden"}`}>
        <div className="col-lg-6">
          <div className="mt-3">
            <div className={styles.registrationPop}>
              <MouseOverPopover text={popOverMaximumDeduction.content} />
            </div>
            <InputPrimary
              label="Maximum deduction($)"
              type="number"
              onInput={(e) => {
                e.target.value = Math.abs(e.target.value);
              }}
              disabled={!amountThreshold.enabled}
              value={amountThreshold.value}
              onChange={(e) =>
                handleChange({
                  ...usageRules,
                  rulesInfo: {
                    ...initialLoyaltyRedemptionPolicyState.usageRules.rulesInfo,
                    amountThreshold: {
                      enabled: amountThreshold.enabled,
                      value: e.target.value,
                    },
                  },
                })
              }
              placeholder="3"
            />
            <span className="validation-help">
              {formValidationErrors?.amountThresholdError ?? ""}
            </span>
            <div className="col-md-12 mb-5">
              <div
                style={{
                  transform: "translateX(-15px)",
                  position: "absolute",
                  top: "10px",
                  left: "-0.3em",
                }}
              >
                <ToggleSwitch
                  status={amountThreshold.enabled}
                  onChange={() => {
                    handleChange({
                      ...usageRules,
                      rulesInfo: {
                        ...initialLoyaltyRedemptionPolicyState.usageRules
                          .rulesInfo,
                        amountThreshold: {
                          enabled: !amountThreshold.enabled,
                          value: amountThreshold.value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mt-3">
            <div className={styles.registrationPop}>
              <MouseOverPopover
                text={popOverMaximumDeductionPercentage.content}
                subText={popOverMaximumDeductionPercentage.subContent}
              />
            </div>
            <InputPrimary
              label="Maximum deduction(% of subtotal)"
              type="number"
              onInput={(e) => {
                e.target.value = Math.abs(e.target.value);
              }}
              disabled={!percentageThreshold.enabled}
              value={percentageThreshold.value}
              onChange={(e) => {
                handleChange({
                  ...usageRules,
                  rulesInfo: {
                    ...initialLoyaltyRedemptionPolicyState.usageRules.rulesInfo,
                    percentageThreshold: {
                      enabled: percentageThreshold.enabled,
                      value: e.target.value,
                    },
                  },
                });
              }}
              placeholder="0"
            />
            <span className="validation-help">
              {formValidationErrors?.percentageError ?? ""}
            </span>
            <div className="col-md-12 mb-5">
              <div
                style={{
                  transform: "translateX(-15px)",
                  position: "absolute",
                  top: "10px",
                  left: "-0.3em",
                }}
              >
                <ToggleSwitch
                  status={percentageThreshold.enabled}
                  onChange={() => {
                    handleChange({
                      ...usageRules,
                      rulesInfo: {
                        ...initialLoyaltyRedemptionPolicyState.usageRules
                          .rulesInfo,
                        percentageThreshold: {
                          enabled: !percentageThreshold.enabled,
                          value: percentageThreshold.value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mt-3">
            <div className={styles.registrationPop}>
              <MouseOverPopover text={popOverMaximumSpendable.content} />
            </div>
            <InputPrimary
              label="Maximum Spendable Points"
              type="number"
              onInput={(e) => {
                e.target.value = Math.abs(e.target.value);
              }}
              disabled={!maximumSpendablePoints.enabled}
              value={maximumSpendablePoints.value}
              onChange={(e) => {
                handleChange({
                  ...usageRules,
                  rulesInfo: {
                    ...initialLoyaltyRedemptionPolicyState.usageRules.rulesInfo,
                    maximumSpendablePoints: {
                      enabled: maximumSpendablePoints.enabled,
                      value: parseInt(Math.abs(e.target.value)),
                    },
                  },
                });
              }}
              placeholder="0"
            />
            <span className="validation-help">
              {formValidationErrors?.spendablePointsError ?? ""}
            </span>
            <div className="col-md-12 mb-5">
              <div
                style={{
                  transform: "translateX(-15px)",
                  position: "absolute",
                  top: "10px",
                  left: "-0.3em",
                }}
              >
                <ToggleSwitch
                  status={maximumSpendablePoints.enabled}
                  onChange={() => {
                    handleChange({
                      ...usageRules,
                      rulesInfo: {
                        ...initialLoyaltyRedemptionPolicyState.usageRules
                          .rulesInfo,
                        maximumSpendablePoints: {
                          enabled: !maximumSpendablePoints.enabled,
                          value: maximumSpendablePoints.value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UsageRules;
const DividerLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-top: 30px;
  margin-bottom: 16px;
`;
