import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import InputPrimary from "../../atoms/InputPrimary";
import SelectPrimary from "../../atoms/SelectPrimary";
import getCroppedImg from "../../helpers/cropImage";
import { TypedDealsTypesSelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import {
  changeEditSinglePropertyAction,
  selectEditDealDefValidationErrors,
  selectEditDealDefState,
} from "../../redux/slices/editDealDefSlice";
// import BogoBundledDealFormComponent from "../AddTypedDeal/bogo-bundled-deal-form";
import BogoBundledDealFormComponent from "../AddTypedDeal/bogo-bundled-deal-form-new";
import RegularDealFormComponent from "../AddTypedDeal/regular-deal-form";
import TieredBundledDealFormComponent from "../AddTypedDeal/tiered-bundled-deal-form";
import { fileUpload } from "../../helpers/firebaseFileUpload";

const PageNotAvailableComponent = () => (
  <Fragment>The Page is not available</Fragment>
);

const EditTypedDealDefForm = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectEditDealDefState);
  let choices = { ...TypedDealsTypesSelectors };
  const getDealsChoices = () => {
    return choices;
  };
  console.log("Initial state", state)
  const { name, dealType, applyToAllOutlets, images } = state;
  const formValidationErrors = useSelector(selectEditDealDefValidationErrors);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [editImage, setEditImage] = useState("");
  const [thumbnail, setThumbnail] = useState({ file: null, source: null });
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageServerUploadLoading, setImageServerUploadLoading] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    if (thumbnail.file) {
      setImageServerUploadLoading(true);
      fileUpload(thumbnail.file).then(url => {
        dispatch(changeEditSinglePropertyAction({ propName: 'images', propValue: [url] }));
        setImageServerUploadLoading(false);
      });
    }
  }, [thumbnail])

  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeEditSinglePropertyAction({ propName, propValue }));
    }
  };

  const showCroppedImage = useCallback(
    async (e) => {
      // e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        // localStorage.setItem("croppedImage", croppedImage);
        setCroppedImage(croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
        handleChange("imageFile", {
          name: fileName,
          source: croppedImage,
        });
      } catch (e) {
        console.error(e);
      }
    },
    //eslint-disable-next-line
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    let numFile = Math.floor(Math.random() * 10) + 1;
    setFileName(event.name + numFile);
    if (event) {
      setEditImage(URL.createObjectURL(event));
      setFile(event);
      setOpen(true);
      setShowCrop(true);
    }
  };
  if (
    dealType === TypedDealsTypes.BOGO ||
    dealType === TypedDealsTypes.TIERED
  ) {
    return <PageNotAvailableComponent />;
  }


  return (
    <Fragment>
      <div className="col-md-12 mb-4">
        <InputPrimary
          value={name}
          name="name"
          label={"Deal Name*"}
          placeholder="Deal Name"
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.name ? formValidationErrors.name : ""}
        </span>
      </div>

      <div className="col-md-12 mb-4">
        <SelectPrimary
          disabled
          value={dealType}
          name="dealType"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          label={"Deal Type"}>
          {Object.values(getDealsChoices()).map((item, index) => (
            <option value={item.value} key={`selected-deal-type-${index}`}>
              {item.displayName}
              &nbsp;
              {item.shortDetails?.length ? item.shortDetails : ""}
            </option>
          ))}
        </SelectPrimary>
      </div>
      <div className="col-md-12 px-3 mb-4">
        <div
          className="fluid d-flex justify-content-center rounded py-4"
          style={{ backgroundColor: "#f9fbff" }}>
            {console.log("This file", images)}
          <FileUpload
            onFileRemoved={async () => {
              handleChange("images", []);
              handleChange("imageFile", null);
            }}
            // onFileLoaded={() => {
            // }}
            loading={imageServerUploadLoading}
            onUpload={setThumbnail}
            label={"Upload Deal Image"}
            size={"150 x 140 px"}
            ImageHeight={"140"}
            ImageWidth={"150"}
            imageSelector={imageSelector}
            cropProperties={{
              cropDialogOpen: open,
              setCropDialogOpen: setOpen,
              showCrop,
              showCroppedImage,
              onCropComplete,
              editImage,
              ImageHeight: 140,
              ImageWidth: 150,
            }}
            file={images[0]}
            editImage={editImage}
          />
        </div>
      </div>
      <div className="col-md-12">
        {dealType === TypedDealsTypes.BOGO_BUNDLED ? (
          <BogoBundledDealFormComponent
            editMode={true}
            applyToAllOutlets={applyToAllOutlets}
          />
        ) : null}
        {dealType === TypedDealsTypes.REGULAR ? (
          <RegularDealFormComponent
            editMode={true}
            applyToAllOutlets={applyToAllOutlets}
          />
        ) : null}
        {dealType === TypedDealsTypes.TIERED_BUNDLED ? (
          <TieredBundledDealFormComponent
            editMode={true}
            applyToAllOutlets={applyToAllOutlets}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default EditTypedDealDefForm;
