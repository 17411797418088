/* eslint-disable jsx-a11y/alt-text */
import styled from "@emotion/styled";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import isEmail from "email-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CA from "../../assets/CA.svg";
import DefaultUserImageSrc from "../../assets/defaultUser.jpg";
import US from "../../assets/US.svg";
import url from "../../config/axios";
import storage from "../../config/firebase";
import data from "../../helpers/countries.json";
import getCroppedImg from "../../helpers/cropImage";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import { toggleChooserOpenStatus } from "../../redux/slices/ocSwitchSlice";
import ProfilePopup from "./ProfilePopup";
import PopupMenu from "../../atoms/Popup/PopupMenu";
import OutletChooserNew from "../common/OutletChooserNew";
import { IconButton } from "@mui/material";
import JoyButton from "../../atoms/Buttons/JoyButton";


function AppbarInfo() {
  const dispatch = useDispatch();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showProfile, setShowProfile] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);

  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleOpenPopupMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [phoneInput, setPhoneInput] = useState({
    countryCode: "+1",
    number: "",
  });
  useEffect(() => {
    setPhoneNo(phoneInput.countryCode + phoneInput.number);
  }, [phoneInput]);
  // const [countryCode, setCountryCode] = useState(0);
  const [selectCountry, setSelectCountry] = useState([]);
  // const [phoneCode, setPhoneCode] = useState("");
  // const [phoneCountry, setPhoneCountry] = useState("")
  const [phoneData, setPhoneData] = useState([
    {
      phoneCode: "+1",
      flag: US,
      phoneCountry: "US",
      selected: false,
    },
    {
      phoneCode: "+1",
      flag: CA,
      phoneCountry: "CA",
      selected: false,
    },
  ]);
  const history = useHistory();
  const [validationMessages, setvalidationMessages] = React.useState({
    name: "",
    email: "",
    phone: "",
  });

  // const [value, setValue] = useState(0);

  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  useEffect(() => {
    getAdminDetails();
    getCountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [phone, setPhone] = useState(admin ? admin[0]?.phone : null);
  const validateData = async () => {
    try {
      var re = /^[0-9\b]+$/;
      let data = { ...validationMessages };
      //validate email
      if (email.trim().length === 0) {
        data = { ...data, email: "Email is required" };
      } else if (!isEmail.validate(email)) {
        data = { ...data, email: "Email is not valid" };
      } else {
        data = { ...data, email: "" };
      }

      //Validate Phone
      if (
        !re.test(phone) ||
        phone.toString().length < 7 ||
        phone.toString().length > 15 ||
        phone === null
      ) {
        data = { ...data, phone: "Phone is not valid" };
      } else {
        data = { ...data, phone: "" };
      }

      //Validate Name
      if (name.split(" ")[0].length <= 3) {
        data = { ...data, name: "First name is not valid" };
      } else {
        data = { ...data, name: "" };
      }

      setvalidationMessages({ ...data });

      return data;
    } catch (e) {}
  };

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({
        value: e.dial_code,
        label: e.flag + e.dial_code,
        flag: e.flag,
        code: e.code,
        dial_code: e.dial_code,
      });
    });
    setSelectCountry(x);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      // e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("ImageSet", croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setEditImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const updateAdmin = (blob) => {
    try {
      if (
        validationMessages?.name === "" &&
        validationMessages?.email === "" &&
        validationMessages?.phone === ""
      ) {
        let body = {
          adminName: name,
          // phone: phoneNo,
          phone,
        };
        if (blob) {
          body["adminImage"] = blob;
        }
        url
          .put(`/oc/?outletChainID=${admin[0].outletChainID}`, body)
          .then((res) => {
            if (res.data.Message === "Success") {
              setName(res.data.data?.adminName);
              setEmail(res.data.data?.adminEmail);
              setPhoneNo(res.data.data?.phone);
              setImage(res.data.data?.adminImage);
              setEditAdmin(false);
              setShowProfile(false);
              updateLocalStorage(res?.data.data);
              setPhone(res.data.data?.phone);
            } else {
            }
          })
          .catch((e) => console.log(e));
      }
    } catch (e) {}
  };

  const updateLocalStorage = async (data) => {
    try {
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        let { media } = prevData[0];
        prevData[0] = { ...data, media };
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        // window.location.reload();
      }
    } catch (e) {}
  };

  const fileUpdate = () => {
    try {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      if (file !== "") {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          (error) => {},
          () => {
            storage
              .ref("images")
              .child(file.name)
              .getDownloadURL()
              .then(async (blob) => {
                await updateAdmin(blob);
              });
          }
        );
      } else {
        validateData();
        updateAdmin();
      }
    } catch (e) {
      validateData();
      updateAdmin();
    }
  };

  const getAdminDetails = () => {
    if (admin !== null) {
      // setCountryCode(admin[0]?.countryCode);
      setName(admin[0]?.adminName ?? "__");
      // setName(admin[0].adminName);
      setEmail(admin[0].adminEmail);
      setPhoneNo(admin[0].phone);
      setImage(admin[0].adminImage);
      setEditImage(admin[0].adminImage);
      setPhoneInput({
        countryCode: "+" + admin[0].countryCode,
        number: admin[0].phone,
      });
      let newArr = phoneData.map((item, i) => {
        if (admin[0]?.phoneCountry === item.phoneCountry) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      setPhoneData(newArr);
      // setPhoneCountry(admin[0]?.phoneCountry)
    }

    if (admin) {
      url
        .get(`/oc/id/?outletChainID=${admin[0].outletChainID}`)
        .then((res) => {
          if (res.data.Message === "Success") {
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);
            setPhoneInput({
              countryCode: "+" + res.data.data[0].countryCode,
              number: res.data.data[0].phone,
            });
            let newArr = phoneData.map((item, i) => {
              if (admin[0]?.phoneCountry === item.phoneCountry) {
                return { ...item, selected: true };
              } else {
                return { ...item, selected: false };
              }
            });
            setPhoneData(newArr);
            // setPhoneCountry(admin[0]?.phoneCountry)
          }
        })
        .catch((e) => console.log(e));
    }
    setvalidationMessages({
      name: "",
      email: "",
      phone: "",
    });
  };
  const updateFieldChanged = (event) => {
    // setPhoneCode(event.target.value.split(",")[0])
    // setPhoneCountry(event.target.value.split(",")[1])
    let newArr = phoneData.map((item, i) => {
      if (event.target.value.split(",")[1] === item.phoneCountry) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setPhoneData(newArr);
  };
  return (
    <Wrapper>
      <button>
        <HelpIcon />
      </button>
      <button onClick={() => history.push("/chat")}>
        <EmailIcon />
      </button>
      <button onClick={() => history.push("/typed-notifications")}>
        <NotificationsIcon />
      </button>

      {/* Uncomment this button for the old switcher */}
      {/* {isMultiChainAdmin && <JoyButton className="switch-outlet-btn" onClick={handleOpenPopupMenu}>Switch Outlet</JoyButton>} */}

      <div className="profile" onClick={() => setShowProfile(true)}>
        <img src={image ? image : DefaultUserImageSrc} />
        <div className="info">
          <h5>{name}</h5>
          {isMultiChainAdmin ? (
            <p className="mail">
              {/* Disable this button for new switcher / enable this button for old switcher */}
              <b
                className="switch-outlet-button"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => dispatch(toggleChooserOpenStatus())}
                // onClick={handleOpenPopupMenu}
              >
                {/* Multi Chain Admin */}
                Switch Outlet
              </b>
            </p>
          ) : (
            <p>Sole Chain Admin</p>
          )}
        </div>
        <OutletChooserNew 
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        {/* <PopupMenu 
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          
          menuItems={[
            { label: "Edit", onClick: () => console.log("Switch") },
            { label: "Delete"}
          ]}
        /> */}
      </div>
      {/* Show profile popup */}
      {showProfile && (
        <ProfilePopup
          show={showProfile}
          setShow={setShowProfile}
          admin={admin}
          // editAdmin={editAdmin}
          editAdmin={true}
          imageSelector={imageSelector}
          editImage={editImage}
          cropProperties={{
            cropDialogOpen: open,
            setCropDialogOpen: setOpen,
            showCrop,
            showCroppedImage,
            onCropComplete,
            editImage,
          }}
          fileUpdate={fileUpdate}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  button:not(.switch-outlet-btn) {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      font-size: 1.5rem;
      color: #a4b0cd;
      &:hover {
        color: #8b96af;
      }
    }
  }
  .profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    img {
      height: 42px;
      width: 42px;
      object-fit: cover;
      border-radius: 50%;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      line-height: 0px;
      h5 {
        color: #192746;
        font-size: 0.875rem;
      }
      p {
        color: #747474;
      }
    }
  }
`;

export default AppbarInfo;
