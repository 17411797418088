import React from "react";
import styled from "@emotion/styled";
import ImageIcon from "../../assets/image_icon.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button } from "@mui/material";
import { getUniqueFileName } from "../../helpers/getFileName";

/**
 label: <String> Label name on the top of the button
 onUplaod: <(file) => {}> After user upload an image, it returns the actual file on Uplaod function
 file: <File, Blob> If there is an existing file, 
 */
function NoCropFileUpload({
  size,
  label,
  onUpload,
  //used in add-typed-deal form
  onFileLoaded,
  onFileRemoved,
  file = null,
  allowedExtensions = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "webp",
    "gif",
    "tiff",
    "ico",
  ],
  className = "",
  _customIcon = null,
  buttonLabel,
  height,
  ImageHeight,
  ImageWidth,
}) {
  const fileInput = React.useRef();
  const [resultFile, setResultFile] = React.useState(file);
  // const handleFileUplaod = (e) => {
  // };
  React.useEffect(() => {
    setResultFile(file);
  }, [file]);
  const handleFileDrop = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === "file") {
        uploadFile(e.dataTransfer.items[0].getAsFile());
      }
    }
  };
  const handleFileUploadInput = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
    }
  };
  const uploadFile = (file) => {
    const splittedName = file.name.split(".");
    const extension = splittedName[splittedName.length - 1];
    if (allowedExtensions.includes(extension)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        setResultFile(reader.result);
        var image = new Image();
        image.onload = () => {};
        image.src = reader.result;
        if (onFileLoaded instanceof Function) {
          onFileLoaded({
            name: getUniqueFileName(file.name),
            source: reader.result,
          });
        }
      });
      if (onUpload) {
        onUpload({ file: file, source: reader.result });
      }
    } else {
    }
  };
  const handleDelete = () => {
    setResultFile(null);
    if (onUpload) {
      onUpload({ file: null, source: null });
    }
    if (onFileRemoved instanceof Function) {
      onFileRemoved();
    }
  };
  return (
    <Container
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
      className={className}
      style={{ height: height }}
    >
      {resultFile ? (
        <div className="preview-image-container">
          <div className="action-icons-container">
            <button
              className="action-btn edit"
              onClick={() => fileInput.current.click()}
            >
              <EditOutlinedIcon /> Edit
            </button>
            <button className="action-btn delete" onClick={handleDelete}>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
          <img src={resultFile} className="preview-image" alt="" />
        </div>
      ) : (
        <>
          <div
            className="label-container"
            style={{
              color: "#747474",
              fontSize: "14px",
            }}
          >
            {_customIcon ?? <img src={ImageIcon} height="32px" alt="" />}
            {label}
          </div>
          <div
            className="label-container"
            style={{
              marginTop: "-1.5em",
              color: "#747474",
              fontSize: "14px",
            }}
          >
            {size}
          </div>
          <Button
            style={{ textTransform: "capitalize" }}
            variant="contained"
            className="upload-button"
            onClick={() => fileInput.current.click()}
          >
            {buttonLabel ?? "Choose File"}
          </Button>
        </>
      )}
      <input
        type="file"
        className="hidden-input"
        ref={fileInput}
        onChange={handleFileUploadInput}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 200px;
  width: 225px;
  background-color: #f9fbff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: relative;
  overflow: hidden;
  .label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .preview-image-container {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    .action-icons-container {
      position: absolute;
      right: 14px;
      top: 14px;
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
      .action-btn {
        border: none;
        outline: none;
        font-size: 12px;
        padding: 6px;
        border-radius: 4px;
        display: flex;
        gap: 4px;
        align-items: center;
        transition: box-shadow 0.3s ease-out;
        cursor: pointer;
        &:hover {
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
      }
      .action-btn.edit {
        background-color: #ffa382;
        color: white;
      }
      .action-btn.delete {
        background-color: red;
        color: white;
      }
    }

    .preview-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .hidden-input {
    opacity: 0;
    height: 0px;
    width: 0px;
    /* display: block !important;  
        position: absolute;
        left: 0;
        top: 0;        
        height: 100%;
        width: 100%;
        z-index: 2; */
  }
  .upload-button {
    background-color: #ffa382;
    z-index: 3;
  }
`;

export default NoCropFileUpload;
