import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DualArrow from "../../../../assets/dual-arrow.svg";
import SimpleModal from "../../../../atoms/Dialogs/SimpleModal";
import InputPrimary from "../../../../atoms/InputPrimary";
import {
  changeSinglePropertyAction,
  clearErrorMessageAction,
  clearSuccessStatusAction,
  getInitialDataThunk,
  selectLoyaltyFormValidationErrors,
  selectLoyaltyRedemptionPolicyState,
  updatePolicyThunk,
} from "../../../../redux/slices/loyaltyRedemptionSlice";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants";
import AwardsSettings from "./Awards";
import RewardPlan from "./Reward-Plan";
import UsageRules from "./UsageRules";

function RedemptionPolicy() {
  const dispatch = useDispatch();
  const {
    inProgress,
    success,
    error,
    initialDataLoaded,
    // redeemRules: { pointsPerUnitCost },
    valuationStrategy: { targetPoints, targetPrice },
  } = useSelector(selectLoyaltyRedemptionPolicyState) ??
  initialLoyaltyRedemptionPolicyState;
  const formValidationErrors = useSelector(selectLoyaltyFormValidationErrors);
  useEffect(
    () => {
      dispatch(getInitialDataThunk());
    },
    //eslint-disable-next-line
    []
  );
  const handleChange = (propName, propValue) => {
    dispatch(changeSinglePropertyAction({ propName, propValue }));
  };
  const updatePolicy = () => {
    dispatch(updatePolicyThunk());
  };
  return (
    <>
      <div className="card card-pro">
        {inProgress && !initialDataLoaded ? (
          <div
            className="row"
            style={{
              padding: "1em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              className="row"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                paddingBottom: "10px",
              }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-32px",
                      backgroundColor: "#FFA382",
                      marginTop: "2px",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  <div className="col-md-3">
                    <div
                      style={{
                        color: "#FFA382",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Reward Plan
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-12">
                <div>
                  <div className={styles.registrationPop}>
                    <MouseOverPopover
                      text={popOverPoints.content}
                      subText={popOverPoints.subContent}
                    />
                  </div>
                  <InputPrimary
                    label="Points Per Dollar"
                    type="number"
                    onInput={(e) => {
                      e.target.value = +parseInt(Math.abs(e.target.value) + "");
                    }}
                    value={pointsPerUnitCost.points}
                    onChange={(e) =>
                      handleChange("redeemRules", {
                        pointsPerUnitCost: {
                          enabled: true,
                          points: e.target.value,
                        },
                      })
                    }
                    points={pointsPerUnitCost.points}
                    placeholder="3"
                    showValue={true}
                  />
                  {formValidationErrors.pointPerUnit && (
                    <span className="validation-help">
                      {formValidationErrors.pointPerUnit}
                    </span>
                  )}
                </div>
              </div> */}
              <div className="col-12 d-flex my-2">
                <div style={{ width: "49.5%" }}>
                  <div className="group-comb">
                    <InputPrimary
                      label="Loyalty Points"
                      name="points"
                      type="number"
                      className="form-control"
                      placeholder="Loyalty points"
                      onChange={(e) => {
                        const parsedNumber = Number(e.target.value);
                        if (!Number.isNaN(parsedNumber) && parsedNumber >= 0) {
                          handleChange("valuationStrategy", {
                            targetPrice,
                            targetPoints: parseInt(Math.abs(e.target.value)),
                          });
                        }
                      }}
                      value={targetPoints}
                    ></InputPrimary>
                  </div>
                  <span className="validation-help">
                    {formValidationErrors["targetPoints"] ?? ""}
                  </span>
                </div>
                <div style={{ width: "2.5%" }} className="filters-bar">
                  <img src={DualArrow} alt="dual-arrow-icon" />
                </div>
                <div style={{ width: "49.5%" }}>
                  <div className="group-comb">
                    <InputPrimary
                      label="Amount Representation ($)"
                      name="points"
                      type="number"
                      className="form-control"
                      placeholder="$"
                      onChange={(e) => {
                        const parsedNumber = Number(e.target.value);
                        if (!Number.isNaN(parsedNumber) && parsedNumber >= 0) {
                          handleChange("valuationStrategy", {
                            targetPrice: Math.abs(e.target.value),
                            targetPoints,
                          });
                        }
                      }}
                      value={targetPrice}
                    ></InputPrimary>
                  </div>
                  <span className="validation-help">
                    {formValidationErrors["targetPrice"] ?? ""}
                  </span>
                </div>
              </div>
              <div className="col-lg-12">
                <AwardsSettings />
              </div>
              <div className="col-lg-12 mt-3">
                <RewardPlan />
              </div>
              <div className="col-lg-12 mt-3">
                <UsageRules />
              </div>
            </div>
            <div className="d-flex justify-content-end ">
              <button
                onClick={() => {
                  updatePolicy();
                }}
                type="button"
                className="btn btn-canaby"
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382", color: "white" }}
              >
                {inProgress ? "Updating..." : "Update"}
              </button>
            </div>
          </>
        )}
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Updated Successfully"}
          onClose={() => {
            dispatch(clearSuccessStatusAction());
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(clearErrorMessageAction());
          }}
        />
      ) : null}
    </>
  );
}

export default RedemptionPolicy;
