/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from "react";
import "./discounts.css";
import url from "../../../config/axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import moment from "moment";
import Popup from "reactjs-popup";
import Failure from "../../../assets/failure.png";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
export default function Coupons() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [coupons, setCoupons] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noRecord, setNoRecord] = useState(false);
  var perPage = 10;
  const notFoundRef = useRef();
  const [prevSearchTerm, setPrevSearchTerm] = useState("");

  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const initialPaginationData = {
    page: 1,
    limit: 10,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  //clientSide search
  useEffect(
    () => {
      if (searchTerm && searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.couponCode
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        );
        if (filtered.length === 0) {
          openNotFoundPopUp();
        }
        setCoupons(filtered);
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);

        setCurrentPage(1);
      } else {
        setCoupons(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);

        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);

  useEffect(
    () => {
      const query = new URLSearchParams(search);
      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/discounts-outlet${queryString}`);
    setQueryData({ ...query });
  };
  useEffect(() => {
    if (parsed) {
      GetAllOutlets();
      GetAllCoupons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsed, queryData]);
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp();
          }
          // setOutlets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const ref = useRef();
  const openTooltip = (couponId) => {
    if (!!couponId) {
      setCouponId(couponId);
      ref.current.open();
    }
  };
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllCoupons();
    } else {
      ref.current.close();
    }
  };
  const [couponId, setCouponId] = useState(null);
  const deleteCoupon = () => {
    url.delete(`/coupon/?couponId=${couponId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        closeTooltip(true);
      } else {
      }
    });
  };

  const GetAllCoupons = () => {
    let queryParams = { ...queryData };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get("/v1/coupons-paginated", {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.coupons.length === 0) {
            openNotFoundPopUp();
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.coupons?.length
          ) {
            setNoRecord(true);
          }
          let modified = res.data?.data?.coupons.reverse() ?? [];
          setCoupons(modified);
          setUnfiltered(modified);
          // setTotalPage(res.data.data.length);
          // setminPro(page * 50 - 50 + 1);
          // setTotalPage(Math.ceil(modified.length / perPage));
          setPaginationData({
            ...paginationData,
            ...res?.data?.data.paginationData,
          });
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => {
        console.log(e, "error");
        openNotFoundPopUp();
      });
  };

  const editCoupon = (data) => {
    localStorage.setItem("couponEdit", JSON.stringify(data));
    history.push("/edit-coupon");
  };

  // const SearchByCoupon = (value) => {
  //   if (!value) {
  //     GetAllCoupons();
  //   } else {
  //     url
  //       .get(
  //         `/coupon/search/?consumerId=${adminID[0].consumerId}&name=${value}`
  //       )
  //       .then(async (res) => {
  //         if (res.data.Message === "Success") {
  //           setCoupons(res.data.data);
  //         } else {
  //           alert(res.data.data);
  //         }
  //       });
  //   }
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row" style={{ borderRadius: "13px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "6px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Coupons
                  </div>
                </div>
                <div className="col-md-4 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={(e) => handleSearchTermChange(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          performSearchOnEnter();
                        }
                      }}
                      value={searchTerm}
                      style={{ borderRight: "none" }}
                      className="form-control"
                      placeholder="Search..."
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>
                                    <img src={sort} />
                                </div> */}
                <div className="col-md-2 filters-bar">
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#FFA382", border: "none" }}
                    onClick={() => history.push("/add-coupon")}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Coupon
                  </button>

                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Coupon Code
                      </span>
                    </th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>

                    {/* <th scope="col">Applicable to</th> */}
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Value
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Expires On
                      </span>
                    </th>
                    {/* <th scope="col">
                                            <span
                                                style={{
                                                    fontSize: "100%",
                                                }}
                                            >
                                                Usage
                                            </span>
                                        </th> */}
                    <th scope="col" style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Status
                      </span>
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "100%",
                        }}
                      >
                        Action
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.length !== 0 ? (
                    coupons
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((coupon, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{ color: "rgba(30, 41, 59, 1)" }}
                              colSpan="4"
                            >
                              {coupon.couponCode}
                            </td>

                            <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                              {coupon.couponType === "Free Shiping"
                                ? "Free Shipping"
                                : coupon.couponType === "Percentage"
                                ? `${coupon.discountValue ?? 0} %`
                                : `$ ${coupon.discountValue ?? 0}`}
                            </td>
                            <td style={{ color: "rgba(30, 41, 59, 1)" }}>
                              {!!coupon.neverExpires
                                ? "Never Expires"
                                : moment(coupon.endDate).format(
                                    "DD MMMM YYYY, h:mm A"
                                  )}
                            </td>
                            {/* <td
                                                            style={{ color: "rgba(30, 41, 59, 1)" }}
                                                            className="usage">
                                                            <span>
                                                                {coupon.rules?.TotalUsageLimit || "N/A"}
                                                            </span>
                                                        </td> */}
                            <td className="status">
                              {coupon.status ? (
                                <div className="newClass" id="coupons">
                                  <div id="enablebutton">
                                    <p
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "center",
                                        fontSize: "13px",
                                        padding: "2px",
                                      }}
                                    >
                                      <span style={{ color: "#0CD600" }}>
                                        Enabled
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="newClass" id="coupons">
                                  <div id="disablebutton">
                                    <p
                                      style={{
                                        fontWeight: "400",
                                        textAlign: "center",
                                        fontSize: "13px",
                                        padding: "2px",
                                      }}
                                    >
                                      <span style={{ color: "red" }}>
                                        Disabled
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "2em",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  style={{
                                    paddingTop: "2px",
                                    color: "#FFA382",
                                    paddingLeft: "3px",
                                  }}
                                  className="fas fa-pen"
                                  onClick={() => {
                                    editCoupon(coupon);
                                  }}
                                ></i>
                                <i
                                  className="fa fa-trash-alt"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    openTooltip(coupon.couponId);
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <>
                      {" "}
                      <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Coupons found
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this coupon ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteCoupon();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <div
            onClick={() => {
              closeNotFoundPopUp();
            }}
            style={{
              color: "red",
              cursor: "pointer",
              textAlign: "right",
              fontSize: "20px",
            }}
          >
            x
          </div>
          <div style={{ color: "red", marginbottom: "10px" }}>Whoops!</div>
          <div style={{ marginTop: "10px" }}>
            <img alt="" src={Failure} />
          </div>
          <p style={{ marginTop: "20px" }}>Coupon(s) not found</p>
          <button
            type="button"
            onClick={() => {
              closeNotFoundPopUp();
            }}
            className="btn btn-primary border-0"
            style={{
              backgroundColor: "#FFA382",
              marginTop: "10px",
              width: "20vh",
              alignSelf: "center",
            }}
          >
            ok
          </button>
        </div>
      </Popup>
    </div>
  );
}
