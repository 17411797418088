export const TypedCouponsDaySelectors = {
  MON: {
    displayName: "Monday",
    value: "MON",
  },
  TUES: {
    displayName: "Tuesday",
    value: "TUES",
  },
  WED: {
    displayName: "Wednesday",
    value: "WED",
  },
  THURS: {
    displayName: "Thursday",
    value: "THURS",
  },
  FRI: {
    displayName: "Friday",
    value: "FRI",
  },
  SAT: {
    displayName: "Saturday",
    value: "SAT",
  },
  SUN: {
    displayName: "Sunday",
    value: "SUN",
  },
};
