import { Howl, Howler } from "howler";
import ring from "../assets/ring.mp3";

const sound = new Howl({
  src: ring,
  html5: true,
  loop: false,
  log: true,
  autoplay: false,
  pool: 5,
});
sound.once("end", () => {
  sound.unload();
});
export function playRing() {
  sound.play();
}
