import styled from "@emotion/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment } from "react";
import { textShrinkMiddle } from "../../helpers/textShrink";
import { colors } from "../../Style.style";

function DropDownProductVariantPicker({
  label,
  blankLabel,
  inputLabel,
  setSelected,
  //   {
  //     <varinatID> : {...<variants>}
  //   }
  selected,
  product,
  isSingleSelect,
}) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const handleSelect = (id, item) => {
    if (isSingleSelect) {
      setSelected({ [id]: item });
    } else {
      setSelected({ ...selected, [id]: item });
    }
  };
  const handleRemove = (value) => {
    if (isSingleSelect) {
      setSelected({});
    } else {
      setSelected(
        Object.keys(selected).reduce((acc, key) => {
          if (key === value) {
            return acc;
          }
          return { ...acc, [key]: selected[key] };
        }, {})
      );
    }
  };
  return (
    <Fragment>
      {product?.variants?.length ? (
        <Container>
          {blankLabel ? (
            <label>&nbsp;</label>
          ) : (
            <label>{label ?? "Pick Variants"}</label>
          )}
          <div className="select-container">
            <div
              className="select-btn"
              onClick={() => setDropdownOpen(!dropdownOpen)}>
              {inputLabel ?? label ?? "Pick Variants"}{" "}
              <span>
                {dropdownOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}
              </span>
            </div>
            <div
              className={`dropdown-container ${!dropdownOpen && "collapsed"}`}>
              <ul className={`select-options`}>
                {product.variants.map((item, index) => {
                  const id = item?.tempProductID ?? item.productID;
                  return (
                    <li
                      key={`selected-variants-${index}`}
                      className={`option ${selected[id] && "selected"}`}
                      onClick={() => handleSelect(id, item)}>
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <Fragment>
            {Object.values(selected).length > 0 && (
              <div className="selected-items">
                {Object.keys(selected).map((key) => {
                  const product = selected[key];
                  return (
                    <Fragment key={`selected-products-${key}`}>
                      <span
                        className="select-item-tag"
                        data-value={key}
                        key={key}>
                        {textShrinkMiddle(product.name, 50, 20)}{" "}
                        <button onClick={() => handleRemove(key)}>
                          <CloseIcon />
                        </button>
                      </span>
                    </Fragment>
                  );
                })}
              </div>
            )}
          </Fragment>
        </Container>
      ) : null}
    </Fragment>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .select-container {
    position: relative;
    .select-btn {
      position: relative;
      display: block;
      width: 100%;
      border: 1px solid #e2e8f0;
      background: transparent;
      font-size: 0.938rem;
      box-sizing: border-box;
      padding: 12px 16px;
      border-radius: 5px;
      cursor: pointer;
      span {
        position: absolute;
        right: 15px;
        top: 60%;
        transform: translateY(-50%);
        svg {
          font-size: 2.5rem;
          color: #747474;
        }
      }
    }
    .loading-container {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-container {
      position: absolute;
      left: 0px;
      top: 60px;
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 3;
      width: 100%;
      max-height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: max-height 0.3s ease-out;
      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b4b4b4a9;
        border-radius: 5px;
        cursor: pointer;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4d5;
        border-radius: 5px;
      }
      .search-product {
        box-sizing: border-box;
        padding: 5px;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #e2e8f0;
          background: transparent;
          font-size: 0.938rem;
          box-sizing: border-box;
          padding: 12px 16px;
          border-radius: 5px;
        }
      }
      .select-options {
        list-style-type: none;
        padding: 0;
        li {
          width: 100%;
          padding: 12px 16px;
          background-color: white;
          transition: background-color 0.2s ease-out;
          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        li.selected {
          background-color: ${colors.accent};
          color: white;
        }
      }
    }
    .dropdown-container.collapsed {
      max-height: 0px;
    }
  }
  .selected-items {
    padding: 10px;
    padding-left: 0px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .select-item-tag {
      padding: 8px;
      color: white;
      background-color: ${colors.accent};
      border-radius: 4px;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      button {
        border: none;
        outline: none;
        background: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
`;

export default DropDownProductVariantPicker;
