import React, { useEffect, useRef, useState } from "react";
import InputPrimary from "../../atoms/InputPrimary";
import ContentContainer from "../../atoms/ContentContainer";
import SelectPrimary from "../../atoms/SelectPrimary";
import { CustomPageTypes } from "./constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PrimaryDialog from "../../atoms/Dialogs/PrimaryDialog";
import styled from "@emotion/styled";
import { renderCappedString } from "../../helpers/utils";
import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { uploadMultipleFiles } from "../../helpers/firebaseFileUpload";
import axios from "../../config/axios";
import RippleLoader from '../../assets/ripple-loader.gif'
import SuccessDialogIcon from '../../assets/success-dialog.svg'
import FailedDialogIcon from '../../assets/failed-dialog.svg'
import FeedbackDialog from "../../atoms/Dialogs/FeedbackDialog";
import FormSkeletalLoader from "../../atoms/Loader/FormSkeletalLoader";

function CustomPageEditor({ id = null }) {
  const theme = useTheme();
  const adminData = JSON.parse(localStorage.getItem('Admin'))[0]
  const [addSectionDialogOpen, setAddSectionDialogOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveCompletedDialogOpen, setSaveCompletedDialogOpen] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const [metaInfo, setMetaInfo] = useState({
    slug: "",
    title: "",
    description: "",
    pageType: CustomPageTypes["BASIC"].id,
  });

  // section uses same data of current section in an array
  const [sections, setSections] = useState([]);

  /**
   * index: number,
   * title: string,
   * description: string,
   * images: Array<{ src: string, file?: (Blob | File), uploaded?: boolean}>
   */
  const currentSectionRef = useRef({
    index: -1, // If an existing section being edited, this index will be populated accordingly
    title: "",
    description: "",
    images: [],
  });
  const [currentSection, _setCurrentSection] = useState(currentSectionRef.current);
  const setCurrentSection = (value) => {
    _setCurrentSection(value);
    currentSectionRef.current = value;
  };

  const currentSectionImageUploaderRef = useRef(null);

  useEffect(() => {
    if (id) {
      setLoading(true)
      axios.get(`/v1/custom-pages/get-single?consumerID=${adminData.consumerID}&id=${id}`).then((res) => {
        const _page = res.data.data.page
        const _pageData = _page.pageData[_page.type]
        setMetaInfo({
          slug: _page.name,
          title: _pageData.title,
          description: _pageData.description,
          pageType: CustomPageTypes[_page.type].id 
        })

        setSections(_pageData.sections.map(section => ({
          title: section.title,
          description: section.description,
          images: section.images.map(img => ({ uploaded: true, src: img })),
        })))
        setLoading(false)
      })
    }
  }, [id])

  const updateMetaInfoByKey = (key, value) => {
    setMetaInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateSectionInfoByKey = (key, value) => {
    setCurrentSection({
      ...currentSectionRef.current,
      [key]: value,
    });
  };

  const updateSections = () => {
    const _section = currentSectionRef.current;
    if (_section.index === -1) {
      console.log("Another one adding");
      setSections((prevState) => [
        ...prevState,
        {
          title: _section.title,
          description: _section.description,
          images: _section.images,
        },
      ]);

    } else {
      setSections((prevState) =>
        prevState.map((s, si) => {
          if (si !== _section.index) return s;
          return {
            title: _section.title,
            description: _section.description,
            images: _section.images,
          };
        })
      );
    }
    resetAndCloseSectionEditor();
  };

  const openUpdateSection = (index, section) => {
    setCurrentSection({
      index: index,
      ...section,
    });
    setAddSectionDialogOpen(true);
  };
  const deleteSection = (index) => {
    setSections((prevState) => prevState.filter((s, si) => si !== index));
  };

  const resetAndCloseSectionEditor = () => {
    setAddSectionDialogOpen(false);
    setCurrentSection({
      index: -1,
      title: "",
      description: "",
      images: [],
    });
  };

  const handleImagesUpload = (event) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onload = (reader) => {
          setCurrentSection({
            ...currentSectionRef.current,
            images: [...currentSectionRef.current.images, { src: reader.target.result, file }],
          });
        };
        fileReader.readAsDataURL(file);
      });
    }
  };

  const removeUploadedImage = (index) => {
    setCurrentSection({
      ...currentSectionRef.current,
      images: currentSectionRef.current.images.filter((img, imgi) => index !== imgi),
    });
  };

  const saveCustomPage = async (metaInfo, sections) => {
    setSaveLoading(true)
    const _sectionImages = []
    sections.forEach((section, si) => {
        section.images.forEach((img, imgi) => {
            if (!img.uploaded) {
              _sectionImages.push({ index: `${si}:${imgi}`, file: img.file })
            }
        })
    })

    let uploadResponse = null
    try {
      uploadResponse = await uploadMultipleFiles(_sectionImages)
    } catch (error) {
      saveLoading(false)
      setSaveSuccess(false)
      setSaveCompletedDialogOpen(true)
      return
    }
    

    const _sections = sections.map((section, si) => ({
        title: section.title,
        description: section.description,
        images: section.images.map((img, imgi) => {
          if (img.uploaded) return img.src
          return uploadResponse.find(ur => ur.index === `${si}:${imgi}`).url
        })
    }))
    
    const body = {
        ...(id && { id }),
        type: "BASIC",
        name: metaInfo.slug,
        pageData: {
            "BASIC": {
                title: metaInfo.title,
                description: metaInfo.description,
                sections: _sections
            }
        }
    }
    try {
        const saveResponse = await axios.post('/v1/custom-pages/upsert', body)
        console.log(saveResponse)
        setSaveSuccess(true)
    } catch (error) {
        console.log(error)
        setSaveSuccess(false)
    }
    
    setSaveLoading(false)
    setSaveCompletedDialogOpen(true)
  };

  return (
    <ContentContainer heading={!id ? "Create Page" : "Edit Page"}>
      {!loading ? <>
        <div className="row g-3">
          <div className="col-md-6">
            <InputPrimary label="Slug" placeholder="Give Page Slug for URL" name="slug" value={metaInfo?.slug} onChange={(e) => updateMetaInfoByKey(e.target.name, e.target.value)} />
          </div>
          <div className="col-md-6">
            <InputPrimary label="Title" placeholder="Page Title" name="title" value={metaInfo?.title} onChange={(e) => updateMetaInfoByKey(e.target.name, e.target.value)} />
          </div>
          <div className="col-md-12 my-3">
            <p
              style={{
                color: "#747474",
                fontWeight: "500",
                fontSize: "0.875rem",
                lineHeight: "1rem",
                display: "block",
                marginTop: 30,
              }}
            >
              Description
            </p>
            <CKEditorContainer>
              <CKEditor
                row={"15"}
                editor={ClassicEditor}
                data={metaInfo?.description}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle("height", "300px", editor.editing.view.document.getRoot());
                  });
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  updateMetaInfoByKey("description", data);
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
                config={{
                  toolbarLocation: "bottom",
                  removeItems: [
                    'bold'
                  ]
                }}
              />
            </CKEditorContainer>
          </div>

          <hr style={{ marginTop: 32 }} />

          {/* <div className="col-md-12 mt-3">
            <SelectPrimary label="Page Type" name="pageType" value={metaInfo?.pageType} onChange={(e) => updateMetaInfoByKey(e.target.name, e.target.value)}>
              {Object.keys(CustomPageTypes).map((key) => (
                <option key={CustomPageTypes[key].key} value={CustomPageTypes[key].value}>
                  {CustomPageTypes[key].label}
                </option>
              ))}
            </SelectPrimary>
          </div> */}

          <div className="col-md-12 mt-5">
            {metaInfo.pageType === CustomPageTypes["BASIC"].id ? (
              <SectionsContainer theme={theme}>
                <div className="added-sections">
                  {sections.map((section, si) => (
                    <div className="added-section">
                      <p className="added-section_title">{section.title}</p>
                      <div className="added-section_description" dangerouslySetInnerHTML={{ __html: renderCappedString(section.description, 400) }} />
                      <div className="added-section_images">
                        {section.images.slice(0, 3).map((img) => (
                          <img src={img.src} />
                        ))}
                        {section.images.length > 3 && <p>{section.images.length - 3}+</p>}
                      </div>
                      <div className="added-section_action-buttons">
                        <button onClick={() => openUpdateSection(si, section)}>
                          <EditIcon />
                          <span>Edit</span>
                        </button>
                        <button onClick={() => deleteSection(si)}>
                          <DeleteIcon />
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="add-new-section-btn-container">
                  <button onClick={() => setAddSectionDialogOpen(true)}>
                    <AddCircleIcon /> Add New Section
                  </button>
                </div>
              </SectionsContainer>
            ) : (
              <div>Buttons for custom page editor</div>
            )}
          </div>
          <PrimaryDialog open={addSectionDialogOpen} setOpen={setAddSectionDialogOpen} title={currentSection?.index === -1 ? "Add New Section" : "Update Section"} sx={{ maxWidth: "1000px" }} disableAutoClose={true}>
            <AddSectionContainer>
              <div className="mb-3">
                <InputPrimary label="Section Title" placeholder="Give your section a title" name="title" value={currentSection?.title} onChange={(e) => updateSectionInfoByKey(e.target.name, e.target.value)} />
              </div>
              <CKEditorContainer>
                <CKEditor
                  row={"15"}
                  editor={ClassicEditor}
                  data={currentSection?.description}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle("height", "300px", editor.editing.view.document.getRoot());
                    });
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updateSectionInfoByKey("description", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  config={{
                    toolbarLocation: "bottom",
                  }}
                />
              </CKEditorContainer>

              {currentSection.images.length !== 0 && (
                <div className="uploaded-images-preview">
                  {currentSection.images.map((img, imgi) => (
                    <div className="uploaded-image">
                      <div className="delete-img-button">
                        <IconButton color="error" onClick={() => removeUploadedImage(imgi)}>
                          <ClearIcon />
                        </IconButton>
                      </div>
                      <img src={img.src} />
                    </div>
                  ))}
                </div>
              )}
              <div className="mt-3">
                <input type="file" accept="image/*" ref={currentSectionImageUploaderRef} onChange={handleImagesUpload} multiple />
                <Button variant="contained" onClick={() => currentSectionImageUploaderRef.current.click()}>
                  <FileUploadIcon /> Upload Images
                </Button>
              </div>
              <div className="mt-3 d-flex justify-content-end" style={{ gap: 12 }}>
                <Button variant="contained" onClick={updateSections}>
                  {currentSection?.index === -1 ? "Add" : "Update"}
                </Button>
                <Button variant="contained" color="error" onClick={resetAndCloseSectionEditor}>
                  Close
                </Button>
              </div>
            </AddSectionContainer>
          </PrimaryDialog>

          <PrimaryDialog open={saveLoading} disableAutoClose={true}>
              <SaveLoadingDialog>
                  <img src={RippleLoader} />
                  <h2>Uploading Your Page</h2>
                  <p>Saving your images and uploading your page. <b>Do not close the browser</b>, please wait...</p>
              </SaveLoadingDialog>
          </PrimaryDialog>

          <FeedbackDialog 
              open={saveCompletedDialogOpen}
              setOpen={setSaveCompletedDialogOpen}
              successData={{
                  title: "Successfully Uploaded!",
                  description: "Your page is uploaded. You can find your page now in custom pages section. Use this page to setup custom page for your shop!"
              }}
              isSuccess={saveSuccess}
          />
        </div>

        <div className="col-md-12 d-flex justify-content-end mt-4">
          <Button variant="contained" onClick={() => saveCustomPage(metaInfo, sections)} disabled={saveLoading}>Save</Button>
        </div>
      </> : <FormSkeletalLoader /> }
    </ContentContainer>
  );
}

const SectionsContainer = styled.div`
  .added-sections {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .added-section {
      position: relative;
      border: 1px solid #bbbbbb;
      padding: 12px;
      border-radius: 7px;
      &_title {
        font-size: 18px;
        font-weight: bold;
      }
      &_description {
        font-size: 14px;
        font-weight: lighter;
      }
      &_action-buttons {
        position: absolute;
        right: 20px;
        top: -10px;
        display: flex;
        gap: 5px;
        button {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 8px;
          padding: 5px 10px;
          svg {
            font-size: 16px;
          }
          span {
            max-width: 0px;
            overflow: hidden;
            transition: all 0.3s ease-out;
          }
          transition: all 0.3s ease-in;
          color: white;
          cursor: pointer;
          &:nth-child(1) {
            background-color: ${(props) => props.theme.palette.secondary.main};
          }
          &:nth-child(2) {
            background-color: ${(props) => props.theme.palette.action.danger};
          }
          &:hover {
            /* gap: 8px; */
            span {
              margin-left: 8px;
              max-width: 50px;
            }
          }
        }
      }
      &_images {
        display: flex;
        align-items: center;
        p {
          font-weight: bold;
          font-size: 18px;
          margin: 0;
          margin-left: 10px;
        }
        img {
          height: 40px;
          width: 40px;
          object-fit: cover;
          border: 1px solid #c4c4c4;
          border-radius: 3px;
          &:not(:first-child) {
            margin-left: -10px;
            background-color: white;
          }
        }
      }
    }
  }
  .add-new-section-btn-container {
    margin-top: 16px;
    button {
      cursor: pointer;
      width: 100%;
      padding: 12px;
      border-radius: 7px;
      border: none;
      outline: none;
      background: none;
      border: 2px dashed #bbbbbb;
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #434343;
      transition: background-color 0.3s ease-out;
      svg {
        font-size: 20px;
      }
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
`;

const AddSectionContainer = styled.div`
  padding: 20px;
  .uploaded-images-preview {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    .uploaded-image {
      position: relative;
      .delete-img-button {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      img {
        height: 200px;
        width: 200px;
        object-fit: contain;
        box-shadow: 10px 10px 9px -9px rgba(110, 110, 110, 0.57);
        background-color: #e1e1e1;
        border-radius: 8px;
      }
    }
  }
`;

const SaveLoadingDialog = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    padding: 32px;
    text-align: center;
    img {
        height: 200px;
        width: 200px;
    }
    p {
        color: #383838;
        font-size: 18px;
    }
`
const CKEditorContainer = styled.div`
  .ck-file-dialog-button {
    display: none;
  }
`

export default CustomPageEditor;
