import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentContainer from "../../atoms/ContentContainer";
import TablePrimary from "../../atoms/Table/TablePrimary";
import { IconButton } from "@mui/material";
import ActionButton from "../../atoms/Buttons/ActionButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../config/axios";
import ConfirmationDialog from "../../atoms/Dialogs/ConfirmationDialog";
import FeedbackDialog from "../../atoms/Dialogs/FeedbackDialog";

function AllCustomPages() {
  const history = useHistory();
  const adminData = JSON.parse(localStorage.getItem('Admin'))[0]

  const [rows, setRows] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [stagedItemForDelete, setStagedItemForDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteFeedbackDialogOpen, setDeleteFeedbackDialogOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(true);

  const columns = [
    { id: "name", label: "Name" },
    { id: "type", label: "Type" },
    { id: "updated_at", label: "Updated At" },
    { id: "Action", label: "Action", render: (rowData) => (
      <div className="d-flex gap-2 justify-content-end">
        <IconButton onClick={() => history.push(`/custom-page/edit/${rowData.id}`)}><EditIcon /></IconButton>
        <IconButton onClick={() => handleStageDelete(rowData)} color="error"><DeleteIcon /></IconButton>
      </div>
    )}
  ]

  const handleStageDelete = (pageItem) => {
    setDeleteDialogOpen(true);
    setStagedItemForDelete(pageItem)
  }
  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const res = await axios.delete(`/v1/custom-pages/remove?id=${stagedItemForDelete.id}`);
      setDeleteSuccess(true);
      setDeleteFeedbackDialogOpen(true);
      setDeleteLoading(false);
    } catch (error) {
      console.error("Failed to delete")
      setDeleteSuccess(false);
      setDeleteFeedbackDialogOpen(true);
      setDeleteLoading(false);
    }
    setDeleteDialogOpen(false);
    handleRefreshPageData();
  }

  const handleRefreshPageData = () => {
    setLoadingData(true)
    axios.get(`/v1/custom-pages/get-all?consumerID=${adminData.consumerID}`).then(res => {
      const pages = res.data.data.pages;
      setRows(pages.map(page => ({
        id: page._id,
        type: page.type,
        name: page.name,
        updated_at: page.updatedAt,
      })))
      setLoadingData(false);
    })
  }
  // const rows = [
  //   { name: "My Custom Page", last_activity: "Not Registered" }
  // ]

  useEffect(() => {
    setLoadingData(true)
    axios.get(`/v1/custom-pages/get-all?consumerID=${adminData.consumerID}`).then(res => {
      const pages = res.data.data.pages;
      setRows(pages.map(page => ({
        id: page._id,
        type: page.type,
        name: page.name,
        updated_at: page.updatedAt,
      })))
      setLoadingData(false);
    })
  }, [])
  return (
    <ContentContainer
      heading={"Custom Pages"}
      _headingRightActionComponent={
        <button
          className="btn btn-primary border-0"
          style={{ backgroundColor: "#FFA382" }}
          onClick={() => {
            history.push("/custom-page/create");
          }}
        >
          <i className="fas fa-plus-circle"></i>
          Create
        </button>
      }
    >
      <TablePrimary 
        columns={columns} 
        rows={rows} 
        isLoading={loadingData}
        emptyMessage={{
          heading: "No Pages Found!",
          description: "Looks like you haven't created any pages yet. Start creating page now to show custom pages on your website!"
        }}
      />
      <ConfirmationDialog 
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={`Deleting Page`}
        description={`Are you sure you want to delete page ${stagedItemForDelete?.name}?`}
        onConfirm={handleDelete}
      />
      <FeedbackDialog 
        open={deleteFeedbackDialogOpen}
        setOpen={setDeleteFeedbackDialogOpen}
        isSuccess={deleteSuccess}
        successData={{
          title: "Successfully Delete",
          description: `Page ${stagedItemForDelete?.name} is deleted successfully`
        }}
        failedData={{
          title: "Failed to Delete",
          description: `Page ${stagedItemForDelete?.name} has failed to delete, please check your internet connection and reload the page then try again. If it still doesn't work try re logging in.`
        }}
      />
    </ContentContainer>
  );
}

export default AllCustomPages;
