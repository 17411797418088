import { useState } from "react";
import SimpleDialogWithErrorAndLoader from "../../atoms/Dialogs/SimpleDialogWithErrorAndLoader";
import url from "../../config/axios";

const ConfirmationOrderDialog = ({
  orderInfo,
  updateOrderDetails,
  onCancel,
  onDone,
}) => {
  const initialFormState = {
    inProgress: false,
    error: null,
  };
  const [formState, setFormState] = useState(initialFormState);

  const updateOrder = async () => {
    setFormState({ ...initialFormState, inProgress: true });
    const body = {
      userID: orderInfo?.userID,
      _id: orderInfo?.orderID,
      statusID: orderInfo?.status,
    };
    url
      .put(`/v1/user-order-status/update-status`, body)
      .then(() => {
        if (onDone instanceof Function) {
          onDone();
          updateOrderDetails();
        }
      })
      .catch((e) => {
        setFormState({
          ...initialFormState,
          loading: false,
          error:
            e.response?.data?.data?.message ?? "Unable to update the order.",
        });
      });
  };
  return (
    <SimpleDialogWithErrorAndLoader
      open={true}
      onDisagree={() => {
        if (!formState.inProgress && onCancel instanceof Function) {
          onCancel();
        }
      }}
      onAgree={() => {
        if (!formState.inProgress && onDone instanceof Function) {
          //   onDone();
          updateOrder();
        }
      }}
      error={formState.error}
      loading={formState.inProgress}
      _agreeButtonText={"Yes"}
      _disagreeButtonText={"No"}
      _loadingButtonText="Updating..."
      heading={`Are you sure want to update the order?`}
    ></SimpleDialogWithErrorAndLoader>
  );
};
export default ConfirmationOrderDialog;
