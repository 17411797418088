import styled from "@emotion/styled"
import React from "react"
import './style.css'
import { colors } from "../../Style.style"


const ContentContainer = ({ heading, _headingRightActionComponent, children }) => {
    return (
        <div className="content-container">
            <div className="heading">
                <h1 className="heading-label">{heading}</h1>
                <div className="heading-right-action">
                    {_headingRightActionComponent}
                </div>
            </div>
            <hr />
            <div className="content">
                {children}
            </div>            
        </div>
    )
}

export default React.memo(ContentContainer);