import React from 'react'
import styled from '@emotion/styled'
import DoneIcon from '@mui/icons-material/Done';
import { colors } from '../../Style.style';
import { useTheme } from '@mui/material';

function CheckboxPrimary({ label, ...props }) {
    const theme = useTheme()
    
    return (
        <Container theme={theme}>
            <div className="checkbox-container">
                <input type="checkbox" {...props} data-icon={<DoneIcon />} />
                {/* <span className="checkmark"><DoneIcon /></span> */}
            </div>
            <label>{label}</label>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .checkbox-container {
        position: relative;
        input[type=checkbox] {
            position: relative;
            accent-color: ${ props => props.theme.palette.primary.main};
            appearance: none;
            height: 30px;
            width: 30px;
            border: 1px solid #E2E8F0;
            border-radius: 3px;   
            cursor: pointer;
            &::after {
                content: "✔";
                color: black;
                position: absolute;
                display: none;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
                color: white;
            }
            &:checked {
                background-color: ${ props => props.theme.palette.primary.main};
                border: none;
            }      
            &:checked::after {
                display: block;
            }               
        }
        /* .checkmark {
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%, -50%);
            color: white;
            font-weight: bold;
        } */
    }
    `

export default CheckboxPrimary