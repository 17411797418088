import React, { Fragment } from "react";
import moment from "moment/moment";
import VariantsComponent, {
  getPriceByVariant,
  getQuantityFromVariant,
} from "../../../../components/Inventory/Variants";
import { LinearProgress } from "@mui/material";
export default function InventoryTable({
  inventory,
  assignColor,
  currentEditingIndex,
  setCurrentEditingIndex,
  inventoryToChangeHiddenStatus,
  setInventoryToChangeHiddenStatus,
}) {
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Name</th>

            <th scope="col">Stock</th>
            <th scope="col">Price / Item</th>
            <th scope="col">Brand</th>
            <th scope="col">Category</th>
            <th scope="col">Featured Product</th>
            <th scope="col">Hidden</th>
            {/* <th scope="col">Status</th> */}
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {inventory.map((inv, i) => {
            return (
              <tr
                height={"2px"}
                style={{
                  borderBottom: "1px solid rgb(241, 245, 249)",
                }}
                key={i}>
                <td colSpan="" style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {inv.product.image ? (
                      <img
                        img
                        alt="yo1"
                        src={inv.product.image}
                        className="img-fluid"
                        width="40"
                        style={{
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      <div
                        className="image-thumbnail-div"
                        style={{
                          backgroundColor: assignColor(),
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "flex-end",
                        }}>
                        <span>
                          {inv?.product?.name?.match(/[a-zA-Z]/)
                            ? inv.product.name.match(/[a-zA-Z]/)[0]
                            : inv?.product?.name?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                    <span
                      style={{
                        color: "#1E293B",
                        width: "60%",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}>
                      {inv.product.name}
                    </span>
                  </div>
                </td>

                <td style={{ color: "#1E293B" }}>
                  {inv?.disableStockTrace ? (
                    "N/A"
                  ) : (
                    <Fragment>
                      {inv.variants?.length > 1
                        ? "---"
                        : inv.variants?.length === 1
                        ? getQuantityFromVariant(inv.variants[0], inv)
                        : inv.quantity}
                    </Fragment>
                  )}
                </td>
                <td style={{ color: "#1E293B" }}>
                  {inv.variants.length ? (
                    inv.variants.length > 1 ? (
                      <VariantsComponent
                        variants={inv.variants}
                        masterProductName={inv?.product?.name ?? "N/A"}
                        rootObject={inv}
                      />
                    ) : (
                      getPriceByVariant(inv.variants[0])
                    )
                  ) : (
                    Number(inv.originalPrice).toLocaleString("fullwide", {
                      useGrouping: false,
                    })
                  )}
                </td>
                <td
                  style={
                    inv.product.brandName
                      ? { color: "#1E293B" }
                      : { paddingLeft: "30px", color: "#1E293B" }
                  }>
                  {inv.product.brandName ? inv.product.brandName : "-"}
                </td>
                <td style={{ color: "#1E293B" }} className="status">
                  {inv.product.category.length
                    ? inv.product.category[0].name
                    : ""}
                </td>
                <td>
                  {inv?.productID === currentEditingIndex?.productID ? (
                    <LinearProgress
                      style={{
                        backgroundColor: "#1E233B",
                      }}
                    />
                  ) : (
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={inv?.featuredProduct}
                        disabled={currentEditingIndex !== null}
                        onChange={async () => {
                          if (currentEditingIndex === null) {
                            setCurrentEditingIndex(inv);
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  )}
                </td>
                <td>
                  {inv?.productID ===
                  inventoryToChangeHiddenStatus?.productID ? (
                    <LinearProgress
                      style={{
                        backgroundColor: "#1E233B",
                      }}
                    />
                  ) : (
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={inv.isHidden}
                        disabled={inventoryToChangeHiddenStatus !== null}
                        onChange={async () => {
                          if (inventoryToChangeHiddenStatus === null) {
                            setInventoryToChangeHiddenStatus(inv);
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  )}
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
