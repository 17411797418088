import * as hash from "object-hash";
export const getHash = (obj) => {
  try {
    return hash(obj);
  } catch (e) {
    return null;
  }
};
export const checkIfArrayObjectsAreSame = (elems) => {
  let isShared = true;
  if (elems?.length) {
    const firstHashed = getHash(elems[0]);
    if (firstHashed === null) {
      return false;
    }
    for (let i = 1; i < elems?.length; i++) {
      const currentHash = getHash(elems[i]);
      if (currentHash !== firstHashed) {
        isShared = false;
        break;
      }
    }
  }
  return isShared;
};
