import { useState } from "react";
import url from "../../config/axios";
const ForgotPassword = ({ onBack, backText="Back to Login" }) => {
  const [formData, setFormData] = useState({
    email: "",
    loading: false,
    error: null,
  });

  const isEmailValid = () => {
    return (formData.email + "")
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleSubmit = () => {
    if (!formData.loading) {
      if (isEmailValid()) {
        setFormData({
          ...formData,
          error: null,
          loading: true,
        });
        url
          .put("/forgot-password", {
            email: formData.email,
          })
          .then(() => {
            onBack();
          })
          .catch((e) => {
            setFormData({
              ...formData,
              loading: false,
              error: "Unable to request",
            });
          });
      }
    }
  };
  return (
    <div
      className="login-v1-form-holder"
      style={{
        maxWidth: "100%",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: "33px",
            color: "black",
          }}>
          Forgot Password
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginBottom: "1em",
        }}>
        <p
          style={{
            fontSize: "14px",
            width: "30vw",
            maxWidth: "400px",
            textAlign: "center",
            color: "#747474",
          }}>
          Enter the Email Address associated with your account and we’ll send
          you a mail containing the login credentials.
        </p>
      </div>
      <div className="login-v1-input-container">
        <label className="login-v1-input-label">Email Address</label>
        <input
          type={"email"}
          name="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
          //   disabled={formData.loading}
          //   required={true}
          className="login-v1-input"
          placeholder="Enter Email"
        />
        {formData.email && !isEmailValid() && (
          <p className="login-v1-input-error">{"Enter a valid email"}</p>
        )}
      </div>
      <button className="login-v1-action-button" onClick={() => handleSubmit()}>
        {formData.loading ? "Requesting..." : "Continue"}
      </button>
      <div className="login-v1-link-container">
        <span className="login-v1-link-text" onClick={() => onBack()}>
          {backText}
        </span>
      </div>
    </div>
  );
};

export default ForgotPassword;
