/* eslint-disable react-hooks/exhaustive-deps */
import "./AddInventory.css";
import React from "react";
import { useState, useEffect } from "react";
import url from "../../config/axios";

export default function AddCatalog({ setAddModal, myProduct }) {
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [singleProductDetails, setSingleProductDetails] = useState(null);
  const [stockQty, setStockQty] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [display, setDisplay] = useState(false);
  const [track, setTrack] = useState(false);

  const nextStep = () => {
    setStepTwo(!stepTwo);
    setStepOne(!stepOne);
  };

  const backStep = () => {
    setStepTwo(!stepTwo);
    setStepOne(!stepOne);
  };

  useEffect(() => {
    if (myProduct != null) {
      setSingleProductDetails(myProduct);
      setDisplay(true);
      setStepOne(true);
    }
  }, []);

  const addInventory = () => {
    const admin = JSON.parse(localStorage.getItem("Admin"));
    let body = {
      outletChainID: admin[0].outletChainID,
      consumerId: admin[0].consumerId,
      productID: singleProductDetails.productID,
      quantity: stockQty,
      originalPrice: originalPrice,
      discountPrice: discountPrice,
    };
    url
      .post(`/inventory/`, body)
      .then(async (res) => {
        if (res.data.Message === "Failure") {
          alert(res.data.data);
        } else {
          // if(myProduct === null){
          //     getAllInventory();
          // }
          alert("Inventory Added");
          // history.push("/outlets");
          setAddModal(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const styles = {
    display: "block",
    // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
  };

  const title = {
    fontSize: "22px",
    fontWeight: "500",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1F6355",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    // color: "#1F6355",
  };

  const mark = {
    color: "#898989",
    background: "none",
  };

  const productDetails = {
    backgroundColor: "#f9f9f9",
    borderRadius: "7px",
  };

  const title2 = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    color: "#000000",
  };
  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={title}>
            <span>Add Product to Inventory</span>
            <i
              className="fas fa-times"
              onClick={() => {
                setAddModal(false);
              }}
            />
          </div>
          {display && (
            <div className="modal-body">
              <div className="row mx-1 align-items-center">
                <div className="col-md-6 step-1 step-active">
                  <div className="row align-items-center">
                    <div className="col-2   m-0 ">
                      <div className="step-circle step-circle-active">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10" style={text2}>
                      <p className="m-0">Step 1:</p>
                      <p className="m-0">Confirm Product</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-md-6 step-1 ${!stepOne ? "step-active" : ""}`}
                >
                  <div className="row align-items-center">
                    <div className="col-2   m-0 ">
                      <div
                        className={`step-circle ${
                          !stepOne ? "step-circle-active" : ""
                        }`}
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10" style={text2}>
                      <p className="m-0">Step 2:</p>
                      <p className="m-0">Submit Billing & Inventory Details</p>
                    </div>
                  </div>
                </div>
              </div>
              {!stepTwo && (
                <p style={title2} className="my-3">
                  Click Next To Continue
                </p>
              )}
              <div className="row">
                {stepTwo && (
                  <>
                    <div className="col-md-12">
                      <div
                        className="d-flex justify-content-start p-3"
                        style={productDetails}
                      >
                        <img
                          src={singleProductDetails.image}
                          alt="product"
                          width="40px"
                          height="40px"
                        />
                        <span className="mx-3">
                          <p style={text1} className="m-0 p-0">
                            {singleProductDetails.name}
                          </p>
                          <p style={text2} className="m-0 p-0">
                            <mark style={mark}>Brand: </mark>{" "}
                            {singleProductDetails.brandID}
                          </p>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <p style={title2} className="my-3">
                        Inventory Details
                      </p>
                      <span className="solo-chain">Track Inventory?</span>
                      <div className="row mt-2">
                        <div className="col-md-4 switch-col">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={track}
                              onChange={() => setTrack(!track)}
                            />
                            <span className="slider round"></span>
                          </label>
                          <span className="ml-3">Yes</span>
                        </div>

                        {track && (
                          <div className="col-md-4">
                            <div className="group-comb">
                              <div className="form-group border border-secondary">
                                <label>Quantity in Stock</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="3"
                                  value={stockQty}
                                  onChange={(e) => setStockQty(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-2"></div>
                      </div>
                      {/* )} */}
                    </div>
                    {track && (
                      <div className="col-md-12">
                        <p style={title2} className="my-3">
                          Billing Details
                        </p>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="group-comb">
                              <div className="form-group border border-secondary">
                                <label>Discounted Price</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleFormControlInput"
                                  placeholder="$ 20.00"
                                  value={discountPrice}
                                  onChange={(e) =>
                                    setDiscountPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="group-comb">
                              <div className="form-group border border-secondary">
                                <label>Original Price</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleFormControlInpu1"
                                  placeholder="$ 25.00"
                                  value={originalPrice}
                                  onChange={(e) =>
                                    setOriginalPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {!stepTwo && (
                <div className="row align-items-center size-text ">
                  <div className="col-md-12">
                    {/* <img src={emptyImg} className="mx-auto d-block" />{" "} */}
                    <div className="row bg-secondary p-3 rounded text-white sel-product">
                      <div className="col-md-3">
                        <img
                          src={singleProductDetails.image}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="top-head">
                              {" "}
                              {singleProductDetails.name}
                            </p>
                          </div>
                          <div className="col-md-5 second-row">
                            Brand: <span>{singleProductDetails.brandName}</span>
                          </div>
                          <div className="col-md-5 second-row">
                            Categories:{" "}
                            <span>
                              {" "}
                              {singleProductDetails.category.length
                                ? singleProductDetails.category[0].name
                                : ""}
                            </span>
                          </div>
                          <div className="col-md-2"></div>

                          <div className="col-md-5 percent">
                            <i className="fas fa-sort-down mr-2" />
                            <span>{singleProductDetails.thc}% TBH</span>
                          </div>
                          <div className="col-md-5 percent">
                            <i className="fas fa-sort-up mr-2" />
                            <span>{singleProductDetails.cbd}% CBD</span>
                          </div>
                          <div className="col-md-2"></div>
                          <div className="col-md-12">
                            <p className="prod-p">
                              {singleProductDetails.shortDescription},{" "}
                              {singleProductDetails.longDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="modal-footer">
            {!stepTwo && (
              <>
                <button className="btn btn-outline-dark">Cancel</button>
                <button className="btn btn-primary" onClick={() => nextStep()}>
                  Next
                </button>
              </>
            )}
            {stepTwo && (
              <>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => backStep()}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => addInventory()}
                >
                  Publish
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
