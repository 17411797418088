import React from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";

export default function BannersTable({ banners, setBannerToDelete }) {
  const history = useHistory();
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Banner Image</th>
            <th scope="col">Title</th>
            <th scope="col" style={{ textAlign: "center" }}>
              Promotions
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Status
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Processing State
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {banners?.map((item, i) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid rgb(241, 245, 249)",
                }}
                key={i}
              >
                <td style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      // I have added here
                      img
                      onClick={() => {
                        //
                      }}
                      alt="yo1"
                      src={
                        item?.image ??
                        `https://static.vecteezy.com/system/resources/previews/006/609/926/original/discount-offer-icon-hand-holding-percentage-vector.jpg`
                      }
                      className="img-fluid"
                      style={{
                        width: "120px",
                        height: "50px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div>{item.title}</div>
                </td>
                <td>
                  <div style={{ textAlign: "center" }}>
                    {item?.targetTopic ?? "N/A"}
                  </div>
                </td>
                <td className={item.status ? "color-blue" : "color-red"}>
                  <span>
                    {item.status ? (
                      <div className="newClass" id="deals">
                        <div id="enablebutton">
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "4px",
                            }}
                          >
                            <span style={{ color: "#0CD600" }}>Enabled</span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="newClass" id="deals">
                        <div id="disablebutton">
                          {" "}
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "4px",
                            }}
                          >
                            <span style={{ color: "red" }}>Disabled</span>
                          </p>
                        </div>
                      </div>
                    )}{" "}
                  </span>
                </td>
                <td>
                  <div style={{ textAlign: "center" }}>
                    {!item?.mediaTrace?.status
                      ? "N/A"
                      : item.mediaTrace?.status === "SUCCESS"
                      ? "SUCCESS"
                      : moment().diff(
                          moment(item.mediaTrace?.createdAt ?? new Date()),
                          "minutes"
                        ) > 1
                      ? "ABORTED"
                      : item.mediaTrace?.status}{" "}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1em",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      onClick={() => {
                        history.push(`/edit-typed-banner?_id=${item._id}`);
                      }}
                      style={{
                        color: "#FFA382",
                        cursor: "pointer",
                      }}
                      className="fas fa-pen"
                    ></i>
                    <i
                      onClick={() => setBannerToDelete(item)}
                      style={{ color: "red", cursor: "pointer" }}
                      className="fas fa-trash"
                    ></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
