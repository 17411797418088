export const TypedBannersAppliesToSelectors = {
  PROMOTIONS: {
    value: "PROMOTIONS",
    displayName: "Select Promotions",
  },
  PRODUCTS: {
    value: "PRODUCTS",
    displayName: "Selected Products",
  },
  CATEGORIES: {
    value: "CATEGORIES",
    displayName: "Selected Categories",
  },
  DEALS: {
    value: "DEALS",
    displayName: "Selected Deals",
  },
  MANUFACTURERS: {
    value: "MANUFACTURERS",
    displayName: "Selected Manufacturers",
  },
};
