import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { muiStyled } from "@mui/material/styles";
import ImageIcon from "../../assets/image_icon.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button, Dialog } from "@mui/material";
import { v4 } from "uuid";
import { getUniqueFileName } from "../../helpers/getFileName";
import Cropper from "react-easy-crop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "@mui/material/Slider";
import CircularProgress from '@mui/material/CircularProgress';

/**
 label: <String> Label name on the top of the button
 onUplaod: <(file) => {}> After user upload an image, it returns the actual file on Uplaod function
 file: <File, Blob> If there is an existing file, 
 */
function FileUpload({
  size,
  label,
  onUpload,
  //used in add-typed-deal form
  onFileLoaded,
  onFileRemoved,
  file = null,
  allowedExtensions = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "webp",
    "gif",
    "tiff",
    "ico",
  ],
  className = "",
  _customIcon = null,
  buttonLabel,
  height,
  ImageHeight,
  ImageWidth,
  cropProperties,
  imageSelector,
  editImage,
  loading=false
}) {
  const fileInput = React.useRef();
  const [resultFile, setResultFile] = React.useState(file);
  // const handleFileUplaod = (e) => {
  // };
  React.useEffect(() => {
    setResultFile(file);
  }, [file]);
  const handleFileDrop = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === "file") {
        uploadFile(e.dataTransfer.items[0].getAsFile());
      }
    }
  };
  const handleFileUploadInput = (e) => {
    if (imageSelector instanceof Function) {
      imageSelector(e.target.files[0]);
    }
    if (e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
    }
  };
  const uploadFile = (file) => {
    const splittedName = file.name.split(".");
    const extension = splittedName[splittedName.length - 1];
    if (allowedExtensions.includes(extension)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        setResultFile(reader.result);
        var image = new Image();
        image.onload = () => {};
        image.src = reader.result;
        if (onFileLoaded instanceof Function) {
          onFileLoaded({
            name: getUniqueFileName(file.name),
            source: reader.result,
          });
        }
      });
      if (onUpload) {
        onUpload({ file: file, source: reader.result });
      }
    } else {
    }
  };
  const handleDelete = () => {
    setResultFile(null);
    if (onUpload) {
      onUpload({ file: null, source: null });
    }
    if (onFileRemoved instanceof Function) {
      onFileRemoved();
    }
  };
  return (
    <Container
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
      className={className}
      style={{ height: height }}
    >
      {(resultFile || editImage) ? (
        <div className="preview-image-container">
          {loading && <div class="upload-loading">
            <CircularProgress />
            <p>Uploading Image...</p>
          </div>}
          <div className="action-icons-container">
            <button
              className="action-btn edit"
              onClick={() => fileInput.current.click()}
            >
              <EditOutlinedIcon /> Edit
            </button>
            <ImageCropper {...cropProperties} />
            <button className="action-btn delete" onClick={handleDelete}>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
          {editImage ? <img src={editImage} className="preview-image" alt="" /> : <img src={resultFile} className="preview-image" alt="" /> }
        </div>
      ) : (
        <>
          <div
            className="label-container"
            style={{
              color: "#747474",
              fontSize: "14px",
            }}
          >
            {_customIcon ?? <img src={ImageIcon} height="32px" alt="" />}
            {label}
          </div>
          <div
            className="label-container"
            style={{
              marginTop: "-1.5em",
              color: "#747474",
              fontSize: "14px",
            }}
          >
            {size}
          </div>
          <Button
            style={{ textTransform: "capitalize" }}
            variant="contained"
            className="upload-button"
            onClick={() => fileInput.current.click()}
          >
            {buttonLabel ?? "Choose File"}
          </Button>
        </>
      )}
      <input
        type="file"
        className="hidden-input"
        ref={fileInput}
        onChange={handleFileUploadInput}
      />
    </Container>
  );
}

function ImageCropper({
  showCrop,
  showCroppedImage,
  onCropComplete,
  cropDialogOpen,
  setCropDialogOpen,
  editImage,
  ImageWidth,
  ImageHeight,
}) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const closeDialog = () => {
    setCropDialogOpen(false);
    showCroppedImage();
  };
  return showCrop ? (
    <Dialog
      fullScreen={fullScreen}
      open={cropDialogOpen}
      fullWidth={true}
      onClose={() => setCropDialogOpen(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <div
        className="App"
        style={{
          padding: "1em",
          width: "auto",
          height: "auto",
        }}
      >
        <div className="crop-container">
          <Cropper
            image={editImage}
            crop={crop}
            zoom={zoom}
            aspect={parseInt(ImageWidth) / parseInt(ImageHeight)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
            // classes={{ root: "slider" }}
          />
        </div>
        <div style={{ margin: "0 auto", width: "fit-content" }}>
          <Button
            sx={{
              backgroundColor: theme.palette.gray.main + "!important",
              color: theme.palette.gray.dark + "!important",
              width: "180px",
              marginRight: "20px",
            }}
            color="primary"
            onClick={() => closeDialog()}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: theme.palette.primary.main + "!important",
              width: "180px",
            }}
            color="primary"
            onClick={() => showCroppedImage()}
            variant="contained"
          >
            Crop
          </Button>
        </div>
      </div>
    </Dialog>
  ) : (
    <Fragment></Fragment>
  );
}

const Container = styled.div`
  height: 200px;
  width: 225px;
  background-color: #f9fbff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: relative;
  overflow: hidden;
  .label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .preview-image-container {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    .upload-loading {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      background-color: #ffffffaa;
      z-index: 2;
    }
    .action-icons-container {
      position: absolute;
      right: 14px;
      top: 14px;
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
      .action-btn {
        border: none;
        outline: none;
        font-size: 12px;
        padding: 6px;
        border-radius: 4px;
        display: flex;
        gap: 4px;
        align-items: center;
        transition: box-shadow 0.3s ease-out;
        cursor: pointer;
        &:hover {
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
      }
      .action-btn.edit {
        background-color: #ffa382;
        color: white;
      }
      .action-btn.delete {
        background-color: red;
        color: white;
      }
    }

    .preview-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .hidden-input {
    opacity: 0;
    height: 0px;
    width: 0px;
    /* display: block !important;  
        position: absolute;
        left: 0;
        top: 0;        
        height: 100%;
        width: 100%;
        z-index: 2; */
  }
  .upload-button {
    background-color: #ffa382;
    z-index: 3;
  }
`;

export default FileUpload;
