import { useState } from "react";
import SimpleDialogWithErrorAndLoader from "../../atoms/Dialogs/SimpleDialogWithErrorAndLoader";
import url from "../../config/axios";

const DeleteOrderDialog = ({ orderInfo: { _id }, onCancel, onDone }) => {
  const initialFormState = {
    inProgress: false,
    error: null,
  };
  const [formState, setFormState] = useState(initialFormState);
  const deleteOrder = async () => {
    setFormState({ ...initialFormState, inProgress: true });
    url
      .delete(`/v1/user-orders/delete?_id=${_id}`)
      .then(() => {
        if (onDone instanceof Function) {
          onDone();
        }
      })
      .catch((e) => {
        // if(e.response?.data?.data?.)
        setFormState({
          ...initialFormState,
          loading: false,
          error:
            e.response?.data?.data?.message ?? "Unable to remove the order.",
        });
      });
  };
  return (
    <SimpleDialogWithErrorAndLoader
      open={true}
      onDisagree={() => {
        if (!formState.inProgress && onCancel instanceof Function) {
          onCancel();
        }
      }}
      onAgree={() => {
        if (!formState.inProgress && onDone instanceof Function) {
          //   onDone();
          deleteOrder();
        }
      }}
      error={formState.error}
      loading={formState.inProgress}
      _agreeButtonText={"Yes"}
      _disagreeButtonText={"No"}
      _loadingButtonText="Deleting..."
      heading={`Are you sure want to delete the order?`}
    ></SimpleDialogWithErrorAndLoader>
  );
};
export default DeleteOrderDialog;
