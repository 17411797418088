/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import EditTypedDealRulesAndExpiryForm from "../../../components/EditTypedDeal/edit-typed-deal-rules-and-expiry-form";
import url from "../../../config/axios";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";
import {
  editDealThunk,
  getFormCompatibleDataFromResponse,
  replaceDealStateAction,
  resetOpStateAction,
  selectEditDealState,
} from "../../../redux/slices/editDealSlice";
export default function EditTypedDealsRulesAndExpiry() {
  const outlets = useSelector(selectMyOutlets);
  localStorage.setItem("outlets", JSON.stringify(outlets))
  const [dealState, setDealState] = useState({
    loading: true,
    deal: null,
    error: null,
  });
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectEditDealState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const dealInfo = useSelector(selectEditDealState);
  const { inProgress, error, success } = dealInfo;
  useEffect(() => {
    // dispatch(resetDealStateAction());
    const dealID = new URLSearchParams(history.location.search).get("_id");
    console.log("Outlets", outlets)
    url
      .get(`/v1/typed-deals?_id=${dealID}`)
      .then((res) => {
        const deal = res.data?.data?.deal;
        setDealState({ deal, error: null, loading: false });
        try {
          dispatch(
            replaceDealStateAction(getFormCompatibleDataFromResponse(deal))
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {
        setDealState({
          deal: null,
          error: "Unable to populate the deal",
          loading: false,
        });
      });
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12">
            <form className="row" onSubmit={(e) => e.preventDefault()}>
              {dealState.loading ? (
                "Populating data..."
              ) : (
                <EditTypedDealRulesAndExpiryForm />
              )}
            </form>
          </div>
          <div className="d-flex justify-content-end border-0">
            {dealState?.loading ? null : (
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    editDealThunk(
                      isMultiChainAdmin && applyToAllOutlets
                        ? [
                            ...outlets
                              .map((key) => key?.outletChainID)
                              .filter((el) => !!el)
                          ]
                        : [JSON.parse(localStorage.getItem("Admin"))[0]
                            ?.outletChainID]
                    )
                  );
                }}
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382" }}
                className="btn  btn-cannaby">
                {!inProgress ? "Update Deal" : "Updating Deal..."}
              </button>
            )}
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Deal updated Successfully"}
          onClose={() => {
            dispatch(resetOpStateAction());
            history.push("/typed-deals");
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
      {dealState.error ? (
        <SimpleModal
          severity={"error"}
          highlights={dealState.error}
          onClose={() => {
            history.push("/typed-deals");
          }}
        />
      ) : null}
    </div>
  );
}
