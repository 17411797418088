// Array<{
//   displayName: string;
//   month: number;
//   day: number;
//   occurrence: number;
//   year: number;
// }>

export const getLineDataFromGenericDateData = (data, label) => {
  const params = Array.isArray(data) ? data : [];
  const lineData = {
    labels: [...params.map((obj) => obj.displayName)],
    datasets: [
      {
        label, //Last Year
        data: [...params.map((obj) => obj.occurrence)],
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(3, 20, -35, 200);
          gradient.addColorStop(0.7, "#C6CEF8");
          gradient.addColorStop(0.6, "#C6CEF8");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0.25)");
          return gradient;
        },
        borderColor: "#192746"
      },
    ],
  };
  return lineData;
};
export const getBarDataFromGenericDateData = (data, label) => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  const params = Array.isArray(data) ? data : [];
  const lineData = {
    // labels: [...params.map((obj) => obj.displayName],
    labels: [...params.map((obj) => `${months[obj.month - 1]} ${obj.day}`)],
    datasets: [
      {
        label, //Last Year
        data: [...params.map((obj) => obj.occurrence)],
        fill: true,
        backgroundColor: "#061049",
        borderColor: "#192746",
      },
    ],
  };
  return lineData;
};
export const getTotalOccurencesFromLineData = (data) => {
  let total = 0;
  if (data?.length) {
    data?.map((obj) => (total += obj?.occurrence ?? 0));
  }
  return total;
};
