/* eslint-disable jsx-a11y/alt-text */
import { CircularProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import url from "../../config/axios";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { colors } from "../../Style.style";
import moment from "moment";
import blank from "../../assets/defaultImage.jpg";
import DeleteNotificationDialog from "../../components/TypedNotification/DeleteNotificationDialog";
import AddTypedNotificationPage from "./sub-pages/add-typed-notifications";
import EditTypedNotificationPage from "./sub-pages/edit-typed-notifications";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import NotificationsTable from "../../components/TypedNotification/NotificationTable";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  resetNotificationStateAction,
  resetOpStateAction,
} from "../../redux/slices/addNotificationSlice";

export default function TypedNotifications() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [currentOutletChainID, setCurrentOutletChainID] = useState(null);
  const setCurrentOutletChainIDState = () => {
    try {
      setCurrentOutletChainID(
        JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID ?? null
      );
    } catch (e) {}
  };
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [composeNotification, setComposeNotification] = useState(false);
  const [open, setOpen] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [notificationData, setNotificationData] = useState(false);
  const [error, setError] = useState(null);
  /**
   *
   * @param {string} value actual value
   */
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };

  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      setCurrentOutletChainIDState();
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllTypedNotifications({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/typed-notifications${queryString}`);
    setQueryData({ ...query });
  };
  const GetAllTypedNotifications = () => {
    setLoading(true);
    let queryParams = {
      ...queryData,
    };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(
        `/v1/consumer-notifications-admin/get-all-notifications-aggregated`,
        {
          params: {
            ...queryParams,
          },
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          const response = res.data?.data;
          if (!response?.notifications?.length && searchTerm) {
            setError("No notifications found");
          }
          setNotifications(response?.notifications);
          setPaginationData({
            ...paginationData,
            ...response?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const Tablenew = useMemo(
    () => (
      <NotificationsTable
        notifications={notifications}
        setNotificationToDelete={setNotificationToDelete}
        setOpenEdit={setOpenEdit}
        setNotificationData={setNotificationData}
        blank={blank}
      />
    ),
    [notifications]
  );
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ borderRadius: "13px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Notifications
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center flex-gap-1">
                      <div className="input-group col-md-10">
                        <input
                          type="text"
                          style={{ borderRight: "none" }}
                          className="form-control"
                          placeholder="Search Notification"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                          value={searchTerm}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm?.length ? (
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              onClick={() => handleSearchTermChange("")}
                              style={{
                                backgroundColor: "white",
                                borderLeft: "none",
                                paddingLeft: 0,
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="input-group-prepend"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                            onClick={performSearchOnEnter}
                          >
                            <i
                              style={{ color: colors.searchColor }}
                              className="fas fa-search"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 filters-bar">
                        <button
                          className="btn btn-primary border-0"
                          style={{ backgroundColor: "#FFA382" }}
                          onClick={() => {
                            // history.push("/add-typed-notification");
                            setComposeNotification(true);
                            setOpen(true);
                            dispatch(resetNotificationStateAction());
                          }}
                        >
                          <i className="fas fa-plus-circle"></i>
                          Compose
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {Tablenew}
                  {!notifications?.length ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      No notification found
                    </div>
                  ) : null}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>

      {invokeLogOut && <InvokeLayoutComponent />}

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Notification(s) not found"
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}

      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Notification(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              handleSearchTermChange();
            }}
          >
            OK
          </button>
        </div>
      </Popup>

      {/* delete notification popup */}
      {notificationToDelete && notificationToDelete?._id && (
        <DeleteNotificationDialog
          notificationInfo={notificationToDelete}
          onDone={() => {
            setNotificationToDelete(null);
            setQueryData({ ...initialPaginationData });
          }}
          onCancel={() => setNotificationToDelete(null)}
        />
      )}

      {/* compose notification */}
      {composeNotification && (
        <AddTypedNotificationPage
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          GetAllTypedNotifications={GetAllTypedNotifications}
          onDone={() => {
            setQueryData({ ...initialPaginationData });
          }}
        />
      )}

      {/* edit notification */}
      {openEdit && (
        <EditTypedNotificationPage
          openEdit={openEdit}
          handleEditClose={handleEditClose}
          notificationData={notificationData}
          onDone={() => {
            setQueryData({ ...initialPaginationData });
          }}
        />
      )}
    </div>
  );
}
