import "./hostchat.styles.css";
import moment from "moment";

const HostChatComponent = ({ content, time }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        gap: "0.5em",
        marginTop: "0.5em",
        flexWrap: "wrap",
        paddingLeft: "20%",
        // overflow: "hidden",
      }}>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "flex-end",
          flexWrap: "wrap",
          gap: "0.3em",
          paddingRight: "1em",
        }}>
        <div className="host-speech-bubble">{content}</div>
        <span
          style={{
            marginLeft: "auto",
            marginRight: "0.5em",
            fontSize: "70%",
            color: "gray",
            fontWeight: "400",
          }}>
          {new Date(time).toString() !== "Invalid Date" &&
            moment(new Date(time).getTime()).fromNow()}
        </span>
      </div>
      {/**actions */}
      {/* <div
        style={{
          display: "flex",
          width: "auto",
        }}></div> */}
    </div>
  );
};
export default HostChatComponent;
