import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  chooserOpen: false,
};
export const ocSwitchSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleChooserOpenStatus: (state) => {
      state.chooserOpen = !state.chooserOpen;
    },
  },
});
export const { toggleChooserOpenStatus } = ocSwitchSlice.actions;
export const selectIfChooserOpen = (state) => state.ocSwitch.chooserOpen;
export default ocSwitchSlice.reducer;
