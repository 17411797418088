import { WifiTetheringOff } from "@mui/icons-material";

const SessionDisconnectedComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "100%",
        height: "85vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "14px",
        flexWrap: "wrap",
      }}>
      <WifiTetheringOff
        sx={{
          height: 56,
          width: 56,
        }}
      />
      You are offline! Trying to reconnect...
    </div>
  );
};

export default SessionDisconnectedComponent;
