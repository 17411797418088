import FormControlLabel from "@mui/material/FormControlLabel";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import Section from "../../atoms/Section";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import {
  changeSinglePropertyAction,
  selectAddBannerState,
  selectValidationErrors,
} from "../../redux/slices/addBannerSlice";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import DropDownCategoryPicker from "../common/DropDownCategoryPicker";
import DropDownProductPicker from "../common/DropDownProductPicker";
import SelectPrimary from "../../atoms/SelectPrimary";
import { TypedBannersAppliesToSelectors } from "../../pages/TypedBanners/constants/typed-banner-selectors.constants";
import DropDownDealPicker from "../common/DropDownDealPicker";
import DropDownManufacturerPicker from "../common/DropDownManfacturerPicker";
import defaultImage from "../../assets/defaultImage.jpg";
import calculateAspectRatio from "calculate-aspect-ratio";
import ImageIcon from "../../assets/image_icon.svg";
import ToggleSwitch from "../../atoms/ToggleSwitch";

const AddTypedBannerForm = () => {
  const dispatch = useDispatch();
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const state = useSelector(selectAddBannerState);
  const bannerInfo = useSelector(selectAddBannerState);
  const [image, setImage] = useState("");
  const [resolutionError, setResolutionError] = useState(null);
  const allowedRatios = ["5:2"];
  const [file, setFile] = useState("");

  const {
    title,
    time,
    applyToAllOutlets,
    canBeOverridden,
    targetTopic,
    targetInfo,
    status,
  } = state;
  const { chosenProducts, chosenCategories, chosenBrands, chosenDeals } =
    bannerInfo;
  const formValidationErrors = useSelector(selectValidationErrors);

  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };

  useEffect(
    () => {
      handleChange("chosenProducts", {});
      handleChange("chosenCategories", {});
      handleChange("chosenDeals", {});
      handleChange("chosenBrands", {});
    },
    //eslint-disable-next-line
    [targetTopic]
  );

  const imageSelector = async (event) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

    if (event.target.files[0]) {
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        var i = new Image();
        i.onload = () => {
          try {
            const aspectRatio = calculateAspectRatio(i.width, i.height);
            if (allowedRatios.includes(aspectRatio + "")) {
              setResolutionError(null);
            } else {
              setResolutionError(
                `Allowed ratio(s) : ${allowedRatios.join(", ")}`
              );
            }
          } catch (e) {
            setResolutionError(`Invalid format`);
          }
        };
        const objectURL = URL.createObjectURL(event.target.files[0]);
        i.src = objectURL;
        setImage(objectURL);
        setFile(event.target.files[0]);

        // convert image in base64 string even.target.files[0]
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          handleChange("image", {
            name: event.target.files[0].name,
            source: reader.result,
          });
        };
      }
    }
  };
  return (
    <Fragment>
      <div className="col-md-12" style={{ paddingTop: "5px" }}>
        <div
          style={{
            color: "#FFA382",
            fontSize: "17px",
            marginTop: "20px",
            marginBottom: "20px",
            paddingLeft: "5px",
            fontWeight: "bold",
          }}
        >
          Banner Details
        </div>
      </div>
      <div className="col-md-12 mb-4">
        <InputPrimary
          value={title}
          name="title"
          label={"Banner Title"}
          placeholder="Banner Title"
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.title ? formValidationErrors.title : ""}
        </span>
      </div>

      <div className="col-md-12 px-3 mb-4">
        {/* <div
          className="fluid d-flex justify-content-center rounded py-4"
          style={{ backgroundColor: "#f9fbff" }}
        >
          <FileUpload
            onFileRemoved={async () => {
              handleChange("image", []);
              handleChange("imageFile", null);
            }}
            onFileLoaded={(file) => {
              handleChange("image", []);
              handleChange("imageFile", file);
            }}
            label={"Upload Banner Image"}
          />
        </div> */}
        <div className="BannerFileuploader2">
          {image !== "" ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  margin: " 0 auto",
                  position: "relative",
                }}
              >
                <img
                  alt="banner"
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: " 0 auto",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                  src={image}
                />
                <label
                  className=""
                  style={{
                    border: "none",
                    backgroundColor: "#FFA382",
                    position: "absolute",
                    top: "10%",
                    right: "5%",
                    width: "70px",
                    borderRadius: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <input
                    type="file"
                    id="image"
                    name="Image"
                    onChange={imageSelector}
                    accept="image/png, image/jpeg"
                  />
                  <div style={{ color: "white", justifyContent: "flex-end" }}>
                    <i
                      style={{ color: "white", marginRight: "7px" }}
                      className="fas fa-pen"
                    ></i>
                    <u>Edit</u>
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "4em",
                  paddingTop: "5em",
                }}
              >
                <img
                  alt="banner"
                  style={{
                    margin: "10px auto",
                    position: "relative",
                  }}
                  src={ImageIcon}
                />
              </div>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                Upload Banner Image
                <p
                  style={{
                    fontSize: "11px",
                    color: "rgba(116, 116, 116, 0.5)",
                    marginBottom: "0px !important",
                  }}
                >
                  Files include PNG, JPEG
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    color: "rgba(116, 116, 116, 0.5)",
                    marginBottom: "0px !important",
                    marginTop: "-10px",
                  }}
                >
                  500 x 200 px
                </p>
              </p>
              <label
                className="custom-file-upload"
                style={{ border: "none", display: "block", padding: "0px" }}
              >
                <input
                  type="file"
                  id="image"
                  name="Image"
                  onChange={imageSelector}
                  accept="image/png, image/jpeg"
                />
                <p
                  style={{
                    backgroundColor: "#FFA382",
                    border: "1px solid #FFA382",
                    width: "120px",
                    color: "white",
                    borderRadius: "5px",
                    padding: "0.6em 1em 0.6em",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "30px",
                    boxShadow: "0px 0px 8px 0px #ccc",
                  }}
                >
                  Choose File
                </p>
              </label>
            </>
          )}
        </div>
        <p
          style={{
            color: "red",
            marginTop: "20px",
          }}
        >
          Please upload a JPG file with dimensions of 500 x 200 px pixels and a
          file size not exceeding 1 MB.
        </p>
        <span className="validation-help">
          {resolutionError && (
            <p className="mt-4" style={{ color: "red" }}>
              {resolutionError}
            </p>
          )}
        </span>
        <span className="validation-help">
          {formValidationErrors?.image ? formValidationErrors.image : ""}
        </span>
      </div>
      <div className="col-md-12 mb-4">
        <InputPrimary
          value={time}
          name="time"
          label={"Banner Duration (seconds)"}
          placeholder="Banner Duration (seconds)"
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.time ? formValidationErrors.time : ""}
        </span>
      </div>

      <div className="col-md-12">
        <div className="mb-4">
          <SelectPrimary
            value={targetTopic}
            name="targetTopic"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            label={"Promotions"}
          >
            {Object.values(TypedBannersAppliesToSelectors).map(
              (item, index) => (
                <option
                  value={item.value}
                  key={`selected-applies-to-type-${index}`}
                >
                  {item.displayName}
                </option>
              )
            )}
          </SelectPrimary>
          <span className="validation-help">
            {formValidationErrors?.targetTopic
              ? formValidationErrors.targetTopic
              : ""}
          </span>
        </div>

        <div className="mb-4">
          {targetTopic === "CATEGORIES" ? (
            <DropDownCategoryPicker
              label={"Pick Categories"}
              isSingleSelect={true}
              selected={chosenCategories}
              setSelected={(value) => {
                handleChange(
                  "targetInfo",
                  Object.values(value)?.length ? Object.values(value)[0] : {}
                );
                handleChange("chosenCategories", value);
              }}
            />
          ) : targetTopic === "DEALS" ? (
            <DropDownDealPicker
              label={"Pick Deal"}
              isSingleSelect={true}
              selected={chosenDeals}
              setSelected={(value) => {
                handleChange(
                  "targetInfo",
                  Object.values(value)?.length ? Object.values(value)[0] : {}
                );
                handleChange("chosenDeals", value);
              }}
            />
          ) : targetTopic === "PRODUCTS" ? (
            <DropDownProductPicker
              label={"Pick Products"}
              isSingleSelect={true}
              selected={chosenProducts}
              setSelected={(value) => {
                handleChange(
                  "targetInfo",
                  Object.values(value)?.length ? Object.values(value)[0] : {}
                );
                handleChange("chosenProducts", value);
              }}
            />
          ) : targetTopic === "MANUFACTURERS" ? (
            <DropDownManufacturerPicker
              label={"Pick Manufacturer"}
              isSingleSelect={true}
              selected={chosenBrands}
              setSelected={(value) => {
                handleChange(
                  "targetInfo",
                  Object.values(value)?.length ? Object.values(value)[0] : {}
                );
                handleChange("chosenBrands", value);
              }}
            />
          ) : null}
          <span className="validation-help">
            {formValidationErrors?.targetInfo && targetTopic !== ""
              ? formValidationErrors.targetInfo
              : ""}
          </span>
        </div>
      </div>

      <div className="col-md-12">
        <Section label="Status">
          <div style={{ transform: "translateX(-15px)" }}>
            <ToggleSwitch
              status={status}
              onChange={() => handleChange("status", !status)}
            />
          </div>
        </Section>
      </div>
      {isMultiChainAdmin ? (
        <div className="col-md-12">
          <Section label="Access Control">
            <Fragment>
              <FormGroup className="mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applyToAllOutlets"
                      checked={applyToAllOutlets}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Apply to all outlets"
                />
              </FormGroup>
            </Fragment>
          </Section>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AddTypedBannerForm;
