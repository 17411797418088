/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import {
  resetNotificationStateAction,
  resetOpStateAction,
  selectAddNotificationState,
} from "../../../redux/slices/addNotificationSlice";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";
import AddTypedNotification from "../../../components/AddTypedNotification/add-typed-notification-form";
import { createNotificationThunk } from "../../../redux/slices/addNotificationSlice";
import { Dialog } from "@mui/material";

export default function AddTypedNotificationPage({
  open,
  handleClickOpen,
  handleClose,
  onDone,
}) {
  const outlets = useSelector(selectMyOutlets);
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectAddNotificationState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationInfo = useSelector(selectAddNotificationState);
  const { inProgress, error, success } = notificationInfo;
  useEffect(() => {
    dispatch(resetOpStateAction());
    dispatch(resetNotificationStateAction());
  }, []);

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div>
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <div className="col-md-12">
            <form
              className="row"
              onSubmit={(e) => e.preventDefault()}
              style={{ height: "auto" }}
            >
              <div className="col-md-12">
                <div
                  className="row d-flex "
                  style={{
                    borderBottom: "2px solid #F1F5F9",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-2px",
                      backgroundColor: "#FFA382",
                      marginTop: "10px ",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  {/* <div className="d-flex justify-content-between w-75 border"> */}
                  <div className="col-md-5 d-flex">
                    <div
                      style={{
                        color: "#FFA382",
                        fontSize: "18px",
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Compose Notifications
                    </div>
                  </div>
                  <div className="col-md-5"> </div>
                  <div className="col-md-1 filters-bar d-flex">
                    <button
                      className="btn btn-primary border-0 "
                      style={{
                        backgroundColor: "#FFA382",
                        marginBottom: "5px",
                        marginLeft: "90px",
                        marginRight: "10px",
                      }}
                      onClick={handleClose}
                    >
                      <i className="fas fa-arrow-left"></i>
                      Back
                    </button>
                  </div>
                  {/* </div> */}
                  <br></br>
                </div>
              </div>
              <div className="col-md-12">
                <AddTypedNotification />
              </div>
            </form>
            <div className="d-flex justify-content-end border-0">
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    createNotificationThunk(
                      isMultiChainAdmin && applyToAllOutlets
                        ? [
                            ...outlets
                              .map((key) => key?.outletChainID)
                              .filter((el) => !!el),
                            JSON.parse(localStorage.getItem("Admin"))[0]
                              ?.outletChainID,
                          ]
                        : [
                            JSON.parse(localStorage.getItem("Admin"))[0]
                              ?.outletChainID,
                          ]
                    )
                  );

                  // handleClose();
                }}
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382" }}
                className="btn  btn-cannaby"
              >
                {!inProgress ? "Send" : "Send Notification..."}
              </button>
            </div>
          </div>
        </div>
        {success ? (
          <SimpleModal
            severity={"success"}
            highlights={"Notification added Successfully"}
            onClose={() => {
              dispatch(resetOpStateAction());
              history.push("/typed-notifications");
            }}
            onDone={onDone}
            closeModal={handleClose}
          />
        ) : null}
        {error ? (
          <SimpleModal
            severity={"error"}
            highlights={error}
            onClose={() => {
              dispatch(resetOpStateAction());
            }}
          />
        ) : null}
      </div>
    </Dialog>
  );
}
