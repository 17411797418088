import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Tooltip } from "@mui/material";
import moment from "moment";
import React from "react";
import { MultipleFlexibleColumns } from "../../../Style.style";
import crossImg from "../../../assets/cross-dialog.png";
import Default from "../../../assets/defaultImage.jpg";
import BasicSelectorChip from "../../../atoms/Chips/BasicSelectorChips";
import CustomInputPrimary from "../../../atoms/CustomInputprimary/CustomInputPrimary";
import Section from "../../../atoms/Section";
import styles from "../styles.module.css";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3),
    minWidth: "790px",
    Height: "750px",
    overflowX: "hidden",
    borderRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "5px !important",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00) !important",
      borderRadius: "10px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D5D5D5 !important",
      borderRadius: "10px !important",
    },
  },
}));
function Regular(props) {
  const classes = useStyles();
  const Orangestyle = {
    height: "25px",
    marginLeft: "-2px",
    marginTop: "6px",
    width: "5px",
  };
  const title = {
    color: "#FFA382",
    fontSize: "20px",
    fontWeight: "600",
    marginTop: "3px",
  };
  const ImageWrapper = styled.div`
    min-height: 200px;
    background: rgba(226, 232, 240, 0.5);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 720px;
    img {
      height: 140px;
      width: 200px;
      display: flex;
      justify-content: center;
      margin: auto;
      margin-top: 24px;
      border-radius: 10px;
    }
  `;

  const DaysWrapper = styled.div`
    width: 700px;
    margin-top: 10px;
    padding-bottom: 15px;
    label {
      color: #747474;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      display: block;
    }
    div {
      display: flex;
      overflow: auto;
      flex-direction: row;
      gap: 10px;
    }
  `;
  const days = props.expiryInfo.applicableDays;
  const currentOutletChainID =
    JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID ?? null;
  return (
    <>
      <Dialog open={true} PaperProps={{ className: classes.dialogPaper }}>
        <div className="d-flex py-2 justify-content-between">
          <div className="d-flex" style={{ gap: "8px" }}>
            <div style={Orangestyle}>
              <span style={{ color: "#FFA382" }}>.</span>
            </div>
            <div style={title}>View Deal</div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => props.onClose(false)}
          >
            <img
              src={crossImg}
              alt=""
              style={{
                marginRight: "10px",
              }}
            />
          </div>
        </div>
        {/* Image div */}
        <ImageWrapper style={{ width: "770px" }}>
          <img
            src={props.data?.images[0] ? props.data?.images[0] : Default}
            alt="img"
          />
          <button
            className={
              props.data?.outletBasisConfig[currentOutletChainID]?.status
                ? styles.enableDeal
                : styles.disableDeal
            }
          >
            {props.data?.outletBasisConfig[currentOutletChainID]?.status
              ? "Enabled"
              : "Disabled"}
          </button>
        </ImageWrapper>
        {/* form div */}
        <div
          className="d-flex "
          style={{
            justifyContent: "space-between",
            padding: "10px",
            paddingTop: "3px",

            flexWrap: "wrap",
          }}
        >
          <CustomInputPrimary
            label="Deal Name"
            hasBorder={false}
            labelHasMargin={true}
            style={{
              width: "339px",
              padding: "12px 16px 12px 0px",
              cursor: "pointer",
              display: "flex",
              flexWrap: "wrap",
              fontSize: props.data?.name.length >= 40 ? "10px" : "inherit",
            }}
            value={props.data?.name}
            readOnly
          />

          <CustomInputPrimary
            label="Deal Type"
            hasBorder={false}
            labelHasMargin={true}
            style={{ width: "339px", padding: "12px 16px 12px 0px" }}
            value={props.data?.dealType}
            readOnly
          />
          <div>
            <CustomInputPrimary
              label={"Applicable To"}
              hasBorder={false}
              labelHasMargin={true}
              style={{ width: "339px", padding: "12px 16px 12px 0px" }}
              value={props.Applicabletitle}
            />

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {props.REGULAR.map((item, i) => {
                const shortenedName =
                  item.brandName || item.productName || item.categoryName
                    ? (
                        item.brandName ||
                        item.productName ||
                        item.categoryName
                      ).slice(0, 25)
                    : "N/A";

                return (
                  <Tooltip title={shortenedName}>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        width: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        background: "#FFA382",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "4px",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {shortenedName}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>

          <CustomInputPrimary
            label="Discount Value"
            hasBorder={false}
            labelHasMargin={true}
            style={{ width: "339px", padding: "12px 16px 12px 0px" }}
            value={
              props.data?.dealInfo[props.DEALTYPE]?.getOff?.type ===
              "PERCENTAGE"
                ? props.data?.dealInfo[props.DEALTYPE]?.getOff?.numericAmount +
                  "%"
                : " $ " +
                  props.data?.dealInfo[props.DEALTYPE]?.getOff?.numericAmount
            }
            readOnly
          />
          <CustomInputPrimary
            label="Starts On"
            hasBorder={false}
            labelHasMargin={true}
            style={{ width: "339px", padding: "12px 16px 12px 0px" }}
            value={moment(props.expiryInfo?.startsOn).format(
              "DD MMM YYYY h:mm A"
            )}
            readOnly
          />
          <CustomInputPrimary
            label={"Expires On"}
            hasBorder={false}
            labelHasMargin={true}
            style={{ width: "339px", padding: "12px 16px 12px 0px" }}
            value={
              props.expiryInfo?.neverExpires
                ? "Never Expires"
                : moment(props.expiryInfo?.endsOn).format("DD MMM YYYY h:mm A")
            }
            readOnly
          />
          <DaysWrapper>
            <label
              style={{
                marginTop: "20px",
              }}
            >
              Select Days
            </label>

            <div
              style={{
                display: "flex",
              }}
            >
              {Object.keys(days).map((item, i) => {
                return (
                  <>
                    {days[item] && days[item] && (
                      <BasicSelectorChip
                        selected={true}
                        disabled={false}
                        label={item}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </DaysWrapper>

          <div className="col-md-12">
            <Section label="Rules">
              <MultipleFlexibleColumns width="350px" gap="20px">
                <div>
                  <CustomInputPrimary
                    label="Total Usage Limit"
                    type="number"
                    name="totalUsageLimit"
                    style={{ width: "339px", padding: "12px 16px 12px 0px" }}
                    value={props.data.rulesInfo.rules.totalUsageLimit}
                  />
                </div>
                <div>
                  <CustomInputPrimary
                    label="Limit Uses per Customer"
                    type="number"
                    name="usageLimitPerCustomer"
                    style={{ width: "339px", padding: "12px 16px 12px 0px" }}
                    value={props.data.rulesInfo.rules?.usageLimitPerCustomer}
                  />
                </div>
              </MultipleFlexibleColumns>
            </Section>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Regular;
