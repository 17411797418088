import storage from "../config/firebase";
export const fileUpload = async (file) => {
  try {
    await storage.ref(`images/${file.name}`).put(file);
    const url = await storage.ref("images").child(file.name).getDownloadURL();

    return url;
  } catch (e) {
    return null;
  }
};

export const fileUploadAsync = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      await storage.ref(`images/${file.name}`).put(file);
      const url = await storage.ref("images").child(file.name).getDownloadURL();
  
      resolve(url);
    } catch (e) {
      reject(e)
    }
  })
}

export const uploadMultipleFiles = async (files) => {
  return new Promise(async (resolve, reject) => {
    try {
      const filesPromises = []
      const filesIndexes = []
      files.forEach(fileData => {
        filesIndexes.push(fileData.index)
        filesPromises.push(fileUploadAsync(fileData.file))
      })
      const uploadResponse = await Promise.all(filesPromises)
      const result = uploadResponse.map((upload_res, index) => ({ index: filesIndexes[index], url: upload_res}))
      resolve(result)
    } catch (error) {
      reject({ error })
    }
    
  })
}

export const base64FileUploadWithPredefinedName = async ({ name, source }) => {
  try {
    // const contentType = source.split(";")[0].split(":")[1];
    // await storage
    //   .ref(`images/${name}`)
    //   .put(new Blob([source], { contentType }));
    const blob = await (await fetch(source)).blob();
    await storage.ref(`images/${name}`).put(blob);
    const url = await storage.ref("images").child(name).getDownloadURL();
    return url;
  } catch (e) {
    console.error("File upload error ", e);
    return null;
  }
};
