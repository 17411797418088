/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { CircularProgress } from "@mui/material";
import debounce from "../../helpers/debounce";
import sort from "../../assets/sort.svg";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import Popup from "reactjs-popup";
import SendNotificationModal from "../../components/Notifications/SendNotification";
import Pagination from "@mui/material/Pagination";

export default function NotificationsIndex() {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 100,
    totalPages: 0,
    total: 0,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  const ref = useRef();
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(null);

  const closeTooltip = () => {
    ref.current.close();
    setDeleteId(null);
    setDeleting(false);
  };

  /**
   *
   * @param {string} deleteId _id of the notification to remove
   */
  const openToolTip = (deleteId) => {
    ref.current.open();
    setDeleteId(deleteId);
    setDeleting(false);
  };

  const deleteNotification = () => {
    setDeleting(true);
    url
      .delete(
        `/v1/notifications/admin-delete-notification?notificationId=${deleteId}`
      )
      .then(() => {
        setSearchTerm("");
        setQueryData({ ...initialPaginationData });
        closeTooltip();
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        console.error(e.response);
        setDeleting(false);
        setDeleteId(null);
      });
  };
  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      // setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllNotifications({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/my-notifications${queryString}`);
    setQueryData({ ...query });
  };

  const GetAllNotifications = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/notifications/admin-paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (!res.data?.data?.notifications?.length) {
            notFoundRef.current.open();
          }
          setNotifications(res.data?.data?.notifications);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
      });
  };
  const [manipulatingNotification, setManipulatingNotification] = useState({});
  const closeModal = (updated = false, data = null) => {
    if (updated) {
      if (manipulatingNotification?._id && data?._id) {
        setNotifications(
          notifications.map((obj) => (obj._id === data._id ? data : obj))
        );
      } else {
        // setSearchTerm("");
        setQueryData({ ...initialPaginationData });
      }
      setManipulatingNotification({});
    } else {
      setManipulatingNotification({});
    }
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ borderRadius: "15px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-3 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Notifications
                      </div>
                    </div>
                    <div className="col-md-3 filters-bar">
                      <div className="dropdown"></div>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          value={searchTerm}
                          style={{ borderRight: "none" }}
                          className="form-control"
                          placeholder="Search title"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                        />
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                          >
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>
                                            <img src={sort} />
                                        </div> */}
                    <div className="col-md-1">
                      <button
                        className="btn btn-primary border-0"
                        style={{ backgroundColor: "#FFA382" }}
                        onClick={() => {
                          setManipulatingNotification({
                            notificationInfo: {
                              title: "",
                              highlights: "",
                            },
                          });
                        }}
                      >
                        <i className="fas fa-plus-circle"></i>
                        Compose
                      </button>
                    </div>
                    <div className="col-md-2 filters-bar">
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4 pagination">
                                            {paginationData.totalPages !== 0 &&
                                                paginationData.currentPage <=
                                                paginationData.totalPages && (
                                                    <Fragment>
                                                        <span>
                                                            {`${paginationData.perPage *
                                                                (paginationData.currentPage - 1) +
                                                                1
                                                                } - ${paginationData.perPage *
                                                                (paginationData.currentPage - 1) +
                                                                notifications.length
                                                                } of ${paginationData.total ?? 0}`}
                                                        </span>
                                                        <div
                                                            className={`btn ${paginationData.currentPage <= 1
                                                                ? ""
                                                                : "cannaby-light"
                                                                }`}
                                                            onClick={() => {
                                                                if (!(paginationData.currentPage <= 1)) {
                                                                    handleChange(
                                                                        "page",
                                                                        paginationData.currentPage - 1
                                                                    );
                                                                }
                                                            }}
                                                            role="button"
                                                        >
                                                            <i className="fas fa-chevron-left"></i>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                if (
                                                                    !(
                                                                        paginationData.currentPage >=
                                                                        paginationData.totalPages
                                                                    )
                                                                ) {
                                                                    handleChange(
                                                                        "page",
                                                                        paginationData.currentPage + 1
                                                                    );
                                                                }
                                                            }}
                                                            className={`btn ${paginationData.currentPage >=
                                                                paginationData.totalPages
                                                                ? ""
                                                                : "cannaby-light"
                                                                }`}
                                                            role="button"
                                                        >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </div>
                                                    </Fragment>
                                                )}
                                        </div> */}
                  </div>
                  {/*  */}
                </div>
                <div className="col-md-12">
                  {notifications.length === 0 ? null : ( // </div> //   No data found //   className="row"> //   }} //     padding: "1em", //     justifyContent: "center", //     alignItems: "center", //   style={{ // <div
                    <table className="table table-striped-cust">
                      <thead>
                        <tr>
                          <th>
                            <span
                              style={{
                                fontSize: "100%",
                              }}
                            >
                              Title
                            </span>
                          </th>
                          <th>
                            <span
                              style={{
                                fontSize: "100%",
                              }}
                            >
                              Message
                            </span>
                          </th>
                          <th>
                            <span
                              style={{
                                fontSize: "100%",
                              }}
                            >
                              Sent At
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}
                            >
                              Actions
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.map((element, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  width: "25%",
                                  color: "rgba(30, 41, 59, 1)",
                                }}
                              >
                                {element?.notificationInfo?.title}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  color: "rgba(30, 41, 59, 1)",
                                }}
                              >
                                {element?.notificationInfo?.highlights}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  color: "rgba(30, 41, 59, 1)",
                                }}
                              >
                                {moment(element?.updatedAt).format(
                                  "D MMM YYYY, h:mm A"
                                )}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  color: "rgba(30, 41, 59, 1)",
                                }}
                                className="text-center"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2em",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-pen"
                                    style={{ color: "#FFA382" }}
                                    onClick={() => {
                                      setManipulatingNotification({
                                        ...element,
                                      });
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-trash-alt"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      openToolTip(element._id);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">
            Do you want to delete this notification ?
          </p>
          <button
            className="btn btn-cannaby"
            onClick={() => deleteNotification()}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Yes"}
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            disabled={deleting}
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Notifications not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}
          >
            OK
          </button>
        </div>
      </Popup>
      {Object.keys(manipulatingNotification).length !== 0 &&
        !manipulatingNotification?._id && (
          <SendNotificationModal
            onClose={closeModal}
            refData={{
              title: manipulatingNotification?.notificationInfo?.title,
              highlights:
                manipulatingNotification?.notificationInfo?.highlights,
            }}
            invokeLogout={() => setInvokeLogout(true)}
          />
        )}
      {Object.keys(manipulatingNotification).length !== 0 &&
        manipulatingNotification?._id && (
          <SendNotificationModal
            notificationId={manipulatingNotification?._id}
            onClose={closeModal}
            refData={{
              title: manipulatingNotification?.notificationInfo?.title,
              highlights:
                manipulatingNotification?.notificationInfo?.highlights,
              viaEmail:
                manipulatingNotification?.notificationInfo?.mediums?.viaEmail,
              viaSms:
                manipulatingNotification?.notificationInfo?.mediums?.viaSms,
              viaMobile:
                manipulatingNotification?.notificationInfo?.mediums?.viaMobile,
              viaSystem:
                manipulatingNotification?.notificationInfo?.mediums?.viaSystem,
            }}
            invokeLogout={() => setInvokeLogout(true)}
          />
        )}
    </div>
  );
}
