import React from 'react'
import NoDataImg from '../../assets/no-data.jpg'
import styled from '@emotion/styled'

function NoData({ image, heading, description }) {
  return (
    <Container>
        <img src={image ?? NoDataImg} className='main-img' />
        <div className="details">
            <p className="no-data-heading">{heading ?? 'No Data Found'}</p>
            {description && <p className='no-data-description'>{description}</p>}
        </div>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    max-width: 390px;
    margin: 0 auto;
    .details {
        text-align: center;
        .no-data-heading {
            font-weight: bold;
            font-size: 20px;
        }
        .no-data-description {
            font-weight: lighter;
        }
    }
    .main-img {
        height: 220px;
    }
`

export default NoData