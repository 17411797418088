import { io } from "socket.io-client";
import {
  addNewSessionAction,
  updateMessageSessionAndChatWindowThunk,
  updateSessionStatusAction,
} from "./messageSessionSlice";
import { setRootNotificationMessageAction } from "./rootNotificationSlice";
import { playRing } from "../../helpers/play-ring";

const { createSlice } = require("@reduxjs/toolkit");

const SOCKET_URI = process.env?.REACT_APP_SOCKET_URI;
export const SOCKET_EVENTS = {
  CONNECT: "connect",
  RECEIVE_NOTIFICATION: "RECEIVE_NOTIFICATION",
  NEW_MESSAGE: "NEW_MESSAGE",
  REQUEST_SESSION: "REQUEST_SESSION",
  SESSION_UPDATED: "SESSION_UPDATED",
  SESSSION_DEACTIVATED: "SESSSION_DEACTIVATED",
  DISCONNECT: "disconnect",
  RECONNECT: "reconnect_attempt",
  LOYALTY_GIVEN: "LOYALTY_GIVEN",
  ORDER_PLACED: "ORDER_PLACED",
};

const socketEventHandler = (event, dispatcher, payload) => {
  const action = SOCKET_EVENT_ACTION_MAP[event];

  if (action) {
    try {
      if (payload?.length) {
        dispatcher(action(payload[0]));
      } else {
        dispatcher(action());
      }

      if (action === SOCKET_EVENT_ACTION_MAP.NEW_MESSAGE && payload?.length) {
        try {
          const message = payload[0]?.message;
          //play sound
          if (!message?.fromAdmin && !message?.regardingTopicChange) {
            playRing();
          }
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      // console.error(e);
    }
  }
};
const initialState = {
  socket: null,
  trying: false,
  connected: false,
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    tryToEstablishConnectionAction: (state) => {
      if (state.socket) {
        try {
          state.socket.close();
        } catch (e) {
          console.error(e);
        }
      }
      state.socket = null;
      state.trying = true;
      state.error = null;
      state.connected = false;
    },
    failedToEstablishConnectionAction: (state, action) => {
      state.trying = false;
      state.error = action?.payload ?? "Unable to establish connection";
      state.connected = false;
    },
    establishSocketConnectionAction: (state, action) => {
      state.trying = false;
      state.error = null;
      state.socket = action.payload;
      state.connected = true;
    },
    setSocketAsConnetedAction: (state) => {
      state.connected = true;
      state.trying = false;
    },
    setSocketAsReconnectAction: (state) => {
      state.connected = false;
      state.trying = true;
    },
    setSocketAsDisconnectedAction: (state) => {
      state.connected = false;
      state.trying = false;
      state.error = "Unable to connect";
    },
    testAction: (state, action) => {},
    resetSocketStateAction: (state) => {
      if (state.socket) {
        try {
          state.socket.close();
        } catch (e) {
          console.error(e);
        }
      }
      return initialState;
    },
  },
});

export const {
  tryToEstablishConnectionAction,
  establishSocketConnectionAction,
  failedToEstablishConnectionAction,
  resetSocketStateAction,
  setSocketAsConnetedAction,
  setSocketAsDisconnectedAction,
  setSocketAsReconnectAction,
} = socketSlice.actions;

export const selectSocketState = (state) => state.socket.socket;
export const selectIfSocketConnected = (state) => state.socket?.connected;
export const initializeSocketStateThunk = () => (dispatch) => {
  dispatch(tryToEstablishConnectionAction());
  let token = null;

  try {
    token = localStorage.getItem("token");
    if (!token) {
      throw new Error();
    }
  } catch (e) {
    dispatch(failedToEstablishConnectionAction("Corrupted data!"));
  }

  try {
    const socketInstance = io(SOCKET_URI, {
      query: { isAdmin: true },
      auth: {
        token,
      },
      transports: ["websocket"],
    });
    if (!socketInstance) {
      throw new Error();
    }
    socketInstance.onAny((event, ...args) => {
      socketEventHandler(event, dispatch, args);
    });
    dispatch(establishSocketConnectionAction(socketInstance));
  } catch (e) {
    dispatch(failedToEstablishConnectionAction());
  }
};

export const SOCKET_EVENT_ACTION_MAP = {
  // TEST: socketSlice.actions.testAction,
  [SOCKET_EVENTS.SESSION_UPDATED]: updateSessionStatusAction,
  [SOCKET_EVENTS.NEW_MESSAGE]: updateMessageSessionAndChatWindowThunk,
  [SOCKET_EVENTS.SESSSION_DEACTIVATED]: updateSessionStatusAction,
  [SOCKET_EVENTS.REQUEST_SESSION]: addNewSessionAction,
  [SOCKET_EVENTS.LOYALTY_GIVEN]: setRootNotificationMessageAction,
  [SOCKET_EVENTS.ORDER_PLACED]: setRootNotificationMessageAction,
};

export default socketSlice.reducer;
