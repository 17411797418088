import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";

const initialState = {
  googleAnalyticsID: "",
  title: "",
  description: "",
};
const getFormValidationErrors = (formData) => {
  let errors = {};
  if (formData?.googleAnalyticsID?.trim() === "") {
    errors["googleAnalyticsID"] = "Google analytics id is required";
  }
  if (formData?.googleAnalyticsID?.includes(" ")) {
    errors["googleAnalyticsID"] =
      "Google analytics id cannot contain white spaces";
  }
  if (formData?.title === "") {
    errors["title"] = "Title is required";
  }
  if (formData?.description === "") {
    errors["description"] = "Description is required";
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @returns
 */
const getSerializedFormData = (formData) => {
  return {
    googleAnalyticsID: formData.googleAnalyticsID,
    title: formData.title,
    description: formData.description,
  };
};
export const addGoogleAnalyticsSlice = createSlice({
  name: "add-google-analytics",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    replaceAnalyticsStateAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetAnalyticsStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetAnalyticsStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
  replaceAnalyticsStateAction,
} = addGoogleAnalyticsSlice.actions;

export const getFormCompatibleDataFromResponse = (data) => {
  const { googleAnalyticsID, title, description } = data;
  try {
  } catch (e) {}
  return {
    ...initialState,
    googleAnalyticsID,
    title,
    description,
  };
};

export const selectAddAnalyticsState = (state) =>
  state?.analyticsState ?? initialState;
export const selectValidationErrors = (state) =>
  state?.analyticsState?.validationErrors ?? {};
export const createGoogleAnalyticsThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(
    getState().analyticsState
  );
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentAnalyticsData = getState().analyticsState;
    let body = getSerializedFormData(currentAnalyticsData, outlets);
    url
      .put(`/v1/google-analytics/upsert`, body)
      .then((res) => {
        dispatch(setSuccessActions());
      })
      .catch((e) => {
        dispatch(setErrorAction(e));
      });
  }
};
export default addGoogleAnalyticsSlice.reducer;
