import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { getNotificationFormInitialData } from "../../pages/TypedNotifications/constants/form-initiator.constants";
import { base64FileUploadWithPredefinedName } from "../../helpers/firebaseFileUpload";

const initialState = getNotificationFormInitialData();
let targetID = null;
const getFormValidationErrors = (formData) => {
  let errors = {};
  if (formData?.title?.trim().length === 0) {
    errors["title"] = "Title must be provided";
  }
  if (formData?.title?.trim().length < 3) {
    errors["title"] = "Title must be 3 characters long";
  }
  if (formData?.highlights?.trim().length === 0) {
    errors["highlights"] = "Highlights must be provided";
  }
  if (formData?.highlights?.trim().length < 3) {
    errors["highlights"] = "Highlights must be 3 characters long";
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 *  @param {strring} targetOutlets get topic can be either others, newDeal, newCoupon
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets, topic) => {
  const allowedTopics = ["others", "newDeal", "newCoupon"];
  return {
    viaInApp: true,
    viaPush: true,
    markAsManual: true,
    title: formData.title,
    highlights: formData?.highlights,
    topic: allowedTopics.includes(topic) ? topic : "others",
    applicableOutlets: Array.isArray(targetOutlets)
      ? targetOutlets
      : [JSON.parse(localStorage.getItem("Admin"))[0]?.outletChainID],
    imageUrl: formData.imageUrl ? formData.imageUrl : undefined,
    targetID: targetID,
  };
};
export const addNotificationSubSlice = createSlice({
  name: "add-notification-sub-module",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    resetNotificationStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetNotificationStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
} = addNotificationSubSlice.actions;

export const selectAddNotificationState = (state) =>
  state?.addNotification ?? initialState;
export const selectValidationErrors = (state) =>
  state?.addNotification?.validationErrors ?? {};
export const createNotificationSubModuleThunk =
  (outlets, targetId, topic) => (dispatch, getState) => {
    targetID = targetId;
    const formValidationErrors = getFormValidationErrors(
      getState().addNotification
    );
    dispatch(
      changeSinglePropertyAction({
        propName: "validationErrors",
        propValue: formValidationErrors,
      })
    );
    if (!Object.values(formValidationErrors).length) {
      dispatch(startInProgressAction());
      const currentNotificationData = getState().addNotification;
      let body = getSerializedFormData(currentNotificationData, outlets, topic);
      if (currentNotificationData?.imageFile) {
        base64FileUploadWithPredefinedName(currentNotificationData?.imageFile)
          .then((res) => {
            if (res) {
              body = getSerializedFormData(
                { ...currentNotificationData, imageUrl: res },
                outlets,
                topic
              );
              dispatch(changeSinglePropertyAction("imageUrl", res));
            } else {
              body = getSerializedFormData(
                { ...currentNotificationData, imageUrl: undefined },
                outlets,
                topic
              );
            }

            url
              .put(
                `/v1/consumer-notifications-admin/upsert-manual-notification`,
                body
              )
              .then(() => {
                dispatch(setSuccessActions());
              })
              .catch((e) => {
                dispatch(setErrorAction(e));
              });
          })
          .catch((e) => {
            console.error(e);
            body = getSerializedFormData(
              { ...currentNotificationData, imageUrl: undefined },
              outlets,
              topic
            );
            url
              .put(
                `/v1/consumer-notifications-admin/upsert-manual-notification`,
                body
              )
              .then(() => {
                dispatch(setSuccessActions());
              })
              .catch((e) => {
                dispatch(setErrorAction(e));
              });
          });
      } else {
        url
          .put(
            `/v1/consumer-notifications-admin/upsert-manual-notification`,
            body
          )
          .then(() => {
            dispatch(setSuccessActions());
          })
          .catch((e) => {
            dispatch(setErrorAction(e));
          });
      }
    }
  };
export default addNotificationSubSlice.reducer;
