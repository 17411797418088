import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as crypt from "crypto-js";
import { useEffect } from "react";
import { Fragment } from "react";
import LoginPageContainer from "../../components/LoginPage/LoginPageContainer";
const LoginPage = () => {
  const SECRET = "0b448dea-0ea2-4d12-821c-af1559b604a3";
  const history = useHistory();
  const [rememberMeState, setRememberMeState] = useState({
    email: "",
    password: "",
    rememberMe: false,
    role: "oca",
    SECRET,
  });
  const [initialDataParsed, setInitialDataParsed] = useState(false);
  const parseInitialData = () => {
    try {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      if (adminID && Array.isArray(adminID)) {
        history.push("/dashboard");
      }

      const tempRemeberMeState = JSON.parse(
        localStorage.getItem("remeberMeState") ?? "{}"
      );

      if (tempRemeberMeState && tempRemeberMeState?.email) {
        try {
          let password = crypt.AES.decrypt(
            tempRemeberMeState.password,
            SECRET
          ).toString(crypt.enc.Utf8);
          setRememberMeState({
            ...rememberMeState,
            password,
            email: tempRemeberMeState?.email ?? "",
            role: tempRemeberMeState?.role ?? "oca",
            rememberMe: true,
          });
        } catch (e) {
          console.error(e);
        }
      }
      setInitialDataParsed(true);
    } catch (e) {}
    setInitialDataParsed(true);
  };
  useEffect(
    () => {
      parseInitialData();
    },
    //eslint-disable-next-line
    []
  );
  return (
    <Fragment>
      {initialDataParsed && (
        <LoginPageContainer rememberMeState={rememberMeState} />
      )}
    </Fragment>
  );
};

export default LoginPage;
