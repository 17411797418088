export const initialLoyaltyRedemptionPolicyState = {
  inProgress: true,
  success: false,
  error: null,
  initialDataLoaded: false,
  formValidationErrors: {},
  awardedPoints: {
    userRegistration: {
      enabled: true,
      points: 12,
    },
    firstOrder: {
      enabled: true,
      points: 12,
    },
  },
  valuationStrategy: {
    targetPoints: 10,
    targetPrice: 0,
  },
  usageRules: {
    isApplicable: true,
    rulesInfo: {
      percentageThreshold: {
        enabled: false,
        value: 0,
      },
      amountThreshold: {
        enabled: false,
        value: 0,
      },
      maximumSpendablePoints: {
        enabled: false,
        value: 0,
      },
    },
  },
  plan: "NONE",
  planInfo: {
    PER_SPENDING_BASIS: {
      targetUnitPrice: 1,
      points: 12,
    },
    SPENDING_BASIS: {
      strategies: [
        {
          purchaseAmount: 10,
          points: 2,
        },
      ],
    },
  },
};
