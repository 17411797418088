import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import { initialEachTier } from "../../pages/TypedDeals/constants/form-initiator.constants";
import {
  TypedDealsAmountTypeSelectors,
  TypedDealsBuyMinimumTypeSelectors,
} from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import {
  changeSinglePropertyAction,
  selectTieredDealInfoState,
  selectTieredTypeValidationErrors,
} from "../../redux/slices/addDealSlice";
import DropDownProductPicker from "../common/DropDownProductPicker";
import DropDownProductVariantPicker from "../common/DropDownProductVariantPicker";

const TieredDealFormComponent = ({ applyToAllOutlets }) => {
  const dispatch = useDispatch();
  const dealInfo = useSelector(selectTieredDealInfoState);
  const {
    buyMinimumType,
    issueAmountType,
    tiers,
    selectedProduct,
    chosenVariants,
  } = dealInfo;
  const formValidationErrors = useSelector(selectTieredTypeValidationErrors);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(
        changeSinglePropertyAction({
          dealType: TypedDealsTypes.TIERED,
          propName,
          propValue,
        })
      );
    }
  };
  const addTier = () => {
    handleChange("tiers", {
      ...tiers,
      [Object.values(tiers).length]: initialEachTier,
    });
  };
  const removeTier = (index) => {
    if (!Number.isNaN(index)) {
      handleChange("tiers", {
        ...Object.values(tiers).filter((item, i) => i !== index),
      });
    }
  };
  /**
   *
   * @param {number} index array index of that tier
   * @param {string} propName name of the input field
   * @param { any } propValue value of the input field
   */
  const changeTieredProp = (index, propName, propValue) => {
    if (tiers[index]) {
      handleChange("tiers", {
        ...Object.values(tiers).map((item, key) => {
          if (+key === +index) {
            return {
              ...item,
              [propName]: propValue,
            };
          }
          return item;
        }),
      });
    }
  };

  useEffect(
    () => {
      if (selectedProduct && Object.values(selectedProduct).length === 1) {
        const value = Object.values(selectedProduct)[0];
        if (value.variants.length === 1) {
          const defaultVariant = value.variants[0];
          const id = defaultVariant?.tempProductID ?? defaultVariant.productID;
          handleChange("chosenVariants", { [id]: defaultVariant });
        } else {
          handleChange("chosenVariants", {});
        }
      } else {
        handleChange("chosenVariants", {});
      }
    },
    //eslint-disable-next-line
    [selectedProduct]
  );

  useEffect(() => {
    handleChange("selectedProduct", {});
  }, [applyToAllOutlets]);

  return (
    <Fragment>
      <DealLabelWrapper>
        <label>TIERED RULES</label>
      </DealLabelWrapper>
      <FormControl>
        <LabelWrapper>Consider Product on the basis of:</LabelWrapper>
        <RadioGroup
          row
          value={buyMinimumType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          aria-labelledby="buyMinimum"
          name="buyMinimumType"
        >
          {Object.values(TypedDealsBuyMinimumTypeSelectors).map(
            (item, index) => (
              <FormControlLabel
                key={`selected-buy-minimum-type-${index}`}
                value={item.value}
                control={<Radio />}
                label={`${item.displayName} (${item.indicator})`}
              />
            )
          )}
        </RadioGroup>
      </FormControl>
      <FormControl className="mt-2">
        <LabelWrapper>Discount type:</LabelWrapper>
        <RadioGroup
          row
          value={issueAmountType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          aria-labelledby="issueAmount"
          name="issueAmountType"
        >
          {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
            <FormControlLabel
              key={`selected-buy-minimum-type-${index}`}
              value={item.value}
              control={<Radio />}
              label={`${item.displayName} (${item.indicator})`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {Object.values(tiers).length ? (
        <Fragment>
          {Object.values(tiers).map((item, index) => {
            return (
              <TierWrapper key={`selected-tier-${index}`}>
                <TierContentHolderWrapper>
                  <TieredContentSection>
                    <div className="tiered-content-input">
                      <InputPrimary
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        onChange={(e) =>
                          changeTieredProp(index, e.target.name, e.target.value)
                        }
                        name="buyMinimum"
                        value={item.buyMinimum}
                        label={"Buy at least *"}
                        type="number"
                      />
                    </div>
                    <div className="tiered-content-selector">
                      <InputPrimary
                        style={{
                          borderLeft: "none",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        disabled
                        value={
                          TypedDealsBuyMinimumTypeSelectors[buyMinimumType]
                            .indicator
                        }
                        blankLabel={true}
                        type="text"
                      />
                    </div>
                  </TieredContentSection>

                  <TieredContentSection>
                    <div className="tiered-content-input">
                      <InputPrimary
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        name="getOffNumericAmount"
                        value={item.getOffNumericAmount}
                        onChange={(e) =>
                          changeTieredProp(index, e.target.name, e.target.value)
                        }
                        label={"For *"}
                        type="number"
                      />
                    </div>
                    <div className="tiered-content-selector">
                      <InputPrimary
                        style={{
                          borderLeft: "none",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        disabled
                        value={`${TypedDealsAmountTypeSelectors[issueAmountType].indicator} Off`}
                        blankLabel={true}
                        type="text"
                      />
                    </div>
                  </TieredContentSection>
                </TierContentHolderWrapper>
                <div className="col-md-1 filters-bar m-1">
                  <button
                    onClick={() => {
                      removeTier(index);
                    }}
                    className="btn btn-primary border-0"
                    style={{
                      color: "#FFA382",
                      backgroundColor: "white",
                    }}
                  >
                    <i className="fas fa-minus-circle"></i>
                    Remove
                  </button>
                </div>
              </TierWrapper>
            );
          })}
        </Fragment>
      ) : (
        <div
          className="mt-2 mb-4 p-3 rounded"
          style={{
            backgroundColor: "#ddebeb",
          }}
        >
          No tiers have been added yet!
        </div>
      )}
      <span className="validation-help">
        {formValidationErrors?.tiersError
          ? formValidationErrors.tiersError
          : ""}
      </span>
      <div className="mt-2 mb-4">
        <button
          onClick={() => addTier()}
          className="btn btn-primary border-0"
          style={{
            backgroundColor: "#FFA382",
            color: "white",
          }}
        >
          <i className="fas fa-plus-circle"></i>
          Add Tier*
        </button>
      </div>

      {/* get product */}
      <ChooserWrapper style={{ zIndex: 1100 }}>
        <div className="col-md-6">
          <DropDownProductPicker
            isSingleSelect={true}
            label="Pick Product*"
            inputLabel={"Select Product"}
            selected={selectedProduct}
            setSelected={(value) => handleChange("selectedProduct", value)}
            referLocalInventory={applyToAllOutlets ? false : true}
          />
          <span className="validation-help">
            {formValidationErrors?.selectedProduct
              ? formValidationErrors.selectedProduct
              : ""}
          </span>
        </div>
        {selectedProduct && Object.values(selectedProduct).length > 0 ? (
          <div className="col-md-6">
            {Object.values(selectedProduct)[0]?.variants?.length > 1 ? (
              <Fragment>
                <DropDownProductVariantPicker
                  product={Object.values(selectedProduct)[0]}
                  inputLabel={"Select Variants"}
                  label="Variants"
                  selected={chosenVariants}
                  setSelected={(value) => handleChange("chosenVariants", value)}
                />
                <span className="validation-help">
                  {formValidationErrors?.chosenVariants
                    ? formValidationErrors.chosenVariants
                    : ""}
                </span>
              </Fragment>
            ) : null}
          </div>
        ) : null}
      </ChooserWrapper>
    </Fragment>
  );
};
export default TieredDealFormComponent;

const DealLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;
const LabelWrapper = styled.label`
  color: #747474;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  display: block;
  text-transform: capitalize;
`;

const TierWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 2em;
`;

const TierContentHolderWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 2%;
`;
const TieredContentSection = styled.div`
  display: flex;
  width: 48%;
  flex-direction: row;

  .tiered-content-input {
    width: 70%;
  }
  .tiered-content-selector {
    width: 30%;
  }
`;
const ChooserWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 3em;
  position: relative;
  transform: translateX(-14px);
`;
