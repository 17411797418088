import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

const BasicSelectorChip = ({
  onChange,
  label,
  disabled = false,
  selected,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Container
      theme={theme}
      disabled={disabled}
      selected={selected}
      onClick={() => {
        if (onChange instanceof Function) {
          //TO DO --> no payload currently
          onChange();
        }
      }}>
      {label}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14%;
  border: 1px solid #e2e8f0;
  font-weight: bold;
  color: ${(props) => (props.selected ? "white" : "black")};
  cursor: ${(props) => (!!props.disabled ? "pointer" : "pointer")};
  background: ${(props) => (props.selected ? "#18253c" : "white")};
  font-size: 0.938rem;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
`;
export default BasicSelectorChip;
