import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import CountryCodes from "../../countryCodes.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function PhoneNumberInputAnik({
  phone,
  setPhone,
  disabled,
  label,
  ...props
}) {
  const [countryCode, setCountryCode] = useState(CountryCodes[38]?.dial_code);
  const [localPhone, setLocalPhone] = useState("");
  function extractPhoneAndCountryCode() {
    //(+880)1122344555
    let value = String(phone).trim();
    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(value);
    if (matches && matches[1]?.length) {
      setCountryCode(matches[1]);
      setLocalPhone(value.substring(matches[0].length));
    } else {
      setLocalPhone(value);
    }
  }
  useEffect(() => {
    extractPhoneAndCountryCode();
  }, [phone]);
  const handleChangeEvent = (e) => {
    switch (e.target.name) {
      case "countryCode":
        setPhone(`(${e.target.value})${localPhone}`);
        break;
      case "localPhone":
        setPhone(`(${countryCode})${String(e.target.value).substring(0, 12)}`);
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <label>{label ?? "Phone"}</label>
      <div className="input-container">
        <div className="country_code_select">
          <select
            disabled={disabled}
            value={countryCode}
            name="countryCode"
            onChange={handleChangeEvent}>
            {CountryCodes.map((item, index) => (
              <option
                value={item.dial_code}
                key={`${item?.dial_code}-${index}`}>
                {item.dial_code}
              </option>
            ))}
          </select>
          <ArrowDropDownIcon />
        </div>
        <div className="v-divider"></div>
        <input
          type="text"
          value={localPhone}
          name="localPhone"
          onChange={handleChangeEvent}
          disabled={disabled}
          {...props}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .input-container {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    /* align-items: center; */
    .country_code_select {
      position: relative;
      width: 110px;
      flex-shrink: 0;
      select {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: none;
        outline: none;
        cursor: pointer;
        appearance: none;
        padding-left: 16px;
      }
      svg {
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
    button {
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: white;
      color: #747474;
      cursor: pointer;
      span {
        color: #ffa382;
      }
    }
    .v-divider {
      width: 1px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        height: 70%;
        width: 100%;
        transform: translateY(-50%);
        background-color: #dbdbdb;
      }
    }
    input {
      width: 1px;
      flex: 1;
      border: none;
      outline: none;
      padding: 7px 15px;
      box-sizing: border-box;
    }
  }
`;
