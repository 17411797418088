import { useEffect } from "react";
import { ArrowDownward, Close } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SessionLoaderComponent from "./SessionLoader";
import {
  clearSearchTermsAction,
  selectSessionSearchTerm,
  setSessionSearchTermAction,
  selectSessionSearchMeta,
  getSessionSearchResultsThunk,
  selectMessageSessionSearches,
  selectPreviousSessionSearchTerm,
} from "../../redux/slices/sessionSearchSlice";
import EachSearchSessionResultCard from "./EachSesssionSearchResultCard";
import { addNewSessionAction } from "../../redux/slices/messageSessionSlice";
import { setActiveSessionAction } from "../../redux/slices/chatSessionSlice";

const SessionSearchModalComponent = ({ onClose }) => {
  const searchTerm = useSelector(selectSessionSearchTerm);
  const { inProgress, hasMore, error } = useSelector(selectSessionSearchMeta);
  const previousSearchTerm = useSelector(selectPreviousSessionSearchTerm);
  const sessions = useSelector(selectMessageSessionSearches);

  const handleChange = (value) => {
    if (value?.length === 0 && previousSearchTerm.length) {
      dispatch(clearSearchTermsAction());
    }
    dispatch(setSessionSearchTermAction(value));
  };
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(clearSearchTermsAction());
    },
    //eslint-disable-next-line
    []
  );
  const performSearchOnEnter = () => {
    dispatch(getSessionSearchResultsThunk());
  };
  const handleClick = (data) => {
    dispatch(setActiveSessionAction(data));
    if (data?.isActive) {
      dispatch(addNewSessionAction({ session: data }));
    }
    onClose();
  };
  return (
    <Fragment>
      <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            height: "80vh",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em 1.5em",
              height: "auto",
              // backgroundColor: "green",
              alignItems: "center",
            }}>
            <h3>Search Sessions</h3>
            <Close
              style={{ color: "black", cursor: "pointer" }}
              onClick={onClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              overflow: "auto",
              flexDirection: "column",
            }}>
            <div
              className="input-group"
              style={{
                width: "50%",
                marginLeft: "1em",
              }}>
              <input
                type="text"
                className="form-control table-search"
                placeholder="Search by user's name or email"
                aria-label="Input group example"
                aria-describedby="btnGroupAddon"
                value={searchTerm}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    performSearchOnEnter();
                  }
                }}
                onChange={(e) => handleChange(e.target.value)}
              />
              {searchTerm?.length ? (
                <div className="input-group-prepend">
                  <div
                    className="input-group-text"
                    onClick={() => dispatch(clearSearchTermsAction())}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "none",
                      paddingLeft: 0,
                      cursor: "pointer",
                    }}>
                    <i className="fa fa-times"></i>
                  </div>
                </div>
              ) : null}
              <div
                className="input-group-prepend"
                style={{
                  cursor: "pointer",
                }}>
                <div
                  className="input-group-text table-search-icon"
                  id="btnGroupAddon"
                  onClick={performSearchOnEnter}>
                  <i className="fas fa-search"></i>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflow: "auto",
                alignItems: "center",
                padding: "1em 0.5em",
              }}>
              {Object.keys(sessions).map((obj, i) => {
                return (
                  <EachSearchSessionResultCard
                    key={i}
                    _id={obj}
                    data={sessions[obj]}
                    boxShadow={(i + 1) % 2 === 0}
                    parentCloser={onClose}
                    clicker={() => handleClick(sessions[obj])}
                  />
                );
              })}
              {previousSearchTerm.trim().length === 0 ? (
                "Search results will appear here"
              ) : (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      width: "90%",
                      justifyContent: "flex-start",
                      alignItems: "left",
                      flexDirection: "column",
                    }}>
                    {inProgress && <SessionLoaderComponent />}
                  </div>
                </Fragment>
              )}
              {!inProgress &&
                !!previousSearchTerm?.length &&
                Object.keys(sessions)?.length === 0 &&
                "No session found"}
              {!inProgress && !error && hasMore && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                    }}
                    endIcon={<ArrowDownward />}
                    onClick={() => performSearchOnEnter()}>
                    See more
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default SessionSearchModalComponent;
