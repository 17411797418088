import styled from "@emotion/styled";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import SelectPrimary from "../../atoms/SelectPrimary";
import { TypedDealsAmountTypeSelectors } from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import {
  changeSinglePropertyAction,
  selectBogoDealInfoState,
  selectBogoTypeValidationErrors,
} from "../../redux/slices/addDealSlice";
import DropDownProductPicker from "../common/DropDownProductPicker";
import DropDownProductVariantPicker from "../common/DropDownProductVariantPicker";

const BogoDealFormComponent = ({ applyToAllOutlets }) => {
  const dispatch = useDispatch();
  const dealInfo = useSelector(selectBogoDealInfoState);
  const formValidationErrors = useSelector(selectBogoTypeValidationErrors);
  const {
    buyProductExactQuantity,
    selectedBuyProduct,
    buyProductVariants,
    selectedGetProduct,
    getProductExactQuantity,
    getProductVariant,
    getOffNumeric,
    getOffType,
  } = dealInfo;
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(
        changeSinglePropertyAction({
          dealType: TypedDealsTypes.BOGO,
          propName,
          propValue,
        })
      );
    }
  };

  useEffect(
    () => {
      if (
        selectedBuyProduct &&
        Object.values(selectedBuyProduct).length === 1
      ) {
        const value = Object.values(selectedBuyProduct)[0];
        if (value.variants.length === 1) {
          const defaultVariant = value.variants[0];
          const id = defaultVariant?.tempProductID ?? defaultVariant.productID;
          handleChange("buyProductVariants", { [id]: defaultVariant });
        } else {
          handleChange("buyProductVariants", {});
        }
      } else {
        handleChange("buyProductVariants", {});
      }
    },
    //eslint-disable-next-line
    [selectedBuyProduct]
  );

  useEffect(
    () => {
      if (
        selectedGetProduct &&
        Object.values(selectedGetProduct).length === 1
      ) {
        const value = Object.values(selectedGetProduct)[0];
        if (value.variants.length === 1) {
          const defaultVariant = value.variants[0];
          const id = defaultVariant?.tempProductID ?? defaultVariant.productID;
          handleChange("getProductVariant", { [id]: defaultVariant });
        } else {
          handleChange("getProductVariant", {});
        }
      } else {
        handleChange("getProductVariant", {});
      }
    },
    //eslint-disable-next-line
    [selectedGetProduct]
  );

  useEffect(() => {
    handleChange("selectedBuyProduct", {});
  }, [applyToAllOutlets]);

  return (
    <Fragment>
      <DealLabelWrapper>
        <label>BOGO RULES</label>
      </DealLabelWrapper>
      {/* buy product */}
      <ChooserWrapper style={{ zIndex: 1200 }}>
        <div className="col-md-4">
          <InputPrimary
            value={buyProductExactQuantity}
            label={"Buy *"}
            type="number"
            name="buyProductExactQuantity"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <span className="validation-help">
            {formValidationErrors?.buyProductExactQuantity
              ? formValidationErrors.buyProductExactQuantity
              : ""}
          </span>
        </div>
        <div className="col-md-4">
          <DropDownProductPicker
            isSingleSelect={true}
            blankLabel={true}
            inputLabel={"Select Product"}
            selected={selectedBuyProduct}
            setSelected={(value) => handleChange("selectedBuyProduct", value)}
            referLocalInventory={applyToAllOutlets ? false : true}
          />
          <span className="validation-help">
            {formValidationErrors?.selectedBuyProduct
              ? formValidationErrors.selectedBuyProduct
              : ""}
          </span>
        </div>
        {selectedBuyProduct && Object.values(selectedBuyProduct).length > 0 ? (
          <div className="col-md-4">
            {Object.values(selectedBuyProduct)[0]?.variants?.length > 1 ? (
              <Fragment>
                <DropDownProductVariantPicker
                  blankLabel={true}
                  product={Object.values(selectedBuyProduct)[0]}
                  inputLabel={"Select Variants"}
                  selected={buyProductVariants}
                  setSelected={(value) =>
                    handleChange("buyProductVariants", value)
                  }
                />
                <span className="validation-help">
                  {formValidationErrors?.buyProductVariants
                    ? formValidationErrors.buyProductVariants
                    : ""}
                </span>
              </Fragment>
            ) : null}
          </div>
        ) : null}
      </ChooserWrapper>

      {/* get product */}
      <ChooserWrapper style={{ zIndex: 1100 }}>
        <div className="col-md-4">
          <InputPrimary
            value={getProductExactQuantity}
            label={"Get *"}
            type="number"
            name="getProductExactQuantity"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <span className="validation-help">
            {formValidationErrors?.getProductExactQuantity
              ? formValidationErrors.getProductExactQuantity
              : ""}
          </span>
        </div>
        <div className="col-md-4">
          <DropDownProductPicker
            isSingleSelect={true}
            blankLabel={true}
            inputLabel={"Select Product"}
            selected={selectedGetProduct}
            setSelected={(value) => handleChange("selectedGetProduct", value)}
          />
          <span className="validation-help">
            {formValidationErrors?.selectedGetProduct
              ? formValidationErrors.selectedGetProduct
              : ""}
          </span>
        </div>
        {selectedGetProduct && Object.values(selectedGetProduct).length > 0 ? (
          <div className="col-md-4">
            {Object.values(selectedGetProduct)[0]?.variants?.length > 1 ? (
              <Fragment>
                <DropDownProductVariantPicker
                  blankLabel={true}
                  isSingleSelect={true}
                  product={Object.values(selectedGetProduct)[0]}
                  inputLabel={"Select Variant"}
                  selected={getProductVariant}
                  setSelected={(value) =>
                    handleChange("getProductVariant", value)
                  }
                />
                <span className="validation-help">
                  {formValidationErrors?.getProductVariant
                    ? formValidationErrors.getProductVariant
                    : ""}
                </span>
              </Fragment>
            ) : null}
          </div>
        ) : null}
      </ChooserWrapper>
      {/* discount */}
      <ChooserWrapper style={{ zIndex: 999 }}>
        <div className="col-md-4">
          <InputPrimary
            value={getOffNumeric}
            label={"For *"}
            type="number"
            name="getOffNumeric"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <span className="validation-help">
            {formValidationErrors?.getOffNumeric
              ? formValidationErrors.getOffNumeric
              : ""}
          </span>
        </div>
        <div className="col-md-4">
          <SelectPrimary
            blankLabel={true}
            value={getOffType}
            name="getOffType"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            {Object.values(TypedDealsAmountTypeSelectors).map((item, index) => (
              <option
                value={item.value}
                key={`selected-deal-amount-type-${index}`}
              >
                {`${item.indicator} Off`}
              </option>
            ))}
          </SelectPrimary>
        </div>
      </ChooserWrapper>
    </Fragment>
  );
};
export default BogoDealFormComponent;

const DealLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;

const ChooserWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1.5em;
  position: relative;
  transform: translateX(-14px);
`;
