import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import * as crypt from "crypto-js";

const initialState = {
  isMultiChainAdmin: false,
  info: null,
  switchingProgress: false,
  myOutlets: [],
  error: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRevenueStats: (state, action) => {
      const Stats = action.payload;
      return {
        ...state,
        Revenuestats: Stats,
      };
    },
    setOrderStats: (state, action) => {
      const Stats = action.payload;
      return {
        ...state,
        Orderstats: Stats,
      };
    },
    startSwitchingProgressAction: (state) => {
      state.switchingProgress = true;
      state.error = null;
      state.isAuthenticated = false;
    },
    resetAuthStateAction: () => initialState,
    finishSwitchingWithErrorAction: (state, action) => {
      state.switchingProgress = false;
      state.error = action?.payload ?? "Something went wrong";
    },
    abortProgressAction: (state) => {
      state.switchingProgress = false;
    },
    //for multichain admin
    changeAuthStateAction: (state, action) => {
      state.isMultiChainAdmin = true;
      state.info = action.payload?.info ?? state.info;
      state.myOutlets = Array.isArray(action.payload?.myOutlets)
        ? action.payload.myOutlets
        : state.myOutlets;
      state.switchingProgress = false;
      state.error = null;
      state.isAuthenticated = true;
      window.location?.reload();
      window?.location?.replace("/dashboard");
    },
    //temp solution for sole chain admin login
    markAsAuthenticatedAction: (state) => {
      state.switchingProgress = false;
      state.error = null;
      state.isAuthenticated = true;
      window.location?.reload();
      window?.location?.replace("/dashboard");
    },
    removeErrorMessageFromAuthStateAction: (state) => {
      state.error = null;
    },
  },
});

export const {
  changeAuthStateAction,
  startSwitchingProgressAction,
  finishSwitchingWithErrorAction,
  resetAuthStateAction,
  removeErrorMessageFromAuthStateAction,
  abortProgressAction,
  markAsAuthenticatedAction,
  setOrderStats,
  setRevenueStats,
} = authSlice.actions;

export const loginSoleChainAdminThunk = (params) => (dispatch) => {
  dispatch(startSwitchingProgressAction());
  url
    .post(`/oca/login`, {
      email: params?.email,
      password: params?.password,
    })
    .then(async (response) => {
      if (response.data.Message === "Success") {
        const resData = response?.data?.data ?? {};
        await localStorage.setItem("Admin", JSON.stringify(resData));
        await localStorage.setItem("token", response?.data?.token);
        try {
          if (params.rememberMeState) {
            await localStorage.setItem(
              "remeberMeState",
              JSON.stringify({
                email: params.email,
                password: crypt.AES.encrypt(
                  params.password,
                  params.SECRET
                ).toString(),
                role: "oca",
              })
            );
          } else {
            await localStorage.removeItem("remeberMeState");
          }
        } catch (e) {
          //to do
        }
        dispatch(markAsAuthenticatedAction());
      } else {
        dispatch(finishSwitchingWithErrorAction("Incorrect email or password"));
      }
    })
    .catch((e) => {
      console.error(e);
      dispatch(finishSwitchingWithErrorAction("Unable to login"));
    });
};

export const loginMultiChainAdminThunk = (params) => (dispatch) => {
  dispatch(startSwitchingProgressAction());
  url
    .post(`v1/mca/login`, {
      email: params?.email,
      password: params?.password,
    })
    .then(async (response) => {
      const resData = response?.data?.data ?? {};
      await localStorage.setItem(
        "Admin",
        JSON.stringify([resData?.myOutlets[0]])
      );
      await localStorage.setItem("token", response?.data?.token);
      try {
        if (params.rememberMeState) {
          await localStorage.setItem(
            "remeberMeState",
            JSON.stringify({
              email: params.email,
              password: crypt.AES.encrypt(
                params.password,
                params.SECRET
              ).toString(),
              role: "mca",
            })
          );
        } else {
          await localStorage.removeItem("remeberMeState");
        }
      } catch (e) {
        //to do
      }
      dispatch(
        changeAuthStateAction({
          switchingProgress: false,
          myOutlets: resData?.myOutlets,
          info: resData?.info,
          isMultiChainAdmin: true,
        })
      );
    })
    .catch((e) => {
      dispatch(
        finishSwitchingWithErrorAction(
          +e?.response?.status === 401
            ? "Session expired.Try to logout and login again"
            : e.response?.data?.data?.message ?? "Something went wrong"
        )
      );
    });
};
export const switchOutletThunk = (outletChainID) => (dispatch) => {
  dispatch(startSwitchingProgressAction());
  url
    .put(
      `v1/mca/switch`,
      {},
      {
        params: {
          outletChainID,
        },
      }
    )
    .then(async (response) => {
      const resData = response?.data?.data ?? {};
      await localStorage.setItem("Admin", JSON.stringify([resData?.myOutlet]));
      await localStorage.setItem("token", response?.data?.token);
      dispatch(changeAuthStateAction());
    })
    .catch((e) => {
      // console.error("Status", e?.response?.status);
      dispatch(
        finishSwitchingWithErrorAction(
          +e?.response?.status === 401
            ? "Session expired.Try to logout and login again"
            : e.response?.data?.data?.message ?? "Something went wrong"
        )
      );
    });
};
export const selectIfUserIsAuthenticated = (state) =>
  state.auth.isAuthenticated;
export const selectAuthStateErrorMessage = (state) => state.auth.error;
export const selectMyOutlets = (state) => state.auth.myOutlets;
export const selectIfSwitchingProgress = (state) =>
  state.auth.switchingProgress;
export const selectifMultiChainAdmin = (state) => state.auth.isMultiChainAdmin;

export const fetchOrderStatsThunk = (strategy, fromDays) => (dispatch) => {
  url
    .get(
      `/v1/sold-products-stats/day-basis-ranks?strategy=${strategy}&fromDays=${fromDays}`
    )
    .then((res) => {
      if (res) {
        dispatch(setOrderStats(res?.data));
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const fetchRevenueStatsThunk = (strategy, fromDays) => (dispatch) => {
  url
    .get(
      `/v1/sold-products-stats/day-basis-ranks?strategy=${strategy}&fromDays=${fromDays}`
    )
    .then((res) => {
      if (res) {
        dispatch(setRevenueStats(res?.data));
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export default authSlice.reducer;
