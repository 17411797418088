/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import AddTypedDealForm from "../../../components/AddTypedDeal/add-typed-deal-form";
import {
  createDealThunk,
  resetDealStateAction,
  resetOpStateAction,
  selectAddDealState,
} from "../../../redux/slices/addDealSlice";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";

export default function AddTypedDealPage() {
  const outlets = useSelector(selectMyOutlets);
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectAddDealState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const dealInfo = useSelector(selectAddDealState);
  const { inProgress, error, success } = dealInfo;
  useEffect(() => {
    dispatch(resetDealStateAction());
  }, []);
  return (
    <div>
      <div className="row" style={{ borderRadius: "13px" }}>
        <div className="col-md-12">
          <div className="card" id="nopad1">
            <div className="col-md-12">
              <form className="row" onSubmit={(e) => e.preventDefault()}>
                <div className="col-md-12">
                  <div
                    className=" d-flex "
                    style={{
                      borderBottom: "2px solid #F1F5F9",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-2px",
                        backgroundColor: "#FFA382",
                        marginTop: "10px ",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    {/* <div className="d-flex justify-content-between w-75 border"> */}
                    <div className="col-md-3 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "18px",
                          marginTop: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Add New Deal
                      </div>
                    </div>
                    <div className="col-md-7"> </div>
                    <div
                      className="col-md-2 filters-bar d-flex"
                      style={{ justifyContent: "end" }}
                    >
                      <button
                        className="btn btn-primary border-0 "
                        style={{
                          backgroundColor: "#FFA382",
                          marginBottom: "5px",
                          marginLeft: "90px",
                        }}
                        onClick={() => {
                          history.push("/typed-deals");
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                        Back
                      </button>
                    </div>
                    {/* </div> */}
                    <br></br>
                  </div>
                </div>
                <AddTypedDealForm />
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            <button
              type="button"
              onClick={() => {
                dispatch(
                  createDealThunk(
                    isMultiChainAdmin && applyToAllOutlets
                      ? [
                          ...outlets
                            .map((key) => key?.outletChainID)
                            .filter((el) => !!el),
                          JSON.parse(localStorage.getItem("Admin"))[0]
                            ?.outletChainID,
                        ]
                      : [
                          JSON.parse(localStorage.getItem("Admin"))[0]
                            ?.outletChainID,
                        ]
                  )
                );
              }}
              disabled={inProgress}
              style={{ backgroundColor: "#FFA382" }}
              className="btn  btn-cannaby"
            >
              {!inProgress ? "Create Deal" : "Creating Deal..."}
            </button>
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Deal added successfully"}
          onClose={() => {
            dispatch(resetOpStateAction());
            history.push("/typed-deals");
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
    </div>
  );
}
