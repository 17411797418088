import { addDays } from "date-fns";
import { TypedCouponsDays } from "./typed-coupons.constants";

export const getCouponFormInitialData = () => ({
  _id: null,
  inProgress: false,
  error: null,
  success: false,
  couponType: "REGULAR",
  couponCode: "",
  discountAmount: 30,
  imageFile: null,
  applicableDays: Object.values(TypedCouponsDays).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  ),
  startsOn: new Date(),
  endsOn: addDays(new Date(), 7),
  status: true,
  isRuleApplicable: true,
  neverExpires: true,
  minimumOrderValue: 10,
  totalUsageLimit: 200,
  usageLimitPerCustomer: 10,
  canBeOverridden: true,
  applyToAllOutlets: false,
  images: [],
  validationErrors: {},
  hasDependenciesOnOtherOutlets: false,
});
