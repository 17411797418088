import React from "react";
import moment from "moment/moment";

export default function NotificationsTable({
  notifications,
  setNotificationToDelete,
  setNotificationData,
  setOpenEdit,
  blank,
}) {
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Message</th>
            <th scope="col">Sent At</th>
            <th></th>
            <th scope="col" style={{ textAlign: "center" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((item, i) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid rgb(241, 245, 249)",
                }}
                key={i}>
                <td style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <img
                      img
                      alt="yo1"
                      src={
                        item?.notificationInfo?.imageUrl === "" ||
                        item?.notificationInfo?.imageUrl === undefined
                          ? blank
                          : item?.notificationInfo?.imageUrl
                      }
                      className="img-fluid"
                      width="40"
                      style={{
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                    {item?.notificationInfo?.title}
                  </div>
                </td>
                <td>
                  <div>{item?.notificationInfo?.highlights}</div>
                </td>
                <td>
                  <div>
                    {item?.isVisible === false
                      ? "Scheduled"
                      : moment(item?.updatedAt).format("MMMM Do YYYY, HH:MM a")}
                  </div>
                </td>

                <td></td>

                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1em",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <i
                      onClick={() => {
                        // history.push(
                        //   `/edit-typed-notification?_id=${item._id}`
                        // );
                        setOpenEdit(true);
                        setNotificationData(item);
                      }}
                      style={{
                        color: "#FFA382",
                        cursor: "pointer",
                      }}
                      className="fas fa-pen"></i>
                    <i
                      onClick={() => setNotificationToDelete(item)}
                      style={{ color: "red", cursor: "pointer" }}
                      className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
