/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import url from "../../../config/axios";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";
import {
  editNotificationThunk,
  getFormCompatibleDataFromResponse,
  replaceNotificationStateAction,
  resetOpStateAction,
  selectEditNotificationState,
} from "../../../redux/slices/editNotificationSlice";
import EditTypedNotificaitonForm from "../../../components/EditTypedNotification/edit-typed-notification-form";
import { Dialog } from "@mui/material";
export default function EditTypedNotificationPage({
  handleEditClose,
  openEdit,
  notificationData,
  onDone,
}) {
  const outlets = useSelector(selectMyOutlets);
  const [notificationState, setNotificationState] = useState({
    loading: true,
    notification: null,
    error: null,
  });
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectEditNotificationState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationInfo = useSelector(selectEditNotificationState);
  const { inProgress, error, success } = notificationInfo;
  useEffect(() => {
    const notificationID = notificationData._id;
    url
      .get(`/v1/consumer-notifications-admin/get-all-notifications-aggregated`)
      .then((res) => {
        const notification = res.data?.data?.notifications.filter((x) => {
          let targetID = notificationID;
          return x._id === targetID;
        });
        let notificationData = notification[0];
        setNotificationState({ notificationData, error: null, loading: false });
        try {
          dispatch(
            replaceNotificationStateAction(
              getFormCompatibleDataFromResponse(notification[0])
            )
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {
        setNotificationState({
          notification: null,
          error: "Unable to populate the notification",
          loading: false,
        });
      });
  }, []);
  return (
    <Dialog
      maxWidth="md"
      open={openEdit}
      onClose={handleEditClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div>
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <div className="col-md-12">
            <div>
              <div className="col-md-12">
                <form className="row" onSubmit={(e) => e.preventDefault()}>
                  <div className="col-md-12">
                    <div
                      className="row d-flex "
                      style={{
                        borderBottom: "2px solid #F1F5F9",
                        paddingBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "25px",
                          marginLeft: "-2px",
                          backgroundColor: "#FFA382",
                          marginTop: "10px ",
                        }}
                      >
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      {/* <div className="d-flex justify-content-between w-75 border"> */}
                      <div className="col-md-3 d-flex">
                        <div
                          style={{
                            color: "#FFA382",
                            fontSize: "18px",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Edit Notification
                        </div>
                      </div>
                      <div className="col-md-7"> </div>
                      <div className="col-md-1 filters-bar d-flex">
                        <button
                          className="btn btn-primary border-0 "
                          style={{
                            backgroundColor: "#FFA382",
                            marginBottom: "5px",
                            marginLeft: "90px",
                          }}
                          onClick={handleEditClose}
                        >
                          <i className="fas fa-arrow-left"></i>
                          Back
                        </button>
                      </div>
                      {/* </div> */}
                      <br></br>
                    </div>
                  </div>
                  {
                    <EditTypedNotificaitonForm
                      notificationData={notificationState.notificationData}
                    />
                  }
                </form>
                <div className="d-flex justify-content-end border-0">
                  {notificationState?.loading ? null : (
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(
                          editNotificationThunk(
                            isMultiChainAdmin && applyToAllOutlets
                              ? [
                                  ...outlets
                                    .map((key) => key?.outletChainID)
                                    .filter((el) => !!el),
                                  JSON.parse(localStorage.getItem("Admin"))[0]
                                    ?.outletChainID,
                                ]
                              : [
                                  JSON.parse(localStorage.getItem("Admin"))[0]
                                    ?.outletChainID,
                                ]
                          )
                        );

                        if (onDone instanceof Function) {
                          onDone();
                        }
                      }}
                      disabled={inProgress}
                      style={{ backgroundColor: "#FFA382" }}
                      className="btn  btn-cannaby"
                    >
                      {!inProgress
                        ? "Update Notification"
                        : "Updating Notification..."}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {success ? (
          <SimpleModal
            severity={"success"}
            highlights={"Notification updated successfully"}
            onClose={() => {
              dispatch(resetOpStateAction());
              history.push("/typed-notifications");
            }}
            closeModal={handleEditClose}
          />
        ) : null}
        {error ? (
          <SimpleModal
            severity={"error"}
            highlights={error}
            onClose={() => {
              dispatch(resetOpStateAction());
            }}
          />
        ) : null}
        {notificationState.error ? (
          <SimpleModal
            severity={"error"}
            highlights={notificationState.error}
            onClose={() => {
              history.push("/typed-notifications");
            }}
          />
        ) : null}
      </div>
    </Dialog>
  );
}
