import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../../../atoms/InputPrimary/index.js";
import {
  changeSinglePropertyAction,
  selectLoyaltyFormValidationErrors,
  selectLoyaltyRedemptionPolicyState,
} from "../../../../redux/slices/loyaltyRedemptionSlice.js";
import { initialPurchasedTypes } from "../constants/purchased-rules-constants.js";
import DualArrow from "../../../../assets/dual-arrow.svg";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants.js";
import { calculatePointsWorth } from "../utils/calculate-amount-representation.js";
function RunningTotalTiers() {
  const dispatch = useDispatch();
  const {
    planInfo: {
      SPENDING_BASIS: { strategies: persistedStrategies },
    },
    valuationStrategy: { targetPoints, targetPrice },
  } =
    useSelector(selectLoyaltyRedemptionPolicyState) ??
    initialLoyaltyRedemptionPolicyState;
  const [parsedStrategies, setParsedStrategies] = useState({});
  const [initialLoaded, setInitialLoaded] = useState(false);
  const formValidationErrors = useSelector(selectLoyaltyFormValidationErrors);
  useEffect(
    () => {
      if (initialLoaded) {
        dispatch(
          changeSinglePropertyAction({
            propName: "planInfo",
            propValue: {
              PER_SPENDING_BASIS: {
                targetUnitPrice: 1,
                points: 12,
              },
              SPENDING_BASIS: {
                strategies: Object.values(parsedStrategies),
              },
            },
          })
        );
      }
    },
    //eslint-disable-next-line
    [parsedStrategies, initialLoaded]
  );
  useEffect(
    () => {
      if (!initialLoaded) {
        setParsedStrategies({
          ...persistedStrategies,
        });
        setInitialLoaded(true);
      }
    },
    //eslint-disable-next-line
    [persistedStrategies, initialLoaded]
  );
  const handleAddChargeSlot = () => {
    setParsedStrategies({
      ...parsedStrategies,
      [Object.keys(parsedStrategies)?.length ?? 0]: {
        ...initialPurchasedTypes,
      },
    });
  };
  const handleRemoveChargeSlot = (index) => {
    setParsedStrategies({
      ...Object.assign(
        {},
        Object.values(parsedStrategies).filter((_, i) => i !== index)
      ),
    });
  };
  /**
   *
   * @param {number} index index of the charge item
   * @param {string} name name of the charge property
   * @param {any} value value of the charge property
   */
  const handleChargeChange = (index, name, value) => {
    if (
      parsedStrategies[index] &&
      parsedStrategies[index].hasOwnProperty(name)
    ) {
      setParsedStrategies({
        ...Object.assign(
          {},
          Object.values(parsedStrategies).map((obj, i) =>
            i !== index ? obj : { ...obj, [name]: value }
          )
        ),
      });
    }
  };
  return (
    <Fragment>
      <div className="row d-flex-column">
        {Object.values(parsedStrategies)?.length ? (
          <Fragment>
            {Object.values(parsedStrategies).map((obj, index) => (
              <Fragment key={`each-charge-${index}`}>
                <div className="col-md-12 d-flex my-2">
                  <div style={{ width: "49.5%" }}>
                    <div className="group-comb">
                      <InputPrimary
                        label="Running Total"
                        name="purchaseAmount"
                        type="number"
                        className="form-control"
                        placeholder="Running Total"
                        onChange={(e) => {
                          handleChargeChange(
                            index,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        value={obj.purchaseAmount}
                      ></InputPrimary>
                      <span className="validation-help">{/* TO DO */}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "2.5%",
                      marginTop: "2em",
                      textAlign: "center",
                    }}
                  >
                    <img src={DualArrow} alt="dual-arrow-icon" />
                  </div>
                  <div style={{ width: "49.5%" }}>
                    <div className="group-comb">
                      <InputPrimary
                        label={`Points To Be Awarded ($${calculatePointsWorth(
                          targetPrice,
                          targetPoints,
                          obj.points
                        )})`}
                        name="points"
                        type="number"
                        className="form-control"
                        placeholder="Points To Be Awarded"
                        onChange={(e) => {
                          const parsedNumber = Number(e.target.value);
                          if (
                            !Number.isNaN(parsedNumber) &&
                            parsedNumber >= 0
                          ) {
                            handleChargeChange(
                              index,
                              e.target.name,
                              parseInt(Math.abs(e.target.value))
                            );
                          }
                        }}
                        value={obj.points}
                      ></InputPrimary>
                    </div>
                    <span className="validation-help">{/* TO DO */}</span>
                  </div>
                </div>
                <div
                  className="col-md-12"
                  style={{
                    textAlign: "right",
                  }}
                >
                  <button
                    onClick={() => handleRemoveChargeSlot(index)}
                    className="btn btn-primary border-0"
                    style={{
                      color: "#FF6D6D",
                      backgroundColor: "white",
                      fontSize: "26px",
                    }}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>
                </div>
              </Fragment>
            ))}
          </Fragment>
        ) : (
          <span
            className="m-2 p-3 rounded"
            style={{
              backgroundColor: "#ddebeb",
            }}
          >
            No scenarios have been added yet!
          </span>
        )}
        <div className="col-md-12">
          <span className="validation-help">
            {formValidationErrors?.spendingError ?? ""}
          </span>
        </div>
        <div className="col-md-12 mt-2">
          <div className="col-md-1 filters-bar">
            <button
              onClick={() => handleAddChargeSlot()}
              className="btn btn-primary border-0"
              style={{
                color: "#FFA382",
                backgroundColor: "white",
              }}
            >
              <i className="fas fa-plus-circle"></i>
              Add Rule
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RunningTotalTiers;
