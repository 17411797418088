import { CloudOff } from "@mui/icons-material";
import { Avatar, Paper } from "@mui/material";
import moment from "moment/moment";
import "./eachsessionsearch.styles.css";

const EachSearchSessionResultCard = ({ data, boxShadow, clicker }) => {
  const getDisplayMessage = (data) => {
    const contentGroup = data?.contentGroup ?? [];
    if (!contentGroup?.length) {
      return "Latest message will appear here";
    }
    let toShow = "";
    if (contentGroup[0]?.contentType === "TEXT") {
      toShow = contentGroup[0]?.content ?? "Latest message will appear here";
    } else {
      toShow =
        contentGroup[0]?.displayName ?? "Latest message will appear here";
    }
    return toShow.length > 30 ? toShow.substring(0, 30) + "..." : toShow;
  };
  return (
    <div
      className={`${boxShadow ? "neuro" : ""} eachsessionsearch`}
      onClick={clicker}
      style={{
        display: "flex",
        gap: "0.5em",
        flexWrap: "wrap",
        width: "90%",
        padding: "0em 2em",
        cursor: "pointer",
        borderRadius: "14px",
        opacity: !data?.isActive ? 0.6 : 1,
      }}>
      {/* avatar container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.8em",
        }}>
        <Avatar
          alt={data?.user?.name ?? "Anonymous"}
          component={Paper}
          elevation={5}
          src={data?.user?.image}
          sx={{ width: 50, height: 50 }}>
          {(data?.user?.name ?? "Anonymous").trim()[0]}
        </Avatar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          flexGrow: 1,
        }}>
        {/**unseem container */}
        <div
          style={{
            display: "flex",
            width: "55px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          {!data?.isActive && (
            <CloudOff
              sx={{
                color: "black",
              }}
            />
          )}
        </div>
        {/**info container */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            flexWrap: "wrap",
          }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexWrap: "nowrap",
            }}>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                height: "auto",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}>
              <span
                style={{
                  fontSize: "105%",
                  fontWeight: "500",
                  color: "#1F6355",
                }}>
                {data?.user?.name ?? "Anonymous"}
              </span>
              <span
                style={{
                  fontSize: "90%",
                  color: "black",
                }}>
                {moment(
                  data?.updatedAt
                    ? new Date(data.updatedAt).getTime()
                    : new Date().getTime()
                ).fromNow()}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              height: "auto",
            }}>
            <span
              style={{
                fontSize: "90%",
                color: "black",
              }}>
              {getDisplayMessage(data?.message ?? {})}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EachSearchSessionResultCard;
