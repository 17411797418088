import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { getCouponFormInitialData } from "../../pages/TypedCoupons/constants/form-initiator.constants";
import { createNotificationSubModuleThunk } from "./addNotificationSubSlice";
import moment from "moment";

const initialState = getCouponFormInitialData();
const getFormValidationErrors = (formData) => {
  let errors = {};
  if (formData?.discountAmount <= 0) {
    errors["discountAmount"] = "discount amount must be provided";
  }
  if (+formData?.discountAmount <= 0) {
    errors["discountAmount"] = "Must be a positive number";
  }
  // discount must be less than 100
  if (+formData?.discountAmount >= 100) {
    errors["discountAmount"] = "Must be less than 100";
  }
  if (formData?.couponCode?.trim().length === 0) {
    errors["couponCode"] = "coupon code must be provided";
  }
  if (formData?.couponCode.trim().length < 4) {
    errors["couponCode"] = "Must of at leat 4 characters long";
  }
  // Coupon Code should be in upper case and contain no white spaces and no special characters
  if (!/^[A-Z0-9]+$/.test(formData?.couponCode)) {
    errors["couponCode"] = "Must be in upper case and contain no white spaces";
  }
  //manipulate start date and end date
  if (new Date(formData.startsOn).toString() === "Invalid Date") {
    errors["startsOn"] = "Invalid date-time";
  }
  if (new Date(formData.endsOn).toString() === "Invalid Date") {
    errors["endsOn"] = "Invalid date-time";
  }
  if (!formData.neverExpires) {
    if (!(new Date(formData.endsOn) > new Date(formData.startsOn))) {
      errors["startsOn"] = "Start date must start before End Date";
      errors["endsOn"] = "End date must start after Start Date";
    }
  }

  //manipulate rules
  if (!!formData?.isRuleApplicable) {
    if (+formData?.minimumOrderValue <= 0) {
      errors["minimumOrderValue"] = "Must be a positive number";
    }
    if (+formData?.totalUsageLimit <= 0) {
      errors["totalUsageLimit"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer <= 0) {
      errors["usageLimitPerCustomer"] = "Must be a positive number";
    }
    if (+formData?.usageLimitPerCustomer >= +formData.totalUsageLimit) {
      errors["usageLimitPerCustomer"] =
        "Must be less than the total usage limit";
    }
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
const getSerializedFormData = (formData, targetOutlets) => {
  return {
    couponCode: formData.couponCode,
    discountAmount: formData.discountAmount,
    applicableOutlets: Array.isArray(targetOutlets) ? targetOutlets : [],
    couponType: "REGULAR",
    images: [],
    canBeOverridden: !!formData?.canBeOverridden,
    applicableDays: formData.applicableDays,
    status: !!formData.status,
    neverExpires: !!formData.neverExpires,
    startsOn: new Date(formData.startsOn).toISOString(),
    endsOn: new Date(formData.endsOn).toISOString(),
    startsOn12Hours: moment(formData.startsOn).format("LT"),
    startsOnDate: moment(formData.startsOn).format("YYYY-MM-DD"),
    endsOn12Hours: moment(formData.endsOn).format("LT"),
    endsOnDate: moment(formData.endsOn).format("YYYY-MM-DD"),
    discountAmountType: "PERCENTAGE",
    applicableOn: "CART",
    rulesInfo: {
      isApplicable: !!formData?.isRuleApplicable,
      rules: {
        minimumOrderValue: formData?.minimumOrderValue,
        totalUsageLimit: formData?.totalUsageLimit,
        usageLimitPerCustomer: formData?.usageLimitPerCustomer,
      },
    },
    chosenCategories: [],
    chosenProducts: [],
  };
};
export const addCouponSlice = createSlice({
  name: "add-coupon",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    resetCouponStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      const { propName, propValue } = action?.payload ?? {};
      state = {
        ...state,
        [propName]: propValue,
      };
      return state;
    },
  },
});

export const {
  resetCouponStateAction,
  changeSinglePropertyAction,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
} = addCouponSlice.actions;

export const selectAddCouponState = (state) => state?.addCoupon ?? initialState;
export const selectValidationErrors = (state) =>
  state?.addCoupon?.validationErrors ?? {};
export const createCouponThunk = (outlets) => (dispatch, getState) => {
  const formValidationErrors = getFormValidationErrors(getState().addCoupon);
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentCouponData = getState().addCoupon;
    let body = getSerializedFormData(currentCouponData, outlets);
    url
      .post(`/v1/typed-coupons`, body)
      .then((res) => {
        dispatch(setSuccessActions());
        dispatch(
          createNotificationSubModuleThunk(
            outlets,
            res?.data?.data.coupon._id,
            "newCoupon"
          )
        );
      })
      .catch((e) => {
        dispatch(setErrorAction(e));
      });
  }
};
export default addCouponSlice.reducer;
