import React from "react";
import { Dialog } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Tieredform from "./Tieredform/index";
import RegularForm from "./Regularform/index";
import BogoForm from "./Bogoform";
import BogoBundled from "./BogoBundledForm";
import TieredBundled from "./TieredBundledForm";
const useStyles = makeStyles((theme) => ({
  marginTop: theme.spacing(0.3),
  marginBottom: theme.spacing(0.3),
  minWidth: "790px",
  Height: "750px",
  overflowX: "hidden",
  borderRadius: "10px",
  "&::-webkit-scrollbar": {
    width: "5px !important",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00) !important",
    borderRadius: "10px !important",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#D5D5D5 !important",
    borderRadius: "10px !important",
  },
}));
export default function Dealdetail(props) {
  const DEALTYPE = props?.data.dealType;
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const Applicabletitle =
    DEALTYPE === "REGULAR"
      ? props.data.dealInfo[DEALTYPE].starterInfo.chosenCategories.length !== 0
        ? "Selected Categories"
        : props.data.dealInfo[DEALTYPE].starterInfo.chosenProducts.length !== 0
        ? "Selected Products"
        : "Selected Brands"
      : "Selected Brands";
  const neverExpires =
    props.data.outletBasisConfig[admin[0].outletChainID]?.neverExpires;
  const Date = neverExpires
    ? props.data.outletBasisConfig[admin[0].outletChainID]?.startsOn
    : props.data.outletBasisConfig[admin[0].outletChainID]?.endsOn;
  const expiryInfo = props.data.outletBasisConfig[admin[0].outletChainID] ?? {};
  const startDate =
    props.data.outletBasisConfig[admin[0].outletChainID]?.startsOn;
  const endDate = props.data.outletBasisConfig[admin[0].outletChainID]?.endsOn;
  const TIEREDDATA =
    DEALTYPE === "TIERED" &&
    props.data.dealInfo?.TIERED.starterInfo.chosenVariants;
  const TIERS = DEALTYPE === "TIERED" && props.data.dealInfo?.TIERED.tiers;
  const TieredApplied =
    DEALTYPE === "TIERED" &&
    props.data.dealInfo?.TIERED.starterInfo.productName;
  const REGULAR =
    props?.data.dealInfo.REGULAR === null
      ? null
      : props?.data.dealInfo.REGULAR?.starterInfo?.chosenCategories.length !== 0
      ? props.data.dealInfo.REGULAR?.starterInfo?.chosenCategories
      : props.data.dealInfo.REGULAR?.starterInfo?.chosenProducts.length !== 0
      ? props.data.dealInfo.REGULAR?.starterInfo?.chosenProducts
      : props.data.dealInfo.REGULAR?.starterInfo?.chosenBrands;
  const classes = useStyles();

  return (
    <Dialog
      open={true}
      className="popup-scroll"
      PaperProps={{ className: classes.dialogPaper }}>
      {DEALTYPE === "REGULAR" && (
        <RegularForm
          data={props.data}
          Applicabletitle={Applicabletitle}
          REGULAR={REGULAR}
          Date={Date}
          onClose={props.onClose}
          neverExpires={neverExpires}
          DEALTYPE="REGULAR"
          expiryInfo={expiryInfo}
        />
      )}
      {DEALTYPE === "TIERED" && (
        <Tieredform
          data={props.data}
          onClose={props.onClose}
          TIEREDDATA={TIEREDDATA}
          TIERS={TIERS}
          Date={Date}
          AppliesTo={TieredApplied}
          Applicabletitle={Applicabletitle}
          neverExpires={neverExpires}
          expiryInfo={expiryInfo}
        />
      )}
      {DEALTYPE === "BOGO" && (
        <BogoForm
          data={props.data}
          onClose={props.onClose}
          startDate={startDate}
          neverExpires={neverExpires}
          endDate={endDate}
          expiryInfo={expiryInfo}
        />
      )}
      {DEALTYPE === "BOGO_BUNDLED" && (
        <BogoBundled
          data={props.data}
          onClose={props.onClose}
          startDate={startDate}
          neverExpires={neverExpires}
          endDate={endDate}
          DEALTYPE="BOGO_BUNDLED"
          expiryInfo={expiryInfo}
        />
      )}
      {DEALTYPE === "TIERED_BUNDLED" && (
        <TieredBundled
          data={props.data}
          onClose={props.onClose}
          startDate={startDate}
          neverExpires={neverExpires}
          endDate={endDate}
          DEALTYPE="TIERED_BUNDLED"
          expiryInfo={expiryInfo}
        />
      )}
    </Dialog>
  );
}
