import React from "react";
import { useDispatch } from "react-redux";
import ChatWindowComponent from "../../components/Chat/ChatWindow";
import SessionComponent from "../../components/Chat/Sessions";
import {
  getMessageSessionThunk,
  resetMessageSessionsAction,
} from "../../redux/slices/messageSessionSlice";
import { useSelector } from "react-redux";
import { selectWholeMessageSessionState } from "../../redux/slices/messageSessionSlice";
import EmptySessionComponent from "../../components/Chat/EmptySession";
import { selectCurrentSessionInfo } from "../../redux/slices/chatSessionSlice";
import SessionDisconnectedComponent from "../../components/Chat/SessionDisconnected";
import { selectIfSocketConnected } from "../../redux/slices/socketSlice";
import { useState } from "react";
import styled from "@emotion/styled";

const ChatPage = () => {
  const dispatch = useDispatch();
  const conected = useSelector(selectIfSocketConnected);
  const [loaded, setLoaded] = useState(false);
  React.useEffect(
    () => {
      dispatch(getMessageSessionThunk({ initialAttempt: true }));
      setLoaded(true);

      return () => dispatch(resetMessageSessionsAction());
    },

    //eslint-disable-next-line
    []
  );
  const { sessions, inProgress, error } = useSelector(
    selectWholeMessageSessionState
  );
  const activeSession = useSelector(selectCurrentSessionInfo);
  if (!conected && loaded) {
    return <SessionDisconnectedComponent />;
  }
  if (!error && !inProgress && !Object.keys(sessions)?.length && loaded) {
    return <EmptySessionComponent />;
  }
  return (
    <ChatContainer>
      <div
        style={{
          display: "flex",
          flex: 1,
          borderRadius: "14px",
          overflow: "hidden",
          backgroundColor: "white"
        }}>
        <SessionComponent />
      </div>
      {activeSession && (
        <div
          className="chat-window"
          style={{
            width: "550px",
            display: "flex",
            borderRadius: "14px",
            backgroundColor: "white",
            // overflow: "hidden",
          }}>
          <ChatWindowComponent />
        </div>
      )}
    </ChatContainer>
  );
};

const ChatContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  background-color: transparent;
  border-radius: 14px;
  @media screen and (max-width: 1344px) {
    flex-direction: column-reverse;
    .chat-window {
      width: 100% !important;
    }
  }
`

export default ChatPage;
