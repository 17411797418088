export const chargeTypes = {
  TAX: "TAX",
};
export const chargeAmountTypes = {
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE",
};
export const initialEachChargeType = {
  type: chargeTypes.TAX,
  chargeType: chargeAmountTypes.PERCENTAGE,
  name: "",
  amount: "",
};
