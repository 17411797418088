/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import url from "../../../config/axios";
import {
  selectMyOutlets,
  selectifMultiChainAdmin,
} from "../../../redux/slices/authSlice";
import {
  editBannerThunk,
  getFormCompatibleDataFromResponse,
  replaceBannerStateAction,
  resetOpStateAction,
  selectEditBannerState,
} from "../../../redux/slices/editBannerSlice";
import EditTypedBannerForm from "../../../components/EditTypedBanner/edit-typed-banner-form";
export default function EditTypedBannerPage() {
  const outlets = useSelector(selectMyOutlets);
  const [bannerState, setBannerState] = useState({
    loading: true,
    banner: null,
    error: null,
  });
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const formState = useSelector(selectEditBannerState);
  const { applyToAllOutlets } = formState;
  const history = useHistory();
  const dispatch = useDispatch();
  const bannerInfo = useSelector(selectEditBannerState);
  const { inProgress, error, success } = bannerInfo;
  useEffect(() => {
    const bannerID = new URLSearchParams(history.location.search).get("_id");
    url
      .get(`/v1/consumer-banners/banner?_id=${bannerID}`)
      .then((res) => {
        const banner = res.data?.data?.banner;
        setBannerState({ banner, error: null, loading: false });
        try {
          dispatch(
            replaceBannerStateAction(getFormCompatibleDataFromResponse(banner))
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => {
        setBannerState({
          banner: null,
          error: "Unable to populate the banner data",
          loading: false,
        });
      });
  }, []);
  return (
    <div>
      <div className="row" style={{ borderRadius: "13px" }}>
        <div className="col-md-12">
          <div className="card" id="nopad1">
            <div className="col-md-12">
              <form className="row" onSubmit={(e) => e.preventDefault()}>
                <div className="col-md-12">
                  <div
                    className="row d-flex "
                    style={{
                      borderBottom: "2px solid #F1F5F9",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-2px",
                        backgroundColor: "#FFA382",
                        marginTop: "10px ",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    {/* <div className="d-flex justify-content-between w-75 border"> */}
                    <div className="col-md-3 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "18px",
                          marginTop: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Edit Banner
                      </div>
                    </div>
                    <div className="col-md-7"> </div>
                    <div className="col-md-1 filters-bar d-flex">
                      <button
                        className="btn btn-primary border-0 "
                        style={{
                          backgroundColor: "#FFA382",
                          marginBottom: "5px",
                          marginLeft: "90px",
                        }}
                        onClick={() => {
                          history.push("/typed-banners");
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                        Back
                      </button>
                    </div>
                    {/* </div> */}
                    <br></br>
                  </div>
                </div>
                {bannerState.loading ? (
                  "Populating data..."
                ) : (
                  <EditTypedBannerForm />
                )}
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            {bannerState?.loading ? null : (
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    editBannerThunk(
                      isMultiChainAdmin && applyToAllOutlets
                        ? [
                            ...outlets
                              .map((key) => key?.outletChainID)
                              .filter((el) => !!el),
                            JSON.parse(localStorage.getItem("Admin"))[0]
                              ?.outletChainID,
                          ]
                        : JSON.parse(localStorage.getItem("Admin"))[0]
                            ?.outletChainID
                    )
                  );
                }}
                disabled={inProgress}
                style={{ backgroundColor: "#FFA382" }}
                className="btn  btn-cannaby"
              >
                {!inProgress ? "Update Banner" : "Updating Banner..."}
              </button>
            )}
          </div>
        </div>
      </div>
      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Banner updated Successfully"}
          onClose={() => {
            dispatch(resetOpStateAction());
            history.push("/typed-banners");
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            dispatch(resetOpStateAction());
          }}
        />
      ) : null}
      {bannerState.error ? (
        <SimpleModal
          severity={"error"}
          highlights={bannerState.error}
          onClose={() => {
            history.push("/typed-banners");
          }}
        />
      ) : null}
    </div>
  );
}
