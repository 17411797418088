import React from "react";

function Table({ categories, assignColor, editCategory }) {
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>

            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Products</th>
            <th scope="col">Parent Category</th>
            {/* <th scope="col">Status</th>
                    <th></th> */}
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, i) => {
            return (
              <tr style={{ borderBottom: "1px solid #F1F5F9" }} key={i}>
                <td colSpan="6">
                  {category.image ? (
                    <div style={{ display: "flex" }}>
                      <img
                        alt="yo1"
                        src={category.image}
                        className="img-fluid "
                        width="40"
                        style={{ height: "40px", borderRadius: "5px" }}
                      />
                      <span
                        style={{
                          color: "#1E293B",
                          width: "60%",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        {category.name}
                      </span>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div
                        className="image-thumbnail-div"
                        style={{
                          backgroundColor: assignColor(),
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <span>{category?.name?.substring(0, 1)}</span>
                      </div>
                      <span
                        style={{
                          color: "#1E293B",
                          width: "60%",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        {category.name}
                      </span>
                    </div>
                  )}
                </td>
                <td style={{ color: "#1E293B" }}>
                  {category.countInventory.length
                    ? category.countInventory[0].count
                    : 0}
                </td>
                <td style={{ color: "#1E293B" }}>
                  {category.parentCategory || "_"}
                </td>
                {/* <td>{moment(category.updatedAt).format(" MMM. Do YYYY, h:mm A")}</td> */}
                {/* <td
                          className={
                            category.status ? "color-blue" : "color-red"
                          }
                        >
                          {category.status ? (
                            <span>Enabled</span>
                          ) : (
                            <span>Disabled</span>
                          )}
                        </td> */}
                <td>
                  <div
                    onClick={() => editCategory(category)}
                    style={{ color: "#FFA382" }}
                  >
                    <i
                      style={{ color: "#FFA382", marginRight: "3px" }}
                      className="fas fa-pen"
                    ></i>
                    <u>Edit</u>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
