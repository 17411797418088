/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./Categories.css";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
import latestCross from "../../assets/latestCross.png";
// import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popup from "reactjs-popup";
import upload from "../../assets/upload.svg";
import sort from "../../assets/sort.svg";
import debounce from "../../helpers/debounce";
import Pagination from "@mui/material/Pagination";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../common/InvokeLogout";
import Table from "./components/Table";
import Failure from "../../assets/failure.png";
import { colors } from "../../Style.style";
import { getUniqueFileName } from "../../helpers/getFileName";
import { Button, CircularProgress, DialogTitle } from "@mui/material";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import { LoadingButton } from "@mui/lab";
import JoyButton from "../../atoms/Buttons/JoyButton";
import SimpleDialog from "../../atoms/Dialogs/SimpleDialog";
import DNDListExtended from "../DNDList/DNDListExtended";
import styled from "@emotion/styled";

const Categories = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [editBoolean, setEditBoolean] = useState(false);
  const [showComponent, setComponent] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [status, setStatus] = useState(false);
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [file, setFile] = useState("");
  const notFoundRef = useRef();
  const [showImage, setShowImage] = useState(false);
  const [showImage1, setShowImage1] = useState(false);
  const [error, setError] = useState(null);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [noRecord, setNoRecord] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [dndCategoryList, setDndCategoryList] = useState([]);
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };
  // const performSearch = debounce(() => {
  //   if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
  //     setPreviousSearchTerm(searchTerm);
  //     handleChange("search", searchTerm, true);
  //   } else if (searchTerm === "") {
  //     setPreviousSearchTerm("");
  //     handleChange("search", "", true);
  //   }
  // }, 1500);
  // useEffect(
  //   () => {
  //     if (parsed && !loading) {
  //       performSearch();
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [searchTerm]
  // );

  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowImage(true);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateCategory = async (blob) => {
    await url
      .put(`/category/?categoryID=${categoryID}`, {
        parentCategory: parentCategory,
        name: categoryName,
        slug: slug,
        image: blob,
        status: status,
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          setIsLoading(false);
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setComponent(false);
          setEditBoolean(false);
          setImage("");
          setFile("");
          setCategories([
            ...categories.map((obj) =>
              obj?.categoryID === res?.data?.data?.categoryID
                ? res?.data?.data
                : obj
            ),
          ]);
          setShowImage(false);
        } else {
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setFile("");
          setComponent(false);
          setEditBoolean(false);
          setImage("");
          setShowImage(false);
          alert("Error: Something went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  const editCategory = (category) => {
    setFile("");
    localStorage.setItem("category", JSON.stringify(category));
    setCategoryID(category.categoryID);
    setParentCategory(
      category.parentCategory === ""
        ? "Select Parent Category"
        : category.parentCategory
    );
    setCategoryName(category?.name ?? "");
    setSlug(category?.slug ?? "");
    setImage(category.image);
    setStatus(category?.status);
    setEditBoolean(true);
    setComponent(!showComponent);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setOpen(true);
      setShowImage(true);
      // i want to set item in local storage

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result);
        localStorage.setItem("ImageSet", reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
      // setShowCrop(true);
    }
  };

  const closeComp = () => {
    setImage("");
    setFile("");
    setShowImage(false);
    setShowImage1(false);
    setComponent(false);
  };

  const fileUpdate = () => {
    setIsLoading(true);
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const fileName = getUniqueFileName(file.name);
      const uploadTask = storage
        .ref(`images/${fileName}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        async (error) => {
          await updateCategory("");
        },
        () => {
          storage
            .ref("images")
            .child(fileName)
            .getDownloadURL()
            .then(async (blob) => {
              await updateCategory(blob);
            });
        }
      );
    } else {
      updateCategory(image);
    }
  };

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);

  //parse passed queries from the URL first

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [formValidationError, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationError };
    if (categoryName.trim().length === 0) {
      data = { ...data, catError: "Name of the category is required" };
    } else {
      let { catError, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors(data);
  };
  useEffect(() => {
    validateForm();
  }, [categoryName, slug]);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllCategories({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */

  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/categories${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllCategories = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/categories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (!res.data?.data?.categories?.length && searchTerm) {
            setError("No categories found");
          }
          setCategories(res.data?.data?.categories);
          setDndCategoryList(res.data?.data?.categories.map(category => ({
            id: category._id,
            body: (
              <div>
                <span>{category.name}</span> <br />
                <span className="id_label">{category.categoryID}</span>
              </div>
            )
          })))
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const Tablenew = useMemo(
    () => (
      <Table
        categories={categories}
        assignColor={assignColor}
        editCategory={editCategory}
      />
    ),
    [categories]
  );

  const handleCardSort = () => {
    const sortedCategories = []
    dndCategoryList.forEach(item => {
      sortedCategories.push(categories.find(cat => cat._id === item.id))
    })
    console.log("Sorted categories", sortedCategories)
    setCategories(sortedCategories)
    setOpenSortDialog(false)
  }

  return (
    <div className="row">
      <div className={`col-md-${showComponent ? '8' : '12'}`}>
        <div className="card card-cat">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="row" style={{ borderRadius: "13px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-32px",
                      backgroundColor: "#FFA382",
                      marginTop: "5px",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  <div className="col-md-5 d-flex">
                    <div
                      style={{
                        color: "#FFA382",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Categories
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <JoyButton onClick={() => setOpenSortDialog(true)}>
                      Sort
                    </JoyButton>
                  </div> */}
                  <div className="col">
                    <div className="input-group">
                      <input
                        type="text"
                        style={{ borderRight: "none" }}
                        className="form-control"
                        placeholder="Search Category "
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            performSearchOnEnter();
                          }
                        }}
                      />
                      {searchTerm?.length ? (
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            onClick={() => handleSearchTermChange("")}
                            style={{
                              backgroundColor: "white",
                              borderLeft: "none",
                              paddingLeft: 0,
                              cursor: "pointer",
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                      ) : null}
                      <div
                        className="input-group-prepend"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="input-group-text newSearchIcon"
                          id="btnGroupAddon"
                          onClick={performSearchOnEnter}
                        >
                          <i
                            style={{ color: colors.searchColor }}
                            className="fas fa-search"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {Tablenew}
                <div
                  className="d-flex col-md-12"
                  style={{
                    justifyContent: "flex-end",
                    marginTop: "5px",
                    marginLeft: "13px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "6px",
                    }}
                  >
                    <Pagination
                      onChange={(e, value) => {
                        handleChange("page", value);
                      }}
                      page={paginationData.currentPage}
                      count={paginationData.totalPages ?? 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showComponent && <div className="col-md-4">
        {showComponent && (
          <div className="card" id="editSection">
            <div
              style={{
                position: "fixed",
                right: "10px",
                width: "26%",
                height: "74vh",
                borderRadius: "5px",
              }}
            >
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    style={{
                      height: "20px",
                      marginLeft: "-15px",
                      backgroundColor: "#FFA382",
                      marginTop: "8px",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  <div className="editHeading">
                    {editBoolean ? "Edit Categories" : "Add Categories"}
                  </div>
                </div>
                <div>
                  <button
                    className="btn close-btn latestCross"
                    onClick={closeComp}
                  >
                    <img src={latestCross} />
                    {/* <i style={{ color: "white" }} className="fas fa-times"></i> */}
                  </button>
                </div>
              </div>

              <br></br>
              <form
                className="parent-form"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="group-comb">
                  <span style={{ color: "#747474" }}>Category Name</span>
                  <div style={{ borderRadius: "1px !important" }}>
                    <input
                      type="text"
                      value={categoryName}
                      disabled={true}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Category Name"
                    />
                    {!!formValidationError.catError && (
                      <span
                        className="validation-help"
                        style={{ color: "red", margin: "0" }}
                      >
                        {formValidationError.catError}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="group-comb"
                  style={{
                    borderRadius: "3px !important",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    className=" img-drop"
                    style={{
                      borderRadius: "3px !important",
                      backgroundColor: "white",
                      border: "1px solid #E2E8F0",
                    }}
                  >
                    {showImage ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div style={{ width: "15%" }}></div>
                        <div style={{ marginTop: "20px" }}>
                          <img
                            style={{ alignSelf: "center" }}
                            alt=""
                            width="150"
                            height="120"
                            src={image}
                            className="rounded mx-auto "
                          ></img>
                        </div>
                        <label className="" style={{ border: "none" }}>
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />
                          <div
                            style={{
                              color: "#FFA382",
                              justifyContent: "flex-end",
                            }}
                          >
                            <i
                              style={{ color: "#FFA382", marginRight: "3px" }}
                              className="fas fa-pen"
                            ></i>
                            <u>Edit</u>
                          </div>
                        </label>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "30%",
                            height: "auto",
                            margin: " 0 auto",
                            padding: "10px",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "40px",
                              margin: " 0 auto",
                              position: "relative",
                            }}
                            src={upload}
                          />
                        </div>

                        <p style={{ textAlign: "center" }}>
                          Upload Category Image
                          <p
                            style={{
                              fontSize: "11px",
                              color: "rgba(116, 116, 116, 0.5)",
                            }}
                          >
                            Files include PNG, JPEG
                          </p>
                        </p>
                        <label
                          className="custom-file-upload"
                          style={{ border: "none" }}
                        >
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />

                          <p
                            style={{
                              backgroundColor: "#FFA382",
                              border: "1px solid #FFA382",
                              width: "60%",
                              color: "white",
                              borderRadius: "5px",
                              paddingLeft: "2em",
                              paddingRight: "2em",
                              paddingTop: "0.5em",
                              paddingBottom: "0.5em",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            Choose File
                          </p>
                        </label>
                      </>
                    )}

                    {/* {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )} */}
                  </div>
                </div>
                <p
                  style={{
                    color: "red",
                  }}
                >
                  Please upload a JPG file and a file size not exceeding 1 MB.
                </p>
                <div className="group" style={{ marginTop: "3px" }}>
                  {editBoolean && (
                    <LoadingButton
                      type="button"
                      onClick={fileUpdate}
                      disabled={Object.keys(formValidationError).length !== 0}
                      className="btn btn-primary"
                      style={{
                        marginTop: "20px",
                        backgroundColor: "#FFA382",
                        border: "none",
                        width: "100%",
                        color: "white",
                      }}
                      loading={isLoading}
                    >
                      Update
                    </LoadingButton>
                  )}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    {/* <br />
                    Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "} */}
                  </p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        )}
      </div>}
      {invokeLogOut && <InvokeLayoutComponent />}
      <Dialog
        open={openSortDialog}
        setOpen={setOpenSortDialog}
        sx={{
          "& .MuiPaper-root": {
            width: '90%',
            minWidth: "400px",
            position: "relative"
        }
        }}
      >
        <DialogTitle>Sort Your Categories</DialogTitle>
        <DNDContainer>
          <div className="dnd-list">
            <DNDListExtended 
              cards={dndCategoryList}
              setCards={setDndCategoryList}
              // onChange={handleCardSort}
            />
          </div>
          <div className="bottom-panel">
            <JoyButton onClick={handleCardSort}>Save</JoyButton>
          </div>
        </DNDContainer>
      </Dialog>
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Categorie(s) not found"
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
    </div>
  );
};
const DNDContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  .dnd-list {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  .bottom-panel {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-top: 1px solid #eaeaea;
  }
`

export default Categories;
