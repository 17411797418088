export const textShrink = (text, size) => {
  if (text.length < size) {
    return text;
  } else {
    return text.slice(0, size) + "...";
  }
};

export const textShrinkMiddle = (text, size, factor) => {
  if (text.length < size) {
    return text;
  } else {
    const factoredLength = text.length - factor;

    return (
      text.slice(0, factor) + "..." + text.slice(factoredLength, text.length)
    );
  }
};
