import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoyaltyRedemptionPolicyState } from "../../../../redux/slices/loyaltyRedemptionSlice";
import { changeSinglePropertyAction } from "../../../../redux/slices/loyaltyRedemptionSlice.js";
import { initialLoyaltyRedemptionPolicyState } from "../constants/form-initiator.constants";
import styled from "@emotion/styled";
import RunningTotalTiers from "./RunningTotalTiers";
import PerSpendingBasisAward from "./PerSpendingBasisAward";
function RewardPlan() {
  const { plan } =
    useSelector(selectLoyaltyRedemptionPolicyState) ??
    initialLoyaltyRedemptionPolicyState;
  const dispatch = useDispatch();
  const handleChange = (propValue) => {
    dispatch(changeSinglePropertyAction({ propName: "plan", propValue }));
  };
  return (
    <Fragment>
      <DividerLine />
      <div
        style={{
          fontSize: "17px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "bold",
        }}>
        Reward Plan
      </div>
      <FormControl>
        <RadioGroup
          row
          className="row d-flex align-items-center"
          value={plan}
          onChange={(e) => handleChange(e.target.value)}
          aria-labelledby="running-total"
          name="running-total">
          <div className="col-md-6">
            <FormControlLabel
              value={"SPENDING_BASIS"}
              control={<Radio />}
              label="Tiered policy on running total "
            />
          </div>
          <div className="col-md-6">
            <FormControlLabel
              value={"PER_SPENDING_BASIS"}
              control={<Radio />}
              label="Loyalty policy on per spending basis"
            />
          </div>
          <div className="col-md-6">
            <FormControlLabel value={"NONE"} control={<Radio />} label="None" />
          </div>
        </RadioGroup>
      </FormControl>
      {plan === "SPENDING_BASIS" ? <RunningTotalTiers /> : null}
      {plan === "PER_SPENDING_BASIS" ? <PerSpendingBasisAward /> : null}
    </Fragment>
  );
}

export default RewardPlan;
const DividerLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-top: 30px;
  margin-bottom: 16px;
`;
