import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import { base64FileUploadWithPredefinedName } from "../../helpers/firebaseFileUpload";
import {
  DealInfoInitialData,
  getDealFormInitialData,
} from "../../pages/TypedDeals/constants/form-initiator.constants";
import {
  BogoBundledTargetOffEntity,
  TypedBogoBundledDealsAppliesTo,
  TypedDealsTypes,
  TypedRegularDealsAppliesTo,
} from "../../pages/TypedDeals/constants/typed-deals.constants";
import { createNotificationSubModuleThunk } from "./addNotificationSubSlice";
import { updateObjectByKey } from "../../helpers/utils";
import moment from "moment";

const initialState = getDealFormInitialData();
export const getFormValidationErrors = (
  formData,
  excludeRuleAndExpiryValidationError = false
) => {
  let errors = {};
  if (formData?.name?.trim().length === 0) {
    errors["name"] = "Deal name must be provided";
  }
  if (!excludeRuleAndExpiryValidationError) {
    //manipulate start date and end date
    if (new Date(formData.startsOn).toString() === "Invalid Date") {
      errors["startsOn"] = "Invalid date-time";
    }
    if (new Date(formData.endsOn).toString() === "Invalid Date") {
      errors["endsOn"] = "Invalid date-time";
    }
    if (!formData.neverExpires) {
      if (!(new Date(formData.endsOn) > new Date(formData.startsOn))) {
        errors["startsOn"] = "Start date must start before End Date";
        errors["endsOn"] = "End date must start after Start Date";
      }
    }

    //manipulate rules
    if (!!formData?.isRuleApplicable) {
      if (+formData?.totalUsageLimit <= 0) {
        errors["totalUsageLimit"] = "Must be a positive number";
      }
      if (+formData?.usageLimitPerCustomer <= 0) {
        errors["usageLimitPerCustomer"] = "Must be a positive number";
      }
      if (+formData?.usageLimitPerCustomer >= +formData.totalUsageLimit) {
        errors["usageLimitPerCustomer"] =
          "Must be less than the total usage limit";
      }
    }
  }
  //validate regular deals
  if (formData.dealType === "REGULAR") {
    const data = formData.dealInfo?.REGULAR ?? {};
    const regularErrors = {};
    if (
      data.appliesTo === TypedRegularDealsAppliesTo.CATEGORIES &&
      Object.keys(data.chosenCategories).length === 0
    ) {
      regularErrors.appliesTo = "Select at least one entity";
    } else if (
      data.appliesTo === TypedRegularDealsAppliesTo.BRANDS &&
      Object.keys(data.chosenBrands).length === 0
    ) {
      regularErrors.appliesTo = "Select at least one entity";
    } else if (
      data.appliesTo === TypedRegularDealsAppliesTo.PRODUCTS &&
      Object.keys(data.chosenProducts).length === 0
    ) {
      regularErrors.appliesTo = "Select at least one entity";
    }

    if (+data.getOffNumeric <= 0) {
      regularErrors["getOffNumeric"] = "Must be a positive number";
    }
    if (data.getOffType === "PERCENTAGE" && +data.getOffNumeric >= 100) {
      regularErrors["getOffNumeric"] = "Must be less than 100";
    }
    if (Object.values(regularErrors).length) {
      errors["regularErrors"] = regularErrors;
    }
  } else if (formData.dealType === "BOGO") {
    const data = formData.dealInfo?.BOGO ?? {};
    const bogoErrors = {};
    const {
      selectedBuyProduct,
      buyProductExactQuantity,
      buyProductVariants,
      selectedGetProduct,
      getProductExactQuantity,
      getProductVariant,
      getOffNumeric,
      getOffType,
    } = data;
    if (buyProductVariants && !Object.values(buyProductVariants).length) {
      bogoErrors.buyProductVariants = "Select at least one variant";
    }
    if (getProductVariant && !Object.values(getProductVariant).length) {
      bogoErrors.getProductVariant = "Select variant";
    }
    if (+buyProductExactQuantity <= 0) {
      bogoErrors.buyProductExactQuantity = "Must be a positive number";
    }
    if (+getProductExactQuantity <= 0) {
      bogoErrors.getProductExactQuantity = "Must be a positive number";
    }
    if (selectedBuyProduct && !Object.values(selectedBuyProduct).length) {
      bogoErrors.selectedBuyProduct = "Select Product";
    }
    if (selectedGetProduct && !Object.values(selectedGetProduct).length) {
      bogoErrors.selectedGetProduct = "Select Product";
    }
    if (+getOffNumeric <= 0) {
      bogoErrors["getOffNumeric"] = "Must be a positive number";
    }
    if (getOffType === "PERCENTAGE" && +getOffNumeric >= 100) {
      bogoErrors["getOffNumeric"] = "Must be less than 100";
    }
    if (Object.values(bogoErrors).length) {
      errors["bogoErrors"] = bogoErrors;
    }
  } else if (formData.dealType === "TIERED") {
    const data = formData.dealInfo?.TIERED ?? {};
    const tieredErrors = {};
    let lockedAmounts = [];
    const tiersToIterate = Object.values(data?.tiers ?? {});
    if (!tiersToIterate.length) {
      tieredErrors.tiersError = "Add at least one tier";
    }
    for (let i = 0; i < tiersToIterate.length; i++) {
      const { getOffNumericAmount, buyMinimum } = tiersToIterate[i];
      if (+buyMinimum <= 0 || +getOffNumericAmount <= 0) {
        tieredErrors.tiersError =
          "One or more tier contains non positive number value";
        break;
      }
      if (lockedAmounts.findIndex((item) => +item === +buyMinimum) >= 0) {
        tieredErrors.tiersError =
          "One or more tier contains conflicting statements";
        break;
      }
      if (
        data.issueAmountType === "PERCENTAGE" &&
        +getOffNumericAmount >= 100
      ) {
        tieredErrors.tiersError = "Discount value must be less than 100";
        break;
      }
      lockedAmounts.push(+buyMinimum);
    }
    if (data.selectedProduct && !Object.values(data.selectedProduct).length) {
      tieredErrors.selectedProduct = "Select Product";
    }
    if (data.chosenVariants && !Object.values(data.chosenVariants).length) {
      tieredErrors.chosenVariants = "Select at least one variant";
    }
    if (Object.values(tieredErrors).length) {
      errors["tieredErrors"] = tieredErrors;
    }
  } else if (formData.dealType === "BOGO_BUNDLED") {
    const data = formData.dealInfo?.BOGO_BUNDLED ?? {};
    const bogoBundledErrors = {};
    const {
      buyProductExactQuantity,
      selectedGetProduct,
      getProductExactQuantity,
      getProductVariant,
      getOffNumeric,
      getOffType,
      appliesTo,
      chosenCategories,
      chosenProducts,
      chosenBrands,
      extendedProductPreference,
    } = data;

    if (
      appliesTo === TypedBogoBundledDealsAppliesTo.CATEGORIES &&
      Object.keys(chosenCategories).length === 0
    ) {
      bogoBundledErrors.appliesTo = "Select at least one entity";
    } else if (
      appliesTo === TypedBogoBundledDealsAppliesTo.BRANDS &&
      Object.keys(chosenBrands).length === 0
    ) {
      bogoBundledErrors.appliesTo = "Select at least one entity";
    } else if (
      appliesTo === TypedBogoBundledDealsAppliesTo.PRODUCTS &&
      Object.keys(chosenProducts).length === 0
    ) {
      bogoBundledErrors.appliesTo = "Select at least one entity";
    }

    if (+buyProductExactQuantity <= 0) {
      bogoBundledErrors.buyProductExactQuantity = "Must be a positive number";
    }
    if (+getProductExactQuantity <= 0) {
      bogoBundledErrors.getProductExactQuantity = "Must be a positive number";
    }
    if (extendedProductPreference === "OTHER") {
      if (selectedGetProduct && !Object.values(selectedGetProduct).length) {
        bogoBundledErrors.selectedGetProduct = "Select Product";
      }
      if (getProductVariant && !Object.values(getProductVariant).length) {
        bogoBundledErrors.getProductVariant = "Select variant";
      }
    }

    if (extendedProductPreference !== "ANY") {
      if (+getOffNumeric <= 0) {
        bogoBundledErrors["getOffNumeric"] = "Must be a positive number";
      }
      if (getOffType === "PERCENTAGE" && +getOffNumeric > 100) {
        bogoBundledErrors["getOffNumeric"] =
          "Must be less than or equal to 100";
      }
      if (Object.values(bogoBundledErrors).length) {
        errors["bogoBundledErrors"] = bogoBundledErrors;
      }
    }
  } else if (formData.dealType === "TIERED_BUNDLED") {
    const data = formData.dealInfo?.TIERED_BUNDLED ?? {};
    const tieredBundledErrors = {};
    const { chosenCategories, chosenProducts, chosenBrands, appliesTo, tiers } =
      data;

    if (
      appliesTo === TypedBogoBundledDealsAppliesTo.CATEGORIES &&
      Object.keys(chosenCategories).length === 0
    ) {
      tieredBundledErrors.appliesTo = "Select at least one entity";
    } else if (
      appliesTo === TypedBogoBundledDealsAppliesTo.BRANDS &&
      Object.keys(chosenBrands).length === 0
    ) {
      tieredBundledErrors.appliesTo = "Select at least one entity";
    } else if (
      appliesTo === TypedBogoBundledDealsAppliesTo.PRODUCTS &&
      Object.keys(chosenProducts).length === 0
    ) {
      tieredBundledErrors.appliesTo = "Select at least one entity";
    }
    let lockedAmounts = [];
    const tiersToIterate = Object.values(tiers ?? {});
    if (!tiersToIterate.length) {
      tieredBundledErrors.tiersError = "Add at least one tier";
    }
    for (let i = 0; i < tiersToIterate.length; i++) {
      const { getOffNumericAmount, buyMinimum } = tiersToIterate[i];
      if (+buyMinimum <= 0 || +getOffNumericAmount <= 0) {
        tieredBundledErrors.tiersError =
          "One or more tier contains non positive number value";
        break;
      }
      if (lockedAmounts.findIndex((item) => +item === +buyMinimum) >= 0) {
        tieredBundledErrors.tiersError =
          "One or more tier contains conflicting statements";
        break;
      }
      if (
        data.issueAmountType === "PERCENTAGE" &&
        +getOffNumericAmount >= 100
      ) {
        tieredBundledErrors.tiersError = "Discount value must be less than 100";
        break;
      }
      lockedAmounts.push(+buyMinimum);
    }
    if (Object.values(tieredBundledErrors).length) {
      errors["tieredBundledErrors"] = tieredBundledErrors;
    }
  }
  return errors;
};
/**
 *
 * @param {object} formData current whole formstate
 * @param {array} targetOutlets get all outletchain ids in an array
 * @returns
 */
export const getSerializedFormData = (
  formData,
  targetOutlets = [],
  excludeRuleAndExpiry = false
) => {
  const bogoDealInfo = formData.dealInfo?.BOGO;
  const tieredDealInfo = formData.dealInfo?.TIERED;
  const regularDealInfo = formData.dealInfo?.REGULAR;
  const bogoBundledDealInfo = formData.dealInfo?.BOGO_BUNDLED;
  const tieredBundledDealInfo = formData.dealInfo?.TIERED_BUNDLED;
  return {
    name: String(formData?.name).trim(),
    dealType: String(formData?.dealType),
    images: Array.isArray(formData?.images) ? formData?.images : [],
    ...(!excludeRuleAndExpiry
      ? {
          startsOn: new Date(formData.startsOn).toISOString(),
          endsOn: new Date(formData.endsOn).toISOString(),
          startsOn12Hours: moment(formData.startsOn).format("LT"),
          startsOnDate: moment(formData.startsOn).format("YYYY-MM-DD"),
          endsOn12Hours: moment(formData.endsOn).format("LT"),
          endsOnDate: moment(formData.endsOn).format("YYYY-MM-DD"),
          canBeOverridden: !!formData?.canBeOverridden,
          applicableOutlets: Array.isArray(targetOutlets) ? targetOutlets : [],
          applicableDays: formData.applicableDays,
          status: !!formData.status,
          neverExpires: !!formData.neverExpires,
          rulesInfo: {
            isApplicable: !!formData?.isRuleApplicable,
            rules: {
              totalUsageLimit: formData?.totalUsageLimit,
              usageLimitPerCustomer: formData?.usageLimitPerCustomer,
            },
          },
        }
      : {}),
    dealInfo: {
      TIERED_BUNDLED: {
        buyMinimumType: tieredBundledDealInfo.buyMinimumType,
        issueAmountType: tieredBundledDealInfo.issueAmountType,
        tiers: Object.values(tieredBundledDealInfo.tiers),
        chosenBrands: Object.keys(tieredBundledDealInfo.chosenBrands),
        chosenCategories: Object.keys(tieredBundledDealInfo.chosenCategories),
        chosenProducts: Object.keys(tieredBundledDealInfo.chosenProducts),
        variantExceptions: Object.keys(
          tieredBundledDealInfo.variantExceptions
        ).filter((item) => tieredBundledDealInfo.variantExceptions[item]),
      },
      BOGO_BUNDLED: {
        chosenBrands: Object.keys(bogoBundledDealInfo.chosenBrands),
        chosenCategories: Object.keys(bogoBundledDealInfo.chosenCategories),
        buyProductExactQuantity: bogoBundledDealInfo.buyProductExactQuantity,
        getProductExactQuantity: bogoBundledDealInfo.getProductExactQuantity,
        chosenProducts: Object.keys(bogoBundledDealInfo.chosenProducts),
        variantExceptions: Object.keys(
          bogoBundledDealInfo.variantExceptions
        ).filter((item) => bogoBundledDealInfo.variantExceptions[item]),
        extendedProductPreference:
          bogoBundledDealInfo.extendedProductPreference,
        targetOffEntity: bogoBundledDealInfo.targetOffEntity,
        getOff: {
          numericAmount: +bogoBundledDealInfo.getOffNumeric,
          type: bogoBundledDealInfo.getOffType,
        },
        extendedProductInfo:
          bogoDealInfo.targetOffEntity === BogoBundledTargetOffEntity.ON_BUNDLE
            ? undefined
            : {
                productID: Object.keys(
                  bogoBundledDealInfo.selectedGetProduct
                )[0],
                variantID: Object.keys(
                  bogoBundledDealInfo.getProductVariant
                )[0],
              },
        userPickedDiscount: {
          ...bogoBundledDealInfo.userPickedDiscount,
          categories: Object.keys(
            bogoBundledDealInfo.userPickedDiscount.categories
          ),
        },
      },
      BOGO: {
        buyProduct: {
          exactQuantity: +bogoDealInfo.buyProductExactQuantity,
          productID: Object.keys(bogoDealInfo.selectedBuyProduct)[0],
          chosenVariants: Object.keys(bogoDealInfo.buyProductVariants),
        },
        getProduct: {
          exactQuantity: +bogoDealInfo.getProductExactQuantity,
          productID: Object.keys(bogoDealInfo.selectedGetProduct)[0],
          variantID: Object.keys(bogoDealInfo.getProductVariant)[0],
        },
        getOff: {
          numericAmount: +bogoDealInfo.getOffNumeric,
          type: bogoDealInfo.getOffType,
        },
      },
      TIERED: {
        buyMinimumType: tieredDealInfo.buyMinimumType,
        issueAmountType: tieredDealInfo.issueAmountType,
        tiers: Object.values(tieredDealInfo.tiers),
        productID: Object.keys(tieredDealInfo.selectedProduct)[0],
        chosenVariants: Object.keys(tieredDealInfo.chosenVariants),
      },
      REGULAR: {
        chosenCategories:
          regularDealInfo.appliesTo === "CATEGORIES"
            ? Object.keys(regularDealInfo.chosenCategories)
            : [],
        chosenProducts:
          regularDealInfo.appliesTo === "PRODUCTS"
            ? Object.keys(regularDealInfo.chosenProducts)
            : [],
        chosenBrands:
          regularDealInfo.appliesTo === "BRANDS"
            ? Object.keys(regularDealInfo.chosenBrands)
            : [],
        getOff: {
          numericAmount: +regularDealInfo.getOffNumeric,
          type: regularDealInfo.getOffType,
        },
      },
    },
  };
};
export const addDealSlice = createSlice({
  name: "add-deal",
  initialState,
  reducers: {
    startInProgressAction: (state) => {
      return {
        ...state,
        inProgress: true,
        error: null,
        success: false,
      };
    },
    setErrorAction: (state, action) => {
      const errorMessage = action.payload?.response?.data?.data?.message;
      return {
        ...state,
        inProgress: false,
        error: errorMessage ?? "Something went wrong",
        success: false,
      };
    },
    setSuccessActions: (state) => {
      return {
        ...state,
        inProgress: false,
        error: null,
        success: true,
      };
    },
    resetOpStateAction: (state) => {
      return {
        ...state,
        error: null,
        success: false,
        inProgress: false,
      };
    },
    resetDealStateAction: () => initialState,
    changeSinglePropertyAction: (state, action) => {
      // action.payload = { propName: String, propValue: any, dealType: TypedDealstype }
      const { propName, propValue, dealType } = action?.payload ?? {};
      if (dealType && Object.keys(TypedDealsTypes).includes(dealType)) {
        state = {
          ...state,
          dealInfo: {
            ...state.dealInfo,
            [dealType]: {
              ...state.dealInfo[dealType],
              [propName]: propValue,
            },
          },
        };
      } else {
        state = {
          ...state,
          [propName]: propValue,
        };
      }
      return state;
    },
    changeSinglePropertyActionUpdated: (state, action) => {
      // action.payload = { propName: String, propValue: any, dealType: TypedDealstype }
      const { propName, propValue, dealType } = action?.payload ?? {};
      if (dealType && Object.keys(TypedDealsTypes).includes(dealType)) {
        const dealTypeData = state.dealInfo[dealType];
        state = {
          ...state,
          dealInfo: {
            ...state.dealInfo,
            [dealType]: updateObjectByKey(propName, propValue, dealTypeData),
          },
        };
      } else {
        state = {
          ...state,
          [propName]: propValue,
        };
      }
      return state;
    },
  },
});

export const {
  resetDealStateAction,
  changeSinglePropertyAction,
  changeSinglePropertyActionUpdated,
  startInProgressAction,
  setErrorAction,
  setSuccessActions,
  resetOpStateAction,
} = addDealSlice.actions;

export const selectAddDealState = (state) => state?.addDeal ?? initialState;
export const selectBogoDealInfoState = (state) =>
  state.addDeal?.dealInfo[TypedDealsTypes.BOGO] ?? DealInfoInitialData.BOGO;
export const selectBogoBundledDealInfoState = (state) =>
  state.addDeal?.dealInfo[TypedDealsTypes.BOGO_BUNDLED] ??
  DealInfoInitialData.BOGO_BUNDLED;
export const selectTieredDealInfoState = (state) =>
  state.addDeal?.dealInfo[TypedDealsTypes.TIERED] ?? DealInfoInitialData.TIERED;
export const selectTieredBubdlesDealInfoState = (state) =>
  state.addDeal?.dealInfo[TypedDealsTypes.TIERED_BUNDLED] ??
  DealInfoInitialData.TIERED_BUNDLED;
export const selectRegularDealInfoState = (state) =>
  state.addDeal?.dealInfo[TypedDealsTypes.REGULAR] ??
  DealInfoInitialData.REGULAR;
export const selectValidationErrors = (state) =>
  state?.addDeal?.validationErrors ?? {};
export const selectRegularTypeValidationErrors = (state) =>
  state?.addDeal?.validationErrors?.regularErrors ?? {};
export const selectBogoTypeValidationErrors = (state) =>
  state?.addDeal?.validationErrors?.bogoErrors ?? {};
export const selectBogoBundledTypeValidationErrors = (state) =>
  state?.addDeal?.validationErrors?.bogoBundledErrors ?? {};
export const selectTieredTypeValidationErrors = (state) =>
  state?.addDeal?.validationErrors?.tieredErrors ?? {};
export const selectTieredBundledTypeValidationErrors = (state) =>
  state?.addDeal?.validationErrors?.tieredBundledErrors ?? {};
export const createDealThunk = (outlets) => (dispatch, getState) => {
  const _addDealData = getState().addDeal;
  const formValidationErrors = getFormValidationErrors(_addDealData);
  dispatch(
    changeSinglePropertyAction({
      propName: "validationErrors",
      propValue: formValidationErrors,
    })
  );
  if (!Object.values(formValidationErrors).length) {
    dispatch(startInProgressAction());
    const currentDealData = getState().addDeal;
    let body = getSerializedFormData(currentDealData, outlets);
    if (currentDealData?.imageFile) {
      base64FileUploadWithPredefinedName(currentDealData?.imageFile)
        .then((res) => {
          if (res) {
            body = getSerializedFormData(
              { ...currentDealData, images: [res] },
              outlets
            );
            dispatch(changeSinglePropertyAction("images", [res]));
          } else {
            body = getSerializedFormData(
              { ...currentDealData, images: [] },
              outlets
            );
          }

          url
            .post(`/v1/typed-deals`, body)
            .then((res) => {
              dispatch(setSuccessActions());
              dispatch(
                createNotificationSubModuleThunk(
                  outlets,
                  res.data?.data?.deal._id,
                  "newDeal"
                )
              );
            })
            .catch((e) => {
              dispatch(setErrorAction(e));
            });
        })
        .catch((e) => {
          body = getSerializedFormData(
            { ...currentDealData, images: [] },
            outlets
          );
          url
            .post(`/v1/typed-deals`, body)
            .then((res) => {
              dispatch(setSuccessActions());
              dispatch(
                createNotificationSubModuleThunk(
                  outlets,
                  res.data?.data?.deal._id,
                  "newDeal"
                )
              );
            })
            .catch((e) => {
              dispatch(setErrorAction(e));
            });
        });
    } else {
      url
        .post(`/v1/typed-deals`, body)
        .then((res) => {
          dispatch(setSuccessActions());
          dispatch(
            createNotificationSubModuleThunk(
              outlets,
              res.data?.data?.deal._id,
              "newDeal"
            )
          );
        })
        .catch((e) => {
          dispatch(setErrorAction(e));
        });
    }
  }
};
export default addDealSlice.reducer;
