import { useState } from "react";
import { Fragment } from "react";
import SessionSearchModalComponent from "./SessionSearchModal";

const SessionSearchComponent = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          paddingLeft: "2em",
        }}>
        <div
          className="input-group"
          onClick={() => setSearchModalOpen(true)}
          style={{
            width: "50%",
            cursor: "pointer",
          }}>
          <input
            type="text"
            className="form-control table-search"
            placeholder="Search by user's name or email"
            aria-label="Input group example"
            aria-describedby="btnGroupAddon"
          />
          <div
            className="input-group-prepend"
            style={{
              cursor: "pointer",
            }}>
            <div
              className="input-group-text table-search-icon"
              id="btnGroupAddon">
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>
      </div>
      {searchModalOpen && (
        <SessionSearchModalComponent
          onClose={() => setSearchModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default SessionSearchComponent;
