export const CustomPageTypes = {
    "BASIC": {
        id: "BASIC",
        label: "Default Page",
        value: "default"
    },
    "custom": {
        id: "custom",
        label: "Custom Page",
        value: "custom"
    }
}