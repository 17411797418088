// eslint-disable-next-line react-hooks/exhaustive-deps
/* eslint-disable no-unused-vars */
import { React, useState, useRef, useEffect } from "react";
import "./SideNav.css";
import { NavLink } from "react-router-dom";
import brandImage from "../assets/brandImage.png";
import { useHistory } from "react-router-dom";
import dashboard from "../assets/dashboard.svg";
import loyalty from "../assets/loyalty.svg";
import page from "../assets/pages.svg";
import promotion from "../assets/Promotion.svg";
import banner from "../assets/banner.svg";
import deals from "../assets/deals.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import { resetAuthStateAction } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import url from "../config/axios";
function Sidenav() {
  // const [admin, setAdmin] = useState(false);
  const history = useHistory();
  const [catalogClick, setCatalogClick] = useState(true);
  const [promotionClick, setPromotionClick] = useState(true);
  // const [loyaltyClick, setLoyaltyClick] = useState(true);
  // const [supportClick, setSupportClick] = useState(true);
  const scrollRef = useRef();
  const [mainLogoRef, setMainLogoRef] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const adminData = JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(adminData) && !!adminData?.length) {
        const mainLogoSource = adminData[0]?.media?.mainLogoFileSource ?? null;
        setMainLogoRef(mainLogoSource);
      }
    } catch (e) { }

    setLoading(false);
  }, [mainLogoRef]);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [promotionClick, catalogClick]);

  const logout = () => {
    try {
      url.put("/oca/logout");
    } catch (e) {
      console.error(e);
    }
    dispatch(resetAuthStateAction());
    const tempRememberMeState = localStorage.getItem("remeberMeState");
    localStorage.clear();
    try {
      localStorage.setItem(
        "remeberMeState",
        tempRememberMeState ??
        JSON.stringify({ rememberMe: false, email: "", password: "" })
      );
    } catch (e) { }
    history.push("/");
  };
  return (
    <div
      className="col-lg-2 col-md-4 col-sm-4 side-nav "
      style={{ padding: " 0px 0px" }}>
      <div
        // className="card card-side-nave table-ac table-mod table-mod-data-ac custom-scrollbar"
        className="card custom-scrollbar"
        style={{
          height: "100vh",
          width: "15%",
          position: "fixed",
          padding: "0",
        }}>
        <div
          className="sidebar"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0em",
            paddingLeft: "0.2em",
          }}>
          <div style={{ textAlign: "center", height: "17vh", paddingTop: "3em", paddingBottom: "5em" }}>

            <img
              alt="yo4"
              src={brandImage}
              className="img-fluid "
              width="100"
              style={{ scale: "1.5" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "auto",
            }}
            className="custom-scrollbar">
            <ul className="nav nav-pills flex-column cannaby-nav">
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/dashboard">
                  {/* <i className="fa fa-tag"></i> */}
                  <i>
                    <img
                      alt="dashboard"
                      style={{ marginTop: "-3px" }}
                      src={dashboard}
                    />
                  </i>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn "
                  data-toggle="collapse"
                  data-target="#demo"
                  to=""
                  onClick={() => setCatalogClick(!catalogClick)}>
                  <i className="fas fa-tag"></i>

                  <span>Catalog</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      catalogClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }></i>
                  <span></span>
                </button>
                <div
                  id="demo"
                  className="collapse"
                  style={{ marginBottom: "-10px" }}
                  ref={scrollRef}>
                  <li className="nav-item">
                    <NavLink
                      style={{ marginLeft: "20px" }}

                      activeClassName="active"
                      className="nav-link "
                      to="/inventory-outlet">
                      Inventory
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    style={{
                      display: "flex",
                      marginRight: "-30px",
                    }}>
                    <NavLink
                      style={{ marginLeft: "20px" }}
                      activeClassName="active"
                      className="nav-link "
                      to="/categories">
                      Categories
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    style={{
                      display: "flex",
                      marginRight: "-30px",
                    }}>
                    <NavLink
                      style={{ marginLeft: "20px" }}
                      activeClassName="active"
                      className="nav-link "
                      to="/brands"
                    // style={{ margin: "0px" }}
                    >
                      Manufacturers
                    </NavLink>
                  </li>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/users">
                  <i className="fa fa-user"></i>
                  Users
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/order-outlet">
                  <i className="fa fa-truck"></i>
                  Orders
                </NavLink>
              </li>
              <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn"
                  data-toggle="collapse"
                  data-target="#demo1"
                  to=""
                  onClick={() => setPromotionClick(!promotionClick)}>
                  <i>
                    <img alt="dashboard" src={promotion} />
                  </i>
                  <span>Promotions</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      promotionClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }></i>
                </button>
                <div id="demo1" className="collapse">
                  <li className="nav-item">
                    <NavLink
                      style={{ marginLeft: "30px" }}
                      activeClassName="active"
                      className="nav-link "
                      to="/banner">
                      Banners
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      style={{ marginLeft: "30px" }}

                      activeClassName="active"
                      className="nav-link "
                      to="/deals">
                      Deals
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      style={{ marginLeft: "30px" }}
                      activeClassName="active"
                      className="nav-link "
                      to="/discounts-outlet">

                      Coupons
                    </NavLink>
                  </li>{" "}
                  <li className="nav-item">
                    <NavLink
                      style={{ marginLeft: "30px" }}
                      activeClassName="active"
                      className="nav-link "
                      to="/Special-Event">
                      Special Event
                    </NavLink>
                  </li>{" "}
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/Page">
                  <i>
                    <img alt="dashboard" src={page} />
                  </i>
                  Pages
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/loyalty-points">
                  <i>
                    <img alt="dashboard" src={loyalty} />
                  </i>
                  Loyalty Points
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/chat">
                  <i
                    class="fa-solid fa-comments"
                    style={{ fontStyle: "normal" }}></i>
                  Chat
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/general-settings"
                  style={{ alignItems: "center" }}>
                  <SettingsIcon fontSize="small" /> &nbsp;Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-danger bg-none text-danger nav-link"
                  onClick={() => logout()}>
                  <i className="fas fa-sign-out-alt"></i>Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidenav;
