/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";
import "./users.css";
import { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { CircularProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";
// import sort from "../../assets/sort.svg"
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import Popup from "reactjs-popup";
import { memo } from "react";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationSettingsDetails } from "../../redux/slices/globalStateSlice";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";

const UserTable = ({ users }) => {
  const history = useHistory();
  return (
    <tbody>
      {users.map((user, i) => {
        return (
          <tr style={{ borderBottom: "1px solid #F1F5F9" }} key={i}>
            <td colSpan="6">
              <div
                style={{
                  display: "flex",
                }}>
                <span
                  style={{
                    paddingTop: "10px",
                    color: "#1E293B",
                    fontWeight: "500",
                  }}>
                  {user.name}
                </span>
              </div>
            </td>
            <td
              className="adminName"
              style={{ color: "#1E293B", fontWeight: "500" }}>
              {user.email ? user.email : user.mobileNo} <br />
            </td>
            <td
              className="adminName"
              style={{ color: "#1E293B", fontWeight: "500" }}>
              {moment(
                new Date(
                  new Date(user?.createdAt ?? new Date()).toLocaleString(
                    "en-US",
                    {
                      timeZone:
                        localStorage.getItem("timezone") ?? "US/Central",
                    }
                  )
                ).toISOString()
              ).format("D MMM YYYY, h:mm A")}
            </td>
            <td
              className="adminName"
              style={{ color: "#1E293B", fontWeight: "500" }}>
              {moment(
                new Date(
                  new Date(user?.updatedAt ?? new Date()).toLocaleString(
                    "en-US",
                    {
                      timeZone:
                        localStorage.getItem("timezone") ?? "US/Central",
                    }
                  )
                ).toISOString()
              ).format("D MMM YYYY, h:mm A")}
            </td>
            <td>
              <div className="newClassUsers" style={{ marginLeft: "-7px" }}>
                <p
                  style={{
                    fontWeight: "400",
                    textAlign: "center",
                    fontSize: "13px",
                    paddingTop: "4px",
                    color: "#0CD600",
                  }}>
                  Active
                </p>
              </div>
            </td>
            <td></td>
            <td></td>
            <td className="text-center">
              <button
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "#FFA382",
                }}
                className="btn btn-primary "
                onClick={() => {
                  localStorage.setItem("singleUser", JSON.stringify(user));
                  history.push(`/typed-user-detail?userID=${user.userID}`);
                }}>
                <i className="fas fa-eye"></i>
                <u>View Account</u>
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const MemoizedTable = memo(UserTable);
export default function UsersIndexV2() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [error, setError] = useState(null);

  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationSettingsDetails(admin[0].outletChainID));
  }, []);
  /**
   *
   * @param {string} value actual value
   */
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };

  // const [previousSearchTerm, setPreviousSearchTerm] = useState("");

  // const performSearch = debounce(() => {
  //   if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
  //     setPreviousSearchTerm(searchTerm);
  //     handleChange("search", searchTerm, true);
  //   } else if (searchTerm === "") {
  //     setPreviousSearchTerm("");
  //     handleChange("search", "", true);
  //   }
  // }, 1500);
  // useEffect(
  //   () => {
  //     if (parsed && !loading) {
  //       performSearch();
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [searchTerm]
  // );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllUsers({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/typed-users${queryString}`);
    setQueryData({ ...query });
  };

  const GetAllUsers = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/users/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (!res.data?.data?.users?.length && searchTerm) {
            setError("Users not found");
          }
          if (!prevSearchTerm.trim().length && !res.data?.data?.users?.length) {
            setNoRecord(true);
          }
          setUsers(res.data?.data?.users);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card" id="users">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ borderRadius: "13px" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}>
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-3 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}>
                        Users
                      </div>
                    </div>
                    <div className="col-md-4 filters-bar">
                      <div className="dropdown"></div>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink">
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(e) => {
                            handleSearchTermChange(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                          value={searchTerm}
                          style={{ borderRight: "none" }}
                          className="form-control"
                          placeholder="Search"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                        />
                        {searchTerm?.length ? (
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              onClick={() => handleSearchTermChange("")}
                              style={{
                                backgroundColor: "white",
                                borderLeft: "none",
                                paddingLeft: 0,
                                cursor: "pointer",
                              }}>
                              <i className="fa fa-times"></i>
                            </div>
                          </div>
                        ) : null}
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => performSearchOnEnter()}>
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>

                      <img src={sort} />

                    </div> */}
                  </div>
                </div>
                <div className="col-md-12">
                  {users.length === 0 ? null : ( // </div> //   No data found //   className="row"> //   }} //     padding: "1em", //     justifyContent: "center", //     alignItems: "center", //   style={{ // <div
                    <table className="table table-striped-cust">
                      <thead style={{ borderRadius: "10px" }}>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Email</th>
                          <th scope="col">Account Created On</th>
                          <th scope="col">Last Updated</th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Status
                          </th>
                          <th></th>
                          <th></th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            {" "}
                            Action
                          </th>
                        </tr>
                      </thead>
                      <MemoizedTable users={users} />
                    </table>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {/* <-----Pagination Here------> */}
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}>
            <Pagination
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>

        {/* <-----Pagination Here------> */}
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="User(s) not found"
          onClose={() => {
            setError(null);
            setSearchTerm("");
          }}
        />
      ) : null}

      {/* <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>User(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              handleSearchTermChange();
            }}
          >
            OK
          </button>
        </div>
      </Popup> */}
    </div>
  );
}
