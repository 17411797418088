import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DoubleColumn, MultipleFlexibleColumns } from "../../Style.style";
import BasicSelectorChip from "../../atoms/Chips/BasicSelectorChips";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import InputPrimary from "../../atoms/InputPrimary";
import Section from "../../atoms/Section";
import FormGroup from "@mui/material/FormGroup";
import SelectPrimary from "../../atoms/SelectPrimary";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import Checkbox from "@mui/material/Checkbox";
import {
  TypedDealsDaySelectors,
  TypedDealsTypesSelectors,
} from "../../pages/TypedDeals/constants/typed-deals-selectors.constants";
import { TypedDealsTypes } from "../../pages/TypedDeals/constants/typed-deals.constants";
import {
  changeSinglePropertyAction,
  selectAddDealState,
  selectValidationErrors,
} from "../../redux/slices/addDealSlice";
import BogoDealFormComponent from "./bogo-deal-form";
import RegularDealFormComponent from "./regular-deal-form";
import TieredDealFormComponent from "./tiered-deal-form";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import DateTimePicker from "../../atoms/DatePicker/DateTimePicker";
import { addDays } from "date-fns";
import AddNotificationSubModule from "../NotificationSubModule/NotificationSubModule";
import getCroppedImg from "../../helpers/cropImage";
// import BogoBundledDealFormComponent from "./bogo-bundled-deal-form";
import BogoBundledDealFormComponent from "./bogo-bundled-deal-form-new";
import MouseOverPopover from "../../atoms/Popover/Popover";
import { popOverDeals } from "../../pages/Loyalty/LoyaltyRules/constants/purchased-rules-constants";
import TieredBundledDealFormComponent from "./tiered-bundled-deal-form";

const AddTypedDealForm = () => {
  const dispatch = useDispatch();
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const state = useSelector(selectAddDealState);
  let choices = { ...TypedDealsTypesSelectors };
  const getDealsChoices = () => {
    // const allowedConsumerIDsForBogoBundled = [
    //   "19",
    //   "5",
    //   "17",
    //   "15",
    //   "8",
    //   "20",
    //   "21",
    //   "22",
    //   "23",
    //   "24",
    //   "25",
    //   "11",
    //   "26",
    //   "27",
    //   "28",
    //   "29",
    //   "18",
    //   "30",
    // ];
    // const allowedConsumerIDsForTieredBundled = [
    //   "19",
    //   "5",
    //   "17",
    //   "15",
    //   "8",
    //   "20",
    //   "21",
    //   "22",
    //   "23",
    //   "24",
    //   "25",
    //   "11",
    //   "26",
    //   "27",
    //   "28",
    //   "29",
    //   "18",
    //   "30",
    // ];
    // try {
    //   const currentConsumerID =
    //     JSON.parse(localStorage.getItem("Admin"))[0]?.consumerID + "";
    //   if (!allowedConsumerIDsForBogoBundled.includes(currentConsumerID)) {
    //     delete choices["BOGO_BUNDLED"];
    //   }
    //   if (!allowedConsumerIDsForTieredBundled.includes(currentConsumerID)) {
    //     delete choices["TIERED_BUNDLED"];
    //   }
    // } catch (e) {}
    return choices;
  };
  const {
    name,
    dealType,
    applicableDays,
    startsOn,
    endsOn,
    totalUsageLimit,
    usageLimitPerCustomer,
    neverExpires,
    status,
    applyToAllOutlets,
    canBeOverridden,
  } = state;
  const formValidationErrors = useSelector(selectValidationErrors);

  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      // e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("croppedImage", croppedImage);
        setCroppedImage(croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
        handleChange("imageFile", {
          name: fileName,
          source: croppedImage,
        });
      } catch (e) {
        console.error(e);
      }
    },
    //eslint-disable-next-line
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    let numFile = Math.floor(Math.random() * 10) + 1;
    setFileName(event.name + numFile);
    if (event) {
      setEditImage(URL.createObjectURL(event));
      setFile(event);
      setOpen(true);
      setShowCrop(true);
    }
  };
  return (
    <Fragment>
      <div className="col-md-12" style={{ paddingTop: "5px" }}>
        <div
          style={{
            color: "#FFA382",
            fontSize: "17px",
            marginTop: "20px",
            marginBottom: "20px",
            paddingLeft: "5px",
            fontWeight: "bold",
          }}>
          Deal Details
        </div>
      </div>
      <div className="col-md-12 mb-4">
        <InputPrimary
          value={name}
          name="name"
          label={"Deal Name*"}
          placeholder="Deal Name"
          onChange={(e) => handleChange(e.target.name, String(e.target.value))}
        />
        <span className="validation-help">
          {formValidationErrors?.name ? formValidationErrors.name : ""}
        </span>
      </div>

      <div className="col-md-12 mb-4">
        <SelectPrimary
          value={dealType}
          name="dealType"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          label={"Deal Type"}>
          {Object.values(getDealsChoices()).map((item, index) => (
            <option value={item.value} key={`selected-deal-type-${index}`}>
              {item.displayName}
              &nbsp;
              {item.shortDetails?.length ? item.shortDetails : ""}
            </option>
          ))}
        </SelectPrimary>
      </div>

      <div className="col-md-12 px-3 mb-4">
        <div
          className="fluid d-flex justify-content-center rounded py-4"
          style={{ backgroundColor: "#f9fbff" }}>
          <FileUpload
            onFileRemoved={async () => {
              handleChange("images", []);
              handleChange("imageFile", null);
            }}
            // onFileLoaded={() => {
            // }}
            label={"Upload Deal Image"}
            size={"150 x 140 px"}
            ImageHeight={"140"}
            ImageWidth={"150"}
            imageSelector={imageSelector}
            cropProperties={{
              cropDialogOpen: open,
              setCropDialogOpen: setOpen,
              showCrop,
              showCroppedImage,
              onCropComplete,
              editImage,
              ImageHeight: 140,
              ImageWidth: 150,
            }}
            editImage={editImage}
          />
        </div>
      </div>
      <div className="col-md-12 mb-4">
        <p
          style={{
            color: "red",
          }}>
          Please upload a JPG file with dimensions of 150 x 140 px pixels and a
          file size not exceeding 1 MB.
        </p>
      </div>
      {isMultiChainAdmin ? (
        <div className="col-md-12">
          <Section label="Access Control">
            <Fragment>
              <div
                className="position-absolute"
                style={{
                  marginTop: "-2px",
                  left: "9em",
                }}>
                <MouseOverPopover text={popOverDeals?.content} subText="" />
              </div>
              <FormGroup className="mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applyToAllOutlets"
                      checked={applyToAllOutlets}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Apply to all outlets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="canBeOverridden"
                      checked={canBeOverridden}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  }
                  label="Sole chain admins can override changes"
                />
              </FormGroup>
            </Fragment>
          </Section>
        </div>
      ) : null}
      <div className="col-md-12">
        {dealType === TypedDealsTypes.BOGO_BUNDLED ? (
          <BogoBundledDealFormComponent applyToAllOutlets={applyToAllOutlets} />
        ) : null}
        {dealType === TypedDealsTypes.BOGO ? (
          <BogoDealFormComponent applyToAllOutlets={applyToAllOutlets} />
        ) : null}
        {dealType === TypedDealsTypes.TIERED ? (
          <TieredDealFormComponent applyToAllOutlets={applyToAllOutlets} />
        ) : null}
        {dealType === TypedDealsTypes.REGULAR ? (
          <RegularDealFormComponent applyToAllOutlets={applyToAllOutlets} />
        ) : null}
        {dealType === TypedDealsTypes.TIERED_BUNDLED ? (
          <TieredBundledDealFormComponent
            applyToAllOutlets={applyToAllOutlets}
          />
        ) : null}
      </div>
      <div className="col-md-12">
        <Section label="Validity">
          <FormControl>
            <RadioGroup
              row
              value={String(+neverExpires)}
              onChange={(e) => {
                handleChange(
                  e.target.name,
                  +e.target.value === 1 ? true : false
                );
                handleChange("startsOn", new Date());
                handleChange("endsOn", addDays(new Date(), 7));
              }}
              aria-labelledby="neverExpiresLabel"
              name="neverExpires">
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                label="Never Expires"
              />
              <FormControlLabel
                value={"0"}
                control={<Radio />}
                label="Specific Dates"
              />
            </RadioGroup>
          </FormControl>
          <DoubleColumn gap="20px">
            <div>
              <DateTimePicker
                label="Starts On"
                value={startsOn}
                onChange={(value) => handleChange("startsOn", value)}
                minDate={new Date()}
              />
              <span className="validation-help">
                {formValidationErrors?.startsOn
                  ? formValidationErrors.startsOn
                  : ""}
              </span>
            </div>
            {/* If never expires checked, expire date picker won't show */}
            {!neverExpires && (
              <div>
                <DateTimePicker
                  label="Expires On"
                  type="date"
                  value={endsOn}
                  onChange={(value) => handleChange("endsOn", value)}
                />
                <span className="validation-help">
                  {formValidationErrors?.endsOn
                    ? formValidationErrors.endsOn
                    : ""}
                </span>
              </div>
            )}
          </DoubleColumn>
          <DividerLine />
          <DaysWrapper>
            <label>Select Days</label>
            <div>
              {Object.values(TypedDealsDaySelectors).map((item, index) => (
                <BasicSelectorChip
                  key={`selected-days-${index}`}
                  selected={!!applicableDays[item.value]}
                  disabled={false}
                  label={item.displayName}
                  onChange={() => {
                    handleChange("applicableDays", {
                      ...applicableDays,
                      [item.value]: !applicableDays[item.value],
                    });
                  }}
                />
              ))}
            </div>
          </DaysWrapper>
        </Section>
      </div>
      <div className="col-md-12">
        <Section label="Rules">
          <MultipleFlexibleColumns width="350px" gap="20px">
            <div>
              <InputPrimary
                label="Total Usage Limit"
                type="number"
                min="0"
                name="totalUsageLimit"
                value={totalUsageLimit}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <span className="validation-help">
                {formValidationErrors?.totalUsageLimit
                  ? formValidationErrors.totalUsageLimit
                  : ""}
              </span>
            </div>
            <div>
              <InputPrimary
                label="Limit Uses per Customer"
                type="number"
                name="usageLimitPerCustomer"
                value={usageLimitPerCustomer}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <span className="validation-help">
                {formValidationErrors?.usageLimitPerCustomer
                  ? formValidationErrors.usageLimitPerCustomer
                  : ""}
              </span>
            </div>
          </MultipleFlexibleColumns>
        </Section>
      </div>
      <AddNotificationSubModule />
      <div className="col-md-12">
        <Section label="Status">
          <div style={{ transform: "translateX(-15px)" }}>
            <ToggleSwitch
              status={status}
              onChange={() => handleChange("status", !status)}
            />
          </div>
        </Section>
      </div>
    </Fragment>
  );
};

export default AddTypedDealForm;
const DaysWrapper = styled.div`
  width: 100%;
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  div {
    display: flex;
    width: 100%;
    overflow: auto;
    flex-direction: row;
    gap: 10px;
  }
`;
const DividerLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-top: 12px;
  margin-bottom: 16px;
`;
