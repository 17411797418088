import { useState } from "react";
import SimpleDialogWithErrorAndLoader from "../../atoms/Dialogs/SimpleDialogWithErrorAndLoader";
import url from "../../config/axios";

const DeleteNotificationDialog = ({
  notificationInfo: { _id },
  onCancel,
  onDone,
}) => {
  const initialFormState = {
    inProgress: false,
    error: null,
  };
  const [formState, setFormState] = useState(initialFormState);
  const deleteNotification = async () => {
    setFormState({ ...initialFormState, inProgress: true });
    url
      .delete(
        `/v1/consumer-notifications-admin/delete-admin-notification?_id=${_id}`
      )
      .then(() => {
        if (onDone instanceof Function) {
          onDone();
        }
      })
      .catch((e) => {
        // if(e.response?.data?.data?.)
        setFormState({
          ...initialFormState,
          loading: false,
          error:
            e.response?.data?.data?.message ??
            "Unable to remove the notification.",
        });
      });
  };
  return (
    <SimpleDialogWithErrorAndLoader
      open={true}
      onDisagree={() => {
        if (!formState.inProgress && onCancel instanceof Function) {
          onCancel();
        }
      }}
      onAgree={() => {
        if (!formState.inProgress && onDone instanceof Function) {
          //   onDone();
          deleteNotification();
        }
      }}
      error={formState.error}
      loading={formState.inProgress}
      _agreeButtonText={"Yes"}
      _disagreeButtonText={"No"}
      _loadingButtonText="Deleting..."
      heading={`Are you sure want to delete the notification?`}
    ></SimpleDialogWithErrorAndLoader>
  );
};
export default DeleteNotificationDialog;
