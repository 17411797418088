import * as React from "react";
import Button from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styledEngine from "@mui/styled-engine";
import { Fragment } from "react";
import { Alert } from "@mui/lab";

export default function SimpleDialogWithErrorAndLoader({
  open,
  setOpen,
  heading,
  message,
  onAgree,
  _agreeButtonText,
  onDisagree,
  _disagreeButtonText,
  loading,
  _loadingButtonText,
  error,
}) {
  const handleClose = () => {
    if (!loading) {
      if (setOpen instanceof Function) {
        setOpen(false);
      } else if (onDisagree instanceof Function) {
        onDisagree();
      }
    }
  };
  const Dialog = styledEngine(MuiDialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      minWidth: "400px",
    },
  }));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {error && (
          <Alert severity="error">
            {error?.length ? error : "Something went wrong"}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {!!loading ? (
          <Button
            disabled
            size="large"
            variant="contained"
            loadingPosition="start">
            {_loadingButtonText ?? "In progress..."}
          </Button>
        ) : (
          <Fragment>
            {onDisagree && (
              <Button onClick={onDisagree}>
                {_disagreeButtonText ?? "Disagree"}
              </Button>
            )}
            {onAgree && (
              <Button onClick={onAgree} variant="contained">
                {_agreeButtonText ?? "Agree"}
              </Button>
            )}
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}
