import React from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

export default function DealsTable({
  deals,
  setDealOpen,
  setDealData,
  setDealToDelete,
  currentOutletChainID,
}) {
  const history = useHistory();
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Expires on</th>
            <th scope="col">Type</th>
            <th
              scope="col"
              style={{
                textAlign: "center",
              }}>
              Status
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {deals.map((item, i) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid rgb(241, 245, 249)",
                }}
                key={i}>
                <td style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="View Deal">
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}>
                      <img
                        // I have added here
                        img
                        onClick={() => {
                          setDealOpen(true);
                          setDealData(item);
                        }}
                        alt="yo1"
                        src={
                          item?.images[0] ??
                          `https://static.vecteezy.com/system/resources/previews/006/609/926/original/discount-offer-icon-hand-holding-percentage-vector.jpg`
                        }
                        className="img-fluid"
                        width="40"
                        style={{
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Tooltip>
                </td>
                <td>
                  <div>{item.name}</div>
                </td>
                <td>
                  <div>
                    {item.outletBasisConfig[currentOutletChainID]?.neverExpires
                      ? "Never Expires"
                      : moment(
                          item.outletBasisConfig[currentOutletChainID]?.endsOn
                        ).format("D MMM YYYY, h:mm A")}
                  </div>
                </td>
                <td>
                  <div>{item.dealType}</div>
                </td>
                <td
                  className={
                    item.outletBasisConfig[currentOutletChainID]?.status
                      ? "color-blue"
                      : "color-red"
                  }>
                  <span>
                    {item.outletBasisConfig[currentOutletChainID]?.status ? (
                      <div
                        className="newClass"
                        id="deals"
                        style={{
                          backgroundColor: `rgba(12, 214, 0, 0.1)`,
                        }}>
                        <p
                          style={{
                            fontWeight: "400",
                            textAlign: "center",
                            fontSize: "13px",
                            paddingTop: "2px",
                          }}>
                          <span style={{ color: "#0CD600" }}>Enabled</span>
                        </p>
                      </div>
                    ) : (
                      <div
                        className="newClass"
                        id="deals"
                        style={{
                          backgroundColor: `rgba(255, 109, 109, 0.08)`,
                        }}>
                        <p
                          style={{
                            fontWeight: "400",
                            textAlign: "center",
                            fontSize: "13px",
                            paddingTop: "2px",
                          }}>
                          <span style={{ color: "red" }}>Disabled</span>
                        </p>
                      </div>
                    )}{" "}
                  </span>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1em",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <i
                      onClick={() => {
                        history.push(
                          `/edit-typed-deal?_id=${
                            item._id
                          }&tab=${"rules-and-expiry"}`
                        );
                      }}
                      style={{
                        color: "#FFA382",
                        cursor: "pointer",
                      }}
                      className="fas fa-pen"></i>
                    <i
                      onClick={() => setDealToDelete(item)}
                      style={{ color: "red", cursor: "pointer" }}
                      className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
