import { useState, useRef, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import { fileUpload } from "../../../helpers/firebaseFileUpload";
import calculateAspectRatio from "calculate-aspect-ratio";
import styled from "@emotion/styled";
import { Box, Button, Grid } from "@mui/material";
import SimpleModal from "../../../atoms/Dialogs/SimpleModal";
import NoCropFileUpload from "../../../atoms/FileUpload/NoCropUpload";
import CustomSwitch from "../../../atoms/Switch/CustomSwitch";
import CustomInputPrimary from "../../../atoms/CustomInputprimary/CustomInputPrimary";
import InputPrimary from "../../../atoms/InputPrimary";
import InfoIcon from "@mui/icons-material/Info";

const initialFormState = {
  mainLogoFile: null,
  mainLogoFileName: null,
  mainLogoFileSource: null,
  footerLogoFile: null,
  footerLogoFileName: null,
  footerLogoFileSource: null,
  faviconLogoFile: null,
  faviconLogoFileName: null,
  faviconLogoFileSource: null,
};

function PricingPreference({ invokeLogout, setActionButtonFunctions }) {
  const [formData, setFormData] = useState({
    ...initialFormState,
  });
  const [errors, setErrors] = useState({
    faviconLogoError: null,
    mainLogoError: null,
    footerLogoError: null,
  });
  const allowedRatios = ["1:1"];
  const [success, setSuccess] = useState(null);
  const [settingsData, setSettingsData] = useState({
    roundFinalPricing: false,
    shouldOnlyFloor: false,
    centAmountThreshold: 0,
  });

  const [error, setError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  /**
   *
   * @param {event} event Actual HTML file event
   * @param {string} target if target = "tom" => set"tom"FileName(filename) and set"tom"File(buffer) and set"tom"FileSource(sourceurl)
   */
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(
        `/v1/general-settings/pricing-preferences?outletChainID=${outletChainID}`
      )
      .then((res) => {
        const data = res.data.data;
        setSettingsData({
          roundFinalPricing: data.roundFinalPricing,
          shouldOnlyFloor: data.shouldOnlyFloor,
          centAmountThreshold: data.centAmountThreshold,
        });
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
      });
  }, []);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const body = {
      ...settingsData,
    };
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;

    try {
      await url.patch(
        `/v1/general-settings/pricing-preferences?outletChainID=${outletChainID}`,
        body
      );
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
    setSubmitLoading(false);
  };
  return (
    <div
      style={{
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
      }}
    >
      <Box>
        <CustomSwitch
          variant="ios"
          label="Round to the nearest whole number if is under/over total cents respectively"
          value={settingsData.roundFinalPricing}
          onChange={(value) => {
            setSettingsData({ ...settingsData, roundFinalPricing: value });
          }}
        />
      </Box>
      {settingsData.roundFinalPricing && (
        <Box>
          <CustomSwitch
            variant="ios"
            label="Do you want your final pricing only be floored?"
            value={settingsData.shouldOnlyFloor}
            onChange={(value) => {
              setSettingsData({ ...settingsData, shouldOnlyFloor: value });
            }}
          />
        </Box>
      )}

      {settingsData.roundFinalPricing && !settingsData.shouldOnlyFloor && (
        <Box style={{ marginBottom: 20 }}>
          <Hint>
            <InfoIcon /> Threshold amount of flooring allows you to define a
            limit of cents to floor your final pricing. For example, if your
            threshold flooring amount is 30 then if your final pricing is $23.30
            r $23.24, it will be floored down to $23, but if the decimal point
            cent is above 30 like $23.40, it will be ceiled to $24.
          </Hint>
          <InputPrimary
            label="Threshold Amount of Flooring"
            placeholder="Enter threshold amount"
            type="number"
            style={{ maxWidth: 400 }}
            value={settingsData.centAmountThreshold}
            onChange={(e) => {
              setSettingsData({
                ...settingsData,
                centAmountThreshold: e.target.value,
              });
            }}
          />
        </Box>
      )}
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={submitLoading}
        >
          {submitLoading ? 'Updating...' : 'Update'} 
        </Button>
      </Grid>

      {/* <ConfirmationPopup data={confirmationPopupState} /> */}

      {success ? (
        <SimpleModal
          severity={"success"}
          highlights={"Media updated successfully"}
          onClose={() => {
            setSuccess(null);
          }}
        />
      ) : null}

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
    </div>
  );
}

const Hint = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${(props) => props.theme.palette.secondary.main}28;
  padding: 8px 12px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  color: 1px solid ${(props) => props.theme.palette.secondary.main};
`;
export default PricingPreference;
