import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import React from "react";

function ActionButton({ icon, label, color, ...props}) {
    const theme = useTheme();
  return (
    <ActionButtonStyled theme={theme} color={color} {...props} >
      {icon}
      <span>{label}</span>
    </ActionButtonStyled>
  );
}

const ActionButtonStyled = styled.button`
  border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 8px;
    padding: 5px 10px;
    svg {
      font-size: 16px;
    }
    span {
      max-width: 0px;
      overflow: hidden;
      transition: all 0.3s ease-out;
    }
    transition: all 0.3s ease-in;
    color: white;
    cursor: pointer;
    background-color: ${(props) => props.color ?? props.theme.palette.secondary.main};
    &:hover {
      /* gap: 8px; */
      span {
        margin-left: 8px;
        max-width: 50px;
      }
    }
`;

export default ActionButton;
