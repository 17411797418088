import FormControlLabel from "@mui/material/FormControlLabel";
import { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputPrimary from "../../atoms/InputPrimary";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import {
  changeSinglePropertyAction,
  selectAddNotificationState,
  selectValidationErrors,
} from "../../redux/slices/addNotificationSlice";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import { useState } from "react";
import getCroppedImg from "../../helpers/cropImage";

const AddNotificationSubModule = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectAddNotificationState);
  const [notificationStatus, setNotificationStatus] = useState(false);
  const { title, highlights } = state;
  const formValidationErrors = useSelector(selectValidationErrors);
  const handleChange = (propName, propValue) => {
    if (propName?.length) {
      dispatch(changeSinglePropertyAction({ propName, propValue }));
    }
  };

  // empty the form on unmount
  useEffect(() => {
    return () => {
      dispatch(
        changeSinglePropertyAction({ propName: "title", propValue: "" })
      );
      dispatch(
        changeSinglePropertyAction({ propName: "highlights", propValue: "" })
      );
      dispatch(
        changeSinglePropertyAction({ propName: "imageUrl", propValue: "" })
      );
      dispatch(
        changeSinglePropertyAction({ propName: "imageFile", propValue: null })
      );
    };
  }, [dispatch]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      // e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("croppedImage", croppedImage);
        setCroppedImage(croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
        handleChange("imageFile", {
          name: fileName,
          source: croppedImage,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    let numFile = Math.floor(Math.random() * 10) + 1;
    setFileName(event.name + numFile);
    if (event) {
      setEditImage(URL.createObjectURL(event));
      setFile(event);
      setOpen(true);
      setShowCrop(true);
    }
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="col-md-12 mb-4">
          <Fragment>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="notifications"
                    checked={notificationStatus}
                    onChange={(e) => setNotificationStatus(!notificationStatus)}
                  />
                }
                label="Do you want to send notifications?"
              />
            </FormGroup>
          </Fragment>
        </div>
      </div>
      {notificationStatus && (
        <>
          <div className="col-md-4 px-3 mb-4">
            <div
              className="fluid d-flex justify-content-center rounded py-4"
              style={{ backgroundColor: "#f9fbff" }}
            >
              <FileUpload
                onFileRemoved={async () => {
                  handleChange("images", []);
                  handleChange("imageFile", null);
                }}
                // onFileLoaded={() => {
                // }}
                label={"Upload Deal Image"}
                size={"500 x 500 px"}
                ImageHeight={"500"}
                ImageWidth={"500"}
                imageSelector={imageSelector}
                cropProperties={{
                  cropDialogOpen: open,
                  setCropDialogOpen: setOpen,
                  showCrop,
                  showCroppedImage,
                  onCropComplete,
                  editImage,
                  ImageHeight: 500,
                  ImageWidth: 500,
                }}
                editImage={editImage}
              />
            </div>
          </div>
          <div className="col-md-8 mb-4">
            <InputPrimary
              value={title}
              name="title"
              label={"Highlights*"}
              placeholder="Title"
              onChange={(e) =>
                handleChange(e.target.name, String(e.target.value))
              }
            />
            <span className="validation-help">
              {formValidationErrors?.title ? formValidationErrors.title : ""}
            </span>

            <div className="mt-3">
              <InputPrimary
                value={highlights}
                name="highlights"
                label={"Description*"}
                placeholder="Write Here..."
                onChange={(e) =>
                  handleChange(e.target.name, String(e.target.value))
                }
                height="9em"
              />
              <span className="validation-help">
                {formValidationErrors?.highlights
                  ? formValidationErrors.highlights
                  : ""}
              </span>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AddNotificationSubModule;
