import React from 'react'
import { Skeleton } from '@mui/material'

function FormSkeletalLoader() {
  return (
    <div className='row'>
        <div className='col-md-6'>
            <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1, width: 150 }} />
            <Skeleton variant="rectangular" sx={{ width: '100%' }} height={40} />
        </div>
        <div className='col-md-6'>
            <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1, width: 170 }} />
            <Skeleton variant="rectangular" sx={{ width: '100%' }} height={40} />
        </div>
        <div className='col-md-12 mt-3'>
            <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1, width: 130 }} />
            <Skeleton variant="rectangular" sx={{ width: '100%' }} height={170} />
        </div>
    </div>
  )
}

export default FormSkeletalLoader