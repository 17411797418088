import { Cached, Telegram } from "@mui/icons-material";
import { Box, IconButton as MuiIconButton, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled as muiStyled, useTheme } from "@mui/material/styles";
import {
  selectCurrentSendBoxContent,
  selectSendBoxMeta,
  setMessageAction,
  sendMessageThunk,
} from "../../redux/slices/sendBoxSlice";
import { selectCurrentSessionInfo } from "../../redux/slices/chatSessionSlice";
const IconButton = muiStyled(MuiIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "& svg": {
    color: "white"
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.hover
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5
  }
}))
const SendMessageComponent = () => {
  const theme = useTheme()
  const { _id: sessionID, isActive } = useSelector(selectCurrentSessionInfo);
  const content = useSelector(selectCurrentSendBoxContent(sessionID)) ?? "";
  const dispatch = useDispatch();
  //to do: error handling
  const { loading } = useSelector(selectSendBoxMeta);
  return (
    <div
      style={{
        display: "flex",
        height: "auto",
        padding: "1em",
      }}>
      <Box
        component={Paper}
        elevation={0}
        sx={{
          width: "100%",
          padding: "0.5em",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
          backgroundColor: theme.palette.secondary.lightBlue
        }}
        borderRadius={2}>
        <IconButton
          color="primary"
          onClick={() => dispatch(sendMessageThunk())}
          disabled={(content + "").trim().length === 0 || loading}>
          {!loading ? <Telegram sx={{ color: "#ffa382" }} /> : <Cached />}
        </IconButton>
        <input
          placeholder="Type your message..."
          disabled={loading || !isActive}
          style={{
            outline: "none",
            flexGrow: 1,
            border: "none",
            backgroundColor: "transparent"
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              dispatch(sendMessageThunk());
            }
          }}
          value={content ?? ""}
          onChange={(e) => {
            dispatch(
              setMessageAction({
                sessionID,
                content: e.target.value + "",
              })
            );
          }}></input>
      </Box>
    </div>
  );
};

export default SendMessageComponent;
